import React, { useEffect, useState, Fragment } from 'react'
import { Layout, Row, Col, Typography, Spin } from 'antd'
import { NavLink, useParams, useHistory } from 'react-router-dom'
import cx from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Box from 'components/ui/Box/Box'
import Button from 'components/ui/Button/Button'
import { useCallback } from '../../hooks'
import WidthContainer from '../../components/layout/WidthContainer/WidthContainer'
import ProductsCarousel from '../../compositions/ProductsCarousel/ProductsCarousel'
import AddressModal from '../../compositions/AddressModal/AddressModal'

import { isMobileLayout, isTabletLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'
import getTotalOrderPrice from '../../helpers/getTotalOrderPrice'
import links from '../../helpers/links'
import {
  clearModifications,
  setCatalogProducts,
  setProducts,
  setProductsByLabel,
} from '../../redux/actions'
import BasketButton from '../../components/ui/BasketButton/BasketButton'

import s from './Product.module.less'


const { Text } = Typography
const { Content } = Layout

const ProductComponent = ({ viewport, labels, sections, productId, mealId, basketItems, totalOrderPrice, user, comboMealComponents }) => {

  const [ data, setData ] = useState(null)
  const [section, setSection] = useState(null)
  const [parentSection, setParentSection] = useState(null)

  const params = useParams()
  const history = useHistory()

  const [ isModalVisible, setModalVisible ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(null)

  const isMobile = isMobileLayout(viewport)

  const setAddedMeal = useCallback(() => {
    if (comboMealComponents && comboMealComponents.length > 0) {
      const indexOfMealInArray = comboMealComponents.findIndex((it) => it.mealId === params.product_alias)
      if (indexOfMealInArray > -1) {
        return true
      }
    }
  }, [params])

  useEffect(() => {
    let sec = sections.find((section) => section.alias === params.section_alias)

    if (!sec) {
      sec = labels.find((label) => label.alias === params.section_alias)
    }

    if (!section || (sec.section_guid !== section.section_guid)) {
      setIsLoading(true)

      if (sec) {
        setSection(sec)

        if (sec.section_guid) {
          setProducts(sec.section_guid)
            .then((data) => setData(data))
            .finally(() => setIsLoading(false))
        }
        else {
          setProductsByLabel(sec.label_guid)
            .then((data) => setData(data))
            .finally(() => setIsLoading(false))
        }
      }
    }

    return () => {
      clearModifications()
    }
  }, [ sections, params ])

  useEffect(() => {
    const currentParentSection = sections?.find((el) => el.section_guid === section?.parent_guid)
    setParentSection(currentParentSection)
  }, [section])

  const alias = parentSection ? parentSection?.alias : params?.section_alias

  const Loader = (
    <div className={s.loader}>
      <Spin spinning={isLoading} />
    </div>
  )

  if (!data) {
    return Loader
  }

  const totalItems = basketItems.reduce((r, a) => r + a.count, 0)

  return (
    <WidthContainer isNarrow>
      <Layout>
        <Content>
          {
            isLoading ? Loader : (
              <>
                <Box mt="md">
                  <Row className={s.productNameContainer}>
                    {
                      isMobile && (
                        <Box mb="md" className={s.topMobileLeft}>
                          <Button type="icon" icon="down" isSmall handleClick={() => history.push(`${links.catalog}/${alias}`)} />
                        </Box>
                      )
                    }
                    <Text>
                      {section.title}
                    </Text>
                    {
                      !isMobile && (
                        <NavLink to={`${links.catalog}/${alias}`}>
                          <Button type="icon" icon="close" circle />
                        </NavLink>
                      )
                    }
                    {
                      isMobile && (
                        <Box mb="md" className={s.topMobileRight}>
                          <NavLink to={links.basket}>
                            <Button type="icon" icon={totalItems > 0 ? 'shoppingToteWhite' : 'shoppingTote'} isSmall primary={totalItems > 0} />
                            {
                              totalItems && (
                                <div className={s.basketItemsCount}>
                                  <Text className={s.basketItemsCountText}>{totalItems}</Text>
                                </div>
                              )
                            }
                          </NavLink>
                        </Box>
                      )
                    }
                  </Row>
                </Box>
                <ProductsCarousel
                  id={mealId || productId}
                  data={data}
                  setModalVisible={setModalVisible}
                  product_alias={params.product_alias}
                  section_alias={params.section_alias}
                  setAddedMeal={setAddedMeal()}
                />
                <AddressModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} />

                <BasketButton />
              </>
            )
          }
        </Content>
      </Layout>
    </WidthContainer>
  )
}

const Product = compose(
  withViewport,
)(ProductComponent)

export default connect((state) => ({
  sections: state.app.sections,
  labels: state.app.labels,
  basketItems: state.app.basketItems,
  comboMealComponents: state.app.comboMealComponents,
  user: state.app.user,
  totalOrderPrice: getTotalOrderPrice(state.app.basketItems),
}))(Product)
