export const statuses = [
  {
    color: '#E9B570',
    status: 'NEW',
    text: 'Принят',
  },
  {
    color: '#E9B570',
    status: 'PAYMENT_SUCCESS',
    text: 'Принят',
  },
  {
    color: '#E9B570',
    status: 'ACCEPTED',
    text: 'Принят',
  },
  {
    color: '#E9B570',
    status: 'UNCONFIRMED',
    text: 'Принят',
  },
  {
    color: '#E9B570',
    status: 'AWAITING_PAYMENT',
    text: 'Ожидает оплаты',
  },
  {
    color: '#464646',
    status: 'NOT_CREATED',
    text: 'Ошибка создания заказа',
  },
  {
    color: '#E9B570',
    status: 'COOKING',
    text: 'Готовим',
  },
  {
    color: '#DD9059',
    status: 'WAITING',
    text: 'Готовим',
  },
  {
    color: '#92B52F',
    status: 'ON_WAY',
    text: 'В пути',
  },
  {
    color: '#2B5E51',
    status: 'CLOSED',
    text: 'Доставлен',
  },
  {
    color: '#2B5E51',
    status: 'DELIVERED',
    text: 'Доставлен',
  },
  {
    color: '#A8A8A8',
    status: 'CANCELLED',
    text: 'Отменён',
  },
  {
    color: '#464646',
    status: 'NOT_CREATED',
    text: 'Ошибка создания заказа',
  },
  {
    color: '#464646',
    status: '',
    text: 'Ошибка',
  },
]
