exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2Fu3ofbn7y0GwdtNl-zQKH {\n  background: #F7F9F8;\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n._2pXz3iQBrYg5tATn5Z_h6m {\n  height: 43px;\n  width: fit-content;\n  flex-direction: row;\n  padding: 11px 12px 11px 12px;\n}\n._2iltk1Pbdgk7gq5WTYDz57 {\n  height: 80px;\n  width: 90px;\n}\n._2A6CCjwlT6JvHqklbCIVcp {\n  width: 120px;\n  height: 120px;\n  padding: 20px;\n}\n._385WyqS45r29nclon8lzzz {\n  font-family: 'Circe-Regular', serif;\n}\n._2_M6ig7Hc0VORZeYhM011m {\n  margin-left: 10px;\n  font-size: 17px;\n}\n._3JCoK7U_RYhVrVeBzvr3wO {\n  margin-top: 0;\n}\n.zGjii_x5vObKtz2badMLj {\n  margin-top: 10px;\n  font-size: 17px;\n  line-height: 17px;\n  text-align: center;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "_2Fu3ofbn7y0GwdtNl-zQKH",
	"smallContainer": "_2pXz3iQBrYg5tATn5Z_h6m",
	"mediumContainer": "_2iltk1Pbdgk7gq5WTYDz57",
	"bigContainer": "_2A6CCjwlT6JvHqklbCIVcp",
	"text": "_385WyqS45r29nclon8lzzz",
	"textSmall": "_2_M6ig7Hc0VORZeYhM011m",
	"textMedium": "_3JCoK7U_RYhVrVeBzvr3wO",
	"textBig": "zGjii_x5vObKtz2badMLj"
};