import React from 'react'
import { Button as AntButton, Typography } from 'antd'
import cx from 'classnames'

import Icon from '../Icon'

import s from './Button.module.less'


const { Text } = Typography

const Button = (props) => {

  const {
    className,
    type, icon, circle, rightIcon,
    text,
    handleClick,
    primary, withBoldText, secondary, bright,
    isSmall, isMedium,
    withEstimatedTime, estimatedTimeGreen, rightText,
    withoutShadow,
    lightGreen,
    flex, disabled,
    ...rest
  } = props

  const classNames = cx(className, {
    [s.iconButton]: type === 'icon',
    [s.textButton]: type !== 'icon',
    [s.iconBtnSquared]: !circle,
    [s.primary]: primary,
    [s.secondary]: secondary,
    [s.bright]: bright,
    [s.lightGreen]: lightGreen,
    [s.mediumBright]: bright && isMedium,
    [s.isSmallBright]: bright && isSmall,
    [s.withBoldTextPrimary]: withBoldText && primary,
    [s.withBoldTextSecondary]: withBoldText && secondary,
    [s.isSmallIconBtn]: isSmall && type === 'icon',
    [s.isSmallTextBtn]: isSmall && type !== 'icon',
    [s.isMediumIconBtn]: isMedium && type === 'icon',
    [s.withoutShadow]: withoutShadow,
    [s.flex]: flex,
    [s.secondaryDisabled]: secondary && disabled,
  })

  return (
    <AntButton
      icon={icon && <Icon name={icon} className={cx(s.icon, type !== 'icon' && s.textType)} />}
      type={type}
      shape={circle && 'circle'}
      onClick={handleClick}
      className={classNames}
      disabled={disabled}
      {...rest}
    >
      {text}
      {
        withEstimatedTime && <Text className={cx(s.withEstimatedTime, estimatedTimeGreen && s.estimatedTimeGreen)}>≈ {withEstimatedTime}</Text>
      }
      {
        rightIcon && <span className={s.basketIcon}><Icon name={rightIcon} className={s.rightIcon} /></span>
      }
      {
        rightText && <Text className={s.rightText}>{rightText}</Text>
      }
    </AntButton>
  )
}

export default Button

