import React from 'react'
import Text from 'antd/lib/typography/Text'
import cx from 'classnames'
import s from './CategorySwiper.module.less'


const CategorySwiper = (props) => {

  const { isMedium, isSmall, icon, text, height, onPointerDown, onPointerUp } = props

  const wrapperClassNames = cx(s.container, {
    [s.smallContainer]: isSmall,
    [s.mediumContainer]: isMedium,
    [s.bigContainer]: !isSmall && !isMedium,
  })

  const textClassNames = cx(s.text, {
    [s.textSmall]: isSmall,
    [s.textMedium]: isMedium,
    [s.textBig]: !isSmall && !isMedium,
  })

  return (
    <div className={wrapperClassNames} onPointerDown={onPointerDown} onPointerUp={onPointerUp}>
      <img src={icon} style={{ height: `${height}px` }} />
      <Text style={{ textAlign: 'center' }} className={textClassNames}>{text}</Text>
    </div>
  )
}

export default CategorySwiper
