exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1hqWz72EBv_65piWmAB4D8 {\n  display: flex;\n  height: 100%;\n  flex-direction: column;\n  justify-content: center;\n}\n@media (max-width: 768px) {\n  ._1hqWz72EBv_65piWmAB4D8 {\n    justify-content: flex-start;\n  }\n}\n._3EgBGfrWueGevIdn9gasHa {\n  font-family: 'Circe-Regular', serif;\n  font-size: 20px;\n  line-height: 30px;\n  color: #3C3C3C;\n}\n@media (max-width: 414px) {\n  ._3EgBGfrWueGevIdn9gasHa {\n    font-size: 16px;\n    line-height: 18px;\n    margin-bottom: 8px;\n  }\n}\n@media (max-width: 768px) {\n  ._3EgBGfrWueGevIdn9gasHa {\n    font-size: 18px;\n    line-height: 20px;\n  }\n}\n._1m5LzGI7vKxUQRAd5rF_v6 {\n  font-family: 'Circe-Regular', serif;\n  font-size: 15px;\n  line-height: 26px;\n  color: #9CA092;\n}\n@media (max-width: 768px) {\n  ._1m5LzGI7vKxUQRAd5rF_v6 {\n    font-size: 13px;\n    line-height: 16px;\n  }\n}\n@media (max-width: 768px) {\n  .zWwKQu0MV0eyM_9pq5WE8 {\n    font-size: 12px;\n    line-height: 16px;\n  }\n}\n._8pk497WkDVQRbmtUA2r1i {\n  font-family: 'SFProDisplay-Regular', serif;\n  font-size: 15px;\n  line-height: 26px;\n  color: #303231;\n}\n._3Evz0MFWdhycWmhmQjiBHx {\n  max-width: 200px;\n  line-height: 20px;\n}\n@media (max-width: 768px) {\n  ._3Evz0MFWdhycWmhmQjiBHx {\n    max-width: 140px;\n    line-height: 16px;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"itemInfo": "_1hqWz72EBv_65piWmAB4D8",
	"title": "_3EgBGfrWueGevIdn9gasHa",
	"subText": "_1m5LzGI7vKxUQRAd5rF_v6",
	"subTextSmall": "zWwKQu0MV0eyM_9pq5WE8",
	"subTextDark": "_8pk497WkDVQRbmtUA2r1i",
	"withWidth": "_3Evz0MFWdhycWmhmQjiBHx"
};