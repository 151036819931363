exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1eJxcoTmjCpZqbFWX3o8Nz {\n  max-width: 480px;\n  margin: 0 auto 36px auto;\n}\n._29kvO5CR_A0nhPBn2XzLXW {\n  font-family: 'Circe-Bold', serif;\n  font-size: 40px;\n  line-height: 59px;\n  color: #3C3C3C;\n}\n@media (max-width: 375px) {\n  ._29kvO5CR_A0nhPBn2XzLXW {\n    font-size: 32px;\n    line-height: 38px;\n  }\n}\n._1yW2jEbn7a3wtijKO6wIUp {\n  font-family: 'Circe-Bold', serif;\n  font-size: 21px;\n  line-height: 25px;\n  color: #2B5E51;\n}\n._1n4XifFeSC6GeMt4wsxY9Q {\n  font-family: 'Circe-Regular', serif;\n  font-size: 13px;\n  line-height: 16px;\n  color: #9CA092;\n}\n.tsDODsOElyaeH1wq9MHNq {\n  margin: 0 5px;\n}\n._3MSUagfTuHVizPad4JVjfu {\n  max-width: 413px;\n  height: 52px;\n  background: #F7F9F8;\n  border-radius: 11px;\n  padding-top: 16px;\n}\n._3MSUagfTuHVizPad4JVjfu div.ant-typography,\n._3MSUagfTuHVizPad4JVjfu .ant-typography p {\n  display: flex;\n  justify-content: space-between;\n}\n._3MSUagfTuHVizPad4JVjfu .ant-typography-copy {\n  margin-top: -3px;\n}\n._3MSUagfTuHVizPad4JVjfu p {\n  font-family: 'Circe-Regular', serif;\n  font-size: 16px;\n  line-height: 18px;\n  color: #303231;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "_1eJxcoTmjCpZqbFWX3o8Nz",
	"title": "_29kvO5CR_A0nhPBn2XzLXW",
	"name": "_1yW2jEbn7a3wtijKO6wIUp",
	"condition": "_1n4XifFeSC6GeMt4wsxY9Q",
	"pointIcon": "tsDODsOElyaeH1wq9MHNq",
	"promocodeWrapper": "_3MSUagfTuHVizPad4JVjfu"
};