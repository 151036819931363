exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3SfdJnoymfDphhwEOgUs6i {\n  max-width: 640px;\n  margin: 0 auto;\n}\n._2DLYgiN1GjFsfMk7f7gpaG {\n  font-family: 'Circe-Bold', serif;\n  font-size: 40px;\n  line-height: 59px;\n  color: #3C3C3C;\n}\n@media (max-width: 375px) {\n  ._2DLYgiN1GjFsfMk7f7gpaG {\n    font-size: 32px;\n    line-height: 38px;\n  }\n}\n.d0hSp9UbuU5OLYnXIUwW9 {\n  font-family: 'Circe-Bold', serif;\n  font-size: 15px;\n  line-height: 22px;\n  color: #303231;\n}\n._1ku4D3rNUspopoJIPWMnvS {\n  font-family: 'Circe-Regular', serif;\n  font-size: 16px;\n  line-height: 19px;\n  color: #303231;\n  opacity: 0.7;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "_3SfdJnoymfDphhwEOgUs6i",
	"title": "_2DLYgiN1GjFsfMk7f7gpaG",
	"paragraph": "d0hSp9UbuU5OLYnXIUwW9",
	"text": "_1ku4D3rNUspopoJIPWMnvS"
};