import React, { useRef }  from 'react'
import { Typography, Row, Col, Carousel, Modal } from 'antd'
import { NavLink, useHistory } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import cx from 'classnames'

import Icon from 'components/ui/Icon'
import WidthContainer from '../../components/layout/WidthContainer/WidthContainer'
import Box from '../../components/ui/Box/Box'
import Button from '../../components/ui/Button/Button'

import { isMobileLayout, isTabletMiddleLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'
import images from './util/images'

import poke1x from './images/poke.jpg'
import poke2x from './images/poke2x.jpg'
import poke3x from './images/poke3x.jpg'
import coffee1x from './images/coffee.jpg'
import coffee2x from './images/coffee2x.jpg'
import coffee3x from './images/coffee3x.jpg'
import bgrFirst1x from './images/bgrFirst.jpg'
import bgrFirst2x from './images/bgrFirst2x.jpg'
import bgrFirst3x from './images/bgrFirst3x.jpg'
import bgrSecond1x from './images/bgrSecond.jpg'
import bgrSecond2x from './images/bgrSecond2x.jpg'
import bgrSecond3x from './images/bgrSecond3x.jpg'
import bgrThird1x from './images/bgrThird.jpg'
import bgrThird2x from './images/bgrThird2x.jpg'
import bgrThird3x from './images/bgrThird3x.jpg'
import smallTree1x from './images/smallTree.png'
import smallTree2x from './images/smallTree2x.png'
import smallTree3x from './images/smallTree3x.png'
import yandexEat1x from './images/yandexEat.png'
import yandexEat2x from './images/yandexEat2x.png'
import yandexEat3x from './images/yandexEat3x.png'
import deliveryClub1x from './images/deliveryClub.png'
import deliveryClub2x from './images/deliveryClub2x.png'
import deliveryClub3x from './images/deliveryClub3x.png'
import whiteLogo1x from './images/whiteLogo.png'
import whiteLogo2x from './images/whiteLogo2x.png'
import whiteLogo3x from './images/whiteLogo3x.png'
import whiteLogoMobile1x from './images/whiteLogoMobile.png'
import whiteLogoMobile2x from './images/whiteLogoMobile2x.png'
import whiteLogoMobile3x from './images/whiteLogoMobile3x.png'

import s from './AboutUs.module.less'
import { useState } from '../../hooks'


const { Text } = Typography

const AboutUsComponent = ({ viewport }) => {

  const isMobile = isMobileLayout(viewport)
  const isTablet = isTabletMiddleLayout(viewport)
  const isTabletBig = viewport.width > 1024 && viewport.width < 1200

  const [ isModalVisible, setIsModalVisible ] = useState(false)

  const history = useHistory()
  const carouselRef = useRef()

  return (
    <Box pt={isMobile ? 'sm' : ''} pb={isMobile ? 'xlg' : ''}>
      {
        isMobile && (
          <WidthContainer>
            <Row style={{ width: '100%', justifyContent: 'space-between' }}>
              <Col>
                <Button type="icon" icon="left" isSmall handleClick={() => history.goBack()} />
              </Col>
            </Row>
          </WidthContainer>
        )
      }

      <Modal
        visible={isModalVisible}
        maskStyle={{ background: 'rgba(255, 255, 255, .75)' }}
        className={s.wrapClassName}
        footer={null}
        closable
        maskClosable
        centered
        closeIcon={<Icon name="close" onClick={() => setIsModalVisible(false)} />}
      >
        <iframe src="https://www.youtube.com/embed/YsiH2q7ZDh0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      </Modal>
      <WidthContainer>
        {
          !isMobile && (
            <Box className={s.buttonBack}>
              <Button type="icon" icon="left" handleClick={() => history.goBack()} />
            </Box>
          )
        }

        <Box className={s.container}>
          <Box mt={isMobile ? 'lg' : 'xlg'} mb="md">
            <Row className={s.title}>
              <Text>О нас</Text>
            </Row>
          </Box>
          <Box mt="xlg" className={s.carouselContainer}>
            <Box className={s.prevButtonContainer}>
              <Button
                type="icon"
                icon="left"
                circle
                isSmall
                className={s.btnPrev}
                handleClick={() => carouselRef.current.prev()}
              />
            </Box>
            <Box className={s.carouselWrapper}>
              <Carousel
                dots={false}
                ref={carouselRef}
                slidesToShow={isMobile ? 1 : isTablet ? 3 : isTabletBig ? 4 : 5}
                className={s.carousel}
                style={{ width: '100%' }}
                slidesToScroll={1}
                infinite
              >
                {
                  images.map((image, i) => (
                    <img
                      key={i}
                      src={image.src}
                      srcSet={image.srcSet}
                    />
                  ))
                }
              </Carousel>
            </Box>
            <Box className={s.nextButtonContainer}>
              <Button
                type="icon"
                icon="right"
                circle
                isSmall
                className={s.btnNext}
                handleClick={() => carouselRef.current.next()}
              />
            </Box>
          </Box>
          <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Box mt="xxl" className={s.mainTextContainer}>
              <Text className={s.mainAboutText}>
                Eat Street — аутентичный вкус и street food со всего мира
              </Text>
              <Box pt="sm" pb="sm" pl={isMobile ? 'sm' : 'xlg'} pr={isMobile ? 'sm' : 'xlg'} className={s.greenTextContainer}>
                <Text className={s.aboutSubText}>
                  Мы выводим доставку на новый уровень: оригинальные рецепты, ресторанное качество, экологичное производство – для повседневного питания по демократичной цене.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </WidthContainer>
      <Box mt={isMobile ? 'md' : 'xxl'} pt="xlg" pb="xlg" className={s.greenContainer}>
        <WidthContainer>
          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <Box mb="lg">
              <Text className={s.mainAboutText}>
                Как мы готовим
              </Text>
            </Box>
            <Text className={s.subText}>
              Наши производства созданы по лучшим современным мировым стандартам. Для достижения уникального вкуса мы сами готовим все компоненты по оригинальным рецептам и используем свежие сезонные локальные и зарубежные продукты.
            </Text>
          </Box>
          <Box mt="lg" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button
              primary
              isSmall
              icon="play"
              text="Смотреть видео"
              onClick={() => setIsModalVisible(true)}
            />
          </Box>
          <Box mt="xxl" className={s.iconsContainer}>
            <Box className={s.itemContainer}>
              <Icon name="cookHatInCircle" />
              <Box mt="sm" style={{ maxWidth: 190 }}>
                <Text>Аутентичный подход к каждому специалитету кухни</Text>
              </Box>
            </Box>
            <Box className={s.dotsContainer}>
              <Icon name="threeDots" />
            </Box>
            <Box className={s.itemContainer}>
              <Icon name="bikeInCircle" />
              <Box mt="sm" style={{ maxWidth: 190 }}>
                <Text>Меню разработано специально с учетом доставки</Text>
              </Box>
            </Box>
            <Box className={s.dotsContainer}>
              <Icon name="threeDots" />
            </Box>
            <Box className={s.itemContainer}>
              <Icon name="shrimpInCircle" />
              <Box mt="sm" style={{ maxWidth: 190 }}>
                <Text>Принципиальный отказ от использования химических добавок</Text>
              </Box>
            </Box>
            <Box className={s.dotsContainer}>
              <Icon name="threeDots" />
            </Box>
            <Box className={s.itemContainer}>
              <Icon name="checkInCircle" />
              <Box mt="sm" style={{ maxWidth: 190 }}>
                <Text>Контроль качества с соблюдением высоких стандартов</Text>
              </Box>
            </Box>
            <Box className={s.dotsContainer}>
              <Icon name="threeDots" />
            </Box>
            <Box className={s.itemContainer}>
              <Icon name="pictureInCircle" />
              <Box mt="sm" style={{ maxWidth: 190 }}>
                <Text>Принцип «честная еда» – как на картинке, так и в жизни</Text>
              </Box>
            </Box>
          </Box>
        </WidthContainer>
      </Box>
      <WidthContainer>
        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Box mt={isMobile ? 'sm' : 'xxl'} className={s.mainTextContainer}>
            <Text className={s.mainAboutText}>
              Что мы готовим
            </Text>
            <Box pt="sm" pb="sm" pl={isMobile ? 'sm' : 'xlg'} pr={isMobile ? 'sm' : 'xlg'} className={s.greenTextContainer}>
              <Text className={s.aboutSubText}>
                В нашем меню представлено более 250 позиций самых востребованных блюд мира, а также профессионально подобранных комбо-наборов.
              </Text>
            </Box>
          </Box>
        </Box>
        <Box mt="xlg" className={s.imagesContainer}>
          <Box className={s.imageItemContainer}>
            <img
              src={poke1x}
              srcSet={`${poke1x} 1x, ${poke2x} 2x, ${poke3x} 3x`}
            />
            <Box mt="md" style={{ display: 'flex', justifyContent: 'center' }}>
              <Text className={s.lightContrastText}>Аутентичные блюда</Text>
            </Box>
            <Box mt="sm" pt="sm" pb="sm" pl={isMobile ? 'sm' : 'md'} pr={isMobile ? 'sm' : 'md'} className={s.greenTextContainer}>
              <Text>Итальянская, японская, паназиатская, европейская и национальная кухня по достоверным рецептам</Text>
            </Box>
          </Box>
          <Box className={s.centerItemContainer}>
            <Box className={s.centerImagesContainer}>
              <Box className={s.mealContainer}>
                <img
                  src={bgrFirst1x}
                  srcSet={`${bgrFirst1x} 1x, ${bgrFirst2x} 2x, ${bgrFirst3x} 3x`}
                />
                <Box className={s.mealTextContainer}>
                  <Text>Завтраки</Text>
                  <Text>Будние дни до 12:00<br />Выходные до 16:00</Text>
                </Box>
              </Box>
              <Box className={s.mealContainer}>
                <img
                  src={bgrSecond1x}
                  srcSet={`${bgrSecond1x} 1x, ${bgrSecond2x} 2x, ${bgrSecond3x} 3x`}
                />
                <Box className={s.mealTextContainer}>
                  <Text>Обеды</Text>
                  <Text>Будние дни<br />с 12:00 до 16:00</Text>
                </Box>
              </Box>
              <Box className={cx(s.mealContainer, s.mealContainerLast)}>
                <img
                  src={bgrThird1x}
                  srcSet={`${bgrThird1x} 1x, ${bgrThird2x} 2x, ${bgrThird3x} 3x`}
                />
                <Box className={s.mealTextContainer}>
                  <Text>Ужины</Text>
                  <Text>Каждый вечер<br />с 16:00 до 23:00</Text>
                </Box>
              </Box>
              <Box className={s.centerItemText}>
                <Box mt="md" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Text className={s.lightContrastText}>Форматы по времени суток</Text>
                </Box>
                <Box mt="sm" pt="sm" pb="sm" pl={isMobile ? 'sm' : 'md'} pr={isMobile ? 'sm' : 'md'} className={s.greenTextContainer}>
                  <Text>Вкусные и полезные завтраки, быстрые сбалансированные обеды и ужины на каждый день</Text>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={s.imageItemContainer}>
            <img
              src={coffee1x}
              srcSet={`${coffee1x} 1x, ${coffee2x} 2x, ${coffee3x} 3x`}
            />
            <Box mt="md" style={{ display: 'flex', justifyContent: 'center' }}>
              <Text className={s.lightContrastText}>Своя линейка напитков</Text>
            </Box>
            <Box mt="sm" pt="sm" pb="sm" pl={isMobile ? 'sm' : 'md'} pr={isMobile ? 'sm' : 'md'} className={s.greenTextContainer}>
              <Text>Кофе, оригинальные чаи и травяные сборы, лимонады, смузи, соки, морсы и другие безалкогольные напитки</Text>
            </Box>
          </Box>
        </Box>
      </WidthContainer>
      {/* <Box pt='md' mt={isMobile ? 'md' : 'xlg'} pb='sm' className={s.greenContainer}> */}
      {/*  <WidthContainer> */}
      {/*    <Box className={s.contactsContainer}> */}
      {/*      <Box mb='md'> */}
      {/*        <Text className={s.mainAboutText}>Контакты</Text> */}
      {/*      </Box> */}
      {/*      <Box pb='md' className={s.contactsWrapper}> */}
      {/*        <Text className={s.email}>info@eatstreet.ru</Text> */}
      {/*        { */}
      {/*          isMobile && ( */}
      {/*            <Box> */}
      {/*              <Text className={s.phone}>+7 (495) 960-12-42	</Text> */}
      {/*            </Box> */}
      {/*          ) */}
      {/*        } */}
      {/*        <Box className={s.linksContainer}> */}
      {/*          <img */}
      {/*            src={smallTree1x} */}
      {/*            srcSet={`${smallTree1x} 1x, ${smallTree2x} 2x, ${smallTree3x} 3x`} */}
      {/*          /> */}
      {/*          <img */}
      {/*            src={yandexEat1x} */}
      {/*            srcSet={`${yandexEat1x} 1x, ${yandexEat2x} 2x, ${yandexEat3x} 3x`} */}
      {/*          /> */}
      {/*          <img */}
      {/*            src={deliveryClub1x} */}
      {/*            srcSet={`${deliveryClub1x} 1x, ${deliveryClub2x} 2x, ${deliveryClub3x} 3x`} */}
      {/*          /> */}
      {/*        </Box> */}
      {/*        { */}
      {/*          !isMobile && ( */}
      {/*            <Box> */}
      {/*              <Text className={s.phone}>+7 (495) 960-12-42	</Text> */}
      {/*            </Box> */}
      {/*          ) */}
      {/*        } */}
      {/*      </Box> */}
      {/*    </Box> */}
      {/*  </WidthContainer> */}
      {/* </Box> */}
      <WidthContainer>
        <Box pt="lg" pb="lg" pr={isMobile ? 'xs' : 'xxl'} className={s.darkContainer}>
          {
            isMobile ? (
              <img
                src={whiteLogoMobile1x}
                srcSet={`${whiteLogoMobile1x} 1x, ${whiteLogoMobile2x} 2x, ${whiteLogoMobile3x} 3x`}
              />
            ) : (
              <img
                src={whiteLogo1x}
                srcSet={`${whiteLogo1x} 1x, ${whiteLogo2x} 2x, ${whiteLogo3x} 3x`}
              />
            )
          }
          <Box className={s.darkContainerTextWrapper}>
            <Box className={s.whiteTextContainer}>
              <Text>Eat Street for Business</Text>
              <Text>Корпоративное питание нового уровня</Text>
            </Box>
            <Box className={s.btnContainer}>
              <Button
                primary
                text="Подробнее..."
                isSmall
                withoutShadow
                handleClick={() => {
                  window.open('https://business.eatstreet.ru')
                }}
              />
            </Box>
          </Box>
        </Box>
      </WidthContainer>
    </Box>
  )
}


const AboutUs = compose(
  withViewport,
)(AboutUsComponent)

export default connect((state) => ({
  user: state.app.user,
}))(AboutUs)
