import React, { useRef, useState, Fragment } from 'react'
import { Carousel, Typography, Row, Col } from 'antd'
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import cx from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Box from '../../../../components/ui/Box/Box'
import Button from '../../../../components/ui/Button/Button'
import Image from '../../../../components/ui/Image'
import FoodIcon from '../../../../components/ui/FoodIcon'
import Switcher from '../../../../components/ui/Switcher/Switcher'
import Toppings from '../../../Toppings/Toppings'
import ComboProductSelect from '../../../../components/ui/ComboProductSelect/ComboProductSelect'

import { useEffect } from '../../../../hooks'

import {
  setBasketItem,
  setFavorites,
  setComboMealComponents,
  setProductSwitchers,
} from '../../../../redux/actions'
import links from '../../../../helpers/links'
import useLocalStorage from '../../../../hooks/useLocalStorage'
import { isMobileLayout, isTabletLayout } from '../../../../helpers/isMobileLayout'
import { withViewport } from '../../../../helpers/withViewport'
import getDishDeclension from '../../../../helpers/getDishDeclension'
import getDeliveryPointById from '../../../../helpers/getDeliveryPointById'
import getRestaurantsFromCities from '../../../../helpers/getRestaurantsFromCities'

import s from './ProductItem.module.less'
import Icon from '../../../../components/ui/Icon'


const { Text } = Typography

const ProductItemComponent = (props) => {

  const {
    viewport,
    item, in_stop,
    setModalVisible,
    selectedDeliveryPoint,
    basketItems,
    isFavorite,
    user,
    isLoggedIn,
    isComboMealProducts,
    comboMealComponents,
    setAddedMeal,
    productModifications,
    productSwitchers,
  } = props

  const { products_guid,
    image,
    name,
    nutrients,
    ingredients,
    badges,
    price,
    weight,
    kkal,
    proteins,
    fats,
    carbohydrates,
    measure_type,
    description,
    toppings_total_max_amount,
    modifiers_objects,
    toppings_objects } = item

  const [ topping, setTopping ] = useState(0)
  const [ selectedDoughSize, setSelectedDoughSize ] = useState(0)

  const [ showWarningText, setShowWarningText ] = useState(false)

  const isMobile = isMobileLayout(viewport)
  const isTablet = isTabletLayout(viewport)

  const history = useHistory()
  const params = useParams()

  const [ storedAddress, setAddress ] = useLocalStorage('address', {}, true)

  const { groups_objects, components_objects: mealComponent_objects } = item || []
  const { component_guid } = item || []

  const productsInBasket = basketItems ? basketItems.filter((i) => i.products_guid === products_guid) : []
  const productsInBasketCount = productsInBasket.reduce((acc, { count }) => acc + count, 0)

  const modificationIndex = productModifications && productModifications.findIndex((i) => i.product === products_guid)
  const modifications = modificationIndex > -1 ? productModifications[modificationIndex].modifications || [] : []
  const totalModifications = modifications.reduce((acc, { quant }) => acc + quant, 0)

  useEffect(() => {
    if (modifiers_objects && modifiers_objects.length === 2) {
      setProductSwitchers(item, modifiers_objects[0].section_guid, modifiers_objects[0].component_objects[0])
      setProductSwitchers(item, modifiers_objects[1].section_guid, modifiers_objects[1].component_objects[0])
    }
  }, [params, modifiers_objects])

  // functions for getting meal info

  const getAddedToMeal = () => {
    if (comboMealComponents && comboMealComponents.length > 0) {
      const indexOfMealInArray = comboMealComponents.findIndex((it) => it.mealId === params.product_alias)
      if (indexOfMealInArray > -1) {
        const indexOfGroupInArray = comboMealComponents[indexOfMealInArray].groups.findIndex((g) => g.groupId === params.group_alias)
        if (indexOfGroupInArray > -1) {
          return comboMealComponents[indexOfMealInArray].groups[indexOfGroupInArray].productComponent.component_guid === `${component_guid}`
        }
      }
    }
  }

  const getTotalMealNutrients = (param) => {
    if (comboMealComponents && comboMealComponents.length > 0) {
      const indexOfMealInArray = comboMealComponents.findIndex((it) => it.mealId === params.product_alias)
      if (indexOfMealInArray > -1) {
        let numsArray = []
        comboMealComponents[indexOfMealInArray].groups.forEach((i) => {
          numsArray.push(i.productComponent.nutrients[param])
        })
        return numsArray.reduce((a, b) => a + b)
      }
    }
  }
  const totalMealCalorific = getTotalMealNutrients('calorific')
  const totalMealCarbohydrates = getTotalMealNutrients('carbohydrates')
  const totalMealFats = getTotalMealNutrients('fats')
  const totalMealProteins = getTotalMealNutrients('proteins')

  const getTotalMealWeight = () => {
    if (comboMealComponents && comboMealComponents.length > 0) {
      const indexOfMealInArray = comboMealComponents.findIndex((it) => it.mealId === params.product_alias)
      if (indexOfMealInArray > -1) {
        let weightsArr = []
        comboMealComponents[indexOfMealInArray].groups.forEach((i) => {
          weightsArr.push(i.productComponent.weight)
        })
        return weightsArr.reduce((a, b) => a + b)
      }
    }
  }

  const setMealReadyToBeAddedToBasket = () => {
    if (groups_objects && groups_objects.length > 0) {
      if (comboMealComponents && comboMealComponents.length > 0) {
        const indexOfMealInArray = comboMealComponents.findIndex((it) => it.mealId === params.product_alias)
        if (indexOfMealInArray > -1) {
          return comboMealComponents[indexOfMealInArray].groups.length === groups_objects.length
        }
      }
    }
    else if (!groups_objects) {
      return true
    }
  }

  const getFullComboForBasket = () => {
    if (comboMealComponents && comboMealComponents.length > 0) {
      const indexOfMealInArray = comboMealComponents.findIndex((it) => it.mealId === params.product_alias)
      if (indexOfMealInArray > -1) {
        let comboProducts = []
        comboMealComponents[indexOfMealInArray].groups.forEach((i) => {
          comboProducts.push(i.productComponent)
        })
        return comboProducts
      }
    }
  }

  // functions for getting product with toppings info

  const getTotalProductNutrients = (param) => {
    let numsArray = []
    if (modifications && modifications.length > 0) {
      modifications.forEach((i) => {
        numsArray.push(i.nutrients[param] * i.quant)
      })
    }
    if (mealComponent_objects && mealComponent_objects.length > 0) {
      mealComponent_objects.forEach((i) => {
        numsArray.push(i.nutrients[param])
      })
    }

    return numsArray.length > 0 ? numsArray.reduce((a, b) => a + b) : null
  }
  const getTotalProductWeight = () => {
    if (modifications && modifications.length > 0) {
      let weightsArr = []
      modifications.forEach((i) => {
        weightsArr.push(i.weight * i.quant)
      })
      return weightsArr.reduce((a, b) => a + b)
    }
  }
  const getTotalProductPrice = () => {
    if (modifications && modifications.length > 0) {
      let pricesArr = []
      modifications.forEach((i) => {
        pricesArr.push(i.price * i.quant)
      })
      return pricesArr.reduce((a, b) => a + b)
    }
  }
  const totalProductCalorific = getTotalProductNutrients('calorific') + nutrients.calorific
  const totalProductCarbohydrates = getTotalProductNutrients('carbohydrates') + nutrients.carbohydrates
  const totalProductFats = getTotalProductNutrients('fats') + nutrients.fats
  const totalProductProteins = getTotalProductNutrients('proteins') + nutrients.proteins
  const totalProductWeight = getTotalProductWeight() + weight
  const totalProductPrice = getTotalProductPrice() + price

  const switcher = productSwitchers.find((switcher) => switcher.product === products_guid)

  return (
    <Box pt="xlg" className={s.categoryWrapper}>

      {in_stop && (
        <div className={s.pane}>
          <Icon name="inStop" />
          <span>В данный момент это блюдо недоступно, проверьте позже</span>
        </div>
      )}
      <Box mb="xxl" center style={{ display: 'flex', justifyContent: 'center' }}>
        {/* { */}
        {/*  !isMobile && ( */}
        {/*    <div className={s.shadow} /> */}
        {/*  ) */}
        {/* } */}
        {
          item.images ? (
            <img
              src={
                isMobile && item.images[0].mobile ? item.images[0].mobile.detail
                  :                  !isMobile && item.images[0].desktop ? item.images[0].desktop.detail
                    : item.images[0].original
              }
              style={{ height: isMobile ? 264 : 422, zIndex: 1 }}
              srcSet={
                isMobile && item.images[0].mobile ? `${item.images[0].mobile.detail} 1x, ${item.images[0].mobile['detail-x2']} 2x`
                  :                  !isMobile && item.images[0].desktop ? `${item.images[0].desktop.detail} 1x, ${item.images[0].desktop['detail-x2']} 2x`
                    : item.images[0].original
              }
            />
          ) : (
            <Image name="noImage" style={{ height: isMobile ? 264 : 422 }} />
          )
        }
      </Box>
      <Row style={{ marginBottom: 20 }}>
        <Col span={18} className={s.nameContainer}>
          <Text>{item.title}</Text>
          {
            badges && !isMobile && badges.map((el, i) => <FoodIcon key={i} name={el} />)
          }
        </Col>
        <Col span={6} className={s.btnsContainer}>
          {
            !in_stop && !isMobile && !isComboMealProducts && (
              <Button
                bright
                text={`${productsInBasketCount > 0 ? `${productsInBasketCount} x ` : ''}${totalProductPrice || price}` + ' ₽'}
                handleClick={() => {
                  if (!selectedDeliveryPoint) {
                    setModalVisible(true)
                  }
                  else if (setMealReadyToBeAddedToBasket()) {
                    setShowWarningText('yes')
                    setBasketItem(item, 0, getFullComboForBasket(), modifications, switcher && switcher.switchers)
                  }
                  else {
                    setShowWarningText('no')
                  }
                }}
              />
            )
          }
          {
            !in_stop && isComboMealProducts && !isMobile && (
              <Button
                text={getAddedToMeal() ? 'Добавлено' : 'Добавить'}
                primary
                isSmall
                handleClick={() => {
                  if (!selectedDeliveryPoint) {
                    setModalVisible(true)
                  }
                  else if (isLoggedIn) {
                    setComboMealComponents(item, params.product_alias, params.group_alias, !!getAddedToMeal())
                    history.push(`${links.catalog}/${params.section_alias}/${params.product_alias}`)
                  }
                  else {
                    history.push(links.login)
                  }
                }}
              />
            )
          }
          {!in_stop && !item.groups_objects && (
            <Button
              type="icon"
              icon={isFavorite ? 'likeActive' : 'likeInActive'}
              handleClick={() => {
                if (isLoggedIn) {
                  setFavorites(item)
                }
                else {
                  history.push(links.login)
                }
              }} />
          )}
        </Col>
      </Row>
      {
        !setAddedMeal ? (
          <Row className={s.nutritionalValueContainer}>
            {
              isMobile ? (
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                  <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    <Col className={s.nutritionalValue}>
                      <Text>Вес</Text>
                      <Text>
                        {
                          getTotalProductWeight() ? parseInt(totalProductWeight, 10) : (parseInt(weight, 10) === 1 ? 0 : parseInt(weight, 10))
                        }
                        &nbsp;
                        {measure_type}
                      </Text>
                    </Col>
                    <Col className={s.nutritionalValue}>
                      <Text>ккал</Text>
                      <Text>{totalProductCalorific ? parseInt(totalProductCalorific, 10) : parseInt(nutrients.calorific, 10)}</Text>
                    </Col>
                  </Box>
                  <Box mt="sm" style={{ display: 'flex', flexDirection: 'row' }}>
                    <Col className={s.nutritionalValue}>
                      <Text>белки</Text>
                      <Text>{totalProductProteins ? `${parseInt(totalProductProteins, 10)} ` : `${parseInt(nutrients.proteins, 10)} `}</Text>
                    </Col>
                    <Col className={s.nutritionalValue}>
                      <Text>жиры</Text>
                      <Text>{totalProductFats ? ` ${parseInt(totalProductFats, 10)} ` : ` ${parseInt(nutrients.fats, 10)} `}</Text>
                    </Col>
                    <Col className={s.nutritionalValue}>
                      <Text>
                        углеводы
                      </Text>
                      <Text>{totalProductCarbohydrates ? ` ${parseInt(totalProductCarbohydrates, 10)}` : ` ${parseInt(nutrients.carbohydrates, 10)}`}</Text>
                    </Col>
                  </Box>
                </Box>
              ) : (
                <>
                  <Col className={s.nutritionalValue}>
                    <Text>Вес</Text>
                    <Text>
                      {
                        getTotalProductWeight() ? parseInt(totalProductWeight, 10) : (parseInt(weight, 10) === 1 ? 0 : parseInt(weight, 10))
                      }
                      &nbsp;
                      {measure_type}
                    </Text>
                  </Col>
                  <Col className={s.nutritionalValue}>
                    <Text>ккал</Text>
                    <Text>{totalProductCalorific ? parseInt(totalProductCalorific, 10) : parseInt(nutrients.calorific, 10)}</Text>
                  </Col>
                  <Col className={s.nutritionalValue}>
                    <Text>белки</Text>
                    <Text>{totalProductProteins ? `${parseInt(totalProductProteins, 10)} ` : `${parseInt(nutrients.proteins, 10)} `}</Text>
                  </Col>
                  <Col className={s.nutritionalValue}>
                    <Text>жиры</Text>
                    <Text>{totalProductFats ? ` ${parseInt(totalProductFats, 10)} ` : ` ${parseInt(nutrients.fats, 10)} `}</Text>
                  </Col>
                  <Col className={s.nutritionalValue}>
                    <Text>углеводы</Text>
                    <Text>{totalProductCarbohydrates ? ` ${parseInt(totalProductCarbohydrates, 10)}` : ` ${parseInt(nutrients.carbohydrates, 10)}`}</Text>
                  </Col>
                </>
              )
            }
          </Row>
        ) : (
          <Row className={s.nutritionalValueContainer}>
            <Col className={s.nutritionalValue}>
              <Text>Вес</Text>
              <Text>
                {
                  getTotalMealWeight() // Если добавлено блюдо
                    ? parseInt((getTotalProductWeight()), 10) // Если добавлены топпинги
                      ? parseInt(getTotalMealWeight(), 10) + parseInt((getTotalProductWeight()), 10)
                      : parseInt(getTotalMealWeight(), 10)
                    : getTotalProductWeight()
                      ? parseInt((weight + getTotalProductWeight()), 10)
                      : parseInt(weight, 10)
                }
                &nbsp;
                {measure_type}
              </Text>
            </Col>
            <Col className={s.nutritionalValue}>
              <Text>ккал</Text>
              <Text>{totalMealCalorific
                ? parseInt(totalMealCalorific, 10) + parseInt(totalProductCalorific, 10)
                : parseInt(nutrients.calorific, 10)}
              </Text>
            </Col>
            <Col className={s.nutritionalValue}>
              <Text>бжу</Text>
              <Text>{
                totalMealProteins
                  ? `${parseInt(totalMealProteins, 10) + parseInt(totalProductProteins, 10)} / ${parseInt(totalMealFats, 10) + parseInt(totalProductFats, 10)} / ${parseInt(totalMealCarbohydrates, 10)  + parseInt(totalProductCarbohydrates, 10)}`
                  : `${parseInt(nutrients.proteins, 10)} / ${parseInt(nutrients.fats, 10)} / ${parseInt(nutrients.carbohydrates, 10)}`
              }
              </Text>
            </Col>
          </Row>
        )
      }
      <Box mt="md" mb="md" className={s.descriptionWrapper}>
        {
          description && (
            <Box mt="xs" mb="xs">
              <Text>{description}</Text>
            </Box>
          )
        }
        {
          ingredients && (
            <Box mt="xs" mb="xs">
              <Text>{ingredients}</Text>
            </Box>
          )
        }
      </Box>
      {
        modifiers_objects && (
          <Row className={s.switchers}>
            {modifiers_objects.map((modifier) => {
              if (!modifier.component_objects) return null
              const values = []
              modifier.component_objects.forEach((component) => {
                values.push(component.title)
              })

              let selected = 0

              if (switcher) {
                if (switcher.switchers[modifier.section_guid]) {
                  selected = modifier.component_objects.findIndex((obj) => obj.component_guid === switcher.switchers[modifier.section_guid].component_guid)
                  if (selected === -1) {
                    selected = 0
                  }
                }
              }

              return (
                <Switcher
                  selectedTab={selected}
                  items={values}
                  onTabSelect={(val) => {
                    setProductSwitchers(item, modifier.section_guid, modifier.component_objects[val])
                  }} />
              )
            })}
          </Row>
        )
      }

      {
        groups_objects && (
          <Row gutter={[24, 24]} style={{ marginBottom: 20 }}>
            {
              groups_objects.map((group, i) => {

                const { title, section_guid, component_objects, required, alias } = group

                const setProductSelected = () => {
                  if (comboMealComponents && comboMealComponents.length > 0) {
                    const indexOfMealInArray = comboMealComponents.findIndex((it) => it.mealId === params.product_alias)
                    if (indexOfMealInArray > -1) {
                      const indexOfGroupInArray = comboMealComponents[indexOfMealInArray].groups.findIndex((g) => g.groupId === alias)
                      if (indexOfGroupInArray > -1) {
                        return comboMealComponents[indexOfMealInArray].groups[indexOfGroupInArray].productComponent
                      }
                    }
                  }
                }

                return (
                  <Col span={isMobile ? 24 : 12} key={i}>
                    {
                      setProductSelected() ? (
                        // <NavLink to={`${document.location.pathname}/${alias}/${setProductSelected().code}`}>
                        <ComboProductSelect
                          mainText={setProductSelected().title}
                          secondaryText={<>{parseInt(setProductSelected().weight, 10)} {setProductSelected().measure_type}</>}
                          handleClick={() => {
                            history.push(`${document.location.pathname}/${alias}/${setProductSelected().code}`)
                          }}
                        />
                        // </NavLink>
                      ) : (
                        // <NavLink to={`${document.location.pathname}/${alias}/${component_objects[0].code}`}>
                        <ComboProductSelect
                          mainText={title}
                          secondaryText={
                            `${getDishDeclension(component_objects.length)} на выбор`
                          }
                          isRed={showWarningText === 'no' && required}
                          handleClick={() => {
                            history.push(`${document.location.pathname}/${alias}/${component_objects[0].code}`)
                          }}
                        />
                        // </NavLink>
                      )
                    }
                  </Col>
                )
              })
            }
            {
              showWarningText === 'no' && (
                <Col span={24}>
                  <Box mt="xs" mb="sm" style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Text className={s.warningText}>Выберите блюда</Text>
                  </Box>
                </Col>
              )
            }
          </Row>
        )
      }

      {
        toppings_objects && toppings_objects.length && (
          <>
            <Box mt="md" mb="md">
              <Row className={s.toppingsSection}>
                <Text>Топпинги</Text>
                <Text>{totalModifications} / {toppings_total_max_amount}</Text>
              </Row>
            </Box>
            <Toppings
              items={toppings_objects}
              total={totalModifications}
              maxTotal={toppings_total_max_amount}
              product={item}
              modifications={modifications} />
          </>
        )
      }

      {
        mealComponent_objects && mealComponent_objects.length > 0 && (
          <Row gutter={[24, 24]}>
            {
              mealComponent_objects.map((component, i) => {

                const { title, images, weight, measure_type } = component

                return (
                  <Col span={isMobile ? 24 : 12} key={i}>
                    <ComboProductSelect
                      mainText={title}
                      secondaryText={`${parseInt(weight, 10)} ${measure_type}`}
                      isMobile={isMobile}
                      images={images}
                    />
                  </Col>
                )
              })
            }
          </Row>
        )
      }
      {
        !in_stop && !isComboMealProducts && isMobile && (
          <div className={s.basketButton}>
            <Button
              text={`В корзину за ${totalProductPrice || price} ₽`}
              withBoldText
              primary
              isSmall
              withoutShadow
              handleClick={() => {
                if (!selectedDeliveryPoint) {
                  setModalVisible(true)
                }
                else if (setMealReadyToBeAddedToBasket()) {
                  setShowWarningText('yes')
                  setBasketItem(item, 0, getFullComboForBasket(), modifications)
                }
                else {
                  setShowWarningText('no')
                }
              }}
            />
          </div>
        )
      }
      {
        isComboMealProducts && isMobile && (
          <div className={s.basketButton}>
            <Button
              text={getAddedToMeal() ? 'Добавлено' : 'Добавить'}
              primary
              isSmall
              disabled={getAddedToMeal()}
              handleClick={() => {
                if (!selectedDeliveryPoint) {
                  setModalVisible(true)
                }
                else if (isLoggedIn) {
                  setComboMealComponents(item, params.product_alias, params.group_alias)
                  history.push(`${links.catalog}/${params.section_alias}/${params.product_alias}`)
                }
                else {
                  history.push(links.login)
                }
              }}
            />
          </div>
        )
      }
    </Box>
  )
}

const ProductItem = compose(
  withViewport,
)(ProductItemComponent)

export default connect((state) => ({
  basketItems: state.app.basketItems,
  comboMealComponents: state.app.comboMealComponents,
  productModifications: state.app.productModifications,
  productSwitchers: state.app.productSwitchers,
  user: state.app.user,
  isLoggedIn: state.app.user && state.app.user.type !== 'guest',
  selectedDeliveryPoint: getDeliveryPointById(state.app.selectedDeliveryPointId, state.app.user && state.app.user.addresses || [], getRestaurantsFromCities(state.app.cities)),
}))(ProductItem)


