exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1QKcf6XU8wxGk7YHDI2qfx {\n  position: relative;\n}\n._3_b4S92yb_xBA6yzk5-eU_ {\n  width: 89px;\n  height: 89px;\n  overflow: hidden;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n}\n@media (max-width: 768px) {\n  ._3_b4S92yb_xBA6yzk5-eU_ {\n    width: 84px;\n    height: 84px;\n  }\n}\n._1-3vVbz9DfqeQciUMkOAOu {\n  background: #F7F9F8;\n  border-radius: 10px;\n  display: flex;\n  justify-content: center;\n}\n@media (max-width: 768px) {\n  ._1Fq1voHzcd6ixInOaDRntU {\n    position: absolute;\n    right: 0;\n    top: 45px;\n  }\n}\n@media (max-width: 768px) {\n  ._3ZNux7oY-62h2BGYACrdSa {\n    position: absolute;\n    right: 0;\n    top: 0;\n  }\n}\n._3Gt6EuF9evNS5sqDnE_aHI {\n  font-family: 'Circe-Bold', serif;\n  font-size: 20px;\n  line-height: 140%;\n  color: #2B5E51;\n}\n@media (max-width: 768px) {\n  ._3Gt6EuF9evNS5sqDnE_aHI {\n    font-size: 16px;\n    line-height: 18px;\n  }\n}\n._1ysa-kVOb8jgvOUzKbkqPJ {\n  padding-top: 10px;\n}\n._1ysa-kVOb8jgvOUzKbkqPJ .ant-btn {\n  padding: 0;\n}\n@media (min-width: 769px) {\n  ._1ysa-kVOb8jgvOUzKbkqPJ {\n    display: flex;\n    justify-content: flex-end;\n  }\n}\n._7ynX-3HPsCNqepyeqTVxu {\n  font-family: 'Circe-Regular', serif;\n  font-size: 15px;\n  line-height: 26px;\n  color: #9CA092;\n}\n@media (max-width: 768px) {\n  ._7ynX-3HPsCNqepyeqTVxu {\n    font-size: 13px;\n    line-height: 16px;\n  }\n}\n._3367ImEBpnDh_5MLoZXQsv {\n  font-family: 'SFProDisplay-Regular', serif;\n  font-size: 15px;\n  line-height: 26px;\n  color: #303231;\n}\n._1CmcgPpRRV-Etcyqd5uMJU {\n  text-align: end;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "_1QKcf6XU8wxGk7YHDI2qfx",
	"imageContainer": "_3_b4S92yb_xBA6yzk5-eU_",
	"cutleryImageContainer": "_1-3vVbz9DfqeQciUMkOAOu",
	"controlsContainer": "_1Fq1voHzcd6ixInOaDRntU",
	"priceContainer": "_3ZNux7oY-62h2BGYACrdSa",
	"price": "_3Gt6EuF9evNS5sqDnE_aHI",
	"trashContainer": "_1ysa-kVOb8jgvOUzKbkqPJ",
	"subText": "_7ynX-3HPsCNqepyeqTVxu",
	"subTextDark": "_3367ImEBpnDh_5MLoZXQsv",
	"isComboRow": "_1CmcgPpRRV-Etcyqd5uMJU"
};