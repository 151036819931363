import React, { useEffect, useState, useRef } from 'react'
import Storyteller from '@getstoryteller/storyteller-sdk-javascript'
import '@getstoryteller/storyteller-sdk-javascript/dist/storyteller.min.css'
import { connect } from 'react-redux'
import cx from 'classnames'
import s from './Storyteller.module.less'
import getStorytellerThemeConfig from './utils/getStorytellerThemeConfig'
import NotFoundBlock from '../../components/ui/NotFoundBlock/NotFoundBlock'


// if you want to update getstoryteller version check classNames in :global. They can be changed by hashes.

const StorytellerList = (props) => {
  const {
    user,
    isMobile,
  } = props

  const storyRow = useRef(null)
  const [hasStories, setHasStories] = useState(true)

  const initializeStoryteller = () => {
    Storyteller.sharedInstance.initialize('99fb3b06-e17c-49c3-8c0f-4e4ee2d7b9f1', { externalId: user.user_guid || undefined })
      .then(() => {
        storyRow.current = new Storyteller.RowView('storyteller-row')
        storyRow.current.theme = new Storyteller.UiTheme(getStorytellerThemeConfig())
        storyRow.current.delegate = {
          onStoriesDataLoadComplete: () => {
            if (!storyRow.current.stories.length) setHasStories(false)
          },
        }
      })
      .catch((e) => {
        setHasStories(false)
        console.error(e)
      })
  }

  useEffect(() => {
    initializeStoryteller()
  }, [])

  return (
    <>
      {hasStories
      && (
        <div
          id="storyteller-row"
          data-cell-type="round"
          className={isMobile ? cx(s.storytellerList, s.storytellerListMobile) : cx(s.storytellerList, s.storytellerListDesktop)}
        />
      )}
      {!hasStories
      && (
        <div className={s.notFoundBlockWrapper}>
          <NotFoundBlock title="Нет доступных сториз" />
        </div>
      )}
    </>

  )
}


export default connect((state) => ({
  user: state.app.user,
}))(StorytellerList)
