import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Typography, Row, Col, Checkbox, Input } from 'antd'
import { NavLink, useHistory } from 'react-router-dom'
import cx from 'classnames'
import { connect } from 'react-redux'

import Box from '../../components/ui/Box/Box'
import Button from '../../components/ui/Button/Button'
import Icon from '../../components/ui/Icon'
import { logout, updateUser } from '../../redux/actions'

import s from './MyData.module.less'
import externalLinks from '../../helpers/externalLinks'
import links from '../../helpers/links'
import setSeoTags from '../../helpers/setSeoTags'


const { Text, Paragraph } = Typography

const MyData = ({ user }) => {
  useEffect(() => {

    setSeoTags({
      title: `Eat Street - Мои данные`,
      seo_desc: '',
      seo_keyw: '',
    })
  }, [])

  const history = useHistory()

  const [ email, setEmail ] = useState(user.email)
  const [ name, setName ] = useState(user.name)

  const [ emailError, setEmailError ] = useState()
  const [ nameError, setNameError ] = useState()

  const onUserChange = useCallback((key, value) => {
    updateUser({
      ...user,
      [key]: value,
    }).catch((e) => e.error.includes('email') ? setEmailError(e.error) : setNameError(e.error))
  }, [ user ])

  const onLogout = useCallback(() => {
    logout()
    history.push('/')
  }, [])

  if (!user || Object.keys(user).length === 0) {
    return null
  }

  return (
    <Box className={s.container}>
      <Box mt="xlg" mb="lg" alignCenter>
        <Text className={s.title}>Мои данные</Text>
      </Box>
      <Box mt="xlg" mb="md" alignCenter>
        <Text className={s.phone}>{user.phone_format}</Text>
      </Box>
      <Box mt="sm" alignCenter>
        {/* <NavLink to={`${links.account}/my-data/points-info`}> */}
        <Button
          text={`У вас ${user.points || '0'} баллов`}
          primary
          className={s.smallBtn}
          handleClick={() => {
            history.push(`${links.account}/bonuses`, {
              showPointsInfo: true,
            })
          }} />
        {/* </NavLink> */}
      </Box>
      <Box mt="md" className={s.editableContainer}>
        <Text>Имя</Text>
        <Input
          placeholder="Введите ваше имя"
          defaultValue={name}
          value={name}
          onChange={(e) => {
            if (e.target.value) {
              if (/^[а-яА-Яa-zA-Z\-\s]+$/.test(e.target.value)) {
                setName(e.target.value)
              }
            }
            else {
              setName(e.target.value)
            }
          }}
          onBlur={() => onUserChange('name', name)}
        />
        <Icon name="edit" />
      </Box>

      <Box mt="md" className={s.editableContainer}>
        <Text>Почта</Text>
        <Input
          placeholder="Введите ваш email"
          defaultValue={email}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={() => onUserChange('email', email)}
        />
        <Icon name="edit" />
      </Box>
      {
        emailError && <Text className={s.warningText}>Введите, пожалуйста, правильный почтовый адрес</Text>
      }

      <Box mt="md" mb="md" alignCenter className={s.checkboxContainer}>
        <Checkbox
          checked={user.send_check_to_mail}
          onChange={(e) => {
            onUserChange('send_check_to_mail', e.target.checked)
          }}>Отправлять чеки на почту
        </Checkbox>
      </Box>
      <Box mt="md" mb="md" alignCenter className={s.checkboxContainer}>
        <Checkbox
          checked={user.confirmed_for_mailing}
          onChange={(e) => {
            onUserChange('confirmed_for_mailing', e.target.checked)
          }}>Получать персональные предложения и акции
        </Checkbox>
      </Box>
      <Box className={s.joinContainer}>
        <Text className={s.boldText}>Присоединяйтесь</Text>
        <Row gutter={[12]} className={s.row}>
          <Col span={12}>
            <Box
              className={s.socialContainer}
              onClick={() => {
                window.open(externalLinks.vk)
              }}>
              <Icon name="vkontakteGreen" />
              <Text className={s.greenText}>Vkontakte</Text>
            </Box>
          </Col>
          <Col span={12}>
            <Box
              className={s.socialContainer}
              onClick={() => {
                window.open(externalLinks.telegram)
              }}>
              <Icon name="telegramGreen" />
              <Text className={s.greenText}>Telegram</Text>
            </Box>
          </Col>
        </Row>
      </Box>
      <Box mt="lg" alignCenter>
        <Text className={s.logoutLink} onClick={onLogout}>Выйти</Text>
      </Box>
    </Box>
  )
}

export default connect((state) => ({
  user: state.app.user,
}))(MyData)

