exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1f4Q4858hhfVQPLGg_0cVE {\n  width: 100%;\n  height: 500px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n._3XTXXfljQGWSSgw7Rj2mQv {\n  position: absolute;\n  left: 40px;\n  top: 0;\n}\n._3dtX1xCs-avflNMrMW8GRq {\n  position: absolute;\n  right: 0;\n  top: 0;\n}\n._1ieAferYPs8zHh4ZJwRvKM {\n  right: -5px;\n  top: -8px;\n  height: 20px;\n  width: 20px;\n  position: absolute;\n  border-radius: 20px;\n  background-color: #E03F3F;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n._1ieAferYPs8zHh4ZJwRvKM ._3bSrWA3xHKLZkRjQ5tdPJr {\n  font-family: 'Roboto', sans-serif;\n  font-weight: bold;\n  font-size: 10px;\n  line-height: 10px;\n  text-align: center;\n  text-transform: uppercase;\n  color: #FFFFFF;\n}\n._2gOJKwsMDqzIVGEpT4LEbG {\n  left: 0;\n  top: -5px;\n}\n._3-HE993rGewtNFmz1RHCTi {\n  right: 0;\n  top: -5px;\n}\n._3mEHw7rhIbYn_to88rc9j- {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n._3mEHw7rhIbYn_to88rc9j- span {\n  font-family: 'Circe-Bold', serif;\n  font-size: 40px;\n  line-height: 59px;\n  text-align: center;\n  color: #3C3C3C;\n}\n@media (max-width: 375px) {\n  ._3mEHw7rhIbYn_to88rc9j- span {\n    font-family: 'Circe-Bold', serif;\n    font-size: 20px;\n    line-height: 32px;\n    letter-spacing: 0.5px;\n    font-feature-settings: 'pnum' on, 'lnum' on;\n    color: #232323;\n  }\n}\n._3mEHw7rhIbYn_to88rc9j- button {\n  position: absolute;\n  right: 0;\n}\n.TaaOAgu1md1SSxNTMtXtI {\n  display: none;\n}\n@media (min-width: 769px) {\n  .TaaOAgu1md1SSxNTMtXtI {\n    display: block;\n    position: fixed;\n    bottom: 100px;\n    z-index: 999;\n    right: 20px;\n  }\n}\n@media (min-width: 1281px) {\n  .TaaOAgu1md1SSxNTMtXtI {\n    right: calc((100vw - 1280px + 20px)/2);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"loader": "_1f4Q4858hhfVQPLGg_0cVE",
	"topMobileLeft": "_3XTXXfljQGWSSgw7Rj2mQv",
	"topMobileRight": "_3dtX1xCs-avflNMrMW8GRq",
	"basketItemsCount": "_1ieAferYPs8zHh4ZJwRvKM",
	"basketItemsCountText": "_3bSrWA3xHKLZkRjQ5tdPJr",
	"arrowDown": "_2gOJKwsMDqzIVGEpT4LEbG",
	"basket": "_3-HE993rGewtNFmz1RHCTi",
	"productNameContainer": "_3mEHw7rhIbYn_to88rc9j-",
	"basketButton": "TaaOAgu1md1SSxNTMtXtI"
};