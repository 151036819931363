import React, { useState } from 'react'
import { Typography, Row, Col, Checkbox } from 'antd'
import cx from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Box from '../../components/ui/Box/Box'
import Icon from '../../components/ui/Icon'

import { isMobileLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import s from './Bonuses.module.less'


const { Text, Paragraph } = Typography

const BonusesComponent = ({ user, viewport }) => {

  const isMobile = isMobileLayout(viewport)

  const [ open1, setOpen1 ] = useState(false)
  const [ open2, setOpen2 ] = useState(false)
  const [ open3, setOpen3 ] = useState(false)

  const pointsDisplayName = user.points ? ((user.points === 1) ? 'балл' : (user.points < 5) ? 'балла' : (user.points >= 5) ? 'баллов' : 'баллов') : 'баллов'

  return (
    <Box className={s.container}>
      {
        !isMobile ? (
          <Box mt="xlg" alignCenter>
            <Text className={s.title}>Бонусы</Text>
          </Box>
        ) : (
          <Box mt="lg" alignCenter>
            <Text className={s.title}>У вас {user.points ? user.points : '0'} {pointsDisplayName}</Text>
          </Box>
        )
      }
      {
        isMobile ? (
          <Box mt="md" alignCenter style={{ display: 'flex', justifyContent: 'center' }}>
            <Paragraph className={s.paragraph}>Получайте баллы за заказы и оплачивайте ими до 30% от заказа</Paragraph>
          </Box>
        ) : (
          <Box mt="xlg" alignCenter style={{ display: 'flex', justifyContent: 'center' }}>
            <Paragraph className={s.paragraph}>У вас {user.points ? user.points : '0'} баллов</Paragraph>
          </Box>
        )
      }
      <Box mt={isMobile ? 'lg' : 'xlg'} className={s.imagesContainer}>
        <Box className={s.imageItemContainer}>
          <Icon name="currencyInCircle" />
          <Box mt="md">
            <Text>Оплата заказа баллами до 30%</Text>
          </Box>
        </Box>
        <Box className={s.imageItemContainer}>
          <Icon name="onePlusOneInCircle" />
          <Box mt="md">
            <Text>1 балл = 1 рубль</Text>
          </Box>
        </Box>
        <Box className={s.imageItemContainer}>
          <Icon name="starInCircle" />
          <Box mt="md">
            <Text>Начисление баллов 5% от суммы заказа</Text>
          </Box>
        </Box>
      </Box>
      <Box mt={isMobile ? 'lg' : 'xxl'} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box p={isMobile ? 'sm' : 'md'} className={s.itemContainer}>
          <Box onClick={() => setOpen1(!open1)} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text className={s.text}>Как и за что начисляются баллы?</Text>
            <Icon name={open1 ? 'arrowUp' : 'down'} />
          </Box>
          {
            open1 && (
              <Box mt="sm" style={{ display: 'flex', flexDirection: 'column' }}>
                <Text className={s.subText}>
                  – Чтобы получить баллы, надо просто оформить заказ
                </Text>
                <Text className={s.subText}>
                  – Начисление происходит уже с первого заказа
                </Text>
                <Text className={s.subText}>
                  – По факту оплаты заказа, вам на счет начисляются бонусы в размере 5% от суммы заказа (после применения всех скидок/ промокодов)
                </Text>
              </Box>
            )
          }
        </Box>
        <Box p={isMobile ? 'sm' : 'md'} className={s.itemContainer}>
          <Box onClick={() => setOpen2(!open2)} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text className={s.text}>Как потратить накопленные баллы?</Text>
            <Icon name={open2 ? 'arrowUp' : 'down'} />
          </Box>
          {
            open2 && (
              <Box mt="sm">
                <Text className={s.subText}>
                  Оплатить заработанными бонусами можно все товары, но не более 30% суммы заказа
                </Text>
              </Box>
            )
          }
        </Box>
        <Box p={isMobile ? 'sm' : 'md'} className={s.itemContainer}>
          <Box onClick={() => setOpen3(!open3)} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text className={s.text}>Где можно проверить количество баллов?</Text>
            <Icon name={open3 ? 'arrowUp' : 'down'} />
          </Box>
          {
            open3 && (
              <Box mt="sm">
                <Text className={s.subText}>
                  Количество баллов можно посмотреть в любое время в личном кабинете
                </Text>
              </Box>
            )
          }
        </Box>
      </Box>
    </Box>
  )
}

const Bonuses = compose(
  withViewport,
)(BonusesComponent)

export default connect((state) => ({
  user: state.app.user,
}))(Bonuses)
