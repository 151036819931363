import React, {
  useState,
  Fragment,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Typography, Input, Radio, Row, Col, Spin } from 'antd'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'

import Box from 'components/ui/Box/Box'
import Button from '../../components/ui/Button/Button'
import Switcher from '../../components/ui/Switcher/Switcher'
import PickupPoint from '../PickupPoint/PickupPoint'
import AddressesMobileCarousel from '../AddressesMobileCarousel/AddressesMobileCarousel'

import pickupPoints from './util/pickupPoints'
import useLocalStorage from '../../hooks/useLocalStorage'
import clearIcon from './images/clear_icon.svg'

import {
  addAddress, getAddressByAddressObject,
  getAddressByCoords,
  getGeoSuggestions,
  setSelectedDeliveryPoint,
} from '../../redux/actions'
import getRestaurantsFromCities from '../../helpers/getRestaurantsFromCities'
import getDeliveryPointById from '../../helpers/getDeliveryPointById'
import { isMobileLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import s from './AddressSelection.module.less'
import formatAddress from '../../helpers/formatAddress'


const { Text } = Typography

const AddressSelectionComponent = ({
  user, restaurants, selectedDeliveryPointId,
  handleSelect, inputAddress, myForwardedRef,
  map, viewport, setInputFocused, selectedAddress,
  setSelectedAddress, inputValue, setInputValue }) => {
  const history = useHistory()

  const isMobile = isMobileLayout(viewport)

  const [ selectedTab, setSelectedTab ] = useState(history.location.state && history.location.state.pickup ? 1 : 0)

  const [ geoSuggestions, setGeoSuggestions ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)
  const [ error, setError ] = useState('')

  const [ apartmentInputValue, setApartmentInputValue ] = useState('')
  const [ blockInputValue, setBlockInputValue ] = useState('')
  const [ floorInputValue, setFloorInputValue ] = useState('')

  useImperativeHandle(myForwardedRef, () => ({
    setInputAddress(address) {
      setSelectedAddress(address)
      setInputValue(address.address)
    },
  }))

  useEffect(() => {
    if (isMobile && selectedAddress) {
      setIsLoading(true)
      setError('')
      setInputValue(selectedAddress.address)
      if (Object.keys(selectedAddress.address_object).length) {
        getAddressByAddressObject(selectedAddress.address_object).then((r) => {
          if (r && r[0]) {
            if (r[0].status !== 1) {
              if (r[0].status === 2) {
                setError('Адрес вне зоны доставки')
              }
              else {
              // setError('Скоро откроется')
              }
            }
            else {
            // setSelectedAddress({
            //   ...r[0],
            //   address_object: {
            //     ...r[0].address_object,
            //   },
            // })
            }
          }
        }).catch(() => {}).finally(() => {
          setIsLoading(false)
        })
      }
      else setIsLoading(false)
    }
  }, [ setInputValue, setError, setIsLoading, selectedAddress])

  const onChange = (e) => {
    const findAddress = user.addresses.find((item) => (item.address_id == e.target.value) || (item.restaurant_guid == e.target.value))

    if (map && map.current) {
      map.current.setCenter(findAddress.coords)
    }

    setSelectedDeliveryPoint(findAddress)

    if (isMobile) {
      history.push('/')
    }
  }

  const isRestaurantSelected = restaurants.find((rest) => rest.restaurant_guid === selectedDeliveryPointId)

  const clickHandler = () => {
    history.push('/')
  }
  return (
    <Box p="sm" alignCenter className={s.rectangle} ref={myForwardedRef}>
      <Box mt="sm" className={s.itemPickup}>
        <Text className={s.saleNumber}>-10%</Text>
        <Switcher
          items={['Доставка', 'Самовывоз']}
          isSmall
          selectedTab={selectedTab}
          onTabSelect={(val) => {
            handleSelect && handleSelect(val)
            setSelectedTab(val)
          }}
        />
      </Box>
      {
        !selectedTab ? (
          <>
            <Box mt="sm" pl="sm" pr="sm" className={s.inputContainer} style={{ position: 'relative' }} onClick={() => setInputFocused(true)}>
              <Text className={s.smallText}>Адрес</Text>
              <Input
                autocomplete="new-password"
                placeholder="Введите адрес"
                value={inputValue}
                disabled={isMobile || isLoading}
                onChange={async (val) => {
                  setInputValue(val.target.value)
                  if (val.target.value) {
                    const suggestions = await getGeoSuggestions(val.target.value) // debounce
                    if (suggestions) {
                      setGeoSuggestions(suggestions.splice(0, 5))
                    }
                    else {
                      setGeoSuggestions([])
                    }
                  }
                  else {
                    setGeoSuggestions([])
                  }
                }}
              />
              {!isLoading && inputValue && (
                <div
                  className={s.clearIcon}
                  onClick={() => {
                    setInputValue('')
                    setGeoSuggestions([])
                  }}>
                  <img src={clearIcon} />
                </div>
              )}
              {
                geoSuggestions.length > 0 && (
                  <div className={s.addressDropdown}>
                    {geoSuggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          setIsLoading(true)
                          setError('')
                          setInputValue(suggestion.address)

                          getAddressByAddressObject(suggestion.address_object).then((r) => {
                            if (r && r[0]) {
                              if (r[0].status !== 1 && r[0].status !== 5) {
                                if (r[0].status === 2) {
                                  setError('Адрес вне зоны доставки')
                                }
                                else if (r[0].status === 3) {
                                  setError('Скоро откроется')
                                }
                                else if (r[0].status === 4) {
                                  setError('Адрес не валидный')
                                }
                              }
                              else {
                                setSelectedAddress({
                                  ...r[0],
                                  address_object: {
                                    ...r[0].address_object,
                                  },
                                })
                              }
                            }
                          }).catch(() => {}).finally(() => {
                            setIsLoading(false)
                          })
                          setGeoSuggestions([])
                        }}>{suggestion.address} <span>{suggestion.city_title}</span>
                      </div>
                    ))}
                  </div>
                )
              }
            </Box>
            <>
              <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Col span={7}>
                  <Box mt="sm" pl="sm" pr="sm" className={s.inputContainer} style={{ position: 'relative' }}>
                    <Text className={s.smallText}>Квартира</Text>
                    <Input
                      value={selectedAddress.address_object.apartment}
                      disabled={isLoading}
                      onChange={(val) => {
                        setSelectedAddress({
                          ...selectedAddress,
                          address_object: {
                            ...selectedAddress.address_object,
                            apartment: val.target.value.replace(/\D/g, ''),
                          },
                        })
                      }}
                    />
                  </Box>
                </Col>
                <Col span={7}>
                  <Box mt="sm" pl="sm" pr="sm" className={s.inputContainer} style={{ position: 'relative' }}>
                    <Text className={s.smallText}>Подъезд</Text>
                    <Input
                      value={selectedAddress.address_object.entrance}
                      disabled={isLoading}
                      onChange={(val) => {
                        setSelectedAddress({
                          ...selectedAddress,
                          address_object: {
                            ...selectedAddress.address_object,
                            entrance: val.target.value.replace(/\D/g, ''),
                          },
                        })
                      }}
                    />
                  </Box>
                </Col>
                <Col span={7}>
                  <Box mt="sm" pl="sm" pr="sm" className={s.inputContainer} style={{ position: 'relative' }}>
                    <Text className={s.smallText}>Этаж</Text>
                    <Input
                      value={selectedAddress.address_object.floor}
                      disabled={isLoading}
                      onChange={(val) => {
                        setSelectedAddress({
                          ...selectedAddress,
                          address_object: {
                            ...selectedAddress.address_object,
                            floor: val.target.value.replace(/\D/g, ''),
                          },
                        })
                      }}
                    />
                  </Box>
                </Col>
              </Row>
              <Row>
                <Box mt="sm" pl="sm" pr="sm" className={s.inputContainer} style={{ position: 'relative' }}>
                  <Text className={s.smallText}>Комментарий к адресу</Text>
                  <Input
                    value={selectedAddress.address_object.comment}
                    disabled={isLoading}
                    onChange={(val) => {
                      setSelectedAddress({
                        ...selectedAddress,
                        address_object: {
                          ...selectedAddress.address_object,
                          comment: val.target.value,
                        },
                      })
                    }}
                  />
                </Box>
              </Row>
            </>
            {
              !isMobile && (
                <Box mt="sm" mb="sm">
                  {isLoading ? <div className={s.loader}><Spin spinning /></div>
                    : (
                      <Button
                        primary
                        text="Добавить адрес"
                        icon="plusWhite"
                        isSmall
                        disabled={error || !selectedAddress
                      || !selectedAddress.address_object
                      || !selectedAddress.address_object.street
                      || !selectedAddress.address_object.house}
                        handleClick={async () => {
                          if (!selectedAddress
                          || !selectedAddress.address_object
                          || !selectedAddress.address_object.street
                          || !selectedAddress.address_object.house) {

                          }
                          else {
                            const addresses = await addAddress(selectedAddress, user)

                            const findAddress = addresses.find((address) => (address.coords[0] === selectedAddress.address_object.coords[0]) && (address.coords[1] === selectedAddress.address_object.coords[1]))

                            setInputValue('')
                            setSelectedAddress(null)

                            if (map && map.current) {
                              map.current.setCenter(findAddress.coords)
                            }

                            setSelectedDeliveryPoint(findAddress)

                            if (isMobile) {
                              history.push('/')
                            }
                          }
                        }}
                        style={{ width: '100%', maxWidth: 375 }}
                      />
                    )}
                </Box>
              )
            }
            {error && <span className={s.error}>{error}</span>}
            {
              user && user.addresses && user.addresses.length > 0 && isMobile && (
                <Box mt="sm" mb="sm">
                  <Radio.Group onChange={onChange} value={selectedDeliveryPointId} className={s.scrollAddresses}>
                    {
                      user.addresses.sort((a, b) => b.address_id - a.address_id).map((item) => {
                        const { address_id, restaurant_guid, title, street, house } = item || {}

                        return (
                          <Radio.Button
                            key={address_id || restaurant_guid}
                            value={address_id || restaurant_guid}
                          >
                            <Button
                              secondary
                              onClick={() => {
                                setSelectedDeliveryPoint(item)
                                history.push('/')
                              }}
                              icon="mapPin"
                              text={title ? <Text>{title}</Text> : <Text>{formatAddress(item)}</Text>}
                              className={cx(s.radioButton, (selectedDeliveryPointId == (address_id || restaurant_guid)) && s.selectedAddress)}
                            />
                          </Radio.Button>
                        )
                      })
                    }
                  </Radio.Group>
                </Box>
              )
            }
            {
              isMobile && (
                <Box mt="sm" mb="sm">
                  {isLoading
                    ? <div className={s.loader}><Spin spinning /></div>
                    :                    (
                      <Button
                        primary
                        text="Добавить адрес"
                        icon="plusWhite"
                        disabled={error || !selectedAddress
                      || !selectedAddress.address_object
                      || !selectedAddress.address_object.street
                      || !selectedAddress.address_object.house}
                        handleClick={async () => {
                          if (!selectedAddress
                          || !selectedAddress.address_object
                          || !selectedAddress.address_object.street
                          || !selectedAddress.address_object.house) {

                          }
                          else {
                            const addresses = await addAddress(selectedAddress, user)

                            const findAddress = addresses.find((address) => (address.coords[0] === selectedAddress.address_object.coords[0]) && (address.coords[1] === selectedAddress.address_object.coords[1]))

                            setInputValue('')
                            setSelectedAddress(null)

                            if (map && map.current) {
                              map.current.setCenter(findAddress.coords)
                            }

                            setSelectedDeliveryPoint(findAddress)
                          }
                        }}
                        style={{ width: '100%', maxWidth: 375 }}
                      />
                    )}
                </Box>
              )
            }
            {/* { */}
            {/*  user && user.addresses && user.addresses.length > 0 && isMobile && ( */}
            {/*    <Box mt='sm' className={s.buttonContainer}> */}
            {/*      <Button */}
            {/*        primary */}
            {/*        text='Выбрать этот адрес' */}
            {/*        isSmall */}
            {/*        disabled={!selectedDeliveryPointId || isRestaurantSelected || isLoading} */}
            {/*        handleClick={() => { */}
            {/*          history.goBack() */}
            {/*        }} */}
            {/*      /> */}
            {/*    </Box> */}
            {/*  ) */}
            {/* } */}
            {
              user && user.addresses && user.addresses.length > 0 && !isMobile && (
                <>
                  <Box mt="md">
                    <Box pl="xs" style={{ textAlign: 'left' }}>
                      <Text className={s.myAddresses}>Адреса</Text>
                    </Box>
                    <PickupPoint map={map} selectedPickupPoint={selectedDeliveryPointId} data={user.addresses} setSelectedPickupPoint={setSelectedDeliveryPoint} />
                  </Box>
                  {/* <Box mt='sm' className={s.buttonContainer} style={{ position: 'sticky', bottom: 0, zIndex: 99999, backgroundColor: '#FFF', marginLeft: -15, marginRight: -15, paddingLeft: 15, paddingRight: 15, paddingBottom: 24 }}> */}
                  {/*  <Button */}
                  {/*    primary */}
                  {/*    text='Выбрать этот адрес' */}
                  {/*    isSmall */}
                  {/*    disabled={!selectedDeliveryPointId || isRestaurantSelected} */}
                  {/*    handleClick={() => { */}
                  {/*      history.goBack() */}
                  {/*    }} */}
                  {/*  /> */}
                  {/* </Box> */}
                </>
              )
            }
          </>
        ) : (
          <>
            <Box>
              <PickupPoint map={map} data={restaurants} selectedPickupPoint={selectedDeliveryPointId} setSelectedPickupPoint={setSelectedDeliveryPoint} />
            </Box>
            <Box mt="sm" className={s.buttonContainer} style={{ marginBottom: 24 }}>
              <Button
                primary
                text="Выбрать этот адрес"
                isSmall
                handleClick={clickHandler}
                disabled={!isRestaurantSelected}
              />
            </Box>
          </>
        )
      }
    </Box>
  )
}

const AddressSelection = compose(
  withViewport,
)(AddressSelectionComponent)

const ConnectedAddressSelection = connect((state) => ({
  user: state.app.user,
  restaurants: getRestaurantsFromCities(state.app.cities),
  selectedDeliveryPointId: state.app.selectedDeliveryPointId,
}))(AddressSelection)

export default forwardRef((props, ref) =>
  <ConnectedAddressSelection {...props} myForwardedRef={ref} />,
)
