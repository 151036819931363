/* eslint-disable no-unused-expressions */
import { images } from 'components/ui/Image/index'


const getSrcString = (imageName, zoomCount) => {
  let result = []

  for (let i = 1; i <= zoomCount; i++) {
    i === 1
      ? result.push(`${images[imageName]} 1x`)
      : result.push(`${images[`${imageName}${i}x`]} ${i}x`)
  }

  return result.join(', ')
}

export const getSrcSet = (imageName) => {

  if (!images[imageName]) throw Error('Image name is not valid')
  if (!images[`${imageName}2x`]) return getSrcString(imageName, 1)
  if (!images[`${imageName}3x`]) return getSrcString(imageName, 2)

  return getSrcString(imageName, 3)
}
