exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3GqDtAIQ5fKuaLpDfyGynV {\n  height: 140px;\n  border-radius: 16px;\n  position: relative;\n  overflow: hidden;\n  opacity: 0.3;\n}\n@media (min-width: 415px) {\n  ._3GqDtAIQ5fKuaLpDfyGynV {\n    height: 160px;\n  }\n}\n.lUXF2Nlm8Jo0Dtuwh1X5V {\n  width: 100%;\n}\n._25CgVWbq2bH5CYvWmMlGhL {\n  position: absolute;\n  height: 164px;\n  margin-top: -15px;\n  object-fit: cover;\n}\n@media (min-width: 415px) {\n  ._25CgVWbq2bH5CYvWmMlGhL {\n    margin-top: 0;\n  }\n}\n._2lk89xuGGmZl5HzNWcY0dQ {\n  position: absolute;\n  height: 160px;\n  width: 410px;\n  object-fit: cover;\n}\n._1aTBjUOiL7fzqAw2b34Qak {\n  position: absolute;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  padding: 20px;\n}\n@media (min-width: 415px) {\n  ._1aTBjUOiL7fzqAw2b34Qak {\n    padding: 24px 30px;\n  }\n}\n._2LgJ_y9iDFvWx5fBPciAIs {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-end;\n}\n._2LgJ_y9iDFvWx5fBPciAIs span {\n  color: #fff;\n  font-family: 'Circe-Light', serif;\n  font-size: 14px;\n  line-height: 14px;\n}\n._2nqaHWoKA2YKTl8kGvGHx4 {\n  font-size: 20px;\n  max-width: 110px;\n  line-height: 20px;\n  font-family: 'Circe-Bold', serif;\n  text-align: left;\n  color: #fff;\n  margin-bottom: 5px !important;\n}\n@media (min-width: 415px) {\n  ._2nqaHWoKA2YKTl8kGvGHx4 {\n    font-size: 34px;\n    line-height: 36px;\n    max-width: 210px;\n  }\n}\n._2lMZFPexy0T5A5pL6ShJyH {\n  font-family: 'Circe-Regular', serif;\n  font-size: 14px;\n  line-height: 20px;\n  text-align: left;\n  color: #fff;\n}\n@media (min-width: 415px) {\n  ._2lMZFPexy0T5A5pL6ShJyH {\n    font-size: 20px;\n  }\n}\n.ucoL3CroMcobY2U3JFFM8 {\n  opacity: 1;\n}\n", ""]);

// Exports
exports.locals = {
	"comboContainer": "_3GqDtAIQ5fKuaLpDfyGynV",
	"isCarousel": "lUXF2Nlm8Jo0Dtuwh1X5V",
	"brgImageMobile": "_25CgVWbq2bH5CYvWmMlGhL",
	"brgImage": "_2lk89xuGGmZl5HzNWcY0dQ",
	"textBlock": "_1aTBjUOiL7fzqAw2b34Qak",
	"timeLeft": "_2LgJ_y9iDFvWx5fBPciAIs",
	"mainText": "_2nqaHWoKA2YKTl8kGvGHx4",
	"secondaryText": "_2lMZFPexy0T5A5pL6ShJyH",
	"active": "ucoL3CroMcobY2U3JFFM8"
};