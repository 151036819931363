import React from 'react'
import { Typography } from 'antd'
import cx from 'classnames'
import { NavLink, useHistory } from 'react-router-dom'
import { compose } from 'redux'

import Button from '../../components/ui/Button/Button'
import Image from '../../components/ui/Image'
import Icon from '../../components/ui/Icon'
import Box from '../../components/ui/Box/Box'

import { isMobileLayout, isTabletLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import s from './MapBanner.module.less'
import links from '../../helpers/links'


const { Text } = Typography

const MapBanner = (props) => {

  const { viewport } = props

  const history = useHistory()

  const isMobile = isMobileLayout(viewport)

  return (
    <Box mt="lg">
      <div className={cx(s.container)}>
        {/* <Image name='mapBanner' className={s.bgrImage} /> */}
        <Box pl={isMobile ? 'xxs' : 'xxl'} className={s.content}>
          <Icon name="deliveryIcon" />
          <Box ml={isMobile ? 'sm' : 'xlg'} mr={isMobile ? 'sm' : 'xxxl'} className={s.textBlock}>
            <Text className={s.mainText}>Быстрая доставка по Москве и Московской области</Text>
            <Text className={s.secondaryText}>Информация о наших зонах доставки и способах оплаты</Text>
          </Box>
          <Button
            text="Подробнее"
            primary
            isSmall
            handleClick={() => {
              history.push(links.delivery)
            }}
            style={{ width: 210, marginRight: 40 }} />
        </Box>
      </div>
    </Box>
  )
}

export default compose(
  withViewport,
)(MapBanner)
