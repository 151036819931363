import React, { useEffect, useMemo, useState } from 'react'
import { Checkbox, Col, Input, Layout, Row, Slider, Spin, Modal, Typography, List  } from 'antd'
import { Form } from 'react-final-form'
import { NavLink, Redirect, useHistory } from 'react-router-dom'
import cx from 'classnames'
import moment from 'moment'
import { connect } from 'react-redux'
import { compose } from 'redux'
import links from 'helpers/links'

import Box from 'components/ui/Box/Box'
import Button from 'components/ui/Button/Button'
import WidthContainer from '../../components/layout/WidthContainer/WidthContainer'
import FormComponent from './components/Form/Form'
import { checkOrder, checkPromocode, checkQrPayment, setQrPaymentStatus, sendOrder, setQrInfo, appendOrder, clearBasket, setCurrentOrderData } from '../../redux/actions'
import getTotalOrderPrice from '../../helpers/getTotalOrderPrice'
import { isMobileLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import Icon from '../../components/ui/Icon'

import s from './OrderForm.module.less'
import getDeliveryPointById from '../../helpers/getDeliveryPointById'
import getRestaurantsFromCities from '../../helpers/getRestaurantsFromCities'
import getDishDeclension from '../../helpers/getDishDeclension'
import formatAddress from '../../helpers/formatAddress'
import setSeoTags from '../../helpers/setSeoTags'
import { generateTimeRangesEat } from '../../helpers/generateTimeRangesEat'
import getIsMobileDevice from '../../helpers/getIsMobileDevice'


const { Text } = Typography
const { Content } = Layout

const OrderFormComponent = (props) => {
  const {
    basketItems,
    isLoggedIn,
    user,
    sections,
    totalOrderPrice,
    viewport,
    personsCount,
    selectedDeliveryPoint,
    selectedRestaurantGuid,
    delayedDeliveryHour,
    cards,
    paymentTypes,
    qrInfo,
    qrPaymentStatus,
    currentOrder,
  } = props

  const history = useHistory()
  const [discountSum, setDiscountSum] = useState(0)
  const [fetchError, setFetchError] = useState('')
  const [localError, setLocalError] = useState(null)
  const [prevLocalError, setPrevLocalError] = useState(null)
  const [errorPoints, setErrorPoints] = useState('')
  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [minDateDelivery, setMinDateDelivery] = useState('')
  const [discountName, setDiscountName] = useState('')
  const [couponLoading, setCouponLoading] = useState(false)
  const [isPointsSettingsVisible, setIsPointsSettingsVisible] = useState(false)
  const isMobile = isMobileLayout(viewport)
  const [couponError, setCouponError] = useState(false)
  const [coupon, setCoupon] = useState('')
  const [acceptedCoupon, acceptCoupon] = useState(false)
  const [checkedOrder, setCheckedOrder] = useState(null)
  const [cost, setCost] = useState(null)
  const [priceDelivery, setPriceDelivery] = useState(null)
  const [maxPointsValue, setMaxPointsValue] = useState(checkedOrder ? checkedOrder.payment_by_points_max : 0)
  const [value, setValue] = useState(maxPointsValue)
  const [isDiscount, setIsDiscount] = useState(false)
  const [timeRange, setTimeRange] = useState([0, 24])
  const timeRangesEat = generateTimeRangesEat(sections)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const modalListData = [
    'Отсканируйте QR-код с камеры смартфона',
    'Выберите банк для оплаты',
    'В открывшемся приложение банка, подтвердите оплату',
    'Если оплата прошла успешно, нажмите кнопку  “я оплатил/а” для возможности отслеживания вашего заказа',
  ]

  const clearBasketAndAppendOrder = (orderData) => {
    appendOrder(orderData)
    clearBasket()
  }

  const redirectAfterSuccessQrPayment = (orderNumber) => {

    setQrPaymentStatus('default')

    clearBasketAndAppendOrder(currentOrder)
    localStorage.removeItem('QRinfo')

    if (orderNumber) {
      history.push(`${links.order}/${orderNumber}`)
    }
  }

  const cardsWithNewCard = useMemo(() => ([
    ...cards,
    {
      id: '',
      display_label: 'Добавить новую карту',
      payment_system: null,
      icon_url: '',
    },
  ]), [cards])

  useEffect(() => {
    setMaxPointsValue(checkedOrder ? checkedOrder.payment_by_points_max : 0)
  }, [checkedOrder])

  useEffect(() => {
    coupon && !acceptedCoupon && setIsPointsSettingsVisible(false)
  }, [coupon, acceptedCoupon])

  const checkMobileQrPayment = () => {
    const storedQRinfo = JSON.parse(localStorage.getItem('QRinfo'))
    if (storedQRinfo && currentOrder) {
      window.slideUpWidget.closeWidget()

      setIsModalVisible(true)

      checkQrPayment({
        orderNumber: storedQRinfo.orderNumber,
        orderGuid: storedQRinfo.orderGuid,
        orderId: storedQRinfo.orderId,
        qrId: storedQRinfo.id,
      })
        .then((response) => {

          if (response.status === 'PENDING' || response.status === 'CONFIRMED') {

            const intervalId = setInterval(async () => {

              await checkQrPayment({
                orderNumber: storedQRinfo.orderNumber,
                orderGuid: storedQRinfo.orderGuid,
                orderId: storedQRinfo.orderId,
                qrId: storedQRinfo.id,
              })
                .then((response) => {
                  if (response.status === 'SUCCESS') {
                    clearInterval(intervalId)

                    setIsModalVisible(false)

                    redirectAfterSuccessQrPayment(storedQRinfo.orderNumber)
                  }

                  if (response.status === 'FAIL') {
                    clearInterval(intervalId)

                    window.removeEventListener('focus', checkMobileQrPayment)

                    setQrPaymentStatus('FAIL')
                  }
                })
            }, 3000)
          }
        })
    }
  }

  useEffect(() => {
    if (getIsMobileDevice()) {
      window.addEventListener('focus', checkMobileQrPayment)

      return () => {
        window.removeEventListener('focus', checkMobileQrPayment)
      }
    }
  }, [currentOrder])

  const checkAndSetCouponData = () => {
    if (coupon && acceptedCoupon) {
      return { coupon }
    }
  }

  const checkCode = async () => {
    try {
      setCouponLoading(true)
      setCouponError(false)
      const date = new Date(initialData.date)
      const hours = parseInt(initialData.hours, 10)
      const minutes = parseInt(initialData.minutes, 10)
      date.setHours(hours, minutes)

      const dateString = moment(date).format()

      const r = await checkPromocode({
        'client_guid': user.user_guid,
        'client_name': `${user.name} ${user.surname}`,
        'client_phone': user.phone_format,
        'address_object': initialData.is_self_service ? undefined : address,
        'restaurant_guid': initialData.restaurant_guid,
        // 'comment': values.comments,
        'coupon': coupon,
        'is_self_service': initialData.is_self_service,
        'persons_count': personsCount,
        'payment_points': isPointsSettingsVisible && value,
        'products': basketItems.map((item) => {
          const data = {
            'product_guid': item.products_guid,
            'quant': item.count,
            'groups': item.groups ? item.groups.filter(
              (groups) => groups.quant).map((groups) => ({
              'product_guid': groups.component_guid,
              'quant': groups.quant,
            })) : [],
            'modifications': item.modifications ? item.modifications.filter(
              (modification) => modification.quant).map((modification) => ({
              'product_guid': modification.component_guid,
              'quant': modification.quant,
            })) : [],
          }

          if (item.switchers) {
            Object.keys(item.switchers).forEach((key) => {
              data.groups.push({
                'component_guid': item.switchers[key].component_guid,
                'quant': item.switchers[key].quant,
                'section_guid': key,
              })
            })
          }

          if (item.products) {
            data.groups.push(...item.products.map((product) => ({
              'component_guid': product.component_guid,
              'quant': 1,
              'section_guid': product.section_guid,
            })))
          }

          if (item.components_objects) {
            data.modifications.push(...item.components_objects.map((product) => ({
              'product_guid': product.component_guid,
              'quant': 1,
            })))
          }

          return data
        }),
      })

      if (r && !couponLoading) {
        setValue(r.payment_by_points_max)
        setMaxPointsValue(r.payment_by_points_max)
      }
      else {
        checkedOrder.payment_by_points_max
      }

      if (r) {
        if (r.discount && !r.discount.error) {
          setCost(Math.floor(r.price_final))
          setIsDiscount(true)
          setErrorPoints('')
          if (r.discount.discounts) {
            setDiscountSum(r.price_discount)
          }
          else {
            setDiscountName(r.discount.message)
          }
          acceptCoupon(true)
        }
        else {
          setDiscountSum(0)
          acceptCoupon(false)
          setCouponError(r.discount && r.discount.message || 'Промокод введён неправильно')
        }
      }
      else {
        setDiscountSum(0)
        acceptCoupon(false)
        setCouponError(true)
      }
      setCouponLoading(false)
    }
    catch (e) {
      setCouponLoading(false)
      setErrorPoints(e.message)
    }
  }

  const prepareOrder = async () => {
    try {
      setFetching(true)
      const ord = await checkOrder({
        'client_guid': user.user_guid,
        'client_name': `${user.name} ${user.surname}`,
        'client_phone': user.phone_format,
        'address_object': initialData.is_self_service ? undefined : address,
        'restaurant_guid': initialData.restaurant_guid || selectedRestaurantGuid,
        // 'comment': values.comments,
        ...checkAndSetCouponData(),
        'is_self_service': initialData.is_self_service,
        'persons_count': personsCount,
        'payment_points': isPointsSettingsVisible ? value : 0,
        'products': basketItems.map((item) => {
          const data = {
            'product_guid': item.products_guid,
            'quant': item.count,
            'groups': item.groups ? item.groups.filter(
              (groups) => groups.quant).map((groups) => ({
              'product_guid': groups.component_guid,
              'quant': groups.quant,
            })) : [],
            'modifications': item.modifications ? item.modifications.filter(
              (modification) => modification.quant).map((modification) => ({
              'product_guid': modification.component_guid,
              'quant': modification.quant,
            })) : [],
          }

          if (item.switchers) {
            Object.keys(item.switchers).forEach((key) => {
              data.groups.push({
                'component_guid': item.switchers[key].component_guid,
                'quant': item.switchers[key].quant,
                'section_guid': key,
              })
            })
          }

          if (item.products) {
            data.groups.push(...item.products.map((product) => ({
              'component_guid': product.component_guid,
              'quant': 1,
              'section_guid': product.section_guid,
            })))
          }

          if (item.components_objects) {
            data.modifications.push(...item.components_objects.map((product) => ({
              'product_guid': product.component_guid,
              'quant': 1,
            })))
          }

          return data
        }),
      })

      if (ord) {
        setCost(Math.floor(ord.price_final + ord.payment_by_points))
        setPriceDelivery(ord.price_delivery)
        setFetchError('')
        setCheckedOrder(ord)
        setValue(ord.payment_by_points_max)
        if (ord.date_delivery) {
          setMinDateDelivery(ord.date_delivery)
        }
      }

    }
    catch (e) {
      if (e && e.message) {
        setFetchError(e.message)
      }
    }
    finally {
      setFetching(false)
    }
  }

  useEffect(() => {
    setSeoTags({
      title: `Eat Street - Оформление заказа`,
      seo_desc: '',
      seo_keyw: '',
    })

    // Проверка есть ли в заказе Завтрак/Обед/Ужин, который не могут быть заказаны сейчас/либо на указанное время
    let error = false
    const productsInBasket = JSON.parse(localStorage.getItem('basketItems'))
    const timeNow = new Date().getHours()
    for (const product of productsInBasket) {
      for (const key in timeRangesEat) {
        if (product.section_guid === timeRangesEat[key].guid
          && !(timeNow >= timeRangesEat[key].timeRange[0] && (timeNow < timeRangesEat[key].timeRange[1]))
        ) {
          setLocalError(timeRangesEat[key].message)
          setTimeRange(timeRangesEat[key].timeRange)
          error = true
        }
      }
    }

    // Если таких позиций нет, то проверяем данные на сервере
    if (!error) prepareOrder()
  }, [])

  useEffect(() => {
    if (localError === '') prepareOrder()
  }, [localError])

  // Проверка попадает ли в необходимый временной диапазон для Завтрака/Обеда/Ужина выбранное отложенное время
  useEffect(() => {
    if (delayedDeliveryHour) {
      if (delayedDeliveryHour >= timeRange[0] && delayedDeliveryHour < timeRange[1]) {
        if (localError) setPrevLocalError(localError)
        setLocalError('')
      }
      else if (!localError) {
        setLocalError(prevLocalError)
      }
    }
  }, [delayedDeliveryHour])

  if (!isLoggedIn) {
    return (
      <Redirect to={{
        pathname: links.login,
        state: { to: links.orderForm },
      }} />
    )
  }

  const validate = (values) => {
    const errors = {}
    if (!values.address) {
      errors.address = 'Required'
    }
    if (!values.apartment) {
      errors.apartment = 'Required'
    }
    if (!values.phone) {
      errors.phone = 'Required'
    }
    if (!values.date) {
      errors.date = 'Required'
    }
    if (!values.time) {
      errors.time = 'Required'
    }
    if (!values.payment) {
      errors.payment = 'Required'
    }
    return errors
  }

  if (!user) {
    return null
  }

  let estimated = 30

  if (selectedDeliveryPoint) {
    estimated = selectedDeliveryPoint.delivery_time_avg || selectedDeliveryPoint.time_delivery || 30
  }

  const onSubmit = async (values) => {

    setLoading(true)
    const date = new Date(values.date)
    const hours = parseInt(values.hours, 10)
    const minutes = parseInt(values.minutes, 10)
    date.setHours(hours, minutes)
    date.setSeconds(0)
    date.setMilliseconds(0)

    const dateString = moment(date).format()

    const {
      apartment,
      floor,
      entrance,
      intercom,
      comments,
      is_self_service,
      restaurant_guid,
    } = values

    const address = {
      ...(selectedDeliveryPoint || user.addresses[0]),
      apartment,
      floor,
      entrance,
      intercom,
      comment: comments,
    }

    try {
      const orderFields = {
        'date_delivery': values.asap ? undefined : dateString,
        'date_created': moment(new Date()).format(),
        'client_guid': user.user_guid,
        'client_name': `${user.name} ${user.surname}`,
        'client_phone': user.phone_format,
        'address_object': is_self_service ? undefined : address,
        'restaurant_guid': restaurant_guid || selectedRestaurantGuid,
        'qr_size': {
          width: 222,
          height: 229,
        },
        'comment': values.orderComments,
        ...checkAndSetCouponData(),
        'is_call': !values.not_call,
        'is_self_service': is_self_service,
        'persons_count': personsCount,
        'payment_points': isPointsSettingsVisible ? value : 0,
        'payment_type': {
          'payment_type_guid': values.payment,
          'value': totalOrderPrice - ((isPointsSettingsVisible && value) || 0) - (checkedOrder ? checkedOrder.price_discount : 0),
        },
        'payment_card_id': paymentTypes.find((payment) => payment.payment_type_guid === values.payment).is_online_pay && values.card ? values.card : null,
        'products': basketItems.map((item) => {
          const data = {
            'product_guid': item.products_guid,
            'quant': item.count,
            'groups': item.groups ? item.groups.filter(
              (groups) => groups.quant).map((groups) => ({
              'product_guid': groups.component_guid,
              'quant': groups.quant,
            })) : [],
            'modifications': item.modifications ? item.modifications.filter(
              (modification) => modification.quant).map((modification) => ({
              'product_guid': modification.component_guid,
              'quant': modification.quant,
            })) : [],
          }


          if (item.switchers) {
            Object.keys(item.switchers).forEach((key) => {
              data.groups.push({
                'component_guid': item.switchers[key].component_guid,
                'quant': item.switchers[key].quant,
                'section_guid': key,
              })
            })
          }

          if (item.products) {
            data.groups.push(...item.products.map((product) => ({
              'component_guid': product.component_guid,
              'quant': 1,
              'section_guid': product.section_guid,
            })))
          }

          if (item.components_objects) {
            data.modifications.push(...item.components_objects.map((product) => ({
              'product_guid': product.component_guid,
              'quant': 1,
            })))
          }

          return data
        }),
      }
      localStorage.setItem('orderFields', JSON.stringify(orderFields))

      const order = await sendOrder(orderFields)

      if (window.gtag) {
        try {
          window.gtag('event', 'purchase', {
            transaction_id: `${order.order_number}`,
            affiliation: 'Eat Street',
            currency: 'RUB',
            tax: 0,
            shipping: 0,
            get value() {
              return totalOrderPrice - ((isPointsSettingsVisible && value) || 0) - (checkedOrder ? checkedOrder.price_discount : 0) - discountSum
            },
            items: basketItems.map((item, index) => ({
              id: item.products_guid,
              name: item.title,
              list_name: 'catalog',
              brand: 'Eat Street',
              get category() {
                const find = sections.find(
                  ({ section_guid }) => section_guid === item.section_guid)
                return find ? find.title : ''
              },
              get variant() {
                let variant = ''

                if (item.switchers) {
                  Object.keys(item.switchers).forEach((key) => {
                    variant = item.switchers[key].title
                  })
                }

                return variant
              },
              list_position: index,
              quantity: item.count,
              price: item.price,
            })),
          })
        }
        catch (e) {
        }
      }

      setLoading(false)


      if (order.qr_info) {
        if (getIsMobileDevice()) {
          localStorage.setItem('QRinfo', JSON.stringify(({ orderId: order.order_payment_id, orderGuid: order.order_guid, orderNumber:order.order_number, ...order.qr_info })))
          window.slideUpWidget.openWidget(order.qr_info.payload)
        }
        else {
          setIsModalVisible(true)
        }
        setCurrentOrderData(order)
        setQrInfo({ orderId: order.order_payment_id, orderGuid: order.order_guid, orderNumber:order.order_number, ...order.qr_info })
      }
      else if (order.pay_link) {
        clearBasketAndAppendOrder(currentOrder)

        window.location.assign(order.pay_link)
      }
      else {
        clearBasketAndAppendOrder(currentOrder)

        history.push(`${links.order}/${order.order_number}`, {
          fromOrder: true,
        })
      }
    }
    catch (e) {
      setLoading(false)
      if (e.error) {
        setFetchError(`${e.message}`)
      }
    }
  }

  if (!selectedDeliveryPoint.restaurant_guid) {
    selectedDeliveryPoint.restaurant_guid = selectedRestaurantGuid
  }

  const address = selectedDeliveryPoint || user.addresses
    && user.addresses[0] || null

  const minDate = new Date()

  minDate.setMinutes(minDate.getMinutes() + estimated + 5)

  const nowDate = new Date()
  const nowHours = nowDate.getHours()
  let deliveryTime = true

  if (nowHours < 10 || nowHours >= 22) {
    deliveryTime = true
  }


  const initialData = {
    'orderComments': '',
    'not_call': true,
    'address': address && !address.areas
      ? formatAddress(address)
      : address.address,
    'entrance': address && address.entrance,
    'floor': address && address.floor,
    'apartment': address && address.apartment,
    'restaurant_guid': address && address.restaurant_guid
      ? address.restaurant_guid
      : selectedRestaurantGuid,
    'comments': address && address.comment || '',
    'phone': user.phone_format,
    'is_self_service': !!(address && address.areas),
    'date': minDate,
    'hours': minDate.getHours(),
    'minutes': minDate.getMinutes(),
    'asap': deliveryTime,
    'card': cardsWithNewCard[0].id || '',
    'payment': paymentTypes[0].payment_type_guid,
  }

  const totalItems = basketItems.reduce((r, a) => r + a.count, 0)

  const checkBoxHandler = (e) => {
    setIsPointsSettingsVisible(!e.target.checked)
  }

  return (
    <WidthContainer>
      <Layout>
        <Content>
          <Modal
            visible={isModalVisible}
            maskStyle={{ background: 'rgba(255, 255, 255, .75)' }}
            className={s.modal}
            footer={null}
            closable
            maskClosable
            centered
            closeIcon={(
              <Icon
                name="close"
                onClick={() => {
                  setQrPaymentStatus('default')
                  setIsModalVisible(false)
                }}  />
            )}
            zIndex={10001}
          >
            <h3 className={s.modalTitle}>Оплата</h3>
            {
              qrPaymentStatus === 'default' || qrPaymentStatus === 'PENDING'
                ?              (
                  <>
                    <div className={s.modalContent}>
                      <img className={s.qrImage} src={`data:image/png;base64,${qrInfo.rendered_qr}`} />
                      <List
                        className={s.modalList}
                        dataSource={modalListData}
                        renderItem={
                          (item, index) => (
                            <List.Item className={s.modalListItem}>
                              <Text className={s.modalListItemText}>
                                {index + 1}. {item}
                              </Text>
                            </List.Item>
                          )
                        } />
                    </div>

                    {
                      qrPaymentStatus === 'default'
                        ?                      (
                          <>
                            <Box className={s.spinnerWrapper}>
                              <Spin spinning color="red" />
                            </Box>

                            <Box mt="md" className={s.modalBtnContainer}>
                              <button
                                type="button"
                                className={s.modalButtonSuccess}
                                onClick={() => checkQrPayment({
                                  orderNumber: qrInfo.orderNumber,
                                  orderGuid: qrInfo.orderGuid,
                                  orderId: qrInfo.orderId,
                                  qrId: qrInfo.id })
                                  .then((response) => {

                                    if (response.status === 'PENDING' || response.status === 'CONFIRMED') {
                                      setQrPaymentStatus('PENDING')

                                      const intervalId = setInterval(async () => {
                                        await checkQrPayment({
                                          orderNumber: qrInfo.orderNumber,
                                          orderGuid: qrInfo.orderGuid,
                                          orderId: qrInfo.orderId,
                                          qrId: qrInfo.id })
                                          .then((response) => {

                                            if (response.status === 'SUCCESS') {

                                              clearInterval(intervalId)
                                              redirectAfterSuccessQrPayment(qrInfo.orderNumber)
                                            }

                                            if (response.status === 'FAIL') {
                                              clearInterval(intervalId)
                                              setQrPaymentStatus('FAIL')
                                            }
                                          })
                                      }, 3000)
                                    }

                                    if (response.status === 'SUCCESS') {
                                      redirectAfterSuccessQrPayment(qrInfo.orderNumber)
                                    }

                                    if (response.status === 'FAIL') {
                                      setQrPaymentStatus('FAIL')
                                    }
                                  })}
                              >
                                {fetching
                                  ? <Spin spinning color="red" />
                                  : 'Я оплатил/а'}
                              </button>


                            </Box>
                          </>
                        )
                        :                         (
                          <Box mt="md" className={s.QRLoaderContainer}>
                            <Spin spinning />
                          </Box>
                        )
                    }

                    <p className={s.copyRight}>Все платежи проводятся  Системой Быстрых Платежей.
                      ©2022 Система Быстрых Платежей. Все права защищены.
                    </p>
                  </>
                )
                : (
                  <>
                    <img src="/qrPaymentWarning.png" className={s.failurePaymentImg} />
                    <h2 className={s.failurePaymentTitle}>Оплата не прошла</h2>

                    <Box mt="md" className={s.modalBtnContainer}>
                      <button
                        type="button"
                        className={s.modalButtonFailed}
                        onClick={() => {
                          const orderFields = JSON.parse(localStorage.getItem('orderFields'))
                          if (orderFields) {
                            setLoading(true)
                            sendOrder(JSON.parse(localStorage.getItem('orderFields')))
                              .then((resp) => {
                                if (resp.qr_info) {
                                  if (getIsMobileDevice()) {
                                    setCurrentOrderData(resp)
                                    window.slideUpWidget.openWidget(resp.qr_info.payload)
                                  }
                                  else {
                                    setIsModalVisible(true)
                                  }

                                  setQrPaymentStatus('default')
                                  localStorage.setItem('QRinfo', JSON.stringify(({ orderId: resp.order_payment_id, orderGuid: resp.order_guid, orderNumber:resp.order_number, ...resp.qr_info })))
                                  setQrInfo({ orderId: resp.order_payment_id, orderGuid: resp.order_guid, orderNumber:resp.order_number, ...resp.qr_info })
                                }
                              })
                              .catch((e) => {
                                if (e.error) {
                                  setFetchError(`${e.message}`)
                                }
                              })
                              .finally(setLoading(false))
                          }
                        }}
                      >
                        {fetching
                          ? <Spin spinning color="red" />
                          : 'Попробовать еще раз'}
                      </button>
                    </Box>
                  </>
                )
            }
          </Modal>
          <Box pt={isMobile ? 'sm' : ''} pb={isMobile ? 'xlg' : ''}>
            {
              isMobile && (
                <Box mb="md" className={s.topMobileRow}>
                  <Button
                    type="icon"
                    icon="left"
                    isSmall
                    onClick={() => {
                      history.push(user ? links.basket : links.login)
                    }} />
                  <NavLink to={user ? links.basket : links.login}>
                    <Text>Изменить данные заказа</Text>
                  </NavLink>
                </Box>
              )
            }
            <Form
              onSubmit={onSubmit}
              validate={validate}
              keepDirtyOnReinitialize
              initialValuesEqual={() => true}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value)
                },
              }}
              initialValues={initialData}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form onSubmit={(e) => {
                  e.preventDefault()
                  if (window.gtag) {
                    window.gtag('event', '_clicktoorder_')
                  }
                  onSubmit(values)
                }}>
                  <Box>
                    <Row style={{ justifyContent: 'space-between' }}>
                      <Col
                        span={isMobile ? 24 : 17}
                        style={{ paddingRight: isMobile ? 0 : 70 }}>
                        <FormComponent
                          paymentTypes={paymentTypes}
                          cards={cardsWithNewCard}
                          setValue={form.mutators.setValue}
                          deliveryTime={deliveryTime}
                          minDateDelivery={minDateDelivery}
                          setMinDate={() => {
                            const minDate = minDateDelivery ? new Date(minDateDelivery) : new Date()
                            minDate.setMinutes(minDate.getMinutes() + estimated)

                            form.mutators.setValue('date', minDate)
                            form.mutators.setValue('hours', minDate.getHours())
                            form.mutators.setValue('minutes', minDate.getMinutes())
                            form.mutators.setValue('asap', true)
                          }}
                          is_self_service={values.is_self_service} />
                      </Col>

                      <Col
                        span={isMobile ? 24 : 7}
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          flexDirection: 'column',
                        }}>
                        {
                          !isMobile && (
                            <Box mt="md" mb="md" alignRight>
                              <NavLink to={user ? links.basket : links.login}>
                                <Text className={s.editBasket}>Изменить данные
                                  заказа
                                </Text>
                              </NavLink>
                            </Box>
                          )
                        }

                        {!fetching
                          && (
                            <Box
                              mt={isMobile ? 'md' : ''}
                              p={isMobile ? 'sm' : 'lg'}
                              className={s.orderPriceContainer}>
                              <Row>
                                <Col span={16} className={s.textContainer}>
                                  <Text className={s.darkText}>Итого к оплате</Text>
                                  <Text className={s.largeText}>
                                    {(cost - ((isPointsSettingsVisible && value) || 0)) % 1 === 0
                                      ? +(cost - ((isPointsSettingsVisible && value) || 0)).toFixed(2)
                                      : (cost - ((isPointsSettingsVisible && value) || 0)).toFixed(2)} ₽
                                  </Text>
                                </Col>
                              </Row>
                              <Box mt="sm">
                                <Row justify="space-between" mt="md">
                                  <Col>
                                    <Text className={s.thinText}>
                                      {getDishDeclension(totalItems)}
                                    </Text>
                                  </Col>
                                  <Col>
                                    <Text className={s.darkTextSmall}>
                                      {totalOrderPrice} ₽
                                    </Text>
                                  </Col>
                                </Row>
                              </Box>

                              <Box mt="sm" className={s.inputContainer}>
                                <Input
                                  placeholder="Промокод"
                                  bordered={false}
                                  value={coupon}
                                  disabled={couponLoading}
                                  onChange={(e) => {
                                    if (acceptedCoupon) {
                                      setDiscountSum(0)
                                      acceptCoupon(false)
                                    }
                                    setCoupon(e.target.value)
                                  }} />
                                <Button
                                  text={couponLoading
                                    ? <Spin spinning color="red" />
                                    : acceptedCoupon ? 'Применен' : 'Применить'}
                                  secondary
                                  isSmall
                                  className={s.applyBtn}
                                  handleClick={() => {
                                    if (couponLoading || acceptedCoupon || !coupon) {
                                      return false
                                    }
                                    checkCode()
                                  }} />
                              </Box>

                              {couponError
                              && (
                                <Box mt="sm" className={s.rows}>
                                  <Row>
                                    <Col span={24}>
                                      <Text
                                        className={s.thinText}
                                        style={{ color: '#DD9059' }}>{couponError}
                                      </Text>
                                    </Col>
                                    <Col span={0} />
                                  </Row>
                                </Box>
                              )}


                              {!discountName && acceptedCoupon && coupon
                              && (
                                <Box mt="sm" className={s.rows}>
                                  <Row>
                                    <Col span={18}>
                                      <Text className={s.thinText}>Промокод</Text>
                                    </Col>
                                    <Col span={6}>
                                      <Text className={s.darkTextSmall}>
                                        -{+(discountSum) % 1 === 0
                                          ? +(discountSum).toFixed(2)
                                          : (discountSum).toFixed(2)} ₽
                                      </Text>
                                    </Col>
                                  </Row>
                                </Box>
                              )}
                              {discountName && acceptedCoupon && coupon
                              && (
                                <Box mt="sm" className={s.rows}>
                                  <Row>
                                    <Col span={24}>
                                      <Text className={s.thinText}>Промокод
                                        активирован. {discountName}
                                      </Text>
                                    </Col>
                                    <Col span={0} />
                                  </Row>
                                </Box>
                              )}

                              {!!user.points && !!maxPointsValue
                              && (
                                <Box
                                  mt="md"
                                  pl="sm"
                                  className={s.couponWrapper}
                                  onClick={() => {
                                    setIsPointsSettingsVisible(!isPointsSettingsVisible)
                                  }}>
                                  <Checkbox
                                    onChange={checkBoxHandler}
                                    checked={isPointsSettingsVisible}
                                  />
                                  <Box ml="sm">
                                    <Text className={s.pointsText}>Списать</Text><br />
                                    <Text
                                      className={s.pointsValue}>{value} баллов
                                    </Text>
                                  </Box>
                                </Box>
                              )}
                              {errorPoints && <div style={{ color: '#E03F3F' }}>{errorPoints}</div>}

                              {isPointsSettingsVisible
                              && (
                                <Box mt="sm" className={s.sliderContainer}>
                                  <Text>Настройте баллы</Text>
                                  <div className={s.sliderWrapper}>
                                    <Slider
                                      onChange={(val) => setValue(val)}
                                      value={value}
                                      defaultValue={0}
                                      max={maxPointsValue}
                                      tooltipVisible={false} />
                                    <Text className={s.pointsValue}>{value} Б</Text>
                                  </div>
                                  <Text className={s.subText}>Баллами можно оплатить
                                    до 30% от суммы заказа
                                  </Text>
                                </Box>
                              )}

                              <Box mt="md" className={s.rows}>
                                {checkedOrder && !!checkedOrder.price_discount && (
                                  <Row>
                                    <Col span={18}>
                                      <Text className={s.thinText}>Скидка</Text>
                                    </Col>
                                    <Col span={6}>
                                      {isDiscount
                                        ? <Text className={s.darkTextSmall}> ₽ </Text>
                                        : (
                                          <Text className={s.darkTextSmall}>
                                            - {checkedOrder.price_discount} ₽
                                          </Text>
                                        )}
                                    </Col>
                                  </Row>
                                )}
                                {!!value && isPointsSettingsVisible && (
                                  <Row>
                                    <Col span={18}>
                                      <Text className={s.thinText}>
                                        Оплата баллами
                                      </Text>
                                    </Col>
                                    <Col span={6}>
                                      <Text
                                        className={s.darkTextSmall}>-{value} Б
                                      </Text>
                                    </Col>
                                  </Row>
                                )}
                                <Row>
                                  <Col span={18}>
                                    <Text className={s.thinText}>
                                      Минимальная сумма заказа
                                    </Text>
                                  </Col>
                                  <Col span={6}>
                                    <Text
                                      className={s.darkTextSmall}>{selectedDeliveryPoint.amount_min || 0} ₽
                                    </Text>
                                  </Col>
                                </Row>
                                {!fetchError && !localError
                                && (
                                  <Row>
                                    <Col span={18}>
                                      <Text className={s.thinText}>Доставка</Text>
                                    </Col>
                                    <Col span={6}>
                                      <Text
                                        className={s.darkTextSmall}>{priceDelivery === 0 ? 'Бесплатно' : `${priceDelivery} ₽`}
                                      </Text>
                                    </Col>
                                  </Row>
                                )}
                              </Box>
                              {loading ? (
                                <div className={s.loader}>
                                  <Spin spinning />
                                </div>
                              ) : (
                                <>
                                  <Box mt="md" className={s.btnContainer}>
                                    <button
                                      type="submit"
                                      className={cx(s.submitButton,
                                        (!checkedOrder || submitting || !values.payment || !values.date || (!values.asap && (values.hours === null || values.minutes === null))),
                                      // && s.disabled)
                                      )}
                                      data-test={`${values.payment}`}
                                    >
                                      {fetching
                                        ? <Spin spinning color="red" /> : 'Заказать'}
                                    </button>
                                  </Box>
                                  {fetching
                                  && (
                                    <Text className={s.checkText}>Уточняем баланс
                                      баллов
                                    </Text>
                                  )}
                                </>
                              )}
                              {(localError || fetchError) && (
                                <Box
                                  mt="md"
                                  style={{
                                    color: '#E03F3F',
                                  }}>
                                  {localError || fetchError}
                                </Box>
                              )}
                            </Box>
                          )}
                        {fetching && !localError
                          && (
                            <Box
                              mt={isMobile ? 'md' : ''}
                              p={isMobile ? 'sm' : 'lg'}
                              className={cx(s.orderPriceContainer, s.orderPriceContainerEmpty)}>
                              <Spin spinning />
                            </Box>
                          )}
                      </Col>
                    </Row>
                  </Box>
                </form>
              )}
            />
          </Box>
        </Content>

      </Layout>
    </WidthContainer>
  )
}

const OrderForm = compose(
  withViewport,
)(OrderFormComponent)

export default connect((state) => ({
  user: state.app.user,
  basketItems: state.app.basketItems,
  personsCount: state.app.personsCount,
  productSwitchers: state.app.productSwitchers,
  isLoggedIn: state.app.user && state.app.user.type !== 'guest',
  totalOrderPrice: getTotalOrderPrice(state.app.basketItems),
  selectedDeliveryPoint: getDeliveryPointById(state.app.selectedDeliveryPointId,
    state.app.user && state.app.user.addresses || [],
    getRestaurantsFromCities(state.app.cities)),
  paymentTypes: state.app.paymentTypes,
  comboMealComponents: state.app.comboMealComponents,
  sections: state.app.sections,
  selectedRestaurantGuid: state.app.selectedRestaurantGuid,
  delayedDeliveryHour: state.app.delayedDeliveryHour,
  cards: state.app.paymentCards,
  qrInfo: state.app.qrInfo,
  currentOrder: state.app.currentOrder,
  qrPaymentStatus: state.app.qrPaymentStatus,
}))(OrderForm)
