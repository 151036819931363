import React, { useState, Fragment } from 'react'
import { Typography, Row, Col, Switch } from 'antd'
import cx from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Box from '../../components/ui/Box/Box'
import BasketItem from './components/BasketItem/BasketItem'

import { withViewport } from '../../helpers/withViewport'
import { isMobileLayout, isTabletMiddleLayout } from '../../helpers/isMobileLayout'

import s from './BasketList.module.less'
import getDishDeclension from '../../helpers/getDishDeclension'
import ProductInfo from '../../components/ui/ProductInfo/ProductInfo'


const { Text } = Typography

const BasketListComponent = ({ basketItems, viewport, personsCount, comboMealComponents, productSwitchers }) => {

  const isMobile = isMobileLayout(viewport)

  const totalItems = basketItems.reduce((r, a) => r + a.count, 0)

  return (
    <Row>
      <Text className={s.sectionTitle}>В корзине {getDishDeclension(totalItems)}</Text>
      {
        basketItems.map((item, index) => {
          const { modifications, alias } = item || {}

          const combo = comboMealComponents && comboMealComponents.length > 0 ? comboMealComponents.filter((el) => el.mealId === alias) : []
          const comboComponents = combo && combo.length > 0 && combo[0].groups

          const getTotalMealWeight = () => {
            if (comboMealComponents && comboMealComponents.length > 0) {
              const indexOfMealInArray = comboMealComponents.findIndex((el) => el.mealId === alias)
              if (indexOfMealInArray > -1) {
                let weightsArr = []
                comboMealComponents[indexOfMealInArray].groups.forEach((i) => {
                  weightsArr.push(i.productComponent.weight)
                })
                return weightsArr.reduce((a, b) => a + b)
              }
            }
          }

          return (
            <Fragment key={`key${JSON.stringify(item).length}`}>
              <Col span={24}>
                <BasketItem
                  index={index}
                  item={item}
                  switcher={item.switchers}
                  isCombo={combo && combo.length > 0}
                  totalWeight={getTotalMealWeight()}
                  comboItemsAmount={combo && combo.length > 0 && combo[0].groups.length}
                  comboComponents={comboComponents}
                />
              </Col>
              { isMobile && modifications && (
                <Col span={24} className={s.modificationsList}>
                  {modifications.map((modification, i) => (
                    <Row key={i}>
                      {
                        modification.title && (
                          <Col span={20}>
                            <Text className={s.subTextDark}>{modification.quant} x {modification.title}</Text>
                          </Col>
                        )
                      }
                      {
                        (modification.quant && modification.price) ? (
                          <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Text className={s.subText}>{parseInt(modification.quant * modification.price)} ₽</Text>
                          </Col>
                        ) : null
                      }
                    </Row>
                  ))}
                </Col>
              )}
              {
                isMobile && comboComponents && comboComponents.length > 0 && (
                  <Col span={24} className={s.modificationsList}>
                    {item.products && item.products.map((el, i) => (
                      <Row key={i}>
                        <Col span={20}>
                          <Text className={s.subTextDark}>{el.title}</Text>
                        </Col>
                        <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Text className={s.subText}>{parseInt(el.weight)} {el.measure_type}</Text>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                )
              }
            </Fragment>
          )
        })
      }
      <BasketItem cutleryItem item={{ count: personsCount, type: 'persons_count' }} />
      {/* <Col span={24}> */}
      {/*  <BasketItem cutleryItem /> */}
      {/* </Col> */}
      {/* <Col span={24}> */}
      {/*  <Box mt={isMobile ? 'xsm' : 'lg'} className={s.switchContainer}> */}
      {/*    <Text className={s.text}>Не кладите одноразовые приборы</Text> */}
      {/*    <Switch /> */}
      {/*  </Box> */}
      {/* </Col> */}
    </Row>
  )
}

const BasketList = compose(
  withViewport,
)(BasketListComponent)

export default connect((state) => ({
  basketItems: state.app.basketItems,
  personsCount: state.app.personsCount,
  comboMealComponents: state.app.comboMealComponents,
  productSwitchers: state.app.productSwitchers,
}))(BasketList)

