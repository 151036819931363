import React from 'react'
import { Typography } from 'antd'
import cx from 'classnames'
import { compose } from 'redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import { useHistory } from 'react-router-dom'
import Box from '../../components/ui/Box/Box'
import Button from '../../components/ui/Button/Button'
import { isMobileLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import s from './Recommendations.module.less'
import links from '../../helpers/links'
import { setBasketItem } from '../../redux/actions'


const Recommendations = (props) => {
  const { viewport, mappedSections, recommendations } = props

  const { Text } = Typography
  const history = useHistory()
  const isMobile = isMobileLayout(viewport)

  let startX = null

  const onPointerDown = (e) => {
    startX = e.pageX
  }

  const onPointerUp = (e, callback) => {
    if (Math.abs(startX - e.pageX) > 10) return
    callback()
  }

  return (
    <Box mt="lg" className={s.container}>
      <Swiper
        slidesPerView={isMobile ? 1 : 2}
        slidesPerGroup={isMobile ? 1 : 2}
        spaceBetween={10}
        pagination={{ clickable: true }}
        modules={[Pagination]}
        className={isMobile ? cx(s.swiperMobile, s.swiper) : s.swiper}
      >
        <h1 className={s.heading}>Рекомендуем</h1>
        {recommendations.map((item, index) => (
          <SwiperSlide key={index}>
            <Box className={s.slideWrapper}>
              <div
                className={s.imageBox}
                onPointerDown={onPointerDown}
                onPointerUp={(e) => {
                  onPointerUp(e, () => history.push(`${links.catalog}/${mappedSections[item.section_guid]}/${item.alias}`))

                }}
              >
                {
                  isMobile && item.images && item.images[0] && item.images[0].mobile ? (
                    <img
                      src={item.images[0].mobile.preview}
                      srcSet={`${item.images[0].mobile.preview} 1x, ${item.images[0].mobile['preview-x2']} 2x`}
                      className={s.image}
                    />
                  ) : !isMobile && item.images && item.images[0] && item.images[0].desktop && (
                    <img
                      src={item.images[0].desktop.preview}
                      srcSet={`${item.images[0].desktop.preview} 1x, ${item.images[0].desktop['preview-x2']} 2x`}
                      className={s.image}
                    />
                  )
                }
              </div>
              <Box className={s.infoBlock}>
                <div
                  className={s.textBlock}
                  onPointerDown={onPointerDown}
                  onPointerUp={(e) => {
                    onPointerUp(e, () => history.push(`${links.catalog}/${mappedSections[item.section_guid]}/${item.alias}`))
                  }}>
                  <Text className={s.mainText}>{item.title}</Text>
                  <Text className={s.subText}>{item.weight} {item.measure_type}</Text>
                </div>
                <Button
                  text={`Добавить за ${item.price} ₽`}
                  bright
                  isMedium
                  onPointerDown={onPointerDown}
                  onPointerUp={(e) => {
                    if (item.is_composite) {
                      history.push(`${links.catalog}/${mappedSections[item.section_guid]}/${item.alias}`)
                    }
                    else {
                      onPointerUp(e, () => setBasketItem(item))
                    }
                  }}
                />
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

export default compose(
  withViewport,
)(Recommendations)


