import React, { useState } from 'react'
import { Typography, Row, Col, Checkbox } from 'antd'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'
import { compose } from 'redux'

import Box from 'components/ui/Box/Box'
import Button from 'components/ui/Button/Button'

import { isMobileLayout, isTabletLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import s from './CookiePopup.module.less'


const { Text, Paragraph } = Typography

const CookiePopup = (props) => {

  const { viewport } = props

  const isMobile = isMobileLayout(viewport)

  const [ isOpen, setOpen ] = useState(!localStorage.getItem('isCookiePopupClosed'))

  return (
    <Box className={s.container} style={{ display: isOpen ? 'flex' : 'none' }}>
      <Box pt="md" pb="md" pl={isMobile ? 'md' : 'lg'} pr={isMobile ? 'md' : 'lg'} className={s.content}>
        <Text className={s.text}>
          Наш сайт использует файлы cookies и сервис сбора технических данных его посетителей. Продолжая перемещение по нашему сайту, вы автоматически <a href="/assets/documents/accept.html" target="_blank">соглашаетесь с использованием</a> данных технологий.
        </Text>
        <Button
          type="icon"
          icon="close"
          isSmall={isMobile}
          className={s.closeButton}
          onClick={() => {
            localStorage.setItem('isCookiePopupClosed', 'true')
            setOpen(false)
          }}
        />
      </Box>
    </Box>
  )
}

export default compose(
  withViewport,
)(CookiePopup)

