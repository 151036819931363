exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "@media (min-width: 768px) {\n  ._1p2sokBduJpmX4CLFHM3Zr {\n    display: flex;\n    justify-content: center;\n  }\n}\n@media (min-width: 768px) {\n  ._1HphwJAmNkw3QVburihqaB {\n    max-width: 630px;\n    padding-top: 100px;\n    justify-content: center;\n  }\n}\n._1fI8tjdnCyJE3BVfLnresS {\n  display: flex;\n  justify-content: center;\n}\n._3bknO7tsjncrii_LJIheJg {\n  width: 100%;\n}\n@media (min-width: 500px) {\n  ._3bknO7tsjncrii_LJIheJg {\n    display: flex;\n    justify-content: center;\n  }\n}\n._3bknO7tsjncrii_LJIheJg img {\n  width: 100%;\n}\n@media (min-width: 500px) {\n  ._3bknO7tsjncrii_LJIheJg img {\n    max-width: 499px;\n  }\n}\n._3adE-gPGNT1CDO4JyuYkr8 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n._2BoAUHnAiyvrCFYU0Kc5lt {\n  font-family: 'Circe-Regular', sans-serif;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 24px;\n  line-height: 40px;\n  text-align: center;\n  color: #303231;\n  margin-bottom: 10px;\n}\n._2vbeJVof_RaDFYIVszD76F {\n  font-family: 'Circe-Regular', sans-serif;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 20px;\n  line-height: 28px;\n  text-align: center;\n  color: #303231;\n  margin-bottom: 40px;\n}\n", ""]);

// Exports
exports.locals = {
	"wrapper": "_1p2sokBduJpmX4CLFHM3Zr",
	"container": "_1HphwJAmNkw3QVburihqaB",
	"logoContainer": "_1fI8tjdnCyJE3BVfLnresS",
	"imageContainer": "_3bknO7tsjncrii_LJIheJg",
	"textContainer": "_3adE-gPGNT1CDO4JyuYkr8",
	"mainText": "_2BoAUHnAiyvrCFYU0Kc5lt",
	"subText": "_2vbeJVof_RaDFYIVszD76F"
};