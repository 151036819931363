export const MAX_MOBILE_SCREEN_WIDTH = 768
export const MAX_TABLET_MIDDLE_SCREEN_WIDTH = 1024
export const MAX_TABLET_SCREEN_WIDTH = 1200

export const isMobileLayout = (viewport) =>
  viewport
    ? viewport.width < MAX_MOBILE_SCREEN_WIDTH
    : typeof window !== 'undefined'
      ? window.innerWidth < MAX_MOBILE_SCREEN_WIDTH
      : undefined

export const isTabletMiddleLayout = (viewport) =>
  viewport
    ? viewport.width < MAX_TABLET_MIDDLE_SCREEN_WIDTH
    : typeof window !== 'undefined'
      ? window.innerWidth < MAX_TABLET_MIDDLE_SCREEN_WIDTH
      : undefined

export const isTabletLayout = (viewport) =>
  viewport
    ? viewport.width < MAX_TABLET_SCREEN_WIDTH
    : typeof window !== 'undefined'
      ? window.innerWidth < MAX_TABLET_SCREEN_WIDTH
      : undefined

