import React from 'react'


const FryingPanIcon = ({ color }) => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.71875 12.9375H5.03125H22.2812C22.678 12.9375 23 13.2595 23 13.6562V16.5312C23 18.5136 21.3886 20.125 19.4062 20.125H7.90625C6.16975 20.125 4.71931 18.8888 4.38581 17.25H0.71875C0.322001 17.25 0 16.928 0 16.5312V13.6562C0 13.2595 0.322001 12.9375 0.71875 12.9375ZM5.75 16.5312C5.75 17.7201 6.71744 18.6875 7.90625 18.6875H19.4062C20.5951 18.6875 21.5625 17.7201 21.5625 16.5312V14.375H5.75V16.5312ZM1.4375 15.8125H4.3125V14.375H1.4375V15.8125Z" fill={color} />
    <path d="M7.99836 6.83548C8.47561 5.98591 8.17661 5.60498 8.12917 5.55179C7.8503 5.28729 7.82874 4.84454 8.08749 4.55416C8.35199 4.2566 8.80911 4.23073 9.10236 4.49379C9.15267 4.53835 10.3314 5.61935 9.25186 7.53985C8.76455 8.40666 9.08511 8.78473 9.10236 8.80629C9.39848 9.07079 9.42723 9.52504 9.16273 9.82116C9.02042 9.98073 8.82348 10.0627 8.62511 10.0627C8.45548 10.0627 8.28442 10.0023 8.14786 9.88154C8.09755 9.83698 6.9188 8.75598 7.99836 6.83548Z" fill={color} />
    <path d="M13.0296 5.3974C13.5069 4.54784 13.2079 4.16546 13.1604 4.11371C12.8815 3.84921 12.86 3.40646 13.1187 3.11609C13.3832 2.81853 13.8389 2.79409 14.1336 3.05571C14.1839 3.10028 15.3627 4.18128 14.2831 6.10178C13.7958 6.96859 14.1164 7.34665 14.1336 7.36821C14.4297 7.63271 14.4585 8.08696 14.194 8.38309C14.0517 8.54265 13.8547 8.62459 13.6564 8.62459C13.4867 8.62459 13.3157 8.56421 13.1791 8.44346C13.1288 8.3989 11.95 7.3179 13.0296 5.3974Z" fill={color} />
    <path d="M18.0609 6.83548C18.5381 5.98591 18.2391 5.60354 18.1917 5.55179C17.9128 5.28729 17.8912 4.84454 18.15 4.55416C18.4159 4.2566 18.8716 4.23073 19.1649 4.49379C19.2152 4.53835 20.3939 5.61935 19.3144 7.53985C18.827 8.40666 19.1476 8.78473 19.1649 8.80629C19.461 9.07079 19.4897 9.52504 19.2252 9.82116C19.0829 9.98073 18.886 10.0627 18.6876 10.0627C18.518 10.0627 18.3469 10.0023 18.2104 9.88154C18.16 9.83698 16.9813 8.75598 18.0609 6.83548Z" fill={color} />
  </svg>
)

export default FryingPanIcon
