exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._14EPL4C_MGaU8Dqlhbf5zO {\n  top: 0;\n  max-width: 100vw;\n  opacity: 1;\n  margin: 0;\n}\n._1lJFY9YEb7X_nqC2rPj5kr {\n  visibility: hidden;\n  opacity: 0;\n}\n._1Khg9rP82lRBTlUxiDP0e1 {\n  pointer-events: all;\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  padding-top: 74px;\n  border-radius: 0px 0px 30px 30px;\n  box-shadow: 0px 4px 43px rgba(43, 94, 81, 0.3);\n  background: #FFFFFF;\n  text-align: center;\n}\n@media (max-width: 768px) {\n  ._1Khg9rP82lRBTlUxiDP0e1 {\n    padding-top: 23px;\n    border-radius: 0px 0px 15px 15px;\n    text-align: start;\n  }\n}\n.responsive-wrapper ~ div .ant-modal-root ._1Khg9rP82lRBTlUxiDP0e1 {\n  padding-top: 100px;\n}\n._2IOJdjOpbJ0aW-d5W7NjIK {\n  position: absolute;\n  right: 0;\n}\n._2nCFISBh8wf4tmMemsxqks {\n  font-family: 'Circe-Regular';\n  font-style: normal;\n  font-weight: 350;\n  font-size: 30px;\n  line-height: 48px;\n}\n@media (max-width: 768px) {\n  ._2nCFISBh8wf4tmMemsxqks {\n    font-size: 22px;\n    line-height: 38px;\n  }\n}\n._2XoVU7u-ox3L4JF0aJGVKh {\n  position: relative;\n  height: 48px;\n}\n@media (max-width: 768px) {\n  ._2XoVU7u-ox3L4JF0aJGVKh {\n    height: 38px;\n  }\n}\n._2-_M6g6Ady8DLed3Q401vl {\n  border-radius: 16px;\n}\n@media (max-width: 768px) {\n  ._2-_M6g6Ady8DLed3Q401vl {\n    border-radius: 12px;\n  }\n}\n._1HCRb9E_simbt9kpo9jxBs {\n  width: 100%;\n  height: 50px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n._1EW2K9GXF8BZjaPdSuVRrJ {\n  margin-bottom: 72px;\n}\n@media (max-width: 768px) {\n  ._1EW2K9GXF8BZjaPdSuVRrJ {\n    margin-bottom: 57px;\n  }\n}\n._1X180wbS1PrVm_EACpDIUN {\n  padding: 40px 0;\n}\n@media (max-width: 768px) {\n  ._1X180wbS1PrVm_EACpDIUN {\n    padding: 20px;\n  }\n}\n._3DaLbGpFYbFo5vO29qdIP3 {\n  margin-bottom: 72px;\n}\n.ant-modal-wrap {\n  -webkit-overflow-scrolling: initial;\n}\n", ""]);

// Exports
exports.locals = {
	"modalOpen": "_14EPL4C_MGaU8Dqlhbf5zO",
	"modalClosed": "_1lJFY9YEb7X_nqC2rPj5kr",
	"searchModal": "_1Khg9rP82lRBTlUxiDP0e1",
	"closeButton": "_2IOJdjOpbJ0aW-d5W7NjIK",
	"searchText": "_2nCFISBh8wf4tmMemsxqks",
	"searchHeader": "_2XoVU7u-ox3L4JF0aJGVKh",
	"input": "_2-_M6g6Ady8DLed3Q401vl",
	"loader": "_1HCRb9E_simbt9kpo9jxBs",
	"inputContainer": "_1EW2K9GXF8BZjaPdSuVRrJ",
	"productList": "_1X180wbS1PrVm_EACpDIUN",
	"notFoundBlock": "_3DaLbGpFYbFo5vO29qdIP3"
};