import { Redirect, Route, Switch } from 'react-router-dom'
import React from 'react'
import { connect } from 'react-redux'
import links from './helpers/links'
import BasicAppLayout from './layouts/BasicAppLayout/BasicAppLayout'
import HomePage from './pages/Home/Home'
import Catalog from './pages/Catalog/Catalog'
import Favorites from './compositions/Favorites/Favorites'
import CustomAppLayout from './layouts/CustomAppLayout/CustomAppLayout'
import Product from './pages/Product/Product'
import ComboMeal from './pages/ComboMeal/ComboMeal'
import Basket from './pages/Basket/Basket'
import OrderForm from './compositions/OrderForm/OrderForm'
import Order from './compositions/Order/Order'
import Account from './pages/Account/Account'
import Map from './pages/Map/Map'
import Login from './pages/Login/Login'
import NotFoundPage from './pages/NotFoundPage/NotFoundPage'
import AboutUs from './pages/AboutUs/AboutUs'
import DeliveryAndPayment from './pages/DeliveryAndPayment/DeliveryAndPayment'
import Sales from './pages/Sales/Sales'
import Contacts from './pages/Contacts/Contacts'
import News from './pages/News/News'


const Routes = ({ user, isLoggedIn }) => (
  <Switch>
    <Route exact path={`${links.catalog}/:alias`} render={() => (<BasicAppLayout><Catalog /></BasicAppLayout>)} />

    <Route exact path={`${links.catalog}/:section_alias/:product_alias`} render={() => (<CustomAppLayout><Product /></CustomAppLayout>)} />
    <Route exact path={`${links.catalog}/:section_alias/:product_alias/:group_alias/:section_code`} render={() => (<CustomAppLayout><ComboMeal /></CustomAppLayout>)} />

    <Route exact path={links.basket} render={() => (<CustomAppLayout><Basket /></CustomAppLayout>)} />
    <Route exact path={links.orderForm} render={() => (<CustomAppLayout><OrderForm /></CustomAppLayout>)} />
    <Route exact path={`${links.order}/:id`} render={() => (<CustomAppLayout><Order /></CustomAppLayout>)} />

    <Route path={links.account} render={() => (<BasicAppLayout><Account /></BasicAppLayout>)} />

    <Route exact path={links.map} render={() => (<CustomAppLayout><Map /></CustomAppLayout>)} />

    <Route exact path={links.about} render={() => (<BasicAppLayout><AboutUs /></BasicAppLayout>)} />
    <Route exact path={links.delivery} render={() => (<BasicAppLayout><DeliveryAndPayment /></BasicAppLayout>)} />
    <Route exact path={links.sales} render={() => (<BasicAppLayout><Sales /></BasicAppLayout>)} />
    <Route exact path={links.blog} render={() => (<BasicAppLayout><News /></BasicAppLayout>)} />
    <Route exact path={links.contacts} render={() => (<BasicAppLayout><Contacts /></BasicAppLayout>)} />

    <Route path={links.notFound} render={() => (<BasicAppLayout withMobileHeader><NotFoundPage /></BasicAppLayout>)} />

    <Route exact path={links.login} render={() => (<BasicAppLayout><Login /></BasicAppLayout>)} />
    <Route exact path={links.home} render={() => (<BasicAppLayout><HomePage /></BasicAppLayout>)} />
    <Redirect to={links.notFound} />
  </Switch>
)

export default connect((state) => ({
  user: state.app.user,
  isLoggedIn: state.app.user && state.app.user.type !== 'guest',
}))(Routes)
