import React, { Fragment } from 'react'
import { Field } from 'react-final-form'
import { Row, Col, Typography, Select } from 'antd'
import cx from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Image from 'components/ui/Image'
import Box from '../../../../components/ui/Box/Box'
import RadioComponent from '../RadioComponent/RadioComponent'
import SelectComponent from '../SelectComponent/SelectComponent'
import InputComponent from '../InputComponent/InputComponent'

import { useEffect, useState } from '../../../../hooks'
import { addAddress, setPaymentTypes } from '../../../../redux/actions'
import { isMobileLayout, isTabletLayout } from '../../../../helpers/isMobileLayout'
import { withViewport } from '../../../../helpers/withViewport'

import s from './Form.module.less'
import Button from '../../../../components/ui/Button/Button'
import getTotalOrderPrice from '../../../../helpers/getTotalOrderPrice'
import getDeliveryPointById from '../../../../helpers/getDeliveryPointById'
import getRestaurantsFromCities
  from '../../../../helpers/getRestaurantsFromCities'
import PaymentType from '../PaymentType/PaymentType'


const FormComponent = ({
  paymentTypes,
  cards,
  viewport,
  setValue,
  is_self_service,
  selectedDeliveryPoint,
  setMinDate,
  minDateDelivery,
  deliveryTime,
}) => {

  const { Text } = Typography

  const [ isDeliveryTime, setIsDeliveryTime ] = useState(deliveryTime)

  let estimated = 0

  if (selectedDeliveryPoint) {
    estimated = selectedDeliveryPoint.delivery_time_avg || selectedDeliveryPoint.time_delivery || 30
  }

  const isMobile = isMobileLayout(viewport)

  return (
    <>
      <Box>
        <Text className={s.title}>Оформление</Text>
      </Box>
      {!is_self_service && (
        <Box mt="md" mb="md">
          <Text className={s.sectionTitle}>Адрес</Text>
          <Row>
            <InputComponent
              text="Адрес"
              fieldName="address"
              mt="xs"
              mb="sm"
              isWhite
              readOnly />
          </Row>

          <Row gutter={[14, 14]}>
            <Col span={isMobile ? 12 : 6}>
              <InputComponent text="Квартира" fieldName="apartment" type="number" />
            </Col>
            <Col span={isMobile ? 12 : 6}>
              <InputComponent text="Подъезд" fieldName="entrance" type="number"  />
            </Col>
            <Col span={isMobile ? 12 : 6}>
              <InputComponent text="Этаж" fieldName="floor" type="number" />
            </Col>
            <Col span={isMobile ? 12 : 6}>
              <InputComponent text="Домофон" fieldName="intercom" />
            </Col>
          </Row>

          <Row gutter={[14, 14]}>
            <Col span={isMobile ? 24 : 12}>
              <Box
                mt="sm"
                pl="sm"
                pr="sm"
                pt="xs"
                pb="xs"
                className={cx(s.commentContainer, s.greenContainer)}>
                <Field
                  name="comments"
                  render={({ input, meta }) => (
                    <Box className={s.fieldContainer}>
                      <Text className={s.smallText}>Комментарий к адресу</Text>
                      <textarea {...input} />
                    </Box>
                  )}
                />
              </Box>
            </Col>
            <Col span={isMobile ? 24 : 12}>
              <Box
                mt="sm"
                pl="sm"
                pr="sm"
                pt="xs"
                pb="xs"
                className={cx(s.commentContainer, s.greenContainer)}>
                <Field
                  name="orderComments"
                  render={({ input, meta }) => (
                    <Box className={s.fieldContainer}>
                      <Text className={s.smallText}>Комментарий к заказу</Text>
                      <textarea {...input} />
                    </Box>
                  )}
                />
              </Box>
            </Col>
          </Row>
        </Box>
      )}

      {is_self_service && (
        <Box mt="md" mb="md">
          <Text className={s.sectionTitle}>Самовывоз</Text>
          <Row>
            <InputComponent text="Адрес" fieldName="address" mt="xs" mb="sm" isWhite readOnly />
          </Row>
        </Box>
      )}

      <Box mb="md" mt={is_self_service ? 'md' : ''}>
        <Text className={s.sectionTitle}>Телефон</Text>
        <Row gutter={[14, 14]}>
          <Col span={isMobile ? 24 : 12}>
            <InputComponent
              text="Номер телефона"
              readOnly
              fieldName="phone"
              mt="xs"
              className={s.phoneContainer} />
          </Col>

          <Col span={isMobile ? 24 : 12}>
            <Field
              name="not_call"
              type="checkbox"
              render={({ input, meta }) => (
                <>
                  <input type="checkbox" id="input_is_call" checked={input.checked} onChange={(e) => setValue('not_call', e.target.checked)}  />
                  <label className={s.checkboxContainer} htmlFor="input_is_call">
                    <i />
                    <Text className={s.checkboxText}>Не звонить для подтверждения заказа</Text>
                  </label>
                </>
              )}
            />
          </Col>
        </Row>
      </Box>

      <Box mb="md">
        <Text className={s.sectionTitle}>Выбор времени</Text>
        <Box mt="sm">
          {!isDeliveryTime ? (
            <Row align="middle">
              <SelectComponent setValue={setValue} estimated={estimated} minDateDelivery={minDateDelivery} />
              <Button
                className={s.btn}
                lightGreen
                text="Сейчас"
                handleClick={() => {
                  setIsDeliveryTime(!isDeliveryTime)
                  setMinDate()
                }}
              />
            </Row>
          ) : (
            <Row align="middle">
              <Image name="deliveryBicycle" />
              <Text className={s.deliveryText}>Доставим в течение {estimated} минут</Text>
              <Button
                lightGreen
                text="Ко времени"
                handleClick={() => {
                  setValue('hours', null)
                  setValue('minutes', null)
                  setValue('asap', false)
                  setIsDeliveryTime(!isDeliveryTime)
                }}
              />
            </Row>
          )}
        </Box>
      </Box>
      <Box mb="md">
        <PaymentType payments={paymentTypes} cards={cards} setValue={setValue} isMobile={isMobile} />
      </Box>
    </>
  )
}

const ComponentForm = compose(
  withViewport,
)(FormComponent)


export default connect((state) => ({
  selectedDeliveryPoint: getDeliveryPointById(state.app.selectedDeliveryPointId, state.app.user && state.app.user.addresses || [], getRestaurantsFromCities(state.app.cities)),
}))(ComponentForm)
