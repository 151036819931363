import icons from 'components/ui/Icon/utils/icons'


const getStorytellerThemeConfig = () => {
  const config = {
    light: {
      colors: {
        alert: '#FF7800',
      },
      lists: {
        row: {
          tileSpacing: 10,
          startInset: 0,
          endInset: 0,
          scrollIndicatorBackgroundColor: '#F7F9F8',
          scrollIndicatorFade: false,
          scrollIndicatorIcon: icons.right,
        },
      },
      storyTiles: {
        title: {
          textSize: 0,
          lineHeight: 0,
        },
        circularTile: {
          unreadIndicatorColor: '#FF7800',
        },
        rectangularTile: {
          chip: {
            alignment: 'end',
          },
          unreadIndicator: {
            backgroundColor: '#FF7800',
          },
        },
        liveChip: {
          unreadBackgroundColor: '#FF7800',
        },
      },
      player: {
        showStoryIcon: true,
      },
      font: 'Circe-Regular',
      buttons: {
        cornerRadius: 20,
      },
      // instructions: {
      //   show: false,
      //   headingColor: null,
      //   subHeadingColor: null,
      //   backgroundColor: null,
      // },
    },
  }

  config.dark = config.light

  return config
}

export default getStorytellerThemeConfig
