const initialState = {
  sections: [],
  catalogProducts: [],
  user: {
    type: 'guest',
  },
  products: [],
  basketItems: [],
  productModifications: [],
  productSwitchers: [],
  comboMealComponents: [],
  cities: [],
  paymentTypes: [],
  selectedDeliveryPointId: localStorage.getItem('selectedDeliveryPoint'),
  favorites: JSON.parse(localStorage.getItem('favorites') || '[]'),
  combo: [],
  offers: [],
  firstSlider: null,
  labels: [],
  orders: [],
  personsCount: 0,
  cachedAddresses: [],
  stopList: [],
  selectedRestaurantGuid: localStorage.getItem('selectedRestaurantGuid'),
  news: [],
  delayedDeliveryHour: null,
  paymentCards: [],
  // {
  // orderId: string
  // orderGuid: string
  // orderNumber: string
  // id: string - id qr-Кода
  // payload: string функциональная ссылка сбп
  // rendered_qr: string ссылка на изображение qr кода
  // }
  qrInfo: {},
  // PENDING, FAIL, SUCCESS, default
  qrPaymentStatus: 'default',
  recommendations: [],
  currentOrder: null,
}

const setDelayedDeliveryHour = (state, payload) => ({
  ...state,
  delayedDeliveryHour: payload,
})

const setNews = (state, payload) => ({
  ...state,
  news: [...payload],
})

const setOffers = (state, payload) => ({
  ...state,
  offers: [...payload],
})

const setSelectedRestaurantGuid = (state, payload) => ({
  ...state,
  selectedRestaurantGuid: payload,
})

const setDeliveryTime = (state, payload) => {
  state.user.addresses.forEach((item, index) => {
    if (item.address_id == payload.address_id) {
      state.user.addresses[index].time_delivery = payload.time_delivery
      state.user.addresses[index].amount_min = payload.amount_min
      state.user.addresses[index].order_sum_for_free_delivery = payload.order_sum_for_free_delivery
    }
  })

  return {
    ...state,
  }
}

const setOrderData = (state, payload) => ({
  ...state,
  currentOrder: payload,
})

const appendOrder = (state, payload) => ({
  ...state,
  orders: [...state.orders, payload],
})

const setPersonsCount = (state, payload) => ({
  ...state,
  personsCount: payload,
})

const setOrders = (state, payload) => ({
  ...state,
  orders: [...payload],
})

const setStopList = (state, payload) => ({
  ...state,
  stopList: [...payload],
})

const setCatalogProducts = (state, payload) => ({
  ...state,
  catalogProducts: [...payload],
})

const setProducts = (state, payload) => ({
  ...state,
  products: [...payload],
})

const setFirstSlider = (state, payload) => ({
  ...state,
  firstSlider: payload[0],
})

const setLabels = (state, payload) => ({
  ...state,
  labels: [...payload],
})

const setSections = (state, payload) => ({
  ...state,
  sections: [...payload],
})

const setCombo = (state, payload) => ({
  ...state,
  combo: [...payload],
})

const setSelectedDeliveryPoint = (state, payload) => ({
  ...state,
  selectedDeliveryPointId: payload,
})

const setFavorites = (state, payload) => ({
  ...state,
  favorites: [...payload],
})

const setPaymentTypes = (state, payload) => ({
  ...state,
  paymentTypes: [...payload],
})

const setBasketItems = (state, payload) => ({
  ...state,
  basketItems: [...payload],
})

const setProductModifications = (state, payload) => ({
  ...state,
  productModifications: [...payload],
})

const setProductSwitchers = (state, payload) => ({
  ...state,
  productSwitchers: [...payload],
})

const setComboMealComponents = (state, payload) => ({
  ...state,
  comboMealComponents: [...payload],
})

const setCities = (state, payload) => ({
  ...state,
  cities: payload,
})

const setUser = (state, payload) => ({
  ...state,
  user: payload,
})

const setAddresses = (state, payload) => ({
  ...state,
  user: {
    ...state.user,
    addresses: payload,
  },
})

const appendAddresses = (state, payload) => ({
  ...state,
  user: {
    ...state.user,
    addresses: [ ...state.user.addresses || [], { ...payload }],
  },
})

const setPaymentCards = (state, payload) => ({
  ...state,
  paymentCards: payload,
})

const setQrInfo = (state, payload) => ({
  ...state,
  qrInfo: payload,
})

const setQrPaymentStatus = (state, payload) => ({
  ...state,
  qrPaymentStatus: payload,
})
const setRecommendations = (state, payload) => ({
  ...state,
  recommendations: payload,
})

const app = {
  initialState,

  appendAddresses,
  setPaymentTypes,
  setCities,
  setUser,
  setCatalogProducts,
  setProducts,
  setSections,
  setBasketItems,
  setProductModifications,
  setComboMealComponents,
  setAddresses,
  setFavorites,
  setCombo,
  setOffers,
  setSelectedDeliveryPoint,
  setFirstSlider,
  setLabels,
  setOrders,
  setPersonsCount,
  setDeliveryTime,
  appendOrder,
  setSelectedRestaurantGuid,
  setProductSwitchers,
  setStopList,
  setNews,
  setDelayedDeliveryHour,
  setPaymentCards,
  setQrInfo,
  setQrPaymentStatus,
  setRecommendations,
  setOrderData,
}

export default {
  app,
}
