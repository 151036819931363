import React, { useEffect, useState, Fragment } from 'react'
import { Typography, Row, Col, Spin } from 'antd'
import cx from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { useHistory, useParams } from 'react-router-dom'

import Box from '../../components/ui/Box/Box'
import ProductCard from '../ProductCard/ProductCard'

import useLocalStorage from '../../hooks/useLocalStorage'
import { setBasketItem, setCatalogProducts, setFavorites } from '../../redux/actions'
import { isMobileLayout, isTabletLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'
import getRestaurantsFromCities from '../../helpers/getRestaurantsFromCities'
import links from '../../helpers/links'

import s from './CatalogGrid.module.less'
import getDeliveryPointById from '../../helpers/getDeliveryPointById'


const { Text } = Typography

const CatalogGridComponent = (props) => {

  const { setModalVisible, selectedRestaurantGuid, data, stopList, section, favorites, user, viewport, isLoggedIn, subCategories, selectedDeliveryPoint } = props

  const history = useHistory()
  const params = useParams()
  const stop = stopList.find((list) => list.restaurant_guid === selectedRestaurantGuid || list.restaurant_guid === selectedDeliveryPoint?.restaurant_guid) || {
    products: [],
  }

  const [ isLoading, setIsLoading ] = useState(null)
  const [ subCategoryNum, setSubCategoryNum ] = useState(0)
  const [ subCategoryData, setSubCategoryData ] = useState([])
  const [products, setProducts] = useState([])


  const isMobile = isMobileLayout(viewport)
  const isTablet = isTabletLayout(viewport)

  const filteredProducts = (productsData) => {
    const copyProductsData = [...productsData]
    if (copyProductsData.length) {
      const filtered = copyProductsData?.reduce((acc, el, _, arr) => {
        const exist = acc?.some((some) => some.type === el.section_guid)
        if (!exist) {
          const obj = {
            section_guid: el.section_guid,
            items: [],
          }
          const filt = arr?.filter((element) => element.section_guid === el.section_guid)
          obj.items = filt
          return [...acc, obj]
        }
        return acc
      }, [])
      const filteredProductsData = filtered?.map((el) => el.items)?.reduce((a, b) => a.concat(b))
      const products = [...new Set(filteredProductsData)]
      setProducts(products)
    }
  }

  useEffect(() => {
    // document.title = `Eat Street - ${section.title}`

    const getSubCategoriesData = async () => {
      if (subCategories && subCategories.length > 0) {
        setIsLoading(true)

        for (let i = 0; i < subCategories.length; i++) {
          subCategories[i].data = await setCatalogProducts(subCategories[i].section_guid)
        }
        setIsLoading(false)
      }
    }
    getSubCategoriesData()
    filteredProducts(data)
  }, [ params ])

  return (
    <Box mr="sm">
      <Box mb={isMobile ? 'md' : 'lg'} className={s.titleContainer}>
        {section && <Text className={s.categoryName}>{section.title}</Text>}
        {
          subCategories && subCategories.length > 0 && (
            <Box mt="sm" mb="sm" className={s.subCategoriesRow}>
              {
                subCategories.map((subCategory, i) => (
                  <Box
                    key={i}
                    mr="sm"
                    pl="md"
                    pr="md"
                    className={cx(s.subCategoryName, i === 0 && s.subCategoryNameActive)}
                    onClick={() => {
                      // setSubCategoryNum(i)
                      const violation = document.getElementById(subCategory.alias)
                      window.scrollTo({
                        top: violation && violation.offsetTop || 0,
                        behavior: 'smooth',
                      })
                    }}
                  >
                    <Text>{subCategory.title}</Text>
                  </Box>
                ))
              }
            </Box>
          )
        }
      </Box>
      {
        !isLoading && subCategories && subCategories.map((subcategory, index) => (
          <Fragment key={index}>
            {index > 0 && (
              <Box mb={isMobile ? 'md' : 'lg'} className={s.titleContainer}>
                <Text className={s.categoryName} id={subcategory.alias}>{section.title}</Text>
                <Box mt="sm" mb="sm" className={s.subCategoriesRow}>
                  <Box
                    mr="sm"
                    pl="md"
                    pr="md"
                    className={cx(s.subCategoryName)}
                    style={{ cursor: 'default' }}
                  >
                    <Text>{subcategory.title}</Text>
                  </Box>
                </Box>
              </Box>
            )}

            <Row className={s.container}>
              {
                subCategories[index].data && subCategories[index].data.map((el, i) => {
                  const isFavorite = !!favorites.find((item) => item.products_guid === el.products_guid)

                  return (
                    <Col span={isMobile ? 24 : 6} key={i}>
                      <Box mb="lg" className={cx(s.itemContainer, isMobile && s.itemContainerMobile)}>
                        {/* { */}
                        {/*  !isMobile && ( */}
                        {/*    <div className={s.shadow} /> */}
                        {/*  ) */}
                        {/* } */}
                        <ProductCard
                          id={i}
                          in_stop={stop.products.indexOf(el.products_guid) > -1}
                          products_guid={el.products_guid}
                          section_alias={subCategories[index].alias}
                          alias={el.alias}
                          image={el.images && el.images[0]}
                          size={el.size}
                          measure_type={el.measure_type}
                          weight={el.weight}
                          bigPrice={el.price}
                          title={el.title}
                          badges={el.badges}
                          isFavorite={isFavorite}
                          onLikeClicked={() => {
                            if (isLoggedIn) {
                              setFavorites(el)
                            }
                            else {
                              history.push(links.login)
                            }
                          }}
                          onPriceClicked={() => {
                            if (!selectedDeliveryPoint) {
                              setModalVisible(true)
                            }
                            else {
                              setBasketItem(el)
                              if (process.env.REACT_APP_API_ENV === 'prod') { ym(95942691,'reachGoal','add-basket')}
                            }
                            // else {
                            //   history.push(links.login)
                            // }
                          }}
                        />
                      </Box>
                    </Col>
                  )
                },
                )
              }
            </Row>
          </Fragment>
        ))
      }
      {
        isLoading ? (
          <div className={s.loader}>
            <Spin spinning={isLoading} />
          </div>
        ) : (
          <Row className={s.container}>
            {
              products?.map((el, i) => {
                const isFavorite = !!favorites.find((item) => item.products_guid === el.products_guid)
                return (
                  <Col span={isMobile ? 24 : 6} key={i}>
                    <Box mb="lg" className={cx(s.itemContainer, isMobile && s.itemContainerMobile)}>
                      {/* { */}
                      {/*  !isMobile && ( */}
                      {/*    <div className={s.shadow} /> */}
                      {/*  ) */}
                      {/* } */}
                      <ProductCard
                        id={i}
                        in_stop={stop.products.indexOf(el.products_guid) > -1}
                        products_guid={el.products_guid}
                        section_alias={(subCategories[subCategoryNum] && subCategories[subCategoryNum].alias) || section.alias}
                        alias={el.alias}
                        image={el.images && el.images[0]}
                        size={el.size}
                        groups_objects={el.groups_objects}
                        weight={el.weight}
                        measure_type={el.measure_type}
                        bigPrice={el.price}
                        title={el.title}
                        badges={el.badges}
                        isFavorite={isFavorite}
                        onLikeClicked={() => {
                          if (isLoggedIn) {
                            setFavorites(el)
                          }
                          else {
                            history.push(links.login)
                          }
                        }}
                        onPriceClicked={() => {
                          if (el.groups_objects) {
                            history.push(`${links.catalog}/${(subCategories[subCategoryNum] && subCategories[subCategoryNum].alias) || section.alias}/${el.alias}`)
                          }
                          else if (!selectedDeliveryPoint) {
                            setModalVisible(true)
                          }
                          else {
                            setBasketItem(el)
                            if (process.env.REACT_APP_API_ENV === 'prod') { ym(95942691,'reachGoal','add-basket')}
                          }
                          // else {
                          //   history.push(links.login)
                          // }
                        }}
                      />
                    </Box>
                  </Col>
                )
              })
            }
          </Row>
        )
      }
    </Box>
  )
}

const CatalogGrid = compose(
  withViewport,
)(CatalogGridComponent)

export default connect((state) => ({
  favorites: state.app.favorites,
  user: state.app.user,
  stopList: state.app.stopList,
  selectedRestaurantGuid: state.app.selectedRestaurantGuid,
  isLoggedIn: state.app.user && state.app.user.type !== 'guest',
  selectedDeliveryPoint: getDeliveryPointById(state.app.selectedDeliveryPointId, state.app.user && state.app.user.addresses || [], getRestaurantsFromCities(state.app.cities)),
}))(CatalogGrid)
