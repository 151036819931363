exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1J7zmhUO79Bjj3X45R6_g3 {\n  width: 100% !important;\n}\n._1J7zmhUO79Bjj3X45R6_g3 .ant-modal-content {\n  width: 654px;\n  height: 317px;\n  background: #FFFFFF;\n  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.11);\n  border-radius: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: auto;\n}\n@media (max-width: 768px) {\n  ._1J7zmhUO79Bjj3X45R6_g3 .ant-modal-content {\n    width: 343px;\n    height: 229px;\n    background: #FFFFFF;\n    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.11);\n    border-radius: 30px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: auto;\n  }\n}\n._1atelGTPjkv3ByLoi-Zg6- {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n._2Tgth_S3FSl8j_p_va0SlX {\n  font-family: 'Circe-Bold', serif;\n  font-size: 32px;\n  line-height: 100%;\n  color: #3C3C3C;\n  text-align: center;\n}\n@media (max-width: 768px) {\n  ._2Tgth_S3FSl8j_p_va0SlX {\n    font-size: 20px;\n  }\n}\n._1g84IGfawaTYfmHQtMxak7 {\n  font-family: 'Circe-Regular', serif;\n  font-size: 18px;\n  line-height: 27px;\n  text-align: center;\n  color: #303231;\n}\n@media (max-width: 768px) {\n  ._1g84IGfawaTYfmHQtMxak7 {\n    font-size: 16px;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"wrapClassName": "_1J7zmhUO79Bjj3X45R6_g3",
	"content": "_1atelGTPjkv3ByLoi-Zg6-",
	"mainText": "_2Tgth_S3FSl8j_p_va0SlX",
	"secondaryText": "_1g84IGfawaTYfmHQtMxak7"
};