import React, { Fragment } from 'react'
import { Field } from 'react-final-form'
import { Col, Typography } from 'antd'
import cx from 'classnames'

import Box from '../../../../components/ui/Box/Box'
import Icon from '../../../../components/ui/Icon'

import s from '../Form/Form.module.less'


const { Text } = Typography

const InputComponent = (props) => {

  const {
    text,
    fieldName,
    className,
    mt,
    mb,
    isWhite,
    type,
    readOnly,
    withoutPlaceholder,
    icon,
    onClick,
  } = props

  return (
    <Box
      mt={mt || ''}
      mb={mb || ''}
      pl="sm"
      pr="sm"
      className={cx(s.fieldContainer,
        isWhite ? s.whiteContainer : s.greenContainer, className)}
      onClick={onClick}
    >
      <Text className={s.smallText}>{text}</Text>
      <Field
        readOnly={readOnly}
        name={fieldName}
        component="input"
        type={type}
        placeholder={withoutPlaceholder ? '' : text} />
      {
        icon && (
          <Icon name="down" className={s.arrowIcon} />
        )
      }
    </Box>
  )
}

export default InputComponent
