import React, { useState } from 'react'
import { Typography, Row, Col, Checkbox } from 'antd'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'
import { compose } from 'redux'

import Box from 'components/ui/Box/Box'
import Button from 'components/ui/Button/Button'
import Icon from 'components/ui/Icon'

import links from 'helpers/links'
import { isMobileLayout, isTabletLayout } from 'helpers/isMobileLayout'
import { withViewport } from 'helpers/withViewport'

import image404_1x from './images/image404.jpg'
import image404_2x from './images/image404@2x.jpg'
import image404_3x from './images/image404@3x.jpg'
import mobileImage404_1x from './images/mobileImage404.jpg'
import mobileImage404_2x from './images/mobileImage404@2x.jpg'
import mobileImage404_3x from './images/mobileImage404@3x.jpg'

import s from './NotFoundPage.module.less'


const { Text, Paragraph } = Typography

const NotFoundPage = (props) => {

  const { viewport } = props

  const isMobile = isMobileLayout(viewport)
  const isMiddleMobile = viewport.width > 500


  return (
    <Box className={s.wrapper}>
      <Box className={s.container}>
        {
          !isMobile && (
            <Box mb="xxl" className={s.logoContainer}>
              <NavLink to={links.home}>
                <Icon name="logoWithText" />
              </NavLink>
            </Box>
          )
        }
        <Box className={s.imageContainer}>
          <img
            src={isMobile ? mobileImage404_1x : image404_1x}
            srcSet={isMiddleMobile
              ? `${image404_1x} 1x, ${image404_2x} 2x, ${image404_3x} 3x`
              : `${mobileImage404_1x} 1x, ${mobileImage404_2x} 2x, ${mobileImage404_3x} 3x`}
          />
        </Box>
        <Box p="md" className={s.textContainer}>
          <Text className={s.mainText}>Упс</Text>
          <Text className={s.subText}>Такой страницы не существует или она еще находится в  разработке</Text>
          <NavLink to={links.home}>
            <Button
              text="Вернуться на главную"
              withBoldText
              primary
              isSmall={isMobile}
            />
          </NavLink>
        </Box>
      </Box>
    </Box>
  )
}

export default compose(
  withViewport,
)(NotFoundPage)

