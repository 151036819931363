exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2Ms1fEoJBcfyzeLYLcG0jk {\n  position: relative;\n  width: 400px;\n  height: 42px;\n  background: #F7F9F8;\n  border-radius: 28px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 1px;\n  margin-bottom: 15px;\n}\n@media (max-width: 375px) {\n  ._2Ms1fEoJBcfyzeLYLcG0jk {\n    margin-bottom: 20px;\n  }\n}\n._3rd5raQRkfn1YVnYUL-LmF {\n  width: 340px;\n  cursor: pointer;\n}\n._3yh3MZvSUJ7BgTmfPOFRnT {\n  position: absolute;\n  width: 205px;\n  height: 38px;\n  background: #FFFFFF;\n  border-radius: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 0;\n  cursor: pointer;\n}\n@media (max-width: 375px) {\n  ._3yh3MZvSUJ7BgTmfPOFRnT {\n    width: 170px;\n  }\n}\n._2tDoKJze9tHqowJi_H26iI {\n  width: 166px;\n}\n.DRmPeAs22k48t-IILJ74l {\n  position: absolute;\n  width: 144px;\n  height: 38px;\n  background: #FFFFFF;\n  border-radius: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 0;\n  cursor: pointer;\n}\n@media (max-width: 375px) {\n  .DRmPeAs22k48t-IILJ74l {\n    width: 120px;\n  }\n}\n._1vJkvz_nTCFc2MtWLc7x9L {\n  left: 2px;\n}\n._2KRDWYdg0a4_HPO3nIrSDy {\n  left: 31.5%;\n}\n._334tryoR54pwGil9anMldm {\n  right: 2px;\n}\n.GpQj5vPh6BgEVaR25EVIT {\n  width: 50%;\n  font-size: 17px;\n  text-align: center;\n  color: #303231;\n  line-height: 25px;\n  z-index: 1;\n}\n._255DrBRKBztslQgDWxnxEE span {\n  font-family: 'Circe-Bold', serif;\n}\n._39hnZmtlPSyWneX7qIMSYa span {\n  font-family: 'Circe-Regular', serif;\n}\n", ""]);

// Exports
exports.locals = {
	"roundedTabsContainer": "_2Ms1fEoJBcfyzeLYLcG0jk",
	"smallContainer": "_3rd5raQRkfn1YVnYUL-LmF",
	"roundedTab": "_3yh3MZvSUJ7BgTmfPOFRnT",
	"smallTab": "_2tDoKJze9tHqowJi_H26iI",
	"roundedTabSmall": "DRmPeAs22k48t-IILJ74l",
	"roundedTab1Active": "_1vJkvz_nTCFc2MtWLc7x9L",
	"roundedTab2Active": "_2KRDWYdg0a4_HPO3nIrSDy",
	"roundedTabLastActive": "_334tryoR54pwGil9anMldm",
	"tab": "GpQj5vPh6BgEVaR25EVIT",
	"tabActive": "_255DrBRKBztslQgDWxnxEE",
	"tabInactive": "_39hnZmtlPSyWneX7qIMSYa"
};