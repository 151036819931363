import React, { useRef } from 'react'
import { Carousel, Typography, Row, Col } from 'antd'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { useHistory } from 'react-router-dom'
import Button from '../../components/ui/Button/Button'
import OfferCard from '../../components/ui/OfferCard/OfferCard'

import { isMobileLayout, isTabletLayout, isTabletMiddleLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import s from './OffersCarousel.module.less'
import links from '../../helpers/links'

import image1 from './images/desktop/1.png'
import image12x from './images/desktop/1-2x.png'

import image2 from './images/desktop/2.png'
import image22x from './images/desktop/2-2x.png'

import image3 from './images/desktop/3.png'
import image32x from './images/desktop/3-2x.png'



const { Text } = Typography


const offers = [{
  image: {
    desktop: {
      preview: image1,
      'preview-x2': image12x,
    },
    mobile: {
      preview: image1,
      'preview-x2': image12x,
    },
  },
  url: links.sales,
}, {
  image: {
    desktop: {
      preview: image2,
      'preview-x2': image22x,
    },
    mobile: {
      preview: image2,
      'preview-x2': image22x,
    },
  },
  url: 'catalog/sety',
}, {
  image: {
    desktop: {
      preview: image3,
      'preview-x2': image32x,
    },
    mobile: {
      preview: image3,
      'preview-x2': image32x,
    },
  },
  url: links.news,
}]

const OffersCarouselComponent = ({ viewport }) => {

  const history = useHistory()
  const isMobile = isMobileLayout(viewport)
  const isTabletMiddle = isTabletMiddleLayout(viewport)
  const isTablet = isTabletLayout(viewport)

  const carouselRef = useRef()

  return (
    <div className={s.container}>
      <div className={s.topRow}>
        <div />
        {/* <Text */}
        {/*  className={s.title} */}
        {/*  onClick={() => { */}
        {/*    history.push(links.sales) */}
        {/*  }} */}
        {/* > */}
        {/*  Акции */}
        {/* </Text> */}
        {
          false && (
            <div className={s.controls}>
              <Button
                type="icon"
                icon="left"
                circle
                isSmall
                className={s.btnPrev}
                handleClick={() => carouselRef.current.prev()}
              />
              <Button
                type="icon"
                icon="right"
                circle
                isSmall
                className={s.btnNext}
                handleClick={() => carouselRef.current.next()}
              />
            </div>
          )
        }
      </div>
      <Carousel
        dots={false}
        ref={carouselRef}
        slidesToShow={isMobile ? 1 : isTabletMiddle ? (offers.length > 2 ? 2 : offers.length) : (offers.length > 3 ? 3 : offers.length)}
        slidesToScroll={1}
        infinite
        centerMode={isTabletMiddle}
        centerPadding={isTabletMiddle ? '20px' : ''}
      >
        {/* { */}
        {/*  offers.map((item, i) => { */}
        {/*    return ( */}
        {/*      <OfferCard */}
        {/*        handleClick={(alias) => { */}
        {/*          history.push(`${item.url}`) */}
        {/*        }} */}
        {/*        alias={item.alias} */}
        {/*        image={item.image} */}
        {/*        key={i} */}
        {/*        mainText={item.title} */}
        {/*        secondaryText={item.entry} */}
        {/*        isDarkText={item.isDarkText} */}
        {/*        isMobile={isMobile} */}
        {/*      /> */}
        {/*    ) */}
        {/*  }) */}
        {/* } */}
      </Carousel>
      {/* <div className={s.bottomRow}> */}
      {/*  <Button text={`Все акции`} primary withoutShadow className={s.smallBtn} handleClick={() => { */}
      {/*    history.push(links.sales) */}
      {/*  }} /> */}
      {/* </div> */}
    </div>
  )
}

const OffersCarousel = compose(
  withViewport,
)(OffersCarouselComponent)

export default connect((state) => ({
  // offers: state.app.offers,
}))(OffersCarousel)

