import { Typography } from 'antd'
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { compose } from 'redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import cx from 'classnames'
import { isMobileLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'
import s from './CategoriesCarouselMini.module.less'
import SwiperNavButton from '../../components/ui/SwiperNavButton/SwiperNavButton'
import links from '../../helpers/links'


const CarouselMini = ({ sections, labels, viewport }) => {
  const { Text } = Typography

  const history = useHistory()

  const isMobile = isMobileLayout(viewport)
  const params = useParams()

  const { menuList, activeIndex } = useMemo(() => {
    let menuList = []
    let activeIndex
    const labelActiveIndexIndex = labels.findIndex((label) => label.alias === params.alias)
    const isLabels = labelActiveIndexIndex >= 0
    if (!isLabels) {
      menuList = sections.filter((el) => el.is_visible && !el.parent_guid)
      activeIndex = menuList.findIndex((section) => section.alias === params.alias)
    }
    else {
      menuList = labels
      activeIndex = labelActiveIndexIndex
    }

    return { menuList, activeIndex }
  }, [sections, labels, params])

  let startX = null

  return (
    <>
      <div
        style={{ position: 'relative' }}
        className={isMobile ? s.swiperWrapperMobile : s.swiperWrapper}
      >
        {!isMobile && menuList.length && (
          <>
            <Swiper
              loop
              spaceBetween={10}
              initialSlide={activeIndex !== -1 ? activeIndex : 0}
              breakpoints={{
                1024: {
                  slidesPerView: 6,
                },
                850: {
                  slidesPerView: 5,
                },
                820: {
                  slidesPerView: 4,
                },
                660: {
                  slidesPerView: 3,
                },
              }}
            >
              {menuList?.map((el, index) => (
                <SwiperSlide key={index}>
                  <div
                    onPointerDown={(e) => {
                      startX = e.pageX
                    }}
                    onPointerUp={(e) => {
                      if (Math.abs(startX - e.pageX) > 10) return
                      history.push(`${links.catalog}/${el.alias}`)
                    }}
                    className={s.categoryLink}
                  >
                    <Text
                      className={
                        el.alias !== params.alias
                          ? s.categoryText
                          : cx(s.categoryText, s.activeText)
                      }
                    >
                      {el.title}
                    </Text>
                  </div>
                </SwiperSlide>
              ))}
              <div className={s.prevButtonSlot}>
                <div className={s.prevButtonWrapper}>
                  <SwiperNavButton
                    type="prev"
                    slidesPerGroup={3}
                    className={s.prevButton}
                  />
                </div>
              </div>
              <div className={s.nextButtonSlot}>
                <div className={s.nextButtonWrapper}>
                  <SwiperNavButton
                    type="next"
                    slidesPerGroup={3}
                    className={s.nextButton}
                  />
                </div>
              </div>
            </Swiper>
          </>
        )}
        {isMobile && menuList.length && (
          <Swiper
            slidesPerView="auto"
            spaceBetween={10}
            loop
            initialSlide={activeIndex !== -1 ? activeIndex : 0}
          >
            {menuList?.map((el, index) => (
              <SwiperSlide key={index} className={s.swiperSlideMobile}>
                <div
                  onPointerDown={(e) => {
                    startX = e.pageX
                  }}
                  onPointerUp={(e) => {
                    if (Math.abs(startX - e.pageX) > 5) return
                    history.push(`${links.catalog}/${el.alias}`)
                  }}
                  className={s.categoryLinkMobile}
                >
                  <Text
                    className={
                      el.alias !== params.alias
                        ? s.categoryText
                        : cx(s.categoryText, s.activeText)
                    }
                  >
                    {el.title}
                  </Text>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </>
  )
}

const CategoriesCarouselMini = compose(withViewport)(CarouselMini)

export default connect((state) => ({
  sections: state.app.sections,
  labels: state.app.labels,
}))(CategoriesCarouselMini)
