import React from 'react'
import { Typography, Row, Col } from 'antd'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'

import cx from 'classnames'
import moment from 'moment'
import WidthContainer
  from '../../components/layout/WidthContainer/WidthContainer'
import Box from '../../components/ui/Box/Box'
import Button from '../../components/ui/Button/Button'
import FryingPanIcon from './components/FryingPanIcon'
import ShoppingBagIcon from './components/ShoppingBagIcon'
import PointIcons from './components/PointIcons'

import { isMobileLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import s from './Order.module.less'
import getOrderByNumber from '../../helpers/getOrderByNumber'
import { useEffect } from '../../hooks'
import getDishDeclension from '../../helpers/getDishDeclension'
import links from '../../helpers/links'
import AcceptedIcon from './components/AcceptedIcon'
import DeliveryIcon from './components/DeliveryIcon'
import { statuses } from '../../helpers/statuses'
import { setOrders } from '../../redux/actions'
import Icon from '../../components/ui/Icon'
import formatAddress from '../../helpers/formatAddress'
import setSeoTags from '../../helpers/setSeoTags'


const { Text } = Typography

const OrderComponent = ({ viewport, orders, isLoggedIn }) => {
  if (!isLoggedIn) {
    return <Redirect to={links.login} />
  }

  const isMobile = isMobileLayout(viewport)
  const history = useHistory()
  const { location } = history
  const fromOrder = location && location.state && location.state.fromOrder
  const { id } = useParams()

  const order = getOrderByNumber(id, orders)

  if (!order) {
    return <Redirect to={links.myOrders} />
  }

  const created = new Date(order.date_created)
  const delivery = new Date(order.date_delivery)

  const filteredStatuses = statuses.filter(
    (el) => el.status === order.status_code)
  const status = filteredStatuses[0]

  const accepting = [
    'AWAITING_PAYMENT',
    'NEW',
    'PAYMENT_SUCCESS',
    'ACCEPTED',
    'UNCONFIRMED'].indexOf(order.status_code) > -1
  const cooking = ['COOKING', 'WAITING'].indexOf(order.status_code) > -1
  const delivering = ['ON_WAY'].indexOf(order.status_code) > -1
  const finishing = ['CLOSED', 'DELIVERED'].indexOf(order.status_code) > -1
  const createError = ['NOT_CREATED'].indexOf(order.status_code) > -1
  const cancelled = ['CANCELLED'].indexOf(order.status_code) > -1

  const searchParams = new URLSearchParams(location.search)
  const failed = searchParams.get('status') === 'fail'
  const totalItems = order.products.reduce((acc, { quant }) => acc + quant, 0)

  useEffect(() => {
    if (order) {
      setSeoTags({
        title: `Eat Street - Заказ #${order.order_number}`,
        seo_desc: '',
        seo_keyw: '',
      })
    }

    if (window.gtag) {
      window.gtag('event', '_orderpage_')
    }

    const interval = setInterval(() => {
      setOrders()
    }, 20 * 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Box pt={isMobile ? 'sm' : ''} pb={isMobile ? 'xlg' : ''}>
      {
        isMobile && (
          <WidthContainer>
            <Row style={{ width: '100%', justifyContent: 'space-between' }}>
              <Col>
                <Button
                  type="icon"
                  icon="left"
                  isSmall
                  handleClick={() => history.push(
                    `${links.account}/my-orders`)} />
              </Col>
              <Col>
                {order.pay_link
                && (
                  <Button
                    type="icon"
                    icon="order"
                    isSmall
                    circle
                    handleClick={() => {
                      document.location.href = order.pay_link
                    }} />
                )}
              </Col>
            </Row>
          </WidthContainer>
        )
      }

      <WidthContainer>
        {
          !isMobile && !fromOrder && (
            <Box className={s.buttonBack}>
              <Button
                type="icon"
                icon="left"
                handleClick={() => history.push(`/account/my-orders`)} />
            </Box>
          )
        }

        <Box className={s.container}>
          <Box mt={isMobile ? 'lg' : 'xlg'} mb="md">
            <Row>
              <Col span={20} className={s.column}>
                <Text className={s.title}>Заказ #{order.order_number}</Text>
                <Text className={s.date}>{moment(created).format('lll')}</Text>
              </Col>
              {
                !isMobile && order.pay_link && (
                  <Col
                    span={4}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      type="icon"
                      icon="order"
                      circle
                      handleClick={() => {
                        document.location.href = order.pay_link
                      }} />
                  </Col>
                )
              }
            </Row>
          </Box>
          {(createError || cancelled || failed) && (
            <Box className={cx(s.error, {
              [s.errorCancelled]: cancelled,
              [s.errorFailed]: failed,
              [s.createError]: createError,
            })}>
              <Icon name="cancel" />
              {cancelled && <Text className={s.errorText}>Заказ отменён</Text>}
              {failed && <Text className={s.errorText}>Оплата не прошла</Text>}
              {createError
            && <Text className={s.errorText}>Ошибка создания заказа</Text>}
            </Box>
          )}
          {(!createError && !cancelled && !failed)
          && (
            <Box className={s.rectangle}>
              <Text className={s.lowContrast}>Доставим ко времени</Text>
              <Text className={s.estimatedTime}>{moment(delivery)
                .format('lll')}
              </Text>
              <Box mt="sm" mb="sm" className={s.svgRow}>
                <AcceptedIcon
                  color={finishing || delivering || cooking || accepting
                    ? '#fff'
                    : 'rgba(255, 255, 255, .3)'}
                />
                <PointIcons
                  color={finishing || delivering || cooking
                    ? '#fff'
                    : 'rgba(255, 255, 255, .3)'}
                />
                <FryingPanIcon
                  color={finishing || delivering || cooking
                    ? '#fff'
                    : 'rgba(255, 255, 255, .3)'}
                />
                <PointIcons
                  color={finishing || delivering
                    ? '#fff'
                    : 'rgba(255, 255, 255, .3)'}
                />
                <DeliveryIcon
                  color={finishing || delivering
                    ? '#fff'
                    : 'rgba(255, 255, 255, .3)'}
                />
                <PointIcons
                  color={finishing ? '#fff' : 'rgba(255, 255, 255, .3)'}
                />
                <ShoppingBagIcon
                  color={finishing ? '#fff' : 'rgba(255, 255, 255, .3)'}
                />
              </Box>
              <Text className={s.status}>
                {status.text}
              </Text>
            </Box>
          )}
          <Box mt="md" mb="xlg" className={s.smallRectangle}>
            <Text>Связаться с нами</Text>
            <Text
              onClick={() => document.location.href = `tel:${order.support_phone_format.replace(/\s/g, '')}`}
              style={{ cursor: 'pointer' }}>{order.support_phone_format}
            </Text>
          </Box>
          <Box mb="md" className={s.column}>
            <Text className={s.subTitle}>Адрес</Text>
            {order.restaurant
            && <Text className={s.text}>{order.restaurant.address}</Text>}
            {order.address
            && <Text className={s.text}>{formatAddress(order.address)}</Text>}
          </Box>
          <Box mb="md" className={s.column}>
            <Text className={s.subTitle}>Получатель</Text>
            {order.customer && (
              <Text
                className={s.text}>{order.customer.name}, {order.customer.phone}
              </Text>
            )}
          </Box>
          <Box mb="md" className={s.column}>
            <Text className={s.subTitle}>Оплата</Text>
            <Text className={s.text}>{order.payment.title}</Text>
          </Box>
          <Box mb="md">
            <Text className={s.subTitle}>
              {getDishDeclension(totalItems)} на {order.price_total} ₽
            </Text>
            <Box mt="md">
              {
                order.products.map((item, i) => (
                  <div key={i}>
                    <Row className={s.row}>
                      <Col span={18}>
                        <Text className={s.text}>{item.title}</Text>
                      </Col>
                      <Col span={6} className={s.alignRight}>
                        <Text
                          className={s.greenText}>{item.quant} x {item.price} ₽
                        </Text>
                      </Col>
                    </Row>
                    {item.groups && item.groups.length > 0
                      && (
                        <Box mt="xs">
                          <Text className={s.greyText}>Состав набора</Text>
                          {item.groups.map((group, i) => (
                            <Row key={i} className={s.smallRow}>
                              <Col span={18}>
                                <Text
                                  className={s.smallText}>{group.title}
                                </Text>
                              </Col>
                              {!!group.sum
                            && (
                              <Col span={6} className={s.alignRight}>
                                <Text
                                  className={s.greenSmallText}>{group.quant} x {group.price} ₽
                                </Text>
                              </Col>
                            )}
                            </Row>
                          ))}
                        </Box>
                      )}
                    {item.modifications && item.modifications.length > 0
                      && (
                        <Box mt="xs">
                          <Text className={s.greyText}>Топпинги</Text>
                          {item.modifications.map((modification, i) => (
                            <Row key={i} className={s.smallRow}>
                              <Col span={18}>
                                <Text
                                  className={s.smallText}>{modification.title}
                                </Text>
                              </Col>
                              {!!modification.sum
                            && (
                              <Col span={6} className={s.alignRight}>
                                <Text
                                  className={s.greenSmallText}>{modification.quant} x {modification.price} ₽
                                </Text>
                              </Col>
                            )}
                            </Row>
                          ))}
                        </Box>
                      )}
                  </div>
                ))
              }
            </Box>
          </Box>
          {/* {(!!order.price_discount || !!order.payment_by_points)
          && ( */}
          <Box mb="md">
            <Text className={s.subTitle}>Скидки, баллы и доставка</Text>
            <Box mt="md">
              {
                !!order.price_discount && (
                  <Row className={s.row}>
                    <Col span={18}>
                      {!!order.coupon
                        ? <Text className={s.text}>Промокод &#171;{order.coupon}&#187;</Text>
                        : <Text className={s.text}>Скидка</Text>}
                    </Col>
                    <Col span={6} className={s.alignRight}>
                      <Text
                        className={s.greenText}>-{order.price_discount} ₽
                      </Text>
                    </Col>
                  </Row>
                )
              }
              {
                !!order.payment_by_points && (
                  <Row className={s.row}>
                    <Col span={18}>
                      <Text className={s.text}>Оплата баллами</Text>
                    </Col>
                    <Col span={6} className={s.alignRight}>
                      <Text
                        className={s.greenText}>-{order.payment_by_points} Б
                      </Text>
                    </Col>
                  </Row>
                )
              }
              {
                order.price_delivery >= 0 && (
                  <Row className={s.row}>
                    <Col span={18}>
                      <Text className={s.text}>Доставка</Text>
                    </Col>
                    <Col span={6} className={s.alignRight}>
                      <Text
                        className={s.greenText}>{order.price_delivery} ₽
                      </Text>
                    </Col>
                  </Row>
                )
              }
              {/*  { */}
              {/*    data.pointsAwarded && ( */}
              {/*      <Row className={s.row}> */}
              {/*        <Col span={18}> */}
              {/*          <Text className={s.text}>Начислено</Text> */}
              {/*        </Col> */}
              {/*        <Col span={6} className={s.alignRight}> */}
              {/*          <Text className={s.orangeText}>+{data.pointsAwarded} Б</Text> */}
              {/*        </Col> */}
              {/*      </Row> */}
              {/*    ) */}
              {/*  } */}
              {/* </Box> */}
            </Box>
          </Box>
          {/* )} */}
          <Box mb="md">
            <Row className={s.row}>
              <Col span={18}>
                <Text className={s.subTitle}>Итого</Text>
              </Col>
              <Col span={6} className={s.alignRight}>
                <Text
                  className={s.greenText}>{order.price_final} ₽
                </Text>
              </Col>
            </Row>
          </Box>
        </Box>
      </WidthContainer>
    </Box>
  )
}

const Order = compose(
  withViewport,
)(OrderComponent)

export default connect((state) => ({
  user: state.app.user,
  isLoggedIn: state.app.user && state.app.user.type !== 'guest',
  orders: state.app.orders,
}))(Order)
