import React from 'react'
import cx from 'classnames'
import { Col, Divider, Row, Typography } from 'antd'
import { compose } from 'redux'
import { NavLink } from 'react-router-dom'

import { connect } from 'react-redux'
import moment from 'moment'
import Icon from '../../components/ui/Icon'
import Box from '../../components/ui/Box/Box'
import WidthContainer from '../../components/layout/WidthContainer/WidthContainer'

import { isMobileLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'
import externalLinks from '../../helpers/externalLinks'
import links from '../../helpers/links'

import s from './Footer.module.less'


const { Text } = Typography

const Footer = (props) => {

  const { viewport, isLoggedIn } = props

  const isMobile = isMobileLayout(viewport)

  // TODO: Delete after New Year
  const getCurrentNewYearTimeRange = () => {
    const nowDate = moment(new Date()).format('YYYY-MM-DD')

    if (moment(nowDate).isAfter('2023-01-01')) {
      return ''
    }
    return '31 декабря до 20:00 \n 1 января с 14:00'
  }

  return (
    <div className={s.container}>
      <Box mt={isMobile ? 'lg' : 'xxl'} mb="md">
        <Divider />
      </Box>
      <WidthContainer>
        <Row gutter={64} style={{ margin: 0 }}>
          <Col
            span={isMobile ? 24 : 8}
            className={cx(s.firstColumn)}
            order={0}
            style={{ padding: 0 }}
          >
            <Box mb={isMobile ? 'md' : 'xs'}>
              <span className={s.phone} onClick={() => document.location.href = 'tel:+74959601242'}>+7 (495) 960-12-42</span>
            </Box>
            {/* TOTO: Uncomment after New Year */}
            {/* <Box mb={isMobile ? 'md' : 'xs'}>
              Каждый день 10-23
            </Box> */}

            {/* TODO: Delete after New Year */}
            {getCurrentNewYearTimeRange()
              ? (
                <>
                  <Box mb={0}>
                    Каждый день 10-23
                  </Box>
                  <Box mb={isMobile ? 'md' : 'xs'} style={{ whiteSpace: 'pre-line' }}>
                    {getCurrentNewYearTimeRange()}
                  </Box>
                </>
              ) : (
                <Box mb={isMobile ? 'md' : 'xs'}>
                  Каждый день 10-23
                </Box>
              )}

            <Box mb={isMobile ? 'md' : 'xs'}>
              Быстрая доставка по Москве и Московской области
            </Box>
            <Box mt="sm" mb={isMobile ? 'md' : ''} className={s.socialBtnsContainer}>
              {/* <Icon onClick={''} name='appStore' style={{ cursor: 'pointer', alignSelf: 'flex-start', width: 120, height: 40, marginRight: 20 }} /> */}
              <Icon
                onClick={() => {
                  window.open(externalLinks.eatStreet)
                }}
                name="appStore"
                style={{ cursor: 'pointer', alignSelf: 'flex-start', width: 120, height: 40, marginRight: 20 }} />
              <Icon
                onClick={() => {
                  window.open(externalLinks.playGoogle)
                }}
                name="googlePlay"
                style={{ cursor: 'pointer', alignSelf: 'flex-start', width: 120, height: 40 }} />
            </Box>
          </Col>
          {/* <Col */}
          {/*  span={isMobile ? 24 : 5} */}
          {/*  className={s.secondColumn} */}
          {/*  order={isMobile ? 3 : 1} */}
          {/*  style={{ padding: 0 }} */}
          {/* > */}
          {/*  <Box p='lg'> */}
          {/*    { */}
          {/*      isMobile ? ( */}
          {/*        <Text className={s.boldTextLarge}>Время обеда</Text> */}
          {/*      ) : ( */}
          {/*        <Text className={s.boldTextLarge}> */}
          {/*          Время<br/>обеда */}
          {/*        </Text> */}
          {/*      ) */}
          {/*    } */}
          {/*    <Text className={cx(s.thinText, s.mediumText)}> */}
          {/*      12:00–16:00 */}
          {/*    </Text> */}
          {/*    { */}
          {/*      isMobile ? ( */}
          {/*        <Text className={cx(s.thinText, s.smallText)}> */}
          {/*          Гибкая настройка ланча под любой аппетит */}
          {/*        </Text> */}
          {/*      ) : ( */}
          {/*        <Text className={cx(s.thinText, s.smallText)}> */}
          {/*          Гибкая настройка<br/>ланча под любой<br/>аппетит */}
          {/*        </Text> */}
          {/*      ) */}
          {/*    } */}
          {/*  </Box> */}
          {/* </Col> */}
          <Col
            span={isMobile ? 24 : 16}
            style={{ padding: 0 }}
            className={s.linksColumn}
            order={4}
          >
            <Box mt={isMobile ? 'md' : ''} className={s.row}>
              <Box mr="md" className={s.column}>
                <NavLink to={links.delivery}>
                  <Text>Доставка и оплата</Text>
                </NavLink>
                <NavLink to={links.sales}>
                  <Text>Акции</Text>
                </NavLink>
                {isLoggedIn && (
                  <NavLink to={links.support}>
                    <Text>Связаться с нами</Text>
                  </NavLink>
                )}
                <a href="https://business.eatstreet.ru/" target="_blank" rel="noreferrer">
                  <Text>Корпоративное питание</Text>
                </a>
              </Box>
              <Box mr="md" className={s.column}>
                <a href="https://eatstreet.ru/blog/">
                  <Text>Блог</Text>
                </a>
                <NavLink to={links.about}>
                  <Text>О нас</Text>
                </NavLink>
                <NavLink to={links.contacts}>
                  <Text>Контакты</Text>
                </NavLink>
              </Box>
              <Box mr="md" className={s.column}>
                {/* <NavLink to={links.sales}> */}
                {/*  <Text>Акции</Text> */}
                {/* </NavLink> */}
                <div className={s.socialsContainer}>
                  <div
                    className={s.socialWrapper}
                    onClick={() => {
                      window.open(externalLinks.telegram)
                    }}>
                    <Icon name="telegram" />
                  </div>

                  <div
                    className={s.socialWrapper}
                    onClick={() => {
                      window.open(externalLinks.vk)
                    }}>
                    <Icon name="vkontakte" />
                  </div>
                  <div
                    className={s.socialWrapper}
                    onClick={() => {
                      window.open(externalLinks.youtube)
                    }}>
                    <Icon name="youtube" />
                  </div>
                  <div
                    className={s.socialWrapper}
                    onClick={() => {
                      window.open(externalLinks.tiktok)
                    }}>
                    <Icon name="tiktok" />
                  </div>
                </div>
              </Box>
            </Box>
            {/* { */}
            {/*  isMobile ? ( */}
            {/*    <Box mt='sm' mb='md'> */}
            {/*      <Icon name='yandexEats' style={{ cursor: 'pointer' }} /> */}
            {/*      <Icon name='deliveryClub' style={{ cursor: 'pointer' }} /> */}
            {/*    </Box> */}
            {/*  ) : ( */}
            {/*    <Fragment> */}
            {/*      <Icon name='yandexEats' style={{ cursor: 'pointer' }} /> */}
            {/*      <Box mt='xlg' mb='xlg' style={{ display: 'flex', justifyContent: 'center' }}> */}
            {/*        <Icon name='deliveryClub' style={{ cursor: 'pointer' }} /> */}
            {/*      </Box> */}
            {/*    </Fragment> */}
            {/*  ) */}
            {/* } */}
          </Col>
        </Row>
      </WidthContainer>
      <Box mt={isMobile ? 'lg' : 'md'} mb={isMobile ? 'lg' : 'md'}>
        <Divider />
      </Box>
      <WidthContainer>
        <Row>
          <Col
            span={isMobile ? 24 : 8}
          >
            <Box className={cx(s.bottomTextWrapper, s.rightTextWrapprer)} mb="sm">
              <span className={s.copyrights}>&copy; Eat Street {new Date().getFullYear()}</span>
              <Text><a href="https://doubletapp.ai/" target="_blank" rel="noreferrer">Разработано в Doubletapp</a></Text>
            </Box>
          </Col>
          <Col
            span={isMobile ? 24 : 8}
          >
            <Box className={s.logo} mb="sm">
              <Icon name="logoWithText" style={{ width: 182 }} />
            </Box>
          </Col>
          <Col
            span={isMobile ? 24 : 8}
          >
            <Box className={cx(s.bottomTextWrapper)} mb="sm">
              <Text onClick={() => {
                window.open('/assets/documents/policy.html\n')
              }}>Политика конфиденциальности
              </Text>
              <Text onClick={() => {
                window.open('/assets/documents/accept.html ')
              }}>Пользовательское соглашение
              </Text>
              <Text className={s.footerStreet}>© Eat Street {new Date().getFullYear()}</Text>
              <Text className={s.footerStreet}><a href="https://doubletapp.ai/" target="_blank" rel="noreferrer">Разработано в Doubletapp</a></Text>
              {/* <Text>Договор оферты</Text> */}
            </Box>
          </Col>
        </Row>
      </WidthContainer>
    </div>
  )
}

const FooterComponent =  compose(
  withViewport,
)(Footer)



export default connect((state) => ({
  isLoggedIn: state.app.user && state.app.user.type !== 'guest',
}))(FooterComponent)
