import React from 'react'
import { Col, Typography } from 'antd'
import cx from 'classnames'

import FoodIcon from '../FoodIcon'
import Icon from '../Icon'

import s from './ComboProductSelect.module.less'
import Box from '../Box/Box'


const { Text } = Typography

const ComboProductSelect = (props) => {

  const { isMobile, images, mainText, secondaryText, handleClick, isRed } = props

  return (
    <div className={cx(s.container, handleClick && s.clickable, isRed && s.isRed)} onClick={handleClick}>
      {
        isMobile && images && images.length > 0 && images[0].mobile ? (
          <img
            src={images[0].mobile.preview}
            style={{ height: 65, borderRadius: 5 }}
            className={s.foodIcon}
            srcSet={`${images[0].mobile.preview} 1x, ${images[0].mobile['preview-x2']} 2x`}
          />
        ) : !isMobile && images && images.length > 0 && images[0].desktop ? (
          <img
            src={images[0].desktop.preview}
            style={{ height: 65, borderRadius: 5 }}
            className={s.foodIcon}
            srcSet={`${images[0].desktop.preview} 1x, ${images[0].desktop['preview-x2']} 2x`}
          />
        ) : null
      }
      <div className={s.textBlock}>
        <Text className={cx(s.mainText, images && images.length > 0 && s.truncatedText)}>{mainText}</Text>
        <Text className={s.secondaryText}>{secondaryText}</Text>
      </div>
      {
        handleClick && <Icon name="right" className={s.rightIcon} />
      }
    </div>
  )
}

export default ComboProductSelect

