exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3nrcb9ADrH3W0aL9sc2Sux {\n  width: 100%;\n  height: 500px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n@media (max-width: 375px) {\n  .j6UMO-uIAobAMIRoBOPMN {\n    width: 100%;\n  }\n}\n.lEGimdf5WVqHGwJySI9Fz {\n  position: fixed;\n  bottom: 100px;\n  right: calc((100vw - 1280px + 20px)/2);\n  z-index: 999;\n}\n@media (max-width: 1280px) {\n  ._2RcrWVzZ_d7MBnlbf6q7jQ {\n    right: 20px;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"loader": "_3nrcb9ADrH3W0aL9sc2Sux",
	"button": "j6UMO-uIAobAMIRoBOPMN",
	"basketButton": "lEGimdf5WVqHGwJySI9Fz",
	"rightSider": "_2RcrWVzZ_d7MBnlbf6q7jQ"
};