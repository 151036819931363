import React, { Fragment } from 'react'
import { Typography, Row, Col } from 'antd'
import cx from 'classnames'
import { compose } from 'redux'
import { useHistory } from 'react-router-dom'

import { connect } from 'react-redux'
import Box from '../../../../components/ui/Box/Box'
import Image from '../../../../components/ui/Image'
import Icon from '../../../../components/ui/Icon'
import Button from '../../../../components/ui/Button/Button'
import ProductInfo from '../../../../components/ui/ProductInfo/ProductInfo'
import Increment from '../../../../components/ui/Increment/Increment'
import BasketItemInfo from '../BasketItemInfo/BasketItemInfo'
import {
  removeBasketItem,
  setBasketItem,
  setPersonsCount,
} from '../../../../redux/actions'
import { isMobileLayout, isTabletMiddleLayout } from '../../../../helpers/isMobileLayout'
import { withViewport } from '../../../../helpers/withViewport'

import s from './BasketItem.module.less'
import links from '../../../../helpers/links'


const { Text } = Typography

const BasketItem = (props) => {

  const { item, cutleryItem, viewport, sections, isCombo, totalWeight, comboItemsAmount, comboComponents, switcher, index } = props
  const history = useHistory()

  const { price, items, count, images, products_guid, section_guid, alias, modifications } = item || {}

  const isMobile = isMobileLayout(viewport)
  const isTabletMiddle = isTabletMiddleLayout(viewport)

  const section = sections.find((section) => section.section_guid === section_guid)

  const getTotalProductPrice = () => {
    if (modifications && modifications.length > 0) {
      let pricesArr = []
      modifications.forEach((i) => {
        pricesArr.push(i.price * i.quant)
      })
      return pricesArr.reduce((a, b) => a + b)
    }
  }
  const totalProductPrice = getTotalProductPrice() ? ((getTotalProductPrice() + price) * count) : price * count

  return (
    <Box mt={isMobile && cutleryItem ? '' : 'sm'} mb={isMobile ? 'lg' : 'sm'} style={{ width: '100%' }}>
      <Row gutter={isTabletMiddle ? 6 : 18} className={s.container}>
        <Col
          span={2.5}
          order={0}
          onClick={() => {
            history.push(`${links.catalog}/${section.alias}/${alias}`)
          }}
          style={{ cursor: 'pointer' }}>
          <Box className={cx(s.imageContainer, cutleryItem && s.cutleryImageContainer)}>
            {/* { */}
            {/*  !cutleryItem ? ( */}
            {/*    <Image name={image} style={{ height: isMobile ? 84 : 89 }} /> */}
            {/*  ) : ( */}
            {/*    <Icon name='cutlery' /> */}
            {/*  ) */}
            {/* } */}
            {cutleryItem ? (
              <Icon name="cutlery" />
            ) : isMobile && images && images[0].mobile ? (
              <img
                src={images[0].mobile.preview}
                style={{ height: 89 }}
                srcSet={`${images[0].mobile.preview} 1x, ${images[0].mobile['preview-x2']} 2x`}
              />
            ) : isMobile && images && images[0].preview ? (
              <img
                src={images[0].preview}
                style={{ height: 89 }}
              />
            ) : !isMobile && images && images[0].desktop ? (
              <img
                src={images[0].desktop.preview}
                style={{ height: 89 }}
                srcSet={`${images[0].desktop.preview} 1x, ${images[0].desktop['preview-x2']} 2x`}
              />
            ) : !isMobile && images && images[0].preview ? (
              <img
                src={images[0].preview}
                style={{ height: 89 }}
              />
            ) : null}
          </Box>
        </Col>
        <Col
          span={11}
          order={1}
          style={{ cursor: cutleryItem ? 'default' : 'pointer' }}
          onClick={() => {
            if (!cutleryItem) {
              history.push(`${links.catalog}/${section.alias}/${alias}`)
            }
          }}>
          <BasketItemInfo
            item={item}
            switcher={switcher}
            cutleryItem={cutleryItem}
            isMobile={isMobile}
            isCombo={isCombo}
            totalWeight={totalWeight}
            comboItemsAmount={comboItemsAmount}
            comboComponents={comboComponents}
          />
        </Col>
        <Col span={3.5} order={isTabletMiddle ? 3 : 2} className={s.controlsContainer}>
          <Box pt="sm">
            <Increment
              defaultCount={count}
              handleChange={(dec) => {
                if (item.type === 'persons_count') {
                  setPersonsCount(dec ? count - 1 : count + 1)
                }
                else {
                  setBasketItem(item, dec, item.products, item.modifications, item.switchers)
                }
              }}
              isLightGreen
            />
          </Box>
        </Col>
        <Col span={isTabletMiddle ? 5 : 3} order={isTabletMiddle ? 2 : 3} className={s.priceContainer}>
          <Box mt={isTabletMiddle ? '' : 'xxs'} pt={isMobile ? '' : 'sm'} alignCenter={!isMobile} alignRight={isMobile}>
            <Text className={s.price}>
              {totalProductPrice ? `${totalProductPrice} ₽` : price ? `${price} ₽` : '0 ₽'}
            </Text>
          </Box>
        </Col>
        {
          !cutleryItem && !isTabletMiddle && (
            <Col span={2} order={4}>
              <Box pt="xs" center className={s.trashContainer}>
                <Button
                  type="icon"
                  icon="trash"
                  isMedium
                  handleClick={() => {
                    removeBasketItem(index)
                  }} />
              </Box>
            </Col>
          )
        }
      </Row>
      {
        isMobile && (
          <Box mt="md">
            {
              items && items.map((el, i) => (
                <Row key={i}>
                  <Col span={18}>
                    <Text className={s.subTextDark}>{el.name}</Text>
                  </Col>
                  <Col span={6} className={s.isComboRow}>
                    <Text className={s.subText}>{parseInt(el.weight)} {el.measure_type}</Text>
                  </Col>
                </Row>
              ))
            }
          </Box>
        )
      }
    </Box>
  )
}

const BasketItemComponent = compose(
  withViewport,
)(BasketItem)



export default connect((state) => ({
  sections: state.app.sections,
}))(BasketItemComponent)
