import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { Col, Layout, Row, Spin, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import NumberFormat from 'react-number-format'

import links from 'helpers/links'

import Box from 'components/ui/Box/Box'
import Button from 'components/ui/Button/Button'
import Icon from 'components/ui/Icon'

import { connect } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import useLocalStorage from '../../hooks/useLocalStorage'

import s from './Login.module.less'
import reducers from '../../redux/reducers'
import externalLinks from '../../helpers/externalLinks'
import WidthContainer from '../../components/layout/WidthContainer/WidthContainer'
import api from '../../helpers/api'
import request from '../../helpers/request'
import { addCachedAddressesToUser, setPaymentCards, setSelectedDeliveryPoint } from '../../redux/actions'


const { Content } = Layout
const { Text } = Typography

const SITEKEY = '6LfqYekfAAAAADy8Q8L-wqT5PB0Ya-hsW6xju9Rf'// '6LcySPAfAAAAALmHlBK795L06cy6kvUrgISPqr5k'

const Login = (props) => {
  const [ loading, setLoading ] = useState(false)
  const [ phoneNumber, setPhoneNumber ] = useState('')
  const [ getCodeStage, setGetCodeStage ] = useState(false)
  const [ codeNumber, setCodeNumber ] = useState('')
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ codeNumberValue, setCodeNumberValue ] = useState('')
  const [ isConsentStatus, setIsConsentStatus ] = useState(false)

  const history = useHistory()

  const { user, selectedDeliveryPointId } = props

  const { location = {} } = history
  const { state = {} } = location

  const isMobile = window.matchMedia('(max-width: 375px)').matches
  const [ token, setToken ] = useLocalStorage('token', null)

  const prevNumberRef = useRef()
  const prevNumber = prevNumberRef.current

  const onCodeSubmit = useCallback(async () => {
    setLoading(true)
    try {
      const response = await request.post(api.checkSms, {
        body: {
          phone: phoneNumber,
          code: parseInt(codeNumber, 10),
        },
      })
      reducers.app.setUser(response.data[0])
      localStorage.setItem('token', response.data[0].token)
      setPaymentCards()

      if (user && user.addresses && user.addresses.length > 0) {
        if (selectedDeliveryPointId) {
          const selectedAddress = user.addresses.find((address) => address.address_id === selectedDeliveryPointId)
          const addresses = await addCachedAddressesToUser(user.addresses)
          if (selectedAddress) {
            const newSelectedAddress = addresses.find((address) => ((address.coords[0] === selectedAddress.coords[0]) && (address.coords[1] === selectedAddress.coords[1])))
            if (newSelectedAddress) {
              await setSelectedDeliveryPoint(newSelectedAddress)
            }
          }
          localStorage.removeItem('cachedAddresses')
        }
      }

      if (state && state.to) {
        history.push(state.to)
      }
      else {
        history.push(links.myData)
      }
    }
    catch (e) {
      if (e.message) {
        setErrorMessage(e.message)
      }
    }
    setLoading(false)
  }, [ setToken, codeNumber, phoneNumber, setErrorMessage ])

  // reCaptcha
  const [getNewCode, setGetNewCode] = useState(false)
  const [validCaptcha, setValidCaptcha] = useState(null)
  const captcha = useRef(null)

  const onChange = () => {
    if (captcha.current.getValue()) {
      setValidCaptcha(true)
    }
  }
  const onPhoneSubmit = useCallback(async () => {
    setGetNewCode(false)
    const captchaToken = captcha.current.getValue()
    if (captchaToken) {
      setValidCaptcha(true)
    }
    else {
      setValidCaptcha(false)
    }
    try {
      setLoading(true)
      const response = await request.post(api.register, {
        body: {
          phone: phoneNumber,
          consent_status: 1,
          recaptcha_response: captchaToken,
        },
      })
      setLoading(false)
      setGetCodeStage(true)
      if (response.data[0].code) {
        setCodeNumber(response.data[0].code)
        setCodeNumberValue(response.data[0].code)
      }
    }
    catch (e) {
      setLoading(false)
      if (e.message) {
        setErrorMessage(e.message)
      }
    }
    finally {
      setLoading(false)
    }

  }, [ isConsentStatus, setGetCodeStage, phoneNumber, setLoading ])

  useEffect(() => {
    prevNumberRef.current = phoneNumber
    if (prevNumber !== phoneNumber && phoneNumber.length === 18) {
      setErrorMessage(false)
    }
  }, [phoneNumber])

  return (
    <Layout>
      <WidthContainer>
        {getCodeStage && (
          <Box className={s.backWrapper} onClick={() => setGetCodeStage(false)}>
            <Button type="icon" icon="left"  />
            <Text className={s.backText}>Изменить номер телефона</Text>
          </Box>
        )}

        <Box className={s.container}>
          <Box mt="lg" alignCenter>
            <Text className={s.sectionTitle}><span>Введите ваши данные<br /> чтобы продолжить</span></Text>
          </Box>
          {
            !getCodeStage ? (
              <>
                <Box mt="md" className={s.phoneContainer}>
                  <div>
                    <Text className={s.smallText}>Номер телефона</Text>
                    <NumberFormat
                      allowEmptyFormatting
                      format="+7 (###) ###-##-##"
                      value={phoneNumber}
                      mask="_"
                      type="tel"
                      onValueChange={(val) => {
                        setPhoneNumber(val.formattedValue)
                      }}
                    />
                  </div>
                </Box>
                <div className={s.recaptcha}>
                  <ReCAPTCHA
                    ref={captcha}
                    sitekey={SITEKEY}
                    onChange={onChange}
                    onExpired={() => setValidCaptcha(false)}
                  />
                </div>
                {errorMessage && <Text className={s.warningText}>{errorMessage}</Text>}
                <Box mt="sm" className={s.buttonWrapper}>
                  {loading ? <Spin /> : (
                    <Button
                      text="Войти"
                      primary
                      disabled={
                        phoneNumber.replace(/\D/g, '').length < 11
                      || errorMessage
                      || !validCaptcha
                      }
                      handleClick={onPhoneSubmit} />
                  )}
                </Box>
              </>
            ) : (
              <>
                {!getNewCode
                  ? (
                    <>
                      <Box mt="md" className={s.phoneContainer}>
                        <div>
                          <Text className={s.smallText}>Введите код из СМС</Text>
                          <NumberFormat
                            format="######"
                            mask="."
                            value={codeNumber}
                            allowEmptyFormatting
                            onValueChange={(val) => setCodeNumber(val.value)}
                          />
                        </div>
                      </Box>
                      {errorMessage && <Text className={s.warningText}>{errorMessage}</Text>}
                      <Text
                        className={s.resendCode}
                        onClick={() => {
                          setGetNewCode(true)
                          setValidCaptcha(false)
                        }}>
                        Прислать новый код
                      </Text>
                      <Box mt="sm" className={s.buttonWrapper}>
                        {loading ? <Spin /> : (
                          <Button
                            text="Войти"
                            primary
                            disabled={codeNumber.length < 6}
                            handleClick={onCodeSubmit}
                          />
                        )}
                      </Box>
                    </>
                  )
                  : (
                    <>
                      <div className={s.recaptcha}>
                        <ReCAPTCHA
                          ref={captcha}
                          sitekey={SITEKEY}
                          onChange={onChange}
                          onExpired={() => setValidCaptcha(false)}
                        />
                      </div>
                      <Box mt="sm" className={s.buttonWrapper}>
                        {loading ? <Spin /> : (
                          <Button
                            text="Прислать новый код"
                            primary
                            disabled={!validCaptcha}
                            handleClick={onPhoneSubmit}
                          />
                        )}
                      </Box>
                    </>
                  )}

              </>
            )
          }
          {!getCodeStage && (
            <Box mt="lg" className={s.checkboxContainer}>
              {/* <Checkbox disabled={getCodeStage} checked={isConsentStatus} onChange={(e) => setIsConsentStatus(e.target.checked)}> */}
              <Text>Нажимая “Войти” вы соглашаетесь с <a href="/assets/documents/policy.html" target="_blank">Политикой Конфиденциальности</a></Text>
              {/* </Checkbox> */}
            </Box>
          )}
          {!getCodeStage && (
            <Box mt="lg" className={s.infoContainer}>
              <Text onClick={() => {
                history.push(links.contacts)
              }}>Связаться с нами
              </Text>
              <Text onClick={() => {
                window.open('/assets/documents/accept.html')
              }}>Пользовательское соглашение
              </Text>
            </Box>
          )}
          {!getCodeStage && (
            <Box mt="md" center className={s.joinContainer}>
              <Text className={s.boldText}>Присоединяйтесь</Text>
              <Row gutter={[12]} className={s.row}>
                <Col span={12}>
                  <Box
                    className={s.socialContainer}
                    onClick={() => {
                      window.open(externalLinks.vk)
                    }}>
                    <Icon name="vkontakteGreen" />
                    <Text className={s.greenText}>Vkontakte</Text>
                  </Box>
                </Col>
                <Col span={12}>
                  <Box
                    className={s.socialContainer}
                    onClick={() => {
                      window.open(externalLinks.telegram)
                    }}>
                    <Icon name="telegramGreen" />
                    <Text className={s.greenText}>Telegram</Text>
                  </Box>
                </Col>
              </Row>
            </Box>
          )}
        </Box>
      </WidthContainer>
    </Layout>
  )
}

export default connect((state) => ({
  user: state.app.user,
  selectedDeliveryPointId: state.app.selectedDeliveryPointId,
}))(Login)
