import React from 'react'


const AcceptedIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.3577 4.76814C16.835 4.29083 17.6089 4.29083 18.0862 4.76814C18.5635 5.24544 18.5635 6.01931 18.0862 6.49662L10.5104 14.0724L6.54572 10.1077C6.06842 9.63042 6.06842 8.85656 6.54572 8.37925C7.02303 7.90194 7.7969 7.90194 8.27421 8.37925L10.5104 10.6154L16.3577 4.76814Z" fill={color} />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.5 16.582V14.082H3.5V16.582C3.5 17.6866 4.39543 18.582 5.5 18.582H18.5C19.6046 18.582 20.5 17.6866 20.5 16.582V14.082H22.5V16.582C22.5 18.7912 20.7091 20.582 18.5 20.582H5.5C3.29086 20.582 1.5 18.7912 1.5 16.582Z" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill={color} />
      </clipPath>
    </defs>
  </svg>

)

export default AcceptedIcon
