import React from 'react'
import cx from 'classnames'
import { Typography } from 'antd'
import { NavLink } from 'react-router-dom'
import { compose } from 'redux'

import Box from '../../components/ui/Box/Box'
import Image from '../../components/ui/Image'
import FoodIcon from '../../components/ui/FoodIcon'
import ProductInfo from '../../components/ui/ProductInfo/ProductInfo'
import ProductCardFooter from './components/ProductCardFooter/ProductCardFooter'

import links from '../../helpers/links'
import { isMobileLayout, isTabletLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import s from './ProductCard.module.less'


const { Paragraph } = Typography

const ProductCard = (props) => {

  const {
    viewport,
    in_stop,
    id,
    products_guid,
    isFavorite,
    groups_objects,
    badges, title,
    measure_type,
    alias,
    section_alias,
    image, size,
    weight,
    price,
    bigPrice,
    onLikeClicked,
    onPriceClicked,
    isLiked,
    onCardClick,
  } = props

  const isMobile = isMobileLayout(viewport)

  const formattedTitle = title.length > 38 ? `${title.slice(0, 38)}...` : title

  const cardClickHandler = () => {
    if (onCardClick) onCardClick()
  }

  return (
    <div className={cx(s.container, isMobile && s.mobileContainer, in_stop && s.stopContainer)}>
      <div onClick={cardClickHandler} className={s.productBox}>
        <NavLink
          to={`${links.catalog}/${section_alias}/${alias}`}>
          <div className={s.product}>
            <div className={s.badges}>
              {
                badges && badges.map((item, i) => (
                  <div className={cx(s.badgeContainer, isMobile && s.badgeContainerMobile)} key={i}>
                    <FoodIcon name={item} />
                  </div>
                ))
              }
            </div>
            {image ? (
              <>
                {
                  isMobile && image.mobile ? (
                    <img
                      src={image.mobile.preview}
                      className={s.productImg}
                      srcSet={`${image.mobile.preview} 1x, ${image.mobile['preview-x2']} 2x`}
                    />
                  ) : isMobile && !image.mobile ? (
                    <img
                      src={image.preview}
                      className={s.productImg}
                    />
                  ) : !isMobile && image.desktop ? (
                    <img
                      src={image.desktop.preview}
                      className={s.productImg}
                      srcSet={`${image.desktop.preview} 1x, ${image.desktop['preview-x2']} 2x`}
                    />
                  ) : !isMobile && !image.desktop ? (
                    <img
                      src={image.preview}
                      className={s.productImg}
                    />
                  ) : null
                }
              </>
            ) : (
              <Image name="noImage" className={s.productImg} />
            )}
          </div>
        </NavLink>
      </div>
      <div className={cx(s.productInfo, isMobile && s.productInfoMobile)}>
        <div onClick={cardClickHandler}>
          <NavLink to={`${links.catalog}/${section_alias}/${alias}`}>
            {
              isMobile && (
                <Paragraph className={s.title}>
                  {formattedTitle}
                </Paragraph>
              )
            }
          </NavLink>
        </div>
        <Box mt={isMobile ? '' : 'md'} mb={isMobile ? 'xs' : 'sm'}>
          <ProductInfo size={size} weight={weight} price={price} measure_type={measure_type} groups_objects={groups_objects} />
        </Box>
        {
          !isMobile && (
            <div onClick={cardClickHandler}>
              <NavLink to={`${links.catalog}/${section_alias}/${alias}`}>
                <Box mb="sm">
                  <Paragraph className={s.title}>
                    {formattedTitle}
                  </Paragraph>
                </Box>
              </NavLink>
            </div>
          )
        }
        <ProductCardFooter
          products_guid={products_guid}
          price={bigPrice}
          onLikeClicked={onLikeClicked}
          onPriceClicked={onPriceClicked}
          isFavorite={isFavorite}
          groups_objects={groups_objects}
        />
      </div>
    </div>
  )
}

export default compose(
  withViewport,
)(ProductCard)

