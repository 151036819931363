exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".fpgtjHzeGd_1V84lsAOsc {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  height: 132px;\n  width: 442px;\n  padding: 10px;\n}\n@media (max-width: 768px) {\n  .fpgtjHzeGd_1V84lsAOsc {\n    height: 90px;\n    width: 250px;\n    margin-bottom: 40px;\n  }\n}\n._1M4e6CWHStWuQ3Y1w5csjE {\n  font-family: 'Circe-Regular', serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 40px;\n  line-height: 40px;\n}\n@media (max-width: 768px) {\n  ._1M4e6CWHStWuQ3Y1w5csjE {\n    font-size: 26px;\n    line-height: 26px;\n  }\n}\n._950YGIi36l0-LtyFkRMtM {\n  font-family: 'Circe-Regular', serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 20px;\n  line-height: 20px;\n  margin-top: 20px;\n}\n@media (max-width: 768px) {\n  ._950YGIi36l0-LtyFkRMtM {\n    margin-top: 10px;\n    font-size: 12px;\n    line-height: 12px;\n  }\n}\n.nvmEg9MuYNiiQl9CPjgfQ {\n  position: absolute;\n  display: inline-block;\n  height: 12px;\n  width: 12px;\n}\n@media (max-width: 768px) {\n  .nvmEg9MuYNiiQl9CPjgfQ {\n    height: 8px;\n    width: 8px;\n  }\n}\n._1Sp9QH_PS5Yll3V29kH4Ny {\n  top: 0;\n  left: 0;\n}\n._25VJeZw_LinhY-yGbrBgGr {\n  top: 0;\n  right: 0;\n  transform: rotate(90deg);\n}\n.ssCdusQB1pCqQys4vJo79 {\n  bottom: 0;\n  right: 0;\n  transform: rotate(180deg);\n}\n._3qXSjpI1dUbsdcWYk6dPx9 {\n  bottom: 0;\n  left: 0;\n  transform: rotate(270deg);\n}\n", ""]);

// Exports
exports.locals = {
	"notFoundBlock": "fpgtjHzeGd_1V84lsAOsc",
	"title": "_1M4e6CWHStWuQ3Y1w5csjE",
	"subtitle": "_950YGIi36l0-LtyFkRMtM",
	"border": "nvmEg9MuYNiiQl9CPjgfQ",
	"topLeft": "_1Sp9QH_PS5Yll3V29kH4Ny",
	"topRight": "_25VJeZw_LinhY-yGbrBgGr",
	"bottomRight": "ssCdusQB1pCqQys4vJo79",
	"bottomLeft": "_3qXSjpI1dUbsdcWYk6dPx9"
};