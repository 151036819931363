import React, { useState } from 'react'
import { Typography, Row, Col, Checkbox } from 'antd'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'

import { connect } from 'react-redux'
import Box from '../../components/ui/Box/Box'

import s from './PointsInfo.module.less'
import getRestaurantsFromCities from '../../helpers/getRestaurantsFromCities'
import links from '../../helpers/links'
import Button from '../../components/ui/Button/Button'


const { Text, Paragraph } = Typography

const PointsInfo = ({ user }) => {

  // const { points } = props

  const isMobile = window.matchMedia('(max-width: 375px)').matches

  return (
    <Box className={s.container}>
      <Box mt="xlg" alignCenter>
        <Text className={s.title}>У вас {user.points ? user.points : '0'} баллов</Text>
      </Box>
      <Box mt={isMobile ? 'md' : 'lg'} alignCenter>
        <Paragraph className={s.paragraph}>Получайте баллы за заказы и оплачивайте ими до 30% от заказа</Paragraph>
      </Box>
      <Box mt="md" alignCenter>
        <Text className={s.text}>
          Программа лояльности — система вознаграждения наших гостей. Заказывая еду мы даем скидку на текущий счет и начисляем баллы на карту лояльности.
        </Text>
      </Box>
      <Box mt={isMobile ? 'md' : 'lg'} alignCenter>
        <Paragraph className={s.paragraph}>Как работают баллы?</Paragraph>
        <Text className={s.text}>
          Балл, в отличие от других единиц, не имеет постоянного размера, то есть одинаковой разности двух значений в баллах не соответствует одинаковая разность абсолютных размеров величины. В баллах принято измерять величины, проявления которых описываются качественно. К ним относятся — интенсивность землетрясений[4], сила шторма, знания школьника или студента.
        </Text>
      </Box>
      <Box mt={isMobile ? 'md' : 'lg'} alignCenter>
        <Paragraph className={s.paragraph}>Как копятся баллы?</Paragraph>
        <Text className={s.text}>
          Согласно статьям 440, 441 Гражданского кодекса РФ договор считается заключённым после того, как лицо, направившее оферту, получает согласие (акцепт). Статья 158 Гражданского кодекса Российской Федерации допускает, что согласие на сделку может следовать из поступков стороны, направленных на реализацию сделки (конклюдентные действия), даже если не было явных заявлений о воле принять условия оферты. Например, факт посадки в общественный транспорт свидетельствует о желании ехать и приемлемости описанных условий оказания транспортных услуг (маршрут, цена), что автоматически обязывает пассажира оплатить проезд.
        </Text>
      </Box>
    </Box>
  )
}

export default connect((state) => ({
  user: state.app.user,
}))(PointsInfo)
