import React from 'react'

import Bacon from './images/Bacon.svg'
import CreamySoup from './images/CreamySoup.svg'
import Jalapena from './images/Jalapena.svg'
import Mozzarella from './images/Mozzarella.svg'
import Mushrooms from './images/Mushrooms.svg'
import Salmon from './images/Salmon.svg'
import SeaBuckthornFruitDrink from './images/SeaBuckthornFruitDrink.svg'
import Shrimp from './images/Shrimp.svg'
import spicyBadge from './images/SpicyBadge.svg'
import veganBadge from './images/VeganBadge.svg'
import Cupcake from './images/CupCake.svg'
import Crumble from './images/Crumble.svg'
import Pancakes from './images/Pancakes.svg'


export const images = {
  bacon: Bacon,
  creamySoup: CreamySoup,
  jalapena: Jalapena,
  mozzarella: Mozzarella,
  mushrooms: Mushrooms,
  salmon: Salmon,
  seaBuckthornFruitDrink: SeaBuckthornFruitDrink,
  shrimps: Shrimp,
  spicy: spicyBadge,
  vegan: veganBadge,
  cupcake: Cupcake,
  crumble: Crumble,
  pancakes: Pancakes,
}

// TODO лосось криво импортируется из figma

const Index = (props) => {
  const { name, className } = props

  return (
    <img className={className} src={images[name]} />
  )
}

export default Index
