export default (cities) => {
  let restaurants = []

  if (cities) {
    cities.forEach((city) => {
      city.restaurants.forEach((restaurant) => {
        restaurants.push(restaurant)
      })
    })
  }

  return restaurants
}
