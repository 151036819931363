exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".D8qqYFWRJo6AYUc4FQf3 {\n  position: fixed;\n  bottom: 0;\n  max-width: 1240px;\n  width: calc(100% - 32px);\n  display: flex;\n  min-height: 136px;\n  align-items: center;\n  justify-content: center;\n  background: transparent;\n  z-index: 99;\n  padding-bottom: 20px;\n}\n@media (min-width: 376px) {\n  .D8qqYFWRJo6AYUc4FQf3 {\n    width: calc(100% - 40px);\n  }\n}\n.yiZNoSPDh3bPXKzKX9350 {\n  width: 100%;\n  background: #F7F9F8;\n  box-shadow: 0 14px 24px rgba(0, 0, 0, 0.15);\n  min-height: 96px;\n  border-radius: 10px;\n  margin: 0 !important;\n  display: flex;\n  justify-content: space-between;\n}\n@media (min-width: 768px) {\n  .yiZNoSPDh3bPXKzKX9350 {\n    align-items: center;\n  }\n}\n._1Cgdb2NNnun0uPxGgcDfLw {\n  font-family: 'Circe-Regular', sans-serif;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 24px;\n  color: #3C3C3C;\n  max-width: 80%;\n}\n.ZYyveVjHmeOWCNVyWudVB {\n  background: #fff;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "D8qqYFWRJo6AYUc4FQf3",
	"content": "yiZNoSPDh3bPXKzKX9350",
	"text": "_1Cgdb2NNnun0uPxGgcDfLw",
	"closeButton": "ZYyveVjHmeOWCNVyWudVB"
};