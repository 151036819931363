exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".dDKudLe6PGL0-KHfpnrl3 {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n._2CwwpSrW8VD955fVrTOyH1 {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n.v8BlA8rTeab4YC3e1G0Fu {\n  position: absolute;\n  top: 0;\n  left: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  padding: 0 40px;\n  font-family: 'Circe-Bold', serif;\n  font-style: normal;\n  background: linear-gradient(112.74deg, #000000 -9.83%, rgba(0, 0, 0, 0) 91.33%) 100% 100% no-repeat;\n}\n._30QAnhZH5bjDD68NZX5wTL {\n  margin-bottom: 20px;\n  font-weight: 700;\n  font-size: 40px;\n  line-height: 40px;\n  color: #FFF;\n}\n@media (max-width: 572px) {\n  ._30QAnhZH5bjDD68NZX5wTL {\n    font-size: 25px;\n    line-height: 26px;\n  }\n}\n._2YLy237MrsZyDqvOqY0nQC {\n  margin-bottom: 25px;\n  font-weight: 700;\n  font-size: 20px;\n  line-height: 26px;\n  color: #FFF;\n}\n@media (max-width: 572px) {\n  ._2YLy237MrsZyDqvOqY0nQC {\n    margin-bottom: 36px;\n    font-size: 15px;\n    line-height: 16px;\n  }\n}\n._1IPhLRuWUYSqXao9Xpsis3 {\n  width: 210px;\n  height: 52px;\n  font-family: 'Circe-Regular', serif;\n}\n@media (max-width: 768px) {\n  ._1IPhLRuWUYSqXao9Xpsis3 {\n    width: 170px;\n    height: 40px;\n    font-family: 'Circe-Bold', serif;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"bannerContainer": "dDKudLe6PGL0-KHfpnrl3",
	"backgroundImage": "_2CwwpSrW8VD955fVrTOyH1",
	"absolutuContainer": "v8BlA8rTeab4YC3e1G0Fu",
	"title": "_30QAnhZH5bjDD68NZX5wTL",
	"subtitle": "_2YLy237MrsZyDqvOqY0nQC",
	"button": "_1IPhLRuWUYSqXao9Xpsis3"
};