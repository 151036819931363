import React from 'react'

import icons from './utils/icons'


const Icon = (props) => {
  const { name, className, ...rest } = props

  const icon = icons[name]

  // TODO добавить svg-рендерер, чтобы можно было пробрасывать цвета

  return (
    <img className={className} src={icon} {...rest} />
  )
}


export default Icon
