import React, { useRef } from 'react'
import { Carousel, Col, Row, Typography } from 'antd'
import { compose } from 'redux'
import { connect } from 'react-redux'

import Box from '../../components/ui/Box/Box'
import ComboCard from '../../components/ui/ComboCard/ComboCard'

import links from '../../helpers/links'
import { isMobileLayout, isTabletLayout, isTabletMiddleLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import s from './Combo.module.less'


const { Text } = Typography

const ComboComponent = ({ combo, viewport }) => {

  const isMobile = isMobileLayout({ ...viewport, width: viewport.width + 332 })
  const isTablet = isTabletLayout(viewport)
  const isTabletMiddle = isTabletMiddleLayout({ ...viewport, width: viewport.width + 80 })
  const carouselRef = useRef()
  const time = new Date().getHours()

  return (
    <Box mt="lg" className={s.container}>
      {
        isTabletMiddle ? (
          <Carousel
            dots={false}
            ref={carouselRef}
            slidesToShow={isMobile ? 1 : isTabletMiddle ? 2 : 3}
            slidesToScroll={1}
            infinite
            centerMode
            centerPadding="20px"
          >
            {
              combo.sort((a, b) => a.sort - b.sort).map((item, i) => (
                <Col span={23} key={i}>
                  <ComboCard
                    time={time}
                    section_guid={item.section_guid}
                    image={item.image}
                    secondaryText={item.entry}
                    isCarousel
                    linkTo={links[item.image]}
                  />
                </Col>
              ))
            }
          </Carousel>
        ) : (
          <Row justify="between" gutter={[12]}>
            {
              combo.sort((a, b) => a.sort - b.sort).map((item, i) => (
                <Col span={8} key={i}>
                  <ComboCard
                    time={time}
                    section_guid={item.section_guid}
                    image={item.image}
                    secondaryText={item.entry}
                    linkTo={links[item.image]}
                  />
                </Col>
              ))
            }
          </Row>
        )
      }
    </Box>
  )
}

const Combo = compose(
  withViewport,
)(ComboComponent)

export default connect((state) => ({
  combo: state.app.combo,
}))(Combo)
