import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import BasicAppLayout from 'layouts/BasicAppLayout/BasicAppLayout'
import ScrollToTop from 'layouts/ScrollToTop/ScrollToTop'

import './App.less'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import Routes from './routes'
import store from './redux/store'
import {
  setSections,
  setUser,
  initBasketItems,
  setCities,
  setCombo,
  setOffers,
  setAddressDelivery,
  setFirstSlider,
  setLabels, setOrders,
  // setOrders,
  setStopList,
  setPaymentTypes,
  setNews,
  setPaymentCards,
  setRecommendations,
} from './redux/actions'


const App = () => {
  const [ loaded, setLoaded ] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem('token')
    let promises = [
      setStopList(), setSections(), initBasketItems(), setPaymentTypes(),
      setUser(), setCombo(), setLabels(), setOffers(),
      setFirstSlider(), setCities(), setNews(), setRecommendations()]
    if (token) {
      promises = [...promises, setOrders(), setPaymentCards()]
    }

    Promise.all(promises).then(async () => {
      await setAddressDelivery()
      setLoaded(true)
    }).catch(() => {
      setLoaded(true)
    })
  }, [ setLoaded ])

  useEffect(() => {
    if (process.env.REACT_APP_API_ENV === 'prod') {
      ym(95942691, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true,
        ecommerce:"dataLayer"
      })
    }
  }, [])

  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        {loaded ? <Routes /> : (
          <BasicAppLayout>
            <div className="loader">
              <Spin spinning />
            </div>
          </BasicAppLayout>
        )}
      </BrowserRouter>
    </ReduxProvider>
  )
}


export default App
