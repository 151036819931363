import React, { useEffect, useState, Fragment } from 'react'
import { Layout, Row, Col, Typography, Spin } from 'antd'
import { NavLink, useParams, useHistory } from 'react-router-dom'
import cx from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Box from 'components/ui/Box/Box'
import Button from 'components/ui/Button/Button'
import WidthContainer from '../../components/layout/WidthContainer/WidthContainer'
import ProductsCarousel from '../../compositions/ProductsCarousel/ProductsCarousel'
import AddressModal from '../../compositions/AddressModal/AddressModal'

import { isMobileLayout, isTabletLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'
import getTotalOrderPrice from '../../helpers/getTotalOrderPrice'
import links from '../../helpers/links'
import { setCatalogProducts, setProducts } from '../../redux/actions'

import s from './ComboMeal.module.less'
import BasketButton from '../../components/ui/BasketButton/BasketButton'


const { Text } = Typography
const { Content } = Layout

const ComboMealComponent = ({ viewport, sections, productId, mealId, basketItems, totalOrderPrice, user, comboMealComponents }) => {

  const [ data, setData ] = useState(null)
  const [ section, setSection ] = useState(null)

  const params = useParams()
  const history = useHistory()

  const [ isModalVisible, setModalVisible ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(null)

  const isMobile = isMobileLayout(viewport)

  useEffect(() => {
    setIsLoading(true)
    const section = sections.find((section) => section.alias === params.section_alias)

    if (section) {
      setSection(section)
      setProducts(section.section_guid)
        .then((data) => {
          setData(data.find((el) => el.alias === params.product_alias).groups_objects.find((el) => el.alias === params.group_alias).component_objects)
        })
        .finally(() => setIsLoading(false))
    }
  }, [ setData, sections, setSection ])

  const Loader = (
    <div className={s.loader}>
      <Spin spinning={isLoading} />
    </div>
  )

  if (!data) {
    return Loader
  }

  const totalItems = basketItems.reduce((r, a) => r + a.count, 0)

  return (
    <WidthContainer isNarrow>
      <Layout>
        <Content>
          {
            isLoading ? Loader : (
              <>
                <Box mt="md">
                  <Row className={s.productNameContainer}>
                    {
                      isMobile && (
                        <Box mb="md" className={s.topMobileLeft}>
                          <NavLink to={`${links.catalog}/${params.section_alias}/${params.product_alias}`}>
                            <Button type="icon" icon="down" isSmall />
                          </NavLink>
                        </Box>
                      )
                    }
                    <Text>
                      {section.title}
                    </Text>
                    {
                      !isMobile && (
                        <NavLink to={`${links.catalog}/${params.section_alias}/${params.product_alias}`}>
                          <Button type="icon" icon="close" circle />
                        </NavLink>
                      )
                    }
                    {
                      isMobile && (
                        <Box mb="md" className={s.topMobileRight}>
                          <NavLink to={links.basket}>
                            <Button type="icon" icon={totalItems > 0 ? 'shoppingToteWhite' : 'shoppingTote'} isSmall primary={totalItems > 0} />
                            {
                              totalItems && (
                                <div className={s.basketItemsCount}>
                                  <Text className={s.basketItemsCountText}>{totalItems}</Text>
                                </div>
                              )
                            }
                          </NavLink>
                        </Box>
                      )
                    }
                  </Row>
                </Box>
                <ProductsCarousel
                  id={mealId || productId}
                  data={data}
                  setModalVisible={setModalVisible}
                  product_alias={params.product_alias}
                  comboProductCode={params.section_code}
                  groupAlias={params.group_alias}
                  section_alias={params.section_alias}
                  isComboMealProducts
                />
                <AddressModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} />

                <BasketButton />
              </>
            )
          }
        </Content>
      </Layout>
    </WidthContainer>
  )
}

const ComboMeal = compose(
  withViewport,
)(ComboMealComponent)

export default connect((state) => ({
  sections: state.app.sections,
  basketItems: state.app.basketItems,
  comboMealComponents: state.app.comboMealComponents,
  user: state.app.user,
  totalOrderPrice: getTotalOrderPrice(state.app.basketItems),
}))(ComboMeal)
