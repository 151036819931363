import React from 'react'

import BDayGift from './images/BDayGift.svg'
import Breakfast from './images/Breakfast.svg'
import Coffee from './images/Coffee.svg'
import Croissant from './images/Croissant.svg'
import Dinner from './images/Dinner.svg'
import NoImage from './images/NoImage.svg'
import Lunch from './images/Lunch.svg'
import PicWithPumpkin from './images/PicWithPumpkin.svg'
import Pizza from './images/Pizza.svg'
import Sushi from './images/Sushi.svg'
import Burgers from './images/Burgers.svg'
import Sandwiches from './images/Sandwiches.svg'
import InLavash from './images/InLavash.svg'
import Soups from './images/Soups.svg'
import Bowls from './images/Bowls.svg'
import Salads from './images/Salads.svg'
import HotMeals from './images/HotMeals.svg'
import SideDish from './images/SideDish.svg'
import Desserts from './images/Desserts.svg'
import Baking from './images/Baking.svg'
import Drinks from './images/Drinks.svg'
import ForKids from './images/ForKids.svg'
import MainBanner from './images/MainBanner.svg'
import MapBanner from './images/MapBanner.svg'
import Cappuccino from './images/Cappuccino.svg'
import Philadelphia from './images/Philadelphia.svg'
import GiftPizza from './images/GiftPizza.svg'
import Discount from './images/Discount.svg'
import iPhone from './images/iPhone.svg'
import MainBannerMobile from './images/MainBannerMobile.png'
import MainBannerMobile2x from './images/MainBannerMobile@2x.png'
import MainBannerMobile3x from './images/MainBannerMobile@3x.png'
import BreakfastsSmall from './images/BreakfastsSmall.svg'
import PizzaSalmon from './images/PizzaSalmon.svg'
import Pizza4Cheeses from './images/Pizza4Cheeses.png'
import PizzaStrachatella from './images/PizzaStrachatella.png'
import PizzaMushrooms from './images/PizzaMushrooms.png'
import TomatoSoup from './images/TomatoSoup.svg'
import Coupon from './images/Coupon.svg'
import Pickpoint1 from './images/Pickpoint1.svg'
import Pickpoint2 from './images/Pickpoint2.svg'
import Pickpoint3 from './images/Pickpoint3.svg'
import Pickpoint4 from './images/Pickpoint4.svg'
import Pickpoint5 from './images/Pickpoint5.svg'
import DeliveryBicycle from './images/DeliveryBicycle.svg'
import Hurt from './images/Hurt.svg'
import businessEatMobile from './images/BusinessEatMobile.png'
import businessEatMobile2x from './images/BusinessEatMobile@2x.png'
import businessEatMobile3x from './images/BusinessEatMobile@3x.png'
import businessEatDesktop from './images/BusinessEatDesktop.png'
import businessEatDesktop2x from './images/BusinessEatDesktop@2x.png'


export const images = {
  bDayGift: BDayGift,
  hurt: Hurt,
  breakfast: Breakfast,
  coffee: Coffee,
  croissant: Croissant,
  dinner: Dinner,
  lunch: Lunch,
  picWithPumpkin: PicWithPumpkin,
  pizza: Pizza,
  sushi: Sushi,
  burgers: Burgers,
  sandwiches: Sandwiches,
  inLavash: InLavash,
  soups: Soups,
  noImage: NoImage,
  bowls: Bowls,
  salads: Salads,
  hotMeals: HotMeals,
  sideDish: SideDish,
  desserts: Desserts,
  baking: Baking,
  drinks: Drinks,
  forKids: ForKids,
  mainBanner: MainBanner,
  mapBanner: MapBanner,
  cappuccino: Cappuccino,
  philadelphia: Philadelphia,
  giftPizza: GiftPizza,
  discount: Discount,
  iphone: iPhone,
  mainBannerMobile: MainBannerMobile,
  breakfastsSmall: BreakfastsSmall,
  pizzaSalmon: PizzaSalmon,
  pizza4Cheeses: Pizza4Cheeses,
  pizzaStrachatella: PizzaStrachatella,
  pizzaMushrooms: PizzaMushrooms,
  tomatoSoup: TomatoSoup,
  coupon: Coupon,
  pickpoint1: Pickpoint1,
  pickpoint2: Pickpoint2,
  pickpoint3: Pickpoint3,
  pickpoint4: Pickpoint4,
  pickpoint5: Pickpoint5,
  deliveryBicycle: DeliveryBicycle,
  businessEatMobile,
  businessEatMobile2x,
  businessEatMobile3x,
  businessEatDesktop,
  businessEatDesktop2x,
}

// TODO лосось криво импортируется из figma

const Index = (props) => {
  const { name, className, style, onLoad } = props

  return (
    <img
      className={className}
      src={images[name]}
      style={style}
      onLoad={onLoad}
      // srcSet={`${images[name] + '2x'} 300w, ${images[name] + '3x'} 1000w`}
    />
  )
}

export default Index
