import React from 'react'
import { Col, Input, Modal, Row, Typography, Upload } from 'antd'
import { NavLink, useHistory } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import classnames from 'classnames'

import Icon from 'components/ui/Icon'
import WidthContainer from '../../components/layout/WidthContainer/WidthContainer'
import Box from '../../components/ui/Box/Box'
import Button from '../../components/ui/Button/Button'

import { isMobileLayout, isTabletMiddleLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'
import links from '../../helpers/links'

import s from './Support.module.less'
import { useState } from '../../hooks'
import request from '../../helpers/request'
import api from '../../helpers/api'


const { Text, Paragraph } = Typography
const { TextArea } = Input

const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

const ContactsComponent = ({ viewport, user, isLoggedIn }) => {
  const [ imageUrl, setImageUrl ] = useState('')
  const isMobile = isMobileLayout(viewport)
  const isTablet = isTabletMiddleLayout(viewport)
  const isTabletBig = viewport.width > 1024 && viewport.width < 1200

  const [ isModalVisible, setModalVisible ] = useState(false)

  const [ name, setName ] = useState(user?.name || '')
  const [ email, setEmail ] = useState(user?.email || '')
  const [ phone, setPhone ] = useState(user ? user.phone_format : '')
  const [ comment, setComment ] = useState('')
  const [ error, setError ] = useState('')
  const [ file, setFile ] = useState(null)

  const history = useHistory()

  const handleSubmit = async () => {
    try {
      const response = await request.post(api.supportClaim, {
        body: {
          name,
          email,
          phone,
          comment,
          file_name: file ? file.name : undefined,
          file: file ? file.img.split('base64,')[1] : undefined,
        },
      })

      setFile(null)
      setComment('')

      setModalVisible(true)
    }
    catch (e) {
      setError('Не удалось отправить сообщение, попробуйте позже')
    }
  }

  return (
    <Box pt={isMobile ? 'sm' : ''} pb={isMobile ? 'xlg' : ''}>
      {
        isMobile && (
          <WidthContainer>
            <Row style={{ width: '100%', justifyContent: 'space-between' }}>
              <Col>
                <Button type="icon" icon="left" isSmall handleClick={() => history.goBack()} />
              </Col>
            </Row>
          </WidthContainer>
        )
      }

      <WidthContainer style={{ overflow:'hidden' }}>
        {
          !isMobile && (
            <Box className={s.buttonBack}>
              <Button type="icon" icon="left" handleClick={() => history.goBack()} />
            </Box>
          )
        }

        <Box className={s.container}>
          <Box mt={isMobile ? 'lg' : 'xlg'} mb="md">
            <Row className={s.title}>
              <Text>Связаться с нами</Text>
            </Row>
          </Box>
          <Box mt="xlg" className={s.innerWrapper}>
            <Box className={s.innerContainer}>
              <Box className={classnames(s.boxWithShadow, s.supportContainer)}>
                <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', width: 433 }}>
                  <Icon name="support" style={{ marginTop: 10 }} />
                  <Text className={s.supportText}>Служба поддержки и контроля качества</Text>
                  <Text className={s.scheduleText}>В рабочие часы <br /> отвечаем в течение часа </Text>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: isMobile ? 24 : 0 }}>
                  <Text
                    className={s.phoneTextNumber}
                    onClick={() => {
                      document.location.href = 'tel:+74959601242'
                    }}>+7 (495) 960-12-42
                  </Text>
                  <Text className={s.scheduleTextTime}>Будние дни с 10:00 до 23:30</Text>
                </div>
              </Box>
              <Box mt="xxl" mb="md" style={{ display: 'flex', justifyContent: 'center' }}>
                <Text className={s.boldText}>Напишите нам</Text>
              </Box>
              {!isLoggedIn && (
                <Box mb="lg" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Text className={s.subText}>У вас есть аккаунт на сайте?
                    <NavLink to={links.login}>
                      <Text className={s.greenText}> Войти</Text>
                    </NavLink>
                  </Text>
                </Box>
              )}
              <Box mb="xxl" className={s.boxWithShadow}>
                <div className={s.boxInnerContainer}>
                  <Box mt={isMobile ? '' : 'md'} className={s.userInfoContainer}>
                    <Text>Имя</Text>
                    {/* <Paragraph> */}
                    {/*  {user.name} */}
                    {/* </Paragraph> */}
                    <Input
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value)
                      }}
                    />
                  </Box>
                  <Box mt="md" className={s.userInfoContainer}>
                    <Text>Email</Text>
                    <Input
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                    />
                  </Box>
                  <Box mt="md" className={s.userInfoContainer}>
                    <Text>Телефон</Text>
                    <Input
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value)
                      }}
                    />
                  </Box>
                  <Box mt="md" className={classnames(s.userInfoContainer, s.commentContainer)}>
                    <Text>Комментарий</Text>
                    <TextArea
                      placeholder="Хотел бы сказать..."
                      value={comment}
                      onChange={(e) => {
                        setComment(e.target.value)
                      }}
                    />
                  </Box>
                  <Box mb="xlg" mt="md">
                    <Upload
                      showUploadList={false}
                      name="file"
                      customRequest={() => {

                      }}
                      onChange={(info) => {
                        getBase64(info.file.originFileObj, (img) =>
                          setFile({
                            ...info.file,
                            img,
                          }),
                        )
                      }}
                    >
                      {file && file.img ? (
                        <div>
                          <img src={file.img} style={{ width: 100 }} />
                          <span
                            style={{ cursor: 'pointer', marginLeft: 10 }}
                            onClick={(e) => {
                              e.stopPropagation()
                              setFile(null)
                            }}><Icon name="close" />
                          </span>
                        </div>
                      ) : <Text className={s.greenText} style={{ cursor: 'pointer' }}>Выбрать файл</Text>}
                    </Upload>
                  </Box>
                  <Box mb="md" style={{ width: '100%' }}>
                    <Button
                      text="Отправить"
                      primary
                      disabled={!email || !phone || !name || !comment}
                      style={{ width: '100%' }}
                      onClick={handleSubmit}
                    />
                  </Box>
                  {error && (
                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                      <Text className={s.error}>{error}</Text>
                    </Box>
                  )}
                </div>
              </Box>
            </Box>
          </Box>
        </Box>

        <Modal
          visible={isModalVisible}
          maskStyle={{ background: 'rgba(255, 255, 255, .75)' }}
          className={s.wrapClassName}
          footer={null}
          closable
          maskClosable
          centered
          closeIcon={<Icon name="close" onClick={() => setModalVisible(false)} />}
        >
          <Box className={s.modalContent}>
            <Box mb="sm" alignCenter>
              <Text className={s.mainModalText}>Сообщение отправлено</Text>
            </Box>
            <Box mt="sm" className={s.buttonsWrapper}>
              <Button
                text="Ок"
                primary
                isSmall
                handleClick={() => {
                  setModalVisible(false)
                }}
              />
            </Box>
          </Box>
        </Modal>
      </WidthContainer>
    </Box>
  )
}

const Contacts = compose(
  withViewport,
)(ContactsComponent)

export default connect((state) => ({
  user: state.app.user,
  isLoggedIn: state.app.user && state.app.user.type !== 'guest',
}))(Contacts)
