import React from 'react'
import { Layout } from 'antd'
import { compose } from 'redux'

import WidthContainer from 'components/layout/WidthContainer/WidthContainer'
import Box from 'components/ui/Box/Box'
import OffersCarousel from 'compositions/OffersCarousel/OffersCarousel'
import Combo from 'compositions/Combo/Combo'
import CategoriesGrid from 'compositions/CategoriesGrid/CategoriesGrid'
import MapBanner from 'compositions/MapBanner/MapBanner'
import MainBanner from 'compositions/MainBanner/MainBanner'
import CookiePopup from 'compositions/CookiePopup/CookiePopup'

import { isMobileLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import { useEffect } from '../../hooks'
import BasketButton from '../../components/ui/BasketButton/BasketButton'
import AddressesRow from '../../compositions/AddressesRow/AddressesRow'

import s from './Home.module.less'
import setSeoTags from '../../helpers/setSeoTags'
import CategoriesSwiper from '../../compositions/CategoriesCarousel/CategoruesSwiper'
import StorytellerList from '../../compositions/Storyteller/Storyteller'


const { Content } = Layout

const HomePageComponent = ({ viewport }) => {

  const isMobile = isMobileLayout(viewport)

  useEffect(() => {
    setSeoTags({
      title: `Eat Street - Доставка еды и напитков`,
      seo_desc: '',
      seo_keyw: '',
    })

    if (document.location.hash === '#menu') {
      setTimeout(() => {
        window.scrollTo(0, document.getElementById('eatmenu').offsetTop + 72)
      }, 1000)
    }
  }, [])

  return (
    <WidthContainer>
      <Layout>
        <Content>
          <Box mb={isMobile ? '' : 'md'} className={isMobile ? s.storytellerMobile : s.storyteller}>
            <StorytellerList isMobile={isMobile} />
          </Box>
          <AddressesRow pt={isMobile ? '' : 'lg'} />
          <OffersCarousel />
          <CategoriesSwiper />
          <Combo />
          <CategoriesGrid />
          <MapBanner />
          <BasketButton />
          <CookiePopup />
        </Content>
      </Layout>
    </WidthContainer>
  )
}

export default compose(
  withViewport,
)(HomePageComponent)

