import React, { useState } from 'react'
import { Layout, Typography, Row, Col } from 'antd'
import { useHistory } from 'react-router-dom'
import {
  YMaps,
  Map,
  Clusterer,
  Placemark,
  Polygon,
  GeolocationControl,
  SearchControl,
  ZoomControl,
} from 'react-yandex-maps'
import { connect } from 'react-redux'
import { compose } from 'redux'
import classnames from 'classnames'
import balloon from './images/balloon.svg'

import { useEffect } from '../../hooks'
import WidthContainer
  from '../../components/layout/WidthContainer/WidthContainer'
import Button from '../../components/ui/Button/Button'
import Box from '../../components/ui/Box/Box'

import useLocalStorage from '../../hooks/useLocalStorage'
import getRestaurantsFromCities from '../../helpers/getRestaurantsFromCities'
import { withViewport } from '../../helpers/withViewport'
import { isMobileLayout, isTabletLayout } from '../../helpers/isMobileLayout'

import bike_1x from './images/bike.png'
import bike_2x from './images/bike@2x.png'
import bike_3x from './images/bike@3x.png'

import manOnBike_1x from './images/manOnBike.jpg'
import manOnBike_2x from './images/manOnBike@2x.jpg'
import manOnBike_3x from './images/manOnBike@3x.jpg'
import placemark from './images/placemark.svg'
import placemarkInactive from './images/placemark_inactive.svg'

import s from './DeliveryAndPayment.module.less'
import { addAddress } from '../../redux/actions'
import links from '../../helpers/links'
import setSeoTags from '../../helpers/setSeoTags'


const { Content, Sider } = Layout
const { Text, Paragraph } = Typography

const DeliveryAndPaymentComponent = ({
  restaurants,
  user,
  selectedDeliveryPointId,
  viewport,
}) => {

  const history = useHistory()
  const isMobile = isMobileLayout(viewport)
  const isTablet = viewport.width > 768 && viewport.width < 1130

  const [isSiderCollapsed, setSiderCollapsed] = useState(false)

  const [selectedTab, setSelectedTab] = useState(
    history.location.state && history.location.state.pickup ? 1 : 0)

  const [coords, setCoords] = useState([])

  useEffect(() => {
    setSeoTags({
      title: `Eat Street - Доставка и оплата`,
      seo_desc: '',
      seo_keyw: '',
    })

    navigator.geolocation.getCurrentPosition((r) => {
      const { latitude, longitude } = r.coords

      // fetch(`http://api-eatstreet.yami.su/api/geo/coords?lat=${latitude.toFixed(6)}&lng=${longitude.toFixed(6)}`, {
      //   method: 'GET',
      //   headers: {
      //     'api-agent': 'eatstreet',
      //     'Conception-Guid': 'fe470000-906b-0025-d2a7-08d89d1803ef',
      //     'content-type': 'application/json',
      //   },
      // }).then((r) => r.json()).then((r) => {
      //   console.log(r)
      // })
    })
  }, [])

  let polygons = []
  let disabled_polygons = []

  restaurants.forEach((restaurant) => {
    restaurant.areas.forEach((area) => {
      area.map_area.features.forEach((feature) => {
        if (restaurant.status) {
          polygons.push(feature.geometry.coordinates)
        }
        else {
          disabled_polygons.push(feature.geometry.coordinates)
        }
      })
    })
  })

  return (
    <Layout className={s.root}>
      <Content className={s.content}>

        <div className={s.inner}>
          <div className={s.leftSider}>
            <Box>
              <Text style={{ fontSize: 28, fontFamily: 'Circe-Bold' }}>Доставка
                и оплата
              </Text>
            </Box>
            <Box mt="sm">
              <Text style={{ fontSize: 20, fontFamily: 'Circe-Bold' }}>Время
                работы
              </Text>
            </Box>
            <Box mt="xs">
              <Text style={{ fontSize: 18, fontFamily: 'Circe-Regular' }}>Каждый день с 10:00 до 23:00</Text>
            </Box>
            <Box mt="md">
              <Text
                style={{ fontSize: 20, fontFamily: 'Circe-Bold' }}>Оплата
              </Text>
            </Box>
            <Box mt="xs">
              <div className={s.tag}>Картой на сайте</div>
              <div className={s.tag}>Курьеру картой</div>
              <div className={s.tag}>Курьеру наличными</div>
            </Box>
            <Box mt="md">
              <Text style={{ fontSize: 20, fontFamily: 'Circe-Bold' }}>Быстрая доставка по Москве и Московской области </Text>
            </Box>
            <Box mt="xs" mb="md">
              <Text style={{ fontSize: 18, fontFamily: 'Circe-Regular' }}>подробнее
                наши зоны доставки представлены на карте
              </Text>
            </Box>
            <Button
              secondary
              text="Добавить адрес"
              icon="plus"
              isSmall
              handleClick={() => {
                history.push(links.map)
              }}
              style={{ width: '100%' }}
            />
          </div>
        </div>

        <YMaps style={{ height: isMobile ? '535px' : '900px' }}>
          <div style={{ position: 'relative' }}>
            <Map
              instanceRef={(ref) => {
                ref && ref.behaviors.disable('scrollZoom')
              }}
              defaultState={{
                center: restaurants && restaurants[0].coords || [55.75, 37.57],
                zoom: 12,
              }}
              modules={['layout.ImageWithContent']}
              style={{ width: '100vw', height: isMobile ? '535px' : '900px' }}
            >

              <ZoomControl options={{ position: { right: 10, top: 10 } }} />

              {restaurants && restaurants.map((point, index) => {
                let fromHours = 10
                let fromMinutes = 0
                let toHours = 22
                let toMinutes = 0

                if (point.working_time && point.working_time.from
                  && point.working_time.to) {
                  const from = new Date(point.working_time.from)
                  fromHours = from.getHours()
                  fromMinutes = from.getMinutes() || '00'
                  const to = new Date(point.working_time.to)
                  toHours = to.getHours()
                  toMinutes = to.getMinutes() || '00'
                }
                return (
                  <Placemark
                    key={index}
                    properties={{
                      iconContent: `<nobr>Время работы с ${fromHours}:${fromMinutes} до ${toHours}:${toMinutes}</nobr>`,
                    }}
                    options={{
                      iconLayout: 'default#imageWithContent',
                      iconImageHref: balloon,
                      iconImageSize: [210, 90],
                      iconContentOffset: [12, 34],
                    }}
                    geometry={point.coords}
                  />
                )
              })}

              {polygons.map((polygon, index) => (
                <Polygon
                  key={index}
                  geometry={[polygon]}
                  options={{
                    fillColor: 'rgba(249, 196, 94, 0.3)',
                    strokeColor: '#F9C45E',
                    strokeWidth: 3,
                  }}
                />
              ))}

              {disabled_polygons.map((polygon, index) => (
                <Polygon
                  key={index}
                  geometry={[polygon]}
                  options={{
                    fillColor: 'rgba(158, 158, 158, 0.3)',
                    strokeColor: '#979797',
                    strokeWidth: 3,
                  }}
                />
              ))}


              {/* <GeolocationControl options={{ float: 'left' }} /> */}
              {/* <SearchControl options={{ float: 'right' }} /> */}
            </Map>
          </div>
        </YMaps>

      </Content>

      {/* <Sider */}
      {/*  width='100%' */}
      {/*  className={s.bottomSider} */}
      {/* > */}
      {/*  <Row> */}
      {/*    <Col span={isMobile ? 24 : 6} className={classnames(s.column, s.imageContainer)}> */}
      {/*      <img */}
      {/*        src={manOnBike_1x} */}
      {/*        srcSet={`${manOnBike_1x} 1x, ${manOnBike_2x} 2x, ${manOnBike_3x} 3x`} */}
      {/*      /> */}
      {/*    </Col> */}
      {/*    <Col span={isMobile ? 24 : isTablet ? 10 : 12}> */}
      {/*      <Paragraph className={s.title}>Доставка и оплата</Paragraph> */}
      {/*      <Paragraph className={s.text}> */}
      {/*        Курьеры Eat Street передвигаются быстрей всех с прочно закрепленным контейнером в передней части трайка – трехколесного электроката. */}
      {/*      </Paragraph> */}
      {/*      <Paragraph className={s.text}> */}
      {/*        Доставка осуществляется в помеченные на карте регионы в указанное время. */}
      {/*      </Paragraph> */}
      {/*      <Paragraph className={classnames(s.text, s.lastText)}> */}
      {/*        Оплата производится внутри сервиса или при получании, наличным или без наличным расчетом. */}
      {/*      </Paragraph> */}
      {/*    </Col> */}
      {/*    <Col span={isMobile ? 24 : 6} className={classnames(s.column, s.bikeContainer)}> */}
      {/*      <img */}
      {/*        src={bike_1x} */}
      {/*        srcSet={`${bike_1x} 1x, ${bike_2x} 2x, ${bike_3x} 3x`} */}
      {/*      /> */}
      {/*    </Col> */}
      {/*  </Row> */}
      {/* </Sider> */}
      {/* <Sider width={48} className={s.rightSider}> */}
      {/*  <Button type='icon' circle icon='close' handleClick={() => history.goBack()} /> */}
      {/* </Sider> */}
    </Layout>
  )
}

const DeliveryAndPayment = compose(
  withViewport,
)(DeliveryAndPaymentComponent)

export default connect((state) => ({
  restaurants: getRestaurantsFromCities(state.app.cities),
  user: state.app.user,
  selectedDeliveryPointId: state.app.selectedDeliveryPointId,
}))(DeliveryAndPayment)
