export default (n) => {
  let str = 'блюд'

  if (n === 1) {
    str = 'блюдо'
  }
  else if (n > 1 && n < 5) {
    str = 'блюда'
  }
  return `${n} ${str}`
}
