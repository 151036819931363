import React from 'react'
import { Modal, Typography } from 'antd'
import { NavLink } from 'react-router-dom'

import Box from 'components/ui/Box/Box'
import Button from 'components/ui/Button/Button'
import Icon from '../../components/ui/Icon'

import links from '../../helpers/links'

import s from './AddressModal.module.less'


const { Text } = Typography

const AddressModal = (props) => {

  const { isModalVisible, setModalVisible } = props

  return (
    <Modal
      visible={isModalVisible}
      maskStyle={{ background: 'rgba(255, 255, 255, .75)' }}
      className={s.wrapClassName}
      footer={null}
      closable
      maskClosable
      centered
      closeIcon={<Icon name="close" onClick={() => setModalVisible(false)} />}
      zIndex={10001}
    >
      <Box className={s.content}>
        <Box mb="sm" alignCenter>
          <Text className={s.mainText}>Выберите адрес доставки<br />или пункт самовывоза</Text>
        </Box>
        <Text className={s.secondaryText}>чтобы добавить блюдо в корзину</Text>
        <NavLink to={links.map}>
          <Box mt="sm">
            <Button text="Выбрать адрес" primary isSmall />
          </Box>
        </NavLink>
      </Box>
    </Modal>
  )
}


export default AddressModal
