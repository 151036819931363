import React, { Fragment } from 'react'
import { Typography, Row, Col, Checkbox } from 'antd'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'

import Box from '../../components/ui/Box/Box'
import Button from '../../components/ui/Button/Button'
import Icon from '../../components/ui/Icon'

import s from './MyPromocodes.module.less'


const data = [
  {
    type: 'discount',
    name: '300 р. при заказе',
    conditions: ['Заказ от 700 р.', '3 блюда', 'При оплате онлайн'],
    promocode: 'HF358ITGO',
  },
  {
    type: 'gift',
    name: 'Кофе в подарок',
    conditions: ['До 01.01.2020', 'При самовывозе'],
    promocode: 'COFFEE-968841',
  },
]

const { Text, Paragraph } = Typography

const MyPromocodes = (props) => {

  const isMobile = window.matchMedia('(max-width: 375px)').matches

  return (
    <Box className={s.container}>
      <Box mt="xlg" mb="lg" alignCenter>
        <Text className={s.title}>Персональные промокоды</Text>
      </Box>
      {
        data.map((item, i) => (
          <Box key={i} mt="md" alignCenter>
            <Text className={s.name}>{item.name}</Text>
            <Box mb="sm" alignCenter>
              {
                item.conditions.map((el, index) => (
                  <Fragment key={index}>
                    <Text className={s.condition}>{el}</Text>
                    {(index !== item.conditions.length - 1) && (
                      <Icon name="smallPoint2" className={s.pointIcon} />
                    )}
                  </Fragment>
                ))
              }
            </Box>
            <Box pl="md" pr="md" center className={s.promocodeWrapper}>
              <Paragraph
                copyable={{
                  icon: [<Icon className={s.copyIcon} name="copy" />],
                  tooltips: ['Скопировать', 'Скопировано'],
                }}
              >
                {item.promocode}
              </Paragraph>
            </Box>
          </Box>
        ))
      }
    </Box>
  )
}


export default MyPromocodes
