exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._18BWWH4g4AkKHHckaSwe0j {\n  width: 100%;\n  height: 500px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n._2PT7JWg1cxvBd1zUDjYzHO {\n  margin-right: -40px;\n}\n@media (min-width: 769px) {\n  ._2PT7JWg1cxvBd1zUDjYzHO {\n    margin-right: -45px;\n  }\n}\n._2PT7JWg1cxvBd1zUDjYzHO .ant-col-6 {\n  margin-right: 0;\n  padding-right: 30px;\n}\n._2A7kwdD8NWe4EXo4xdfVrD {\n  width: 100%;\n}\n._2LaeriUYaeS7OTrsPsCmwK {\n  max-width: 100%;\n}\n.hE07PoOLZZAi5EXJhkKEu {\n  display: flex;\n  align-items: flex-start;\n  flex-direction: column;\n}\n@media (min-width: 769px) {\n  .hE07PoOLZZAi5EXJhkKEu {\n    flex-direction: row;\n    align-items: center;\n  }\n}\n._16zsA3JefOFXTcu1Hzb0ve {\n  position: absolute;\n  width: 200.42px;\n  height: 200.37px;\n  top: 15px;\n  background: #70775B;\n  opacity: 0.8;\n  filter: blur(30px);\n  transform: matrix(1, 0.08, -0.08, 1, 0, 0);\n  border-radius: 50%;\n  z-index: 0;\n}\n._2VawvKuxMS9Dgu2xTmCAuj {\n  font-family: 'Circe-Bold', serif;\n  font-size: 32px;\n  line-height: 44px;\n  color: #3C3C3C;\n}\n@media (min-width: 769px) {\n  ._2VawvKuxMS9Dgu2xTmCAuj {\n    font-size: 40px;\n  }\n}\n._4dcqxW2VXatLHCWi_PFZz {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  overflow: auto;\n}\n@media (min-width: 769px) {\n  ._4dcqxW2VXatLHCWi_PFZz {\n    margin-left: 24px;\n  }\n}\n._2bK7LoJ_JAjoN5CwT8RkQ2 {\n  cursor: pointer;\n  width: fit-content;\n  height: 48px;\n  background: linear-gradient(0deg, #F7F9F8, #F7F9F8), #E8E8E8;\n  border-radius: 16px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  white-space: nowrap;\n}\n._2bK7LoJ_JAjoN5CwT8RkQ2 span {\n  font-family: 'Circe-Regular', serif;\n  font-size: 20px;\n  line-height: 24px;\n  color: #3C3C3C;\n}\n._3dLx2GoQbDLg1kbD1anN11 {\n  background: #046858;\n}\n._3dLx2GoQbDLg1kbD1anN11 span {\n  color: #fff;\n}\n", ""]);

// Exports
exports.locals = {
	"loader": "_18BWWH4g4AkKHHckaSwe0j",
	"container": "_2PT7JWg1cxvBd1zUDjYzHO",
	"itemContainer": "_2A7kwdD8NWe4EXo4xdfVrD",
	"itemContainerMobile": "_2LaeriUYaeS7OTrsPsCmwK",
	"titleContainer": "hE07PoOLZZAi5EXJhkKEu",
	"shadow": "_16zsA3JefOFXTcu1Hzb0ve",
	"categoryName": "_2VawvKuxMS9Dgu2xTmCAuj",
	"subCategoriesRow": "_4dcqxW2VXatLHCWi_PFZz",
	"subCategoryName": "_2bK7LoJ_JAjoN5CwT8RkQ2",
	"subCategoryNameActive": "_3dLx2GoQbDLg1kbD1anN11"
};