import { NavLink, useHistory } from 'react-router-dom'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import links from '../../../helpers/links'
import Button from '../Button/Button'
import s from '../../../pages/Product/Product.module.less'
import getTotalOrderPrice from '../../../helpers/getTotalOrderPrice'
import { isMobileLayout } from '../../../helpers/isMobileLayout'
import { withViewport } from '../../../helpers/withViewport'
import getDeliveryPointById from '../../../helpers/getDeliveryPointById'
import getRestaurantsFromCities from '../../../helpers/getRestaurantsFromCities'


const BasketButtonComponent = ({ viewport, basketItems, user, totalOrderPrice, selectedDeliveryPoint }) => {

  const history = useHistory()
  const isMobile = isMobileLayout(viewport)

  if (basketItems.length === 0) {
    return null
  }

  let estimated = 0

  if (selectedDeliveryPoint) {
    estimated = selectedDeliveryPoint.delivery_time_avg || selectedDeliveryPoint.time_delivery || 30
  }


  return (
    <Button
      text={`${totalOrderPrice} ₽`}
      rightIcon="orangeBasket"
      withEstimatedTime={`${estimated} мин`}
      withBoldText
      primary
      isSmall={isMobile}
      className={s.basketButton}
      handleClick={() => {
        if (window.gtag) {
          window.gtag('event', '_clicktobasket_')
        }
        if (selectedDeliveryPoint) {
          history.push(links.basket)
        }
        else {
          history.push(links.map)
        }
      }}
    />
  )
}

const BasketButton = compose(
  withViewport,
)(BasketButtonComponent)

export default connect((state) => ({
  basketItems: state.app.basketItems,
  user: state.app.user,
  totalOrderPrice: getTotalOrderPrice(state.app.basketItems),
  selectedDeliveryPoint: getDeliveryPointById(state.app.selectedDeliveryPointId, state.app.user && state.app.user.addresses || [], getRestaurantsFromCities(state.app.cities)),
}))(BasketButton)
