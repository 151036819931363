exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._20zDdcYSZqCgYFJt_W9JPx {\n  position: relative;\n  border-radius: 30px;\n  overflow: hidden;\n  cursor: pointer;\n}\n.sETbqM2ZjJgRrpAiThFb8 {\n  position: absolute;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n}\n._2a_ZgXThbyiiKEyaJgTZJE {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n._1xWgUNYavVRpmPMfNxTWdp {\n  width: 223px;\n  height: 32px;\n  background: #FFFFFF;\n  border-radius: 30px;\n  display: flex;\n  justify-content: center;\n  padding-top: 4px;\n  align-items: center;\n}\n._1xWgUNYavVRpmPMfNxTWdp span {\n  font-family: 'Circe-Regular', serif;\n  font-size: 16px;\n  color: #3C3C3C;\n}\n._1c-pbGngonmbNDdVLz1Izv {\n  font-family: 'Circe-Bold', serif;\n  font-size: 24px;\n  line-height: 40px;\n  color: #3C3C3C;\n}\n._1dmNq6r0xFxv0W0dVURVKz {\n  font-family: 'Circe-Regular', serif;\n  font-size: 24px;\n  line-height: 40px;\n  color: #3C3C3C;\n}\n._1GrG2FJ_XLsR9woYqvyOl1 {\n  font-family: 'Circe-Bold', serif;\n  font-size: 16px;\n  color: #2B5E51;\n}\n", ""]);

// Exports
exports.locals = {
	"mainBanner": "_20zDdcYSZqCgYFJt_W9JPx",
	"textBlockWrapper": "sETbqM2ZjJgRrpAiThFb8",
	"textBlock": "_2a_ZgXThbyiiKEyaJgTZJE",
	"rectangle": "_1xWgUNYavVRpmPMfNxTWdp",
	"boldText": "_1c-pbGngonmbNDdVLz1Izv",
	"thinText": "_1dmNq6r0xFxv0W0dVURVKz",
	"bottomText": "_1GrG2FJ_XLsR9woYqvyOl1"
};