exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3QDni6GqxTuG5BeM8ULgDM {\n  width: 100%;\n  margin-bottom: 30px;\n}\n._3QDni6GqxTuG5BeM8ULgDM .ant-carousel {\n  width: 100%;\n}\n@media (min-width: 1025px) {\n  ._3QDni6GqxTuG5BeM8ULgDM .slick-slider {\n    margin-right: -20px;\n  }\n}\n._3QDni6GqxTuG5BeM8ULgDM .slick-list {\n  padding: 0 !important;\n}\n._2c3iFUD9OQ5oh7WPbHcb2u {\n  height: 36px;\n  width: 200px;\n}\n._3xed0KnnDEG1C0ZZFWxabQ {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 20px;\n}\n.KcMVmdTeESunE7TOeHr8f {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 20px;\n}\n@media (max-width: 770px) {\n  .KcMVmdTeESunE7TOeHr8f {\n    margin: -40px 0px 0px 0px;\n  }\n}\n._2liMPivXjzWFGDJzhgWD_p {\n  font-family: 'Circe-Bold', serif;\n  font-size: 24px;\n  line-height: 20px;\n  color: #3C3C3C;\n  cursor: pointer;\n}\n@media (max-width: 375px) {\n  ._2liMPivXjzWFGDJzhgWD_p {\n    font-size: 17px;\n  }\n}\n._3ET2M5D93W6WY9rAalPBkL {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row;\n}\n._3ET2M5D93W6WY9rAalPBkL button:first-child {\n  margin-right: 10px;\n}\n._3ET2M5D93W6WY9rAalPBkL button {\n  position: relative;\n}\n._3ET2M5D93W6WY9rAalPBkL button img {\n  position: absolute;\n  top: 10px;\n}\n._142WkcApOpXLdpWDLOJ9xj img {\n  left: 13px;\n}\n._1WFuc5WWxmaTricPA0ojZw img {\n  left: 16px;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "_3QDni6GqxTuG5BeM8ULgDM",
	"smallBtn": "_2c3iFUD9OQ5oh7WPbHcb2u",
	"bottomRow": "_3xed0KnnDEG1C0ZZFWxabQ",
	"topRow": "KcMVmdTeESunE7TOeHr8f",
	"title": "_2liMPivXjzWFGDJzhgWD_p",
	"controls": "_3ET2M5D93W6WY9rAalPBkL",
	"btnPrev": "_142WkcApOpXLdpWDLOJ9xj",
	"btnNext": "_1WFuc5WWxmaTricPA0ojZw"
};