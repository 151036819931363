export default (items) => {
  let price = 0

  if (items) {
    items.forEach((item) => {
      price += item.price * item.count

      if (item.modifications) {
        item.modifications.forEach((modification) => {
          price += modification.price * modification.quant * item.count
        })
      }
    })
  }

  return price
}
