import React from 'react'
import { useSwiper } from 'swiper/react'
import Button from '../Button/Button'


const SwiperNavButton = ({ type, slidesPerGroup, className }) => {
  const swiper = useSwiper()

  const moveSlidesPerGroup = () => {
    if (type === 'prev') {
      if (swiper.activeIndex < slidesPerGroup) {
        swiper.slidePrev()
        return
      }
      swiper.slideTo(swiper.activeIndex - slidesPerGroup)
    }
    if (type === 'next') {
      if (swiper.activeIndex > swiper.snapGrid.length - slidesPerGroup) {
        swiper.slideNext()
        return
      }
      swiper.slideTo(swiper.activeIndex + slidesPerGroup)
    }
  }

  return (
    <Button
      icon={type === 'next' ? 'right' : 'left'}
      type="button"
      handleClick={moveSlidesPerGroup}
      secondary
      className={className}
    />
  )
}

export default SwiperNavButton
