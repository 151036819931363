exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DREkwBYdtQn91wHyeuTVh {\n  width: 100%;\n}\n@media (max-width: 770px) {\n  .DREkwBYdtQn91wHyeuTVh {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: row;\n    align-items: center;\n  }\n}\n.DREkwBYdtQn91wHyeuTVh .ant-carousel {\n  width: 85%;\n}\n._3_0hLhOo5Sh7suycxh_1l2 {\n  flex-direction: column;\n  align-items: flex-start;\n}\n._3_0hLhOo5Sh7suycxh_1l2 .ant-carousel {\n  width: 100%;\n}\n._2a5RnrPt8WryjieJEDxGE4 {\n  font-family: 'Circe-Bold', serif;\n  font-size: 17px;\n  line-height: 20px;\n  color: #3C3C3C;\n  margin-bottom: 15px;\n}\n._2C3HfA9Ir7xT_06CF4Qvgi.swiper {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  height: 120px;\n  padding: 0 90px;\n}\n._2C3HfA9Ir7xT_06CF4Qvgi .swiper-wrapper {\n  order: 2;\n}\n._3RmdUOJV9FBlPedF_Xp74D,\n._1F_FMuqSIYsoAuRcezHzGN {\n  position: relative;\n  display: block;\n  width: 90px;\n  height: 100%;\n  flex-shrink: 0;\n}\n._3RmdUOJV9FBlPedF_Xp74D {\n  order: 1;\n}\n._1F_FMuqSIYsoAuRcezHzGN {\n  order: 3;\n}\n._3BpUj4__3hC_pbyQeHSI-u,\n.Co8QU6EKH7exyOwfNQ-gQ {\n  position: absolute;\n  z-index: 5;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  background: #FFFFFF;\n}\n._3BpUj4__3hC_pbyQeHSI-u {\n  justify-content: flex-start;\n  padding-left: 6px;\n}\n.Co8QU6EKH7exyOwfNQ-gQ {\n  justify-content: flex-end;\n  padding-right: 6px;\n}\n._1WwNK0ADmh-GYru8bhE69d,\n._1pc9u2wrodpz7E64vK49lh {\n  display: flex;\n  justify-content: center;\n  width: 48px;\n  height: 48px;\n  border-radius: 50%;\n  outline: none;\n  border: none;\n}\n._1WwNK0ADmh-GYru8bhE69d img,\n._1pc9u2wrodpz7E64vK49lh img {\n  margin: 0;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "DREkwBYdtQn91wHyeuTVh",
	"mobileWrapper": "_3_0hLhOo5Sh7suycxh_1l2",
	"mobileText": "_2a5RnrPt8WryjieJEDxGE4",
	"desktopSwiper": "_2C3HfA9Ir7xT_06CF4Qvgi",
	"prevButtonSlot": "_3RmdUOJV9FBlPedF_Xp74D",
	"nextButtonSlot": "_1F_FMuqSIYsoAuRcezHzGN",
	"prevButtonWrapper": "_3BpUj4__3hC_pbyQeHSI-u",
	"nextButtonWrapper": "Co8QU6EKH7exyOwfNQ-gQ",
	"prevButton": "_1WwNK0ADmh-GYru8bhE69d",
	"nextButton": "_1pc9u2wrodpz7E64vK49lh"
};