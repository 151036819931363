import React, { useCallback } from 'react'
import { Divider, Drawer, Typography } from 'antd'
import { NavLink, useHistory } from 'react-router-dom'
import cx from 'classnames'

import WidthContainer from 'components/layout/WidthContainer/WidthContainer'
import PageHeader from 'compositions/PageHeader/PageHeader'
import { connect } from 'react-redux'
import Box from '../../components/ui/Box/Box'
import Icon from '../../components/ui/Icon'
import links from '../../helpers/links'

import s from './AccountMenuDrawer.module.less'
import { logout } from '../../redux/actions'
import externalLinks from '../../helpers/externalLinks'


const { Text } = Typography

const AccountMenuDrawer = (props) => {
  const { isMenuDrawerVisible, setMenuDrawerVisible, isAccountDrawerVisible, setAccountDrawerVisible, user, isLoggedIn } = props

  const history = useHistory()

  const onLogout = useCallback(() => {
    logout()
    history.push('/')
  }, [])

  return (
    <Drawer
      placement="right"
      closable={false}
      maskClosable
      visible={isAccountDrawerVisible}
      className={s.drawer}
      height="100vh"
      width="100wv"
    >
      <WidthContainer>
        <PageHeader
          isMenuDrawerVisible={isMenuDrawerVisible}
          setMenuDrawerVisible={setMenuDrawerVisible}
          isAccountDrawerVisible={isAccountDrawerVisible}
          setAccountDrawerVisible={setAccountDrawerVisible}
          withoutLogo
        />
        <Box mb="lg">
          {
            isLoggedIn ? (
              <Box>
                <Box pb="sm">
                  <div className={s.topRow}>
                    <Text
                      className={s.phone}
                      onClick={() => {
                        history.push(`${links.account}/my-data`)
                        setAccountDrawerVisible(false)
                      }}>{user.phone_format}
                    </Text>
                    <Text
                      className={s.smallGreenText}
                      onClick={() => {
                        history.push(`${links.account}/bonuses`)
                        setAccountDrawerVisible(false)
                      }}>{user.points} баллов
                    </Text>
                  </div>
                </Box>
                <Divider />
                <Box pt="md" pb="xs" className={cx(s.bigTextContainer, s.links)}>
                  <NavLink to={`${links.account}/my-data`} onClick={() => setAccountDrawerVisible(false)}>
                    <Text>Профиль</Text>
                  </NavLink>
                  <NavLink to={`${links.account}/my-orders`} onClick={() => setAccountDrawerVisible(false)}>
                    <Text>Заказы</Text>
                  </NavLink>
                  <NavLink to={`${links.account}/my-addresses`} onClick={() => setAccountDrawerVisible(false)}>
                    <Text>Адреса</Text>
                  </NavLink>
                  {/* <NavLink to={`${links.account}/bonuses`} onClick={() => setAccountDrawerVisible(false)}> */}
                  {/*  <Text>Бонусы</Text> */}
                  {/* </NavLink> */}
                  <NavLink to={links.favorites} onClick={() => setAccountDrawerVisible(false)}>
                    <Text>Избранное</Text>
                  </NavLink>
                  <NavLink to={links.sales} onClick={() => setAccountDrawerVisible(false)}>
                    <Text>Акции</Text>
                  </NavLink>
                  {/* <NavLink to={`${links.account}/my-promocodes`} onClick={() => setAccountDrawerVisible(false)}> */}
                  {/*  <Text>Мои промокоды</Text> */}
                  {/* </NavLink> */}
                </Box>
                <Divider />
                {/* <Box pt="md" pb="xs" className={cx(s.bigTextContainer, s.links)}> */}
                {/*  <NavLink to={links.sales} onClick={() => setAccountDrawerVisible(false)}> */}
                {/*    <Text>Акции</Text> */}
                {/*  </NavLink> */}
                {/* <a */}
                {/*  href="#" */}
                {/*  onClick={(e) => { */}
                {/*    e.preventDefault() */}
                {/*    setMenuDrawerVisible(true) */}
                {/*    setAccountDrawerVisible(false) */}
                {/*  }} */}
                {/* > */}
                {/*  <Text>Меню</Text> */}
                {/* </a> */}
                {/* </Box> */}
                <Divider />
                <Box pt="md" pb="xs" className={cx(s.smallTextContainer, s.links)}>
                  <NavLink to={links.delivery} onClick={() => setAccountDrawerVisible(false)}>
                    <Text>Доставка и оплата</Text>
                  </NavLink>
                  <NavLink to={links.about} onClick={() => setAccountDrawerVisible(false)}>
                    <Text>О нас</Text>
                  </NavLink>
                  {/* <NavLink to={links.about} onClick={() => setAccountDrawerVisible(false)}> */}
                  {/*  <Text>Контроль качества</Text> */}
                  {/* </NavLink> */}
                  <NavLink to={links.contacts} onClick={() => setAccountDrawerVisible(false)}>
                    <Text>Контакты</Text>
                  </NavLink>
                  {/* <NavLink to={links.support} onClick={() => setAccountDrawerVisible(false)}> */}
                  {/*  <Text>Поддержка</Text> */}
                  {/* </NavLink> */}
                </Box>
                <Divider />
                <Box className={s.wrapper}>
                  <Text className={s.phoneNumber}>
                    +7 (495) 960 12 42
                  </Text>
                  <Text className={s.itemText}>
                    Быстрая доставка
                  </Text>
                  <Text className={s.itemText}>
                    Ежедневно с 10:00 до 23:00
                  </Text>
                </Box>
                <Divider />
                <Box className={s.logoutLink}>
                  <Text onClick={onLogout}>Выйти</Text>
                </Box>
                <Divider />
                <Box pt="md" pb="sm" className={s.smallTextContainer}>
                  <Text onClick={() => {
                    window.open('/assets/documents/policy.html')
                  }}>Политика конфиденциальности
                  </Text>
                  <Text onClick={() => {
                    window.open('/assets/documents/accept.html ')
                  }}>Пользовательское соглашение
                  </Text>
                </Box>
                <Divider />
                <Box pt="md">
                  <a href="#">
                    <Icon
                      onClick={() => {
                        window.open(externalLinks.eatStreet)
                      }}
                      name="appStore"
                      style={{ cursor: 'pointer', alignSelf: 'flex-start', width: 120, height: 40, marginRight: 20 }} />
                    <Icon
                      onClick={() => {
                        window.open(externalLinks.playGoogle)
                      }}
                      name="googlePlay"
                      style={{ cursor: 'pointer', alignSelf: 'flex-start', width: 120, height: 40 }} />
                  </a>
                </Box>
              </Box>
            ) : (
              <Box>
                <Box pb="sm">
                  <NavLink to={links.login} onClick={() => setAccountDrawerVisible(false)}>
                    <Text className={s.greenText}>Войти</Text>
                  </NavLink>
                </Box>
                <Divider />
                <Box pt="md" pb="xs" className={cx(s.bigTextContainer, s.links)}>
                  <NavLink to={links.sales} onClick={() => setAccountDrawerVisible(false)}>
                    <Text>Акции</Text>
                  </NavLink>
                  {/* <a */}
                  {/*  href="#" */}
                  {/*  onClick={(e) => { */}
                  {/*    e.preventDefault() */}
                  {/*    setMenuDrawerVisible(true) */}
                  {/*  }} */}
                  {/* > */}
                  {/*  <Text>Меню</Text> */}
                  {/* </a> */}
                </Box>
                <Divider />
                <Box pt="md" pb="xs" className={cx(s.smallTextContainer, s.links)}>
                  <NavLink to={links.delivery} onClick={() => setAccountDrawerVisible(false)}>
                    <Text>Доставка и оплата</Text>
                  </NavLink>
                  <NavLink to={links.about} onClick={() => setAccountDrawerVisible(false)}>
                    <Text>О нас</Text>
                  </NavLink>
                  {/* <NavLink to={links.about} onClick={() => setAccountDrawerVisible(false)}> */}
                  {/*  <Text>Контроль качества</Text> */}
                  {/* </NavLink> */}
                  <NavLink to={links.contacts} onClick={() => setAccountDrawerVisible(false)}>
                    <Text>Контакты</Text>
                  </NavLink>
                  {/* <NavLink to={links.about} onClick={() => setAccountDrawerVisible(false)}> */}
                  {/*  <Text>Обратиться в поддержку</Text> */}
                  {/* </NavLink> */}
                </Box>
                <Divider />
                <Box className={s.wrapper}>
                  <Text className={s.phoneNumber}>
                    +7 (495) 960 12 42
                  </Text>
                  <Text className={s.itemText}>
                    Быстрая доставка
                  </Text>
                  <Text className={s.itemText}>
                    Ежедневно с 10:00 до 23:00
                  </Text>
                </Box>
                <Divider />
                <Box pt="md" pb="sm" className={s.smallTextContainer}>
                  <Text onClick={() => {
                    window.open('/assets/documents/policy.html\n')
                  }}>Политика конфиденциальности
                  </Text>
                  <Text onClick={() => {
                    window.open('/assets/documents/accept.html ')
                  }}>Пользовательское соглашение
                  </Text>
                </Box>
                <Divider />
                <Box pt="md">
                  <a href="#">
                    <Icon
                      onClick={() => {
                        window.open(externalLinks.eatStreet)
                      }}
                      name="appStore"
                      style={{ cursor: 'pointer', alignSelf: 'flex-start', width: 120, height: 40, marginRight: 20 }} />
                    <Icon
                      onClick={() => {
                        window.open(externalLinks.playGoogle)
                      }}
                      name="googlePlay"
                      style={{ cursor: 'pointer', alignSelf: 'flex-start', width: 120, height: 40 }} />
                  </a>
                </Box>
              </Box>
            )
          }
        </Box>

      </WidthContainer>
    </Drawer>
  )
}

export default connect((state) => ({
  user: state.app.user,
  isLoggedIn: state.app.user && state.app.user.type !== 'guest',
}))(AccountMenuDrawer)
