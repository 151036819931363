exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1hM4a6Zqq4MhFIyTJk_XRE {\n  max-width: 640px;\n  margin: 0 auto 36px auto;\n}\n._3HXSNIQuNoojiXBKhXU1ta {\n  font-family: 'Circe-Bold', serif;\n  font-size: 40px;\n  line-height: 59px;\n  color: #3C3C3C;\n}\n@media (max-width: 375px) {\n  ._3HXSNIQuNoojiXBKhXU1ta {\n    font-size: 32px;\n    line-height: 38px;\n  }\n}\n._2wgZdyNv6rma9StxC0QDXm {\n  font-family: 'Circe-Bold', serif;\n  font-size: 15px;\n  line-height: 22px;\n  color: #303231;\n}\n.ktd_shXhUrlVN-9uiaUrE {\n  font-family: 'Circe-Regular', serif;\n  font-size: 16px;\n  line-height: 19px;\n  color: #303231;\n  opacity: 0.7;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "_1hM4a6Zqq4MhFIyTJk_XRE",
	"title": "_3HXSNIQuNoojiXBKhXU1ta",
	"paragraph": "_2wgZdyNv6rma9StxC0QDXm",
	"text": "ktd_shXhUrlVN-9uiaUrE"
};