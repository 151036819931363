const links = {
  'telegram': 'https://t.me/eatstreet_ru',
  'instagram': 'https://www.instagram.com/eat_street_ru/',
  'facebook': 'https://www.facebook.com/eatstreetmoscow',
  'tiktok': 'https://vm.tiktok.com/ZSJAF1hoN/',
  'youtube': 'https://www.youtube.com/channel/UCnTHT6gCOOpOUmvnj5rbpOw',
  'vk': 'https://vk.com/club197839420',
  'accept': 'https://ekb.yamiyami.ru/docs/accept.html',
  'rockapps': 'https://www.rockapps.ru/',
  'eatStreet': 'https://apps.apple.com/ca/app/eat-street-%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0-%D0%B5%D0%B4%D1%8B/id1562346056',
  'playGoogle': 'https://play.google.com/store/apps/details?id=com.rockapps.eatstreet',
  'businessEat': 'https://business.eatstreet.ru',
}

export default links
