import axios from 'axios'
import { getCamelCasedData } from './dataCaseHelpers'
import config from './config'


const defaultOptions = {
  withToken: true,
  setIsFetching: () => {},
  withFlattenValues: true,
  setDebugError: () => {},
}

const baseURL = config.api


const request = (method) => async (url, options = {}) => {
  options = {
    ...defaultOptions,
    ...options,
  }

  const {
    setIsFetching, auth,
    withToken, withFlattenValues,
    setDebugError,
    headers = {},
    query, body,
    ...restOptions
  } = options

  let axiosOptions = {
    headers: {
      'Api-Agent': 'eatstreet',
      'Content-Type': 'application/json',
      ...headers,
    },
    ...restOptions,
  }

  if (withToken) {
    const token = localStorage.getItem('token')

    if (token) {
      axiosOptions.headers.Authorization = `Bearer ${token}`
    }
  }

  if (auth) {
    axiosOptions.auth(...options.auth)
  }

  const params = query
  const data = body

  const axiosPromise = axios({
    method,
    url: `${baseURL}${url}`,
    params,
    data,
    ...axiosOptions,
  })

  return new Promise((resolve, reject) => {
    setIsFetching(true)

    return axiosPromise
      .then(async (response) => {
        const { data: rawData } = response

        // console.log(method, url, response)
        // console.log('data', rawData)

        if (rawData.token) {
          console.log('new token', rawData.token)

          localStorage.setItem('token', rawData.token)
        }

        const data = getCamelCasedData(rawData)

        if (data.results && data.results instanceof Array) {
          data.results = data.results.map(getCamelCasedData)
        }

        resolve(data)
      })
      .catch((error) => {
        const errorData = error && error.response && error.response.data
        const status = error && error.response && error.response.status

        console.log('error', method, url, error, error.toJSON && error.toJSON())
        console.log('error response', error.response)
        console.log('error response message', errorData)

        setDebugError(errorData ? JSON.stringify({ ...errorData, status }) : status)

        reject(errorData)
      })
      .finally(() => setIsFetching(false))
  })
}

request.get = request('get')
request.post = request('post')
request.patch = request('patch')
request.put = request('put')
request.delete = request('delete')


export default request
