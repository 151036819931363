const api = {
  cities: 'cities',
  profile: 'profile',
  cards: 'profile/cards',
  orders: 'orders',
  ordersCheck: 'orders/check',
  checkSms: 'auth/check-sms',
  checkPromocode: 'orders/check-promocode',
  checkQrPayment: 'orders/check-qr-payment-mobile',
  register: 'auth/register',
  catalogSections: 'catalogs/sections',
  paymentTypes: 'payments/types',
  geoCoords: (query) => `geo/coords?query=${encodeURIComponent(query)}`,
  geoAddress: (coords) => `geo/address?lat=${coords[0].toFixed(6)}&lng=${coords[1].toFixed(6)}`,
  geoAddressCheck: (address) => {
    let preparedObject = {}
    Object.keys(address).forEach((key, index) => {
      preparedObject[`address_object[${key}]`] = address[key]
    })
    const searchParams = new URLSearchParams(preparedObject)
    return `geo/address-check?${searchParams.toString()}`
  },
  labels: 'catalogs/labels',
  profileAddress: 'profile/address',
  profileAddressById: (id) => `profile/address/${id}`,
  combo: 'advertising/combo',
  offers: 'offers',
  stopList: 'catalogs/stop-list',
  supportClaim: 'support/claim',
  search: (value) => `search?query=${value}`,
  firstSlider: 'advertising/first-slider',
  catalogProducts: (catalogUID) => `catalogs/products?section_guid=${catalogUID}`,
  catalogProductsByLabel: (labelUID) => `catalogs/products?label_guid=${labelUID}`,
  products: (sectionUID) => `catalogs/products?section_guid=${sectionUID}&objects=true`,
  productsByLabel: (labelUID) => `catalogs/products?label_guid=${labelUID}&objects=true`,
  recommendations: 'catalogs/recommendations',
  news: 'news',
}


export default api
