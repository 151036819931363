import { isEqual } from 'lodash'
import reducers from './reducers'
import store from './store'
import request from '../helpers/request'
import api from '../helpers/api'
import getDeliveryPointById from '../helpers/getDeliveryPointById'
import getRestaurantsFromCities from '../helpers/getRestaurantsFromCities'


const setSections = async (value) => {
  const response = await request.get(api.catalogSections)
  reducers.app.setSections(response.data)
}

const setStopList = async () => {
  const response = await request.get(api.stopList)
  reducers.app.setStopList(response.data)
}

const sendOrder = async (data) => {
  const response = await request.post(api.orders, {
    body: data,
  })
  if (response.data && response.data[0]) {
    return response.data[0]
  }
  return {}
}

const appendOrder = (order) => {
  reducers.app.appendOrder(order)
}

const checkPromocode = async (data) => {
  const response = await request.post(api.checkPromocode, {
    body: data,
  })
  if (response.data && response.data[0]) {
    return response.data[0]
  }
  return {}
}

const checkOrder = async (data) => {
  const response = await request.post(api.ordersCheck, {
    body: data,
  })
  if (response.data && response.data[0]) {
    return response.data[0]
  }
  return {}
}

const setCities = async () => {
  const response = await request.get(api.cities)
  reducers.app.setCities(response.data)

  // NOTE: comment after adding new restaurant. Check that it's work ok
  // if (response.data[0] && response.data[0].restaurants && response.data[0].restaurants[0]) {
  //   reducers.app.setSelectedRestaurantGuid(response.data[0].restaurants[0].restaurant_guid)
  // }
}

const updateUser = async (user) => {
  const response = await request.put(api.profile, {
    body: {
      email: user.email,
      name: user.name,
      phone: user.phone,
      send_check_to_mail: user.send_check_to_mail,
      confirmed_for_mailing: user.confirmed_for_mailing,
    },
  })
  if (response.data && response.data[0]) {
    reducers.app.setUser(response.data[0])
  }
}

const setFavorites = (el) => {
  const favorites = JSON.parse(localStorage.getItem('favorites') || '[]')
  const findIndex = favorites.findIndex((item) => item.products_guid === el.products_guid)

  if (findIndex > -1) {
    favorites.splice(findIndex, 1)
  }
  else {
    favorites.push(el)
  }

  localStorage.setItem('favorites', JSON.stringify(favorites))
  reducers.app.setFavorites(favorites)
}

const logout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('selectedDeliveryPoint')
  localStorage.removeItem('favorites')
  localStorage.removeItem('basketItems')
  reducers.app.setBasketItems([])
  reducers.app.setUser({ type: 'guest' })
  reducers.app.setSelectedDeliveryPoint(null)
}

const setPaymentTypes = async () => {
  const token = localStorage.getItem('token')
  const response = await request.get(api.paymentTypes)
  let types = []

  try {
    types = JSON.parse(JSON.stringify(response.data || [])).filter((type) => !type.is_apple_pay && !type.is_google_pay)
  }
  catch (e) {
    console.log(e.message)
  }
  reducers.app.setPaymentTypes(types)
}


const setSelectedDeliveryPoint = async (value) => {
  const { address_id, restaurant_guid } = value
  localStorage.setItem('selectedDeliveryPoint', address_id || restaurant_guid)

  reducers.app.setSelectedDeliveryPoint(address_id || restaurant_guid)

  if (address_id) {
    if (!('time_delivery' in value) && !('order_sum_for_free_delivery' in value)) {

      try {
        const response = await request.get(api.geoAddressCheck(value))
        if (response.data && response.data[0] && response.data[0].area) {
          reducers.app.setDeliveryTime({
            address_id,
            time_delivery: response.data[0].area.time_delivery,
            amount_min: response.data[0].area.amount_min,
            order_sum_for_free_delivery: response.data[0].area.order_sum_for_free_delivery,
          })
          localStorage.setItem('selectedRestaurantGuid', response.data[0].area.restaurant_guid)
          reducers.app.setSelectedRestaurantGuid(response.data[0].area.restaurant_guid)
        }
      }
      catch (e) {
        console.log(e.message)
      }
    }
  }
}

const setAddressDelivery = async () => {
  const { selectedDeliveryPointId, user, cities } = store.getState().app

  const point = getDeliveryPointById(
    selectedDeliveryPointId,
    user && user.addresses || [],
    getRestaurantsFromCities(cities),
  )
  if (point) {
    await setSelectedDeliveryPoint(point)
  }
}

const setUser = async (value) => {
  const token = localStorage.getItem('token')
  if (!token) {
    const cachedAddresses = JSON.parse(localStorage.getItem('cachedAddresses'))
    if (cachedAddresses) {
      const addressesPromises = []
      for (let i = 0; i < cachedAddresses.length; i++) {
        addressesPromises.push(request.get(api.geoAddressCheck(cachedAddresses[i])))
      }
      const responses = await Promise.all(addressesPromises)
      const filteredAddresses = cachedAddresses.filter((item, i) => responses[i].data?.[0].status !== 2)
      reducers.app.setAddresses(filteredAddresses)
    }
  }
  else {
    const response = await request.get(api.profile)
    if (response.data && response.data[0]) {
      reducers.app.setUser(response.data[0])
    }
  }
}

const setOrders = async (value) => {
  const response = await request.get(api.orders)
  if (response.data) {
    console.log(response.data, 'response.data')
    reducers.app.setOrders(response.data.filter((order) => order !== null))
  }
}

const setCatalogProducts = async (sectionUID) => {
  const response = await request.get(api.catalogProducts(sectionUID))
  // if (response.data) {
  //   reducers.app.setCatalogProducts(response.data)
  // }
  return response.data
}

const setCatalogProductsByLabel = async (labelUID) => {
  const response = await request.get(api.catalogProductsByLabel(labelUID))
  // if (response.data) {
  //   reducers.app.setCatalogProducts(response.data)
  // }
  return response.data
}

const setProducts = async (sectionUID) => {
  const response = await request.get(api.products(sectionUID))

  return response.data
}

const setProductsByLabel = async (labelUID) => {
  const response = await request.get(api.productsByLabel(labelUID))

  return response.data
}

const getAddressByAddressObject = async (address) => {
  const response = await request.get(api.geoAddressCheck(address))

  return response.data
}

const getAddressByCoords = async (coords) => {
  const response = await request.get(api.geoAddress(coords))

  return response.data
}

const getGeoSuggestions = async (query) => {
  const response = await request.get(api.geoCoords(query))

  return response.data
}

const initBasketItems = () => {
  const items = JSON.parse(localStorage.getItem('basketItems')) || []
  reducers.app.setBasketItems(items)
}

const removeBasketItem = (index) => {
  const items = JSON.parse(localStorage.getItem('basketItems')) || []
  items.splice(index, 1)
  localStorage.setItem('basketItems', JSON.stringify([...items]))
  reducers.app.setBasketItems([...items])
}

const clearBasket = () => {
  localStorage.setItem('basketItems', JSON.stringify([]))
  reducers.app.setBasketItems([])
}

const setPersonsCount = (count) => {
  if (count >= 0) {
    localStorage.setItem('personsCount', count)
    reducers.app.setPersonsCount(count)
  }
}

const sortModifications = (a, b) => {
  if (a.component_guid < b.component_guid) return -1
  if (a.component_guid > b.component_guid) return 1
  return 0
}

const setBasketItem = async (item, decrement, comboProducts, productModifications = [], switchers) => {
  if (window.gtag) {
    window.gtag('event', '_clickaddtobasket_')
  }

  if (item.modifiers_objects && !switchers) {
    if (!item.switchers) {
      item.switchers = {}
    }

    item.modifiers_objects.forEach((obj) => {
      item.switchers[obj.section_guid] = {
        component_guid: obj.component_objects[0].component_guid,
        quant: 1,
        title: obj.component_objects[0].title,
      }
    })
  }
  else {
    item.switchers = switchers
  }

  let items = JSON.parse(localStorage.getItem('basketItems')) || []

  const itemInBasketIndex = items.findIndex((i) => {
    const isProductsGuidEqual = i.products_guid === item.products_guid

    const isModificationsEqual = productModifications && i.modifications && isEqual(productModifications.sort(sortModifications), i.modifications.sort(sortModifications))
    const isSwitchersEqual = isEqual(item.switchers, i.switchers)

    const isComboEqual = !comboProducts || (comboProducts && i.products && isEqual(comboProducts.sort(sortModifications), i.products.sort(sortModifications)))

    return isProductsGuidEqual && isModificationsEqual && isSwitchersEqual && isComboEqual
  })

  if (itemInBasketIndex > -1) {
    if (decrement) {
      items[itemInBasketIndex].count -= 1
    }
    else {
      items[itemInBasketIndex].count += 1
    }

    items = items.filter((a) => a.count > 0)

    localStorage.setItem('basketItems', JSON.stringify([...items]))
    reducers.app.setBasketItems([...items])
  }
  else {
    localStorage.setItem('basketItems', JSON.stringify(
      [...items,
        {
          ...item,
          products: comboProducts,
          modifications: productModifications, count: 1,
        },
      ],
    ))

    reducers.app.setBasketItems(
      [...items,
        {
          ...item,
          products: comboProducts,
          modifications: productModifications,
          count: 1,
        },
      ],
    )
  }
}

const setComboMealComponents = async (item, mealId, groupId, remove) => {
  const { comboMealComponents } = store.getState().app

  const items = JSON.parse(JSON.stringify(comboMealComponents))

  const indexOfMealInArray = items.findIndex((it) => it.mealId === mealId)

  if (indexOfMealInArray > -1 || remove) {
    const indexOfGroupInArray = items[indexOfMealInArray].groups.findIndex((g) => g.groupId === groupId)
    if (indexOfGroupInArray > -1 || remove) {
      if (remove) {
        items[indexOfMealInArray].groups.splice(indexOfGroupInArray, 1)

        if (items[indexOfMealInArray].groups.length === 0) {
          items.splice(indexOfGroupInArray, 1)
        }
      }
      else {
        items[indexOfMealInArray].groups[indexOfGroupInArray] = { groupId, productComponent: item }
      }
    }
    else {
      items[indexOfMealInArray].groups.push({ groupId, productComponent: item })
    }
  }
  else {
    items.push({
      mealId, groups: [{ groupId, productComponent: item }],
    })
  }

  reducers.app.setComboMealComponents([...items])
}


const clearModifications = () => {
  reducers.app.setProductSwitchers([])
  reducers.app.setProductModifications([])
  // reducers.app.setComboMealComponents([])
}

const setProductSwitchers = (product, section_guid, value) => {
  const { productSwitchers } = store.getState().app
  let items = JSON.parse(JSON.stringify(productSwitchers))

  const itemIndex = items.findIndex((i) => i.product === product.products_guid)

  if (itemIndex > -1) {
    items[itemIndex].switchers[section_guid] = {
      component_guid: value.component_guid,
      title: value.title,
      quant: 1,
    }
  }
  else {
    const data = {
      product: product.products_guid,
      switchers: {},
    }

    data.switchers[section_guid] = {
      component_guid: value.component_guid,
      quant: 1,
      title: value.title,
    }

    items.push(data)
  }

  reducers.app.setProductSwitchers([...items])
}

const setProductModifications = (product, modification, dec) => {
  const { productModifications } = store.getState().app
  let items = JSON.parse(JSON.stringify(productModifications))

  const itemIndex = items.findIndex((i) => i.product === product.products_guid)

  if (itemIndex > -1) {
    const indexOfModificationInArray = items[itemIndex].modifications.findIndex((m) => m.component_guid === modification.component_guid)
    if (indexOfModificationInArray > -1) {
      if (dec) {
        items[itemIndex].modifications[indexOfModificationInArray].quant -= 1
        if (items[itemIndex].modifications[indexOfModificationInArray].quant === 0) {
          items[itemIndex].modifications.splice(indexOfModificationInArray, 1)
        }
      }
      else {
        items[itemIndex].modifications[indexOfModificationInArray].quant += 1
      }
    }
    else {
      items[itemIndex].modifications.push({
        component_guid: modification.component_guid,
        quant: 1,
        title: modification.title,
        nutrients: modification.nutrients,
        price: modification.price,
        weight: modification.weight,
      })
    }
  }
  else {
    items.push({
      product: product.products_guid,
      modifications: [
        {
          component_guid: modification.component_guid,
          quant: 1,
          title: modification.title,
          nutrients: modification.nutrients,
          price: modification.price,
          weight: modification.weight,
        },
      ],
    })
  }

  reducers.app.setProductModifications([...items])
}

const deleteAddress = async (id) => {
  const response = await request.delete(api.profileAddressById(id))
  reducers.app.setAddresses(response.data)

  const selectedDeliveryPoint = localStorage.getItem('selectedDeliveryPoint')
  const { user } = store.getState().app

  if (selectedDeliveryPoint === id) {
    if (user && user.addresses && user.addresses.length) {
      setSelectedDeliveryPoint(user.addresses[0])
    }
  }
}

const addCachedAddressesToUser = async (addresses) => {
  const response = await request.post(api.profileAddress, {
    body: {
      addresses: addresses.map((address) => {
        const { address_id, ...rest } = address
        return { ...rest, comment: '' }
      }),
    },
  })
  reducers.app.setAddresses(response.data)


  return response.data
}


const addAddress = async (address, user) => {
  if (user && user.type !== 'guest') {
    const response = await request.post(api.profileAddress, {
      body: {
        addresses: [{
          ...address.address_object,
          comment: address.address_object ? address.address_object.comment : '',
        }],
      },
    })
    reducers.app.setAddresses(response.data)
    return response.data
  }
  const adr = {
    ...address.address_object, address_id: Date.now(),
  }
  const cachedAddresses = JSON.parse(localStorage.getItem('cachedAddresses')) || []
  const find = cachedAddresses.find((item) => (
    item.coords
      && address.address_object.coords
      && item.coords[0] === address.address_object.coords[0]
      && item.coords[1] === address.address_object.coords[1]
  ))
  if (!find) {
    cachedAddresses.push(adr)
    localStorage.setItem('cachedAddresses', JSON.stringify(cachedAddresses))
    reducers.app.appendAddresses(adr)
  }

  return cachedAddresses

}

const setCombo = async () => {
  const response = await request.get(api.combo, {
    withToken: true,
  })

  reducers.app.setCombo(response.data || [])
}


const setOffers = async () => {
  const response = await request.get(api.offers, {
    withToken: true,
  })

  reducers.app.setOffers(response.data || [])
}

const setFirstSlider = async () => {
  const response = await request.get(api.firstSlider)
  reducers.app.setFirstSlider(response.data || [])
}

const setLabels = async () => {
  const response = await request.get(api.labels)
  reducers.app.setLabels(response.data || [])
}

const setNews = async () => {
  const response = await request.get(api.news)
  reducers.app.setNews(response.data || [])
}

const setPaymentCards = async () => {
  const response = await request.get(api.cards)
  reducers.app.setPaymentCards(response.data || [])
}

const setQrInfo = (qrInfo) => {
  reducers.app.setQrInfo(qrInfo)
}
const setCurrentOrderData = (orderData) => {
  reducers.app.setOrderData(orderData)
}

const setQrPaymentStatus = (paymentStatus) => {
  reducers.app.setQrPaymentStatus(paymentStatus)
}

const checkQrPayment = async (data) => {
  const response = await request.get(`${api.checkQrPayment}/${data.orderNumber}?order_guid=${data.orderGuid}&orderId=${data.orderId}&qrId=${data.qrId}`)
  // return {status: PENDING |FAIL | SUCCESS | CONFIRMED}
  return response
}

const setRecommendations = async () => {
  const response = await request.get(api.recommendations)
  reducers.app.setRecommendations(response.data || [])
}

export {
  getAddressByCoords,
  logout,
  setUser,
  setSections,
  setCities,
  setBasketItem,
  updateUser,
  initBasketItems,
  setComboMealComponents,
  setProductModifications,
  removeBasketItem,
  checkPromocode, // setModifications,
  getGeoSuggestions,
  deleteAddress,
  addAddress,
  checkOrder,
  setPaymentTypes,
  setFavorites,
  setSelectedDeliveryPoint,
  setCombo,
  setOffers,
  setProductSwitchers,
  setFirstSlider,
  setLabels,
  sendOrder,
  setOrders,
  addCachedAddressesToUser,
  setCatalogProducts,
  setProducts,
  setStopList,
  setAddressDelivery,
  clearBasket,
  appendOrder,
  setCurrentOrderData,
  setPersonsCount,
  setProductsByLabel,
  setCatalogProductsByLabel,
  clearModifications,
  getAddressByAddressObject,
  setNews,
  setRecommendations,
  setPaymentCards,
  setQrInfo,
  checkQrPayment,
  setQrPaymentStatus,
}
