import { useState } from 'react'


const useLocalStorage = (key, initialValue, addItemsFlag) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    }
    catch (err) {
      console.error(err)
      return initialValue
    }
  })

  const setValue = (value) => {
    // to store previously added items and to not to overwrite them
    if (addItemsFlag) {
      const oldItems = JSON.parse(localStorage.getItem(key)) || []
      oldItems.push(value)
      setStoredValue(oldItems)
      window.localStorage.setItem(key, JSON.stringify(oldItems))
    }
    else {
      try {
        const valueToStore = value instanceof Function ? value(storedValue) : value
        setStoredValue(valueToStore)
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
      catch (err) {
        console.error(err)
      }
    }
  }

  return [storedValue, setValue]
}

export default useLocalStorage
