import React, { useState, Fragment } from 'react'
import { Typography, Row, Col } from 'antd'
import cx from 'classnames'
import { compose } from 'redux'

import ToppingCard from './components/ToppingCard'

import { isMobileLayout, isTabletLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import s from './Toppings.module.less'


const config = [
  {
    icon: 'shrimps',
    name: 'Креветки',
    weight: 40,
    price: 65,
  },
  {
    icon: 'jalapena',
    name: 'Халапенью',
    weight: 40,
    price: 65,
  },
  {
    icon: 'salmon',
    name: 'Лосось филе',
    weight: 40,
    price: 65,
  },
  {
    icon: 'mozzarella',
    name: 'Моцарелла',
    weight: 40,
    price: 65,
  },
  {
    icon: 'mushrooms',
    name: 'Шампиньоны',
    weight: 40,
    price: 65,
  },
  {
    icon: 'bacon',
    name: 'Бекон',
    weight: 40,
    price: 65,
  },
]

const { Text } = Typography

const Toppings = ({ viewport, items, product, modifications = [], maxTotal, total }) => {

  const isMobile = isMobileLayout(viewport)

  return (
    <Row>
      {
        items.map((item, i) => (
          <Col span={isMobile ? 24 : 12} key={i}>
            <ToppingCard
              total={total}
              maxTotal={maxTotal}
              key={i}
              item={item}
              product={product}
              modifications={modifications}
              isMobile={isMobile}
            />
          </Col>
        ))
      }
    </Row>
  )
}

export default compose(
  withViewport,
)(Toppings)

