import React, { useEffect, useState } from 'react'
import { Typography, Row, Col, Checkbox } from 'antd'
import { useLocation, useHistory, NavLink, useParams } from 'react-router-dom'
import { compose } from 'redux'
import { setOrders } from '../../redux/actions'
import { connect } from 'react-redux'
import moment from 'moment'
import Box from '../../components/ui/Box/Box'
import StatusTag from '../../components/ui/StatusTag/StatusTag'
import Icon from '../../components/ui/Icon'

import links from '../../helpers/links'
import { isMobileLayout, isTabletLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import s from './MyOrders.module.less'
import getDishDeclension from '../../helpers/getDishDeclension'
import PointsInfo from '../PointsInfo/PointsInfo'
import setSeoTags from '../../helpers/setSeoTags'


const { Text } = Typography

const MyOrders = (props) => {

  const {
    viewport,
    orders,
  } = props

  const history = useHistory()

  useEffect(() => {
    setSeoTags({
      title: `Eat Street - Заказы`,
      seo_desc: '',
      seo_keyw: '',
    })

    const interval = setInterval(() => {
      setOrders()
    }, 15 * 1000)

    return () => clearInterval(interval)
  }, [])


  const isMobile = isMobileLayout(viewport)

  if (history.location.state && history.location.state.showPointsInfo) {
    return (
      <PointsInfo />
    )
  }

  return (
    <Box className={s.container}>
      <Box mt="xlg" mb="lg" alignCenter>
        <Text className={s.title}>Заказы</Text>
      </Box>
      {
        orders.length === 0 && (
          <Box className={s.emptyText}>
            <Text>У вас пока нет заказов</Text>
          </Box>
        )
      }
      {
        orders.filter((order) => order !== null).sort((a, b) => new Date(b.date_created) - new Date(a.date_created)).map((item, i) => {
          const created = new Date(item.date_created)

          return (
            <NavLink to={`${links.order}/${item.order_number}`} key={item.order_number}>
              <Box pr="md" pl={isMobile ? 'sm' : 'md'} mb="sm" className={s.orderContainer}>
                <Col>
                  <Row>
                    <Text className={s.blackText}>{moment(created).format('lll')} на {item.price_final} ₽</Text>
                  </Row>
                  <Row>
                    <StatusTag status={item.status_code} />
                    <Box ml={isMobile ? 'xs' : 'sm'} className={s.textContainer}>
                      <Text>Заказ #{item.order_number}</Text>
                      <Icon className={s.pointIcon} name="smallPoint2" />
                      {item.products && (
                        <Text>
                          {
                            getDishDeclension(item.products.length)
                          }
                        </Text>
                      )}
                    </Box>
                  </Row>
                </Col>
                <Col>
                  <Icon name="right" />
                </Col>
              </Box>
            </NavLink>
          )
        })
      }
    </Box>
  )
}

const Orders =  compose(
  withViewport,
)(MyOrders)

export default connect((state) => ({
  orders: state.app.orders,
}))(Orders)
