import React, { useRef }  from 'react'
import { Typography, Row, Col, Carousel } from 'antd'
import { NavLink, useHistory } from 'react-router-dom'
import { compose } from 'redux'
import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux'
import classnames from 'classnames'

import WidthContainer from '../../components/layout/WidthContainer/WidthContainer'
import Box from '../../components/ui/Box/Box'
import Button from '../../components/ui/Button/Button'

import { isMobileLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'
import sales from './util/sales'

import s from './Sales.module.less'
import { useEffect } from '../../hooks'


const { Text, Paragraph } = Typography

const SalesComponent = ({ viewport, offers }) => {

  const isMobile = isMobileLayout(viewport)

  const history = useHistory()

  useEffect(() => {
    if (history.location.hash) {
      const node = findDOMNode(document.getElementById(history.location.hash.replace('#', '')))
      if (node) {
        window.scrollTo(0, node.offsetTop)
      }
    }
  }, [])

  return (
    <Box pt={isMobile ? 'sm' : ''} pb={isMobile ? 'xlg' : ''}>
      {
        isMobile && (
          <WidthContainer>
            <Row style={{ width: '100%', justifyContent: 'space-between' }}>
              <Col>
                <Button type="icon" icon="left" isSmall handleClick={() => history.goBack()} />
              </Col>
            </Row>
          </WidthContainer>
        )
      }

      <WidthContainer>
        {
          !isMobile && (
            <Box className={s.buttonBack}>
              <Button type="icon" icon="left" handleClick={() => history.goBack()} />
            </Box>
          )
        }

        <Box className={s.container}>
          <Box mt={isMobile ? 'lg' : 'xlg'} mb={isMobile ? 'lg' : 'xlg'}>
            <Row className={s.title}>
              <Text>Акции</Text>
            </Row>
          </Box>
          <Box>
            {
              offers.filter((item) => Date.now() < (new Date(item.date_to)).getTime()).map((item, i) => (
                <Row style={{ justifyContent: 'space-between', marginBottom: 53 }} id={item.alias}>
                  <Col span={isMobile ? 24 : 8}>
                    <div className={s.offerCard}>
                      {
                        isMobile && item.image ? (
                          item.image.mobile && (
                            <img
                              src={item.image.mobile.detail}
                              style={{ borderRadius: 16 }}
                              srcSet={`${item.image.mobile.detail} 1x, ${item.image.mobile['detail-x2']} 2x`}
                            />
                          )
                        ) : !isMobile && item.image ? (
                          item.image.desktop && (
                            <img
                              src={item.image.desktop.detail}
                              style={{ borderRadius: 16 }}
                              srcSet={`${item.image.desktop.detail} 1x, ${item.image.desktop['detail-x2']} 2x`}
                            />
                          )
                        ) : null
                      }
                      {/* <div className={s.textBlock}> */}
                      {/*  <Text className={classnames(s.mainText, item.isDarkText &&  s.darkText)}>{item.title}</Text> */}
                      {/*  <Text className={classnames(s.secondaryText, item.isDarkText && s.darkText)}>{item.descriptionShort}</Text> */}
                      {/* </div> */}
                    </div>
                    <Box mt={isMobile ? 'md' : 'lg'} mb={isMobile ? 'md' : 'lg'} className={s.lightContrast}>
                      <Text>Акция действует до {(new Date(item.date_to)).toLocaleString()}</Text>
                    </Box>
                    <Box pl="md" pr="md" center className={s.promocodeWrapper}>
                      <Paragraph
                        copyable={{
                          tooltips: ['Скопировать', 'Скопировано'],
                        }}
                      >
                        {item.entry}
                      </Paragraph>
                    </Box>
                  </Col>
                  <Col span={isMobile ? 24 : 15} className={s.textContainer}>
                    <Box mb="md">
                      <Text className={classnames(s.mainText, s.darkText)}>{item.title}</Text>
                    </Box>
                    <Box mb="md">
                      <div className={s.description} dangerouslySetInnerHTML={{ __html: item.content }} />
                    </Box>
                    {/* <Box mb='md'> */}
                    {/*  <Text className={classnames(s.description, s.boldText)}>Условия:</Text> */}
                    {/* </Box> */}
                    {/* { */}
                    {/*  item.conditions.map((condition, index) => { */}
                    {/*    return ( */}
                    {/*      <Box mb='md' key={index}> */}
                    {/*        <Text> • </Text> */}
                    {/*        <Text className={s.description}>{condition}</Text> */}
                    {/*      </Box> */}
                    {/*    ) */}
                    {/*  }) */}
                    {/* } */}
                  </Col>
                </Row>
              ))
            }
            {
              offers.filter((item) => Date.now() > (new Date(item.date_to)).getTime()).map((item, i) => (
                <>
                  <Box mt={isMobile ? 'lg' : 'xlg'} mb={isMobile ? 'lg' : 'xlg'}>
                    <Row className={s.title}>
                      <Text>Прошедшие акции</Text>
                    </Row>
                  </Box>
                  <Row style={{ justifyContent: 'space-between', marginBottom: 53 }}>
                    <Col span={isMobile ? 24 : 8}>
                      <div className={classnames(s.offerCard, s.disabledCard)}>
                        {
                          isMobile ? (
                            item.image && (
                              <img
                                src={item.image.mobile.detail}
                                style={{ borderRadius: 16 }}
                                srcSet={`${item.image.mobile.detail} 1x, ${item.image.mobile['detail-x2']} 2x`}
                              />
                            )
                          ) : (
                            item.image && (
                              <img
                                src={item.image.desktop.detail}
                                style={{ borderRadius: 16 }}
                                srcSet={`${item.image.desktop.detail} 1x, ${item.image.desktop['detail-x2']} 2x`}
                              />
                            )
                          )
                        }
                        {/* <div className={s.textBlock}> */}
                        {/*  <Text className={classnames(s.mainText, item.isDarkText && s.darkText)}>{item.title}</Text> */}
                        {/*  <Text className={classnames(s.secondaryText, item.isDarkText && s.darkText)}>{item.descriptionShort}</Text> */}
                        {/* </div> */}
                      </div>
                      <Box mt={isMobile ? 'md' : 'lg'} mb={isMobile ? 'md' : 'lg'}>
                        <Text className={s.lightContrast}>Акция действует до {(new Date(item.date_to)).toLocaleString()}</Text>
                      </Box>
                      <Box pl="md" pr="md" center className={classnames(s.promocodeWrapper, s.disabledPromocodesWrapper)}>
                        <Paragraph>
                          {item.entry}
                        </Paragraph>
                      </Box>
                    </Col>
                    <Col span={isMobile ? 24 : 15} className={s.textContainer}>
                      <Box mb="md">
                        <Text className={classnames(s.mainText, s.darkText)}>{item.title}</Text>
                      </Box>
                      <Box mb="md">
                        <div className={s.description} dangerouslySetInnerHTML={{ __html: item.content }} />
                      </Box>
                      {/* <Box mb='md'> */}
                      {/*  <Text className={classnames(s.description, s.boldText)}>Условия:</Text> */}
                      {/* </Box> */}
                      {/* { */}
                      {/*  item.conditions.map((condition, index) => { */}
                      {/*    return ( */}
                      {/*      <Box mb='md' key={index}> */}
                      {/*        <Text> • </Text> */}
                      {/*        <Text className={s.description}>{condition}</Text> */}
                      {/*      </Box> */}
                      {/*    ) */}
                      {/*  }) */}
                      {/* } */}
                    </Col>
                  </Row>
                </>
              ))
            }
          </Box>

        </Box>
      </WidthContainer>
    </Box>
  )
}

const Sales = compose(
  withViewport,
)(SalesComponent)

export default connect((state) => ({
  user: state.app.user,
  offers: state.app.offers,
}))(Sales)
