export function generateTimeRangesEat(sections) {

  const timeRangesEat = {}
  sections.forEach((item) => {
    if (item.title === 'Завтраки') {
      const timeRange = [new Date(item.expiration.from).getHours(), new Date(item.expiration.to).getHours()]
      timeRangesEat.breakfasts = {
        name: 'Завтраки',
        timeRange,
        guid: item.section_guid,
        message: `Завтрак можно заказать только на время с ${timeRange[0]} до ${timeRange[1]}`,
      }
    }
    if (item.title === 'Обеды') {
      const timeRange = [new Date(item.expiration.from).getHours(), new Date(item.expiration.to).getHours()]
      const message = timeRange[0] === 0 && timeRange[1] === 0
        ? 'Обед можно заказать только по будням на время с 12 до 16'
        : `Обед можно заказать только на время с ${timeRange[0]} до ${timeRange[1]}`
      timeRangesEat.lunches = {
        name: 'Обеды',
        timeRange,
        guid: item.section_guid,
        message,
      }
    }
    if (item.title === 'Ужины') {
      const timeRange = [new Date(item.expiration.from).getHours(), new Date(item.expiration.to).getHours()]
      timeRangesEat.dinners = {
        name: 'Ужины',
        timeRange,
        guid: item.section_guid,
        message: `Ужин можно заказать только на время с ${timeRange[0]} до ${timeRange[1]}`,
      }
    }
  })

  return timeRangesEat
}
