import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

import './assets/fonts/fonts.css'


function noop() {}

if (process.env.REACT_APP_API_ENV === 'prod') {
  console.log = noop
  console.warn = noop
  console.error = noop
}


ReactDOM.render(
  <App />, document.getElementById('root'),
)
