import React, { Fragment, useEffect, useState } from 'react'
import { Col, Divider, Row, Tooltip, Typography } from 'antd'
import { NavLink, useHistory } from 'react-router-dom'
import cx from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'
import moment from 'moment'

import CategoryTag from 'components/ui/CategoryTag/CategoryTag'
import Image from 'components/ui/Image'
import Box from 'components/ui/Box/Box'

import { isMobileLayout } from '../../../helpers/isMobileLayout'
import { withViewport } from '../../../helpers/withViewport'
import links from '../../../helpers/links'

import s from './MenuContent.module.less'


const { Text } = Typography

const MenuContentComponent = ({ sections, setDrawerVisible, viewport, labels }) => {

  const isMobile = isMobileLayout(viewport)

  const history = useHistory()

  const [isBar, setIsBar] = useState(false)

  let firstSections = []
  let otherSections = []
  let sortedSections = []

  const getSortedSections = () => {
    if (sections) {
      let _firstSections = sections.filter((el) => el.title === 'Завтраки' || el.title === 'Обеды' || el.title === 'Ужины' || el.title === 'Сеты')
      let _otherSections = sections.filter((el) => el.title !== 'Завтраки' && el.title !== 'Обеды' && el.title !== 'Ужины' && el.title !== 'Сеты')
      firstSections = sections.filter((el) => el.title === 'Завтраки' || el.title === 'Обеды' || el.title === 'Ужины' || el.title === 'Сеты')
      otherSections = sections.filter((el) => el.title !== 'Завтраки' && el.title !== 'Обеды' && el.title !== 'Ужины' && el.title !== 'Сеты')

      _firstSections.reverse().forEach((el) => {
        _otherSections.unshift(el)
      })
      sortedSections = _otherSections
    }

    return firstSections, otherSections, sortedSections
  }

  if (sections) {
    getSortedSections()
  }

  const defaultMargin = () => {
    setIsBar(false)
  }

  useEffect(() => {
    const bar = document.querySelector('.cf-bar')?.offsetHeight
    if (bar && bar !== 0) {
      setIsBar(true)
    }
    else {
      setIsBar(false)
    }
    const close = document.querySelector('.cf-cta-close')
    if (close) close.addEventListener('click', defaultMargin)
    return () => close?.removeEventListener('click', defaultMargin)
  })

  return (
    <>
      <Box mb="md" className={s.wrapper}>
        <Row
          gutter={[0, 8]}
          className={isBar ? cx(s.categoriesWrapper, s.flexBox) : cx(s.categoriesWrapperNon, s.flexBox)}
        >
          {
            isMobile ? (
              <>
                {
                  firstSections && firstSections.filter((el) => el.is_visible && !el.parent_guid).map((item, i) => (
                    <Col span={12} key={i}>
                      <NavLink to={`/catalog/${item.alias}`} className={cx(s.container, s.greenContainer)} onClick={() => setDrawerVisible(false)}>
                        {
                          (isMobile && item.images && item.images[0]) ? (
                            <img
                              src={item.images[0].mobile.preview}
                              style={{ minWidth: 40, maxWidth: 41, maxHeight: 41, borderRadius: 40 }}
                              srcSet={`${item.images[0].mobile.preview} 1x, ${item.images[0].mobile['preview-x2']} 2x`}
                            />
                          ) : (
                            <Image name="noImage" style={{ minWidth: 40, maxWidth: 41, maxHeight: 41 }} />
                          )
                        }
                        <Box className={s.textContainer}>
                          <Text className={s.text} style={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.title}</Text>
                          {
                            item.expiration && (
                              <Text className={s.expiration}>{`с ${moment(item.expiration.from).format('H')} до ${moment(item.expiration.to).format('H')}`}</Text>
                            )
                          }
                        </Box>
                      </NavLink>
                    </Col>
                  ))
                }
                <Box mt="sm" mb="xs" style={{ width: '100%', marginLeft: 20, marginRight: 20 }}>
                  <Divider style={{ margin: 0 }} />
                </Box>
                {
                  otherSections && otherSections.filter((el) => el.is_visible && !el.parent_guid).map((item, i) => (
                    <Col span={12} key={i}>
                      <NavLink to={`/catalog/${item.alias}`} className={cx(s.container)} onClick={() => setDrawerVisible(false)}>
                        {
                          (isMobile && item.images && item.images[0]) ? (
                            <img
                              src={item.images[0].mobile.preview}
                              style={{ minWidth: 40, maxWidth: 41, maxHeight: 41, borderRadius: 40 }}
                              srcSet={`${item.images[0].mobile.preview} 1x, ${item.images[0].mobile['preview-x2']} 2x`}
                            />
                          ) : (
                            <Image name="noImage" style={{ minWidth: 40, maxWidth: 41, maxHeight: 41 }} />
                          )
                        }
                        <Box className={s.textContainer}>
                          <Text className={s.text} style={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.title}</Text>
                          {
                            item.expiration && (
                              <Text className={s.expiration}>{`с ${moment(item.expiration.from).format('H')} до ${moment(item.expiration.to).format('H')}`}</Text>
                            )
                          }
                        </Box>
                      </NavLink>
                    </Col>
                  ))
                }
              </>
            ) : (
              <>
                {
                  sortedSections && sortedSections.filter((el) => el.is_visible && !el.parent_guid).map((item, i) => (
                    <Col span={6} key={i}>
                      <NavLink to={`/catalog/${item.alias}`} className={cx(s.container, i < 4 && s.greenContainer)} onClick={() => setDrawerVisible(false)}>
                        {
                          (!isMobile && item.images && item.images[0]) ? (
                            <img
                              src={item.images[0].desktop.preview}
                              style={{ minWidth: 40, maxWidth: 41, maxHeight: 41, borderRadius: 40 }}
                              srcSet={`${item.images[0].desktop.preview} 1x, ${item.images[0].desktop['preview-x2']} 2x`}
                            />
                          ) : (
                            <Image name="noImage" style={{ minWidth: 40, maxWidth: 41, maxHeight: 41 }} />
                          )
                        }
                        <Tooltip title={item.title} color="#2B5E51">
                          <Box className={s.textContainer}>
                            <Text className={s.text} style={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.title}</Text>
                            {
                              item.expiration && (
                                <Text
                                  className={s.expiration}>{`с ${moment(item.expiration.from).format('H')} до ${moment(item.expiration.to).format('H')}`}
                                </Text>
                              )
                            }
                          </Box>
                        </Tooltip>
                      </NavLink>
                    </Col>
                  ))
                }
              </>
            )
          }
        </Row>
      </Box>
      <Box mt="lg" mb="lg">
        <Divider />
      </Box>
      <Row gutter={[0, 16]} className={s.flexBox}>
        {
          labels.map((item, i) => (
            <Fragment key={i}>
              {
                isMobile ? (
                  <Col span={12} key={i}>
                    <CategoryTag
                      icon={item.image.active ? item.image.active.resize['preview-x2'] : ''}
                      text={item.title}
                      isSmall
                      height={25}
                      onClick={() => {
                        setDrawerVisible(false)
                        history.push(`${links.catalog}/${item.alias}`)
                      }}
                      className={s.tagBox}
                    />
                  </Col>
                ) : (
                  <Box mr="xs" mb="sm" key={i}>
                    <CategoryTag
                      icon={item.image.active ? item.image.active.resize['preview-x2'] : ''}
                      text={item.title}
                      isSmall
                      height={25}
                      onClick={() => {
                        setDrawerVisible(false)
                        history.push(`${links.catalog}/${item.alias}`)
                      }}
                    />
                  </Box>
                )
              }
            </Fragment>
          ))
        }
      </Row>
    </>
  )
}

const MenuContent = compose(
  withViewport,
)(MenuContentComponent)

export default connect((state) => ({
  sections: state.app.sections,
  labels: state.app.labels,
}))(MenuContent)
