import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'
import Text from 'antd/lib/typography/Text'
import { withViewport } from '../../helpers/withViewport'
import { isMobileLayout, MAX_MOBILE_SCREEN_WIDTH } from '../../helpers/isMobileLayout'
import CategorySwiper from '../../components/ui/CategorySwiper/CategorySwiper'
import links from '../../helpers/links'
import Box from '../../components/ui/Box/Box'
import s from './CategoriesCarousel.module.less'
import SwiperNavButton from '../../components/ui/SwiperNavButton/SwiperNavButton'


const ComponentCategoriesSwiper = ({ labels, viewport }) => {

  const isMobile = isMobileLayout(viewport)
  const history = useHistory()

  let startX = null

  return (
    <Box mt={isMobile ? '' : 'lg'} className={cx(s.container, isMobile && s.mobileWrapper)}>
      <div style={{ position: 'relative', width: '100%' }}>
        {!isMobile && labels.length ? (
          <>
            <Swiper
              loop
              breakpoints={{
                1084: {
                  spaceBetween: 10,
                  slidesPerView: 7,
                },
                680: {
                  spaceBetween: 10,
                  slidesPerView: 5,
                },
              }}
              className={s.desktopSwiper}
            >
              {labels?.map((el, index) => (
                <SwiperSlide key={index}>
                  <CategorySwiper
                    onPointerDown={(e) => {
                      startX = e.pageX
                    }}
                    onPointerUp={(e) => {
                      if (Math.abs(startX - e.pageX) > 5) return
                      history.push(`${links.catalog}/${el.alias}`)
                    }}
                    icon={el.image.active ? el.image.active.resize['preview-x2'] : ''}
                    text={el.title}
                    isMedium={isMobile}
                    height={isMobile ? 30 : 50} />
                </SwiperSlide>
              ),
              )}
              <div className={s.prevButtonSlot}>
                <div className={s.prevButtonWrapper}>
                  <SwiperNavButton
                    type="prev"
                    slidesPerGroup={3}
                    className={s.prevButton}
                  />
                </div>
              </div>
              <div className={s.nextButtonSlot}>
                <div className={s.nextButtonWrapper}>
                  <SwiperNavButton
                    type="next"
                    slidesPerGroup={3}
                    className={s.nextButton}
                  />
                </div>
              </div>
            </Swiper>
          </>
        )
          :          (
            <>
              <p><Text className={s.mobileText}>Подборки</Text></p>
              <Swiper
                loop
                breakpoints={{
                  680: {
                    spaceBetween: 10,
                    slidesPerView: 6,
                  },
                  610: {
                    spaceBetween: 20,
                    slidesPerView: 5,
                  },
                  450: {
                    spaceBetween: 10,
                    slidesPerView: 4,
                  },
                  310: {
                    spaceBetween: 8,
                    slidesPerView: 3,
                  },
                }}>
                {labels?.map((el, index) => (
                  <SwiperSlide key={index}>
                    <CategorySwiper
                      onPointerDown={(e) => {
                        startX = e.pageX
                      }}
                      onPointerUp={(e) => {
                        if (Math.abs(startX - e.pageX) > 5) return
                        history.push(`${links.catalog}/${el.alias}`)
                      }}
                      icon={el.image.active ? el.image.active.resize['preview-x2'] : ''}
                      text={el.title}
                      isMedium={isMobile}
                      height={isMobile ? 30 : 50} />
                  </SwiperSlide>
                ),
                )}
              </Swiper>
            </>
          )}
      </div>
    </Box>
  )
}

const CategoriesSwiper = compose(
  withViewport,
)(ComponentCategoriesSwiper)

export default connect((state) => ({
  labels: state.app.labels,
}))(CategoriesSwiper)
