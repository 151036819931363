import React from 'react'
import cx from 'classnames'
import { images } from 'components/ui/Image/index'
import { Typography } from 'antd'
import Box from '../../components/ui/Box/Box'
import s from './BannerWithButton.module.less'
import { getSrcSet } from './utils/getSrcSet'
import Button from '../../components/ui/Button/Button'

// To use this component with different images' sizes you need to name them by the way bellow
// name name2x name3x
// But its no cause error if you put in this component only one image

const BannerWithButton = (props) => {
  const {
    backgroundImageMobile,
    backgroundImageDesktop,
    buttonText,
    onClick,
    title,
    subtitle,
    isMobile = false,
    className,
  } = props

  const { Text } = Typography

  return (
    <div className={cx(s.bannerContainer, className)}>
      {isMobile ? (
        <img
          srcSet={getSrcSet(backgroundImageMobile)}
          src={`${images[`${backgroundImageMobile}`]}`}
          className={s.backgroundImage}
        />
      ) : (
        <img
          srcSet={getSrcSet(backgroundImageDesktop)}
          src={`${images[`${backgroundImageDesktop}`]}`}
          className={s.backgroundImage}
        />
      )}
      <Box className={s.absolutuContainer} alignLeft>
        <Text className={s.title}>{title}</Text>
        <Text className={s.subtitle}>{subtitle}</Text>
        <Button
          className={s.button}
          text={buttonText}
          primary
          handleClick={onClick}
        />
      </Box>
    </div>
  )
}

export default BannerWithButton
