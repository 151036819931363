exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1asguiN7q-Tyw7KS_XsoFC {\n  width: 100%;\n  height: fit-content;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row;\n  align-items: center;\n  border-radius: 16px;\n}\n._1asguiN7q-Tyw7KS_XsoFC .ant-carousel {\n  width: 100%;\n}\n._1asguiN7q-Tyw7KS_XsoFC .ant-carousel .slick-dots ul {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row;\n}\n._1asguiN7q-Tyw7KS_XsoFC .ant-carousel .slick-dots li {\n  width: 90px;\n}\n._1asguiN7q-Tyw7KS_XsoFC .ant-carousel .slick-dots li button {\n  background: rgba(43, 94, 81, 0.2);\n}\n._1asguiN7q-Tyw7KS_XsoFC .ant-carousel .slick-dots li.slick-active button {\n  background: #2B5E51;\n}\n._1asguiN7q-Tyw7KS_XsoFC .ant-carousel .slick-dots {\n  margin: 0;\n}\n._1asguiN7q-Tyw7KS_XsoFC .ant-carousel .slick-dots ul {\n  width: 100%;\n  padding: 0;\n}\n._1asguiN7q-Tyw7KS_XsoFC .slick-list {\n  z-index: 99;\n}\n._2gzd1GkKmuNIQ_m_C3peoF {\n  padding-bottom: 130px;\n}\n._2gzd1GkKmuNIQ_m_C3peoF .slick-list {\n  height: fit-content;\n}\n._3PLZtwl7hg273HBgObNbun {\n  position: absolute;\n  left: 0;\n  z-index: 999;\n  top: 300px;\n}\n._3LqsVB6-U1UV1DTukmQvvB {\n  position: absolute;\n  right: 0;\n  z-index: 999;\n  top: 300px;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "_1asguiN7q-Tyw7KS_XsoFC",
	"mobileWrapper": "_2gzd1GkKmuNIQ_m_C3peoF",
	"prevBtn": "_3PLZtwl7hg273HBgObNbun",
	"nextBtn": "_3LqsVB6-U1UV1DTukmQvvB"
};