exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2GvbQg4UXwgxNQdaCGD04J {\n  width: 100%;\n}\n._2GvbQg4UXwgxNQdaCGD04J .ant-radio-button-wrapper:first-child {\n  border: none;\n}\n._2GvbQg4UXwgxNQdaCGD04J .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {\n  background: #F7F9F8;\n}\n.xN9HKqO0Kj3dRCd1Dy2sy {\n  width: 100%;\n  height: 100%;\n  background: #F7F9F8;\n  border-radius: 11px;\n  padding: 16px;\n  margin-top: 16px;\n  border: none;\n  box-shadow: none;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n.xN9HKqO0Kj3dRCd1Dy2sy span:last-child {\n  width: 100%;\n}\n.k2UmB2l6miy-VYzKHKGN5 {\n  display: flex;\n  flex-direction: row;\n}\n._1rQCRM8zsClVof4mzlaVMR {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 2;\n  text-align: left;\n  justify-content: center;\n}\n._1rQCRM8zsClVof4mzlaVMR span:first-child {\n  font-family: 'Circe-Regular', serif;\n  font-size: 16px;\n  line-height: 19px;\n  color: #303231;\n  margin-bottom: 5px;\n}\n._1rQCRM8zsClVof4mzlaVMR span:last-child {\n  font-family: 'Circe-Regular', serif;\n  font-size: 14px;\n  line-height: 21px;\n  color: #A8A8A8;\n}\n._2AccB3nRbg09DKHTpjzKdZ {\n  display: flex;\n  justify-content: flex-end;\n  align-items: baseline;\n}\n._2v5SU5ci3Bu9eTb2NW5bMr {\n  width: 20px;\n  height: 20px;\n  background: #E1EDE7;\n  border-radius: 50%;\n}\n", ""]);

// Exports
exports.locals = {
	"wrapper": "_2GvbQg4UXwgxNQdaCGD04J",
	"container": "xN9HKqO0Kj3dRCd1Dy2sy",
	"row": "k2UmB2l6miy-VYzKHKGN5",
	"textContainer": "_1rQCRM8zsClVof4mzlaVMR",
	"iconContainer": "_2AccB3nRbg09DKHTpjzKdZ",
	"notChecked": "_2v5SU5ci3Bu9eTb2NW5bMr"
};