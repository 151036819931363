import React, { useState } from 'react'
import { Layout, Drawer, Typography } from 'antd'
import { NavLink, useLocation } from 'react-router-dom'
import { compose } from 'redux'

import Box from 'components/ui/Box/Box'
import WidthContainer from 'components/layout/WidthContainer/WidthContainer'
import PageHeader from '../../compositions/PageHeader/PageHeader'
import PageFooter from '../../compositions/Footer/Footer'
import MenuDrawer from '../../compositions/MenuDrawer/MenuDrawer'
import AccountMenuDrawer from '../../compositions/AccountMenuDrawer/AccountMenuDrawer'

import { isMobileLayout, isTabletLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'


const { Content } = Layout
const { Text } = Typography

const CustomAppLayout = (props) => {

  const { children, viewport } = props

  const location = useLocation()

  const [ isMenuDrawerVisible, setMenuDrawerVisible ] = useState(false)
  const [ isAccountDrawerVisible, setAccountDrawerVisible ] = useState(false)

  const isMobile = isMobileLayout(viewport)

  return (
    <Layout>
      {
        !isMobile && (
          <WidthContainer>
            <PageHeader
              isMenuDrawerVisible={isMenuDrawerVisible}
              setMenuDrawerVisible={setMenuDrawerVisible}
              isAccountDrawerVisible={isAccountDrawerVisible}
              setAccountDrawerVisible={setAccountDrawerVisible}
            />
          </WidthContainer>
        )
      }
      <Content>
        {
          isMenuDrawerVisible && (
            <MenuDrawer
              isMenuDrawerVisible={isMenuDrawerVisible}
              setMenuDrawerVisible={setMenuDrawerVisible}
              isAccountDrawerVisible={isAccountDrawerVisible}
              setAccountDrawerVisible={setAccountDrawerVisible}
            />
          )
        }
        {
          isAccountDrawerVisible && isMobile && (
            <AccountMenuDrawer
              isAccountDrawerVisible={isAccountDrawerVisible}
              setAccountDrawerVisible={setAccountDrawerVisible}
              isMenuDrawerVisible={isMenuDrawerVisible}
              setMenuDrawerVisible={setMenuDrawerVisible}
            />
          )
        }
        {children}
      </Content>
      {
        !isMobile && location.pathname !== '/map' && <PageFooter />
      }
    </Layout>
  )
}


export default compose(
  withViewport,
)(CustomAppLayout)
