import React, { useState } from 'react'
import { Typography } from 'antd'
import cx from 'classnames'
import { setBasketItem } from '../../../redux/actions'
import Box from '../Box/Box'

import s from './Increment.module.less'
import { useEffect } from '../../../hooks'


const { Text } = Typography

const Increment = (props) => {

  const { handleChange, defaultCount, max, min = 0, isLightGreen, maxTotal, total } = props

  const [ count, setCount ] = useState(defaultCount)

  useEffect(() => {
    setCount(defaultCount)
  }, [setCount, defaultCount])

  return (
    <div className={cx(s.container, count && !isLightGreen && s.activeContainer)}>
      <div
        className={s.btn}
        onClick={() => {
          if (count <= min) {
            return false
          }

          setCount(count - 1)
          handleChange(true)
        }}
      >
        <Text>-</Text>
      </div>
      <Text className={cx(s.count, count && !isLightGreen && s.activeCount)}>{count}</Text>
      <div
        className={s.btn}
        onClick={() => {
          if (max && count >= max) {
            return false
          }

          if (total >= maxTotal) {
            return false
          }

          setCount(count + 1)
          handleChange()
        }}
      >
        <Text>+</Text>
      </div>
    </div>
  )
}

export default Increment

