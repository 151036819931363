exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".NrajtIUkh5dfj4vkEiswy {\n  max-width: 414px;\n  margin: 0 auto 36px auto;\n}\n._3lwy5jRXiryj4ZcIjjA59P {\n  font-family: 'Circe-Bold', serif;\n  font-size: 40px;\n  line-height: 59px;\n  color: #3C3C3C;\n}\n@media (max-width: 375px) {\n  ._3lwy5jRXiryj4ZcIjjA59P {\n    font-size: 32px;\n    line-height: 38px;\n  }\n}\n._3AH72v94ad7BHZf5J58Mil {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n._3AH72v94ad7BHZf5J58Mil span {\n  font-family: 'Circe-Regular', serif;\n  font-size: 20px;\n  line-height: 30px;\n  color: #3C3C3C;\n}\n.oUsTLcg-SPd4GE6adXNo3 {\n  width: 100%;\n  height: 89px;\n  background: #FFFFFF;\n  box-shadow: 0 0 24px rgba(0, 0, 0, 0.05);\n  border-radius: 11px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  cursor: pointer;\n}\n._2f0kaHEKTt3yL8WGaBnVJy {\n  font-family: 'Circe-Bold', serif;\n  font-size: 15px;\n  line-height: 30px;\n  color: #303231;\n}\n._3hcTeNPBynxNKTdPfQNeoy {\n  display: flex;\n  align-items: center;\n}\n._3hcTeNPBynxNKTdPfQNeoy span {\n  font-family: 'Circe-Regular', serif;\n  font-size: 13px;\n  line-height: 16px;\n  color: #9CA092;\n}\n._2bHG5nRoE6CIKdGpEmk0hv {\n  margin: 0 5px;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "NrajtIUkh5dfj4vkEiswy",
	"title": "_3lwy5jRXiryj4ZcIjjA59P",
	"emptyText": "_3AH72v94ad7BHZf5J58Mil",
	"orderContainer": "oUsTLcg-SPd4GE6adXNo3",
	"blackText": "_2f0kaHEKTt3yL8WGaBnVJy",
	"textContainer": "_3hcTeNPBynxNKTdPfQNeoy",
	"pointIcon": "_2bHG5nRoE6CIKdGpEmk0hv"
};