import React from 'react'


const ShoppingBagIcon = ({ color }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16.4556 0.667969L17.2838 15.5755C17.3348 16.4946 16.6312 17.281 15.7121 17.3321C15.6659 17.3342 15.6659 17.3342 15.6197 17.3346H2.38151C1.46104 17.3346 0.714844 16.5884 0.714844 15.668C0.715272 15.6217 0.715272 15.6217 0.71741 15.5755L1.54561 0.667969H16.4556ZM3.1216 2.33594L2.38086 15.6693H15.619L14.8783 2.33594H3.1216ZM6.50065 4H4.83398V6.08333C4.83398 8.08391 6.77002 9.83333 9.00065 9.83333C11.2313 9.83333 13.1673 8.08391 13.1673 6.08333V4H11.5007V6.08333C11.5007 7.12364 10.3464 8.16667 9.00065 8.16667C7.65493 8.16667 6.50065 7.12364 6.50065 6.08333V4Z" fill={color} />
  </svg>
)

export default ShoppingBagIcon
