exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3O-s-F2FhDcm8FlwrZk-cn {\n  font-family: 'Circe-Bold', serif;\n  font-size: 40px;\n  line-height: 59px;\n  color: #3C3C3C;\n}\n.PMB4ixUWUt714WAtFwgFn {\n  max-width: 250px;\n}\n._2vvYrzjBEMzCJxsRi-Hy_c {\n  max-width: 100%;\n}\n._40zz3TAP3_1ewPeRwdEKH {\n  position: absolute;\n  width: 200.42px;\n  height: 200.37px;\n  top: 15px;\n  background: #70775B;\n  opacity: 0.8;\n  filter: blur(30px);\n  transform: matrix(1, 0.08, -0.08, 1, 0, 0);\n  border-radius: 50%;\n  z-index: 0;\n}\n._2nl-NULjn8CrQhwM6JesDu {\n  color: #303231;\n}\n._3Ab9aY_IFOsb9S9xqklYn_ {\n  width: 265px;\n  height: 52px;\n  margin-top: 40px;\n}\n", ""]);

// Exports
exports.locals = {
	"title": "_3O-s-F2FhDcm8FlwrZk-cn",
	"itemContainer": "PMB4ixUWUt714WAtFwgFn",
	"itemContainerMobile": "_2vvYrzjBEMzCJxsRi-Hy_c",
	"shadow": "_40zz3TAP3_1ewPeRwdEKH",
	"subText": "_2nl-NULjn8CrQhwM6JesDu",
	"button": "_3Ab9aY_IFOsb9S9xqklYn_"
};