import guyPhoto_1x from '../images/guyPhoto.jpg'
import guyPhoto_2x from '../images/guyPhoto@2x.jpg'
import guyPhoto_3x from '../images/guyPhoto@3x.jpg'

import pizzaPhoto_1x from '../images/pizzaPhoto.jpg'
import pizzaPhoto_2x from '../images/pizzaPhoto@2x.jpg'
import pizzaPhoto_3x from '../images/pizzaPhoto@3x.jpg'

import girlWithSushi_1x from '../images/girlWithSushi.jpg'
import girlWithSushi_2x from '../images/girlWithSushi@2x.jpg'
import girlWithSushi_3x from '../images/girlWithSushi@3x.jpg'

import girlWithPizza_1x from '../images/girlWithPizza.jpg'
import girlWithPizza_2x from '../images/girlWithPizza@2x.jpg'
import girlWithPizza_3x from '../images/girlWithPizza@3x.jpg'

import boxesPhoto_1x from '../images/boxesImage.jpg'
import boxesPhoto_2x from '../images/boxesImage@2x.jpg'
import boxesPhoto_3x from '../images/boxesImage@3x.jpg'


const images = [
  {
    src: guyPhoto_1x,
    srcSet: `${guyPhoto_1x} 1x, ${guyPhoto_2x} 2x, ${guyPhoto_3x} 3x`,
  },
  {
    src: pizzaPhoto_1x,
    srcSet: `${pizzaPhoto_1x} 1x, ${pizzaPhoto_2x} 2x, ${pizzaPhoto_3x} 3x`,
  },
  {
    src: girlWithSushi_1x,
    srcSet: `${girlWithSushi_1x} 1x, ${girlWithSushi_2x} 2x, ${girlWithSushi_3x} 3x`,
  },
  {
    src: girlWithPizza_1x,
    srcSet: `${girlWithPizza_1x} 1x, ${girlWithPizza_2x} 2x, ${girlWithPizza_3x} 3x`,
  },
  {
    src: boxesPhoto_1x,
    srcSet: `${boxesPhoto_1x} 1x, ${boxesPhoto_2x} 2x, ${boxesPhoto_3x} 3x`,
  },
  {
    src: guyPhoto_1x,
    srcSet: `${guyPhoto_1x} 1x, ${guyPhoto_2x} 2x, ${guyPhoto_3x} 3x`,
  },
  {
    src: pizzaPhoto_1x,
    srcSet: `${pizzaPhoto_1x} 1x, ${pizzaPhoto_2x} 2x, ${pizzaPhoto_3x} 3x`,
  },
  {
    src: girlWithSushi_1x,
    srcSet: `${girlWithSushi_1x} 1x, ${girlWithSushi_2x} 2x, ${girlWithSushi_3x} 3x`,
  },
  {
    src: girlWithPizza_1x,
    srcSet: `${girlWithPizza_1x} 1x, ${girlWithPizza_2x} 2x, ${girlWithPizza_3x} 3x`,
  },
  {
    src: boxesPhoto_1x,
    srcSet: `${boxesPhoto_1x} 1x, ${boxesPhoto_2x} 2x, ${boxesPhoto_3x} 3x`,
  },
]

export default images



