import React from 'react'
import { Typography, Divider } from 'antd'
import cx from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Box from 'components/ui/Box/Box'
import Image from 'components/ui/Image'

import { useHistory } from 'react-router-dom'
import { isMobileLayout, isTabletLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import s from './MainBanner.module.less'
import { useState } from '../../hooks'


const { Text } = Typography

const MainBannerComponent = ({ firstSlider, viewport }) => {
  const [ loaded, setLoaded ] = useState(false)

  const isMobile = isMobileLayout(viewport)
  const history = useHistory()

  if (!firstSlider || !firstSlider.image) {
    return null
  }

  // console.log(firstSlider)

  return (
    <Box
      className={s.mainBanner}
      onClick={() => {
        history.push(firstSlider.link)
      }}>
      { firstSlider && firstSlider.image && (
        <>
          {
            isMobile ? (
              <img
                src={firstSlider.image.mobile.detail}
                style={{ width: '100%' }}
                onLoad={setLoaded}
                srcSet={`${firstSlider.image.mobile.detail} 1x, ${firstSlider.image.mobile['detail-x2']} 2x`}
              />
            ) : (
              <img
                src={firstSlider.image.desktop.detail}
                style={{ width: '100%' }}
                onLoad={setLoaded}
                srcSet={`${firstSlider.image.desktop.detail} 1x, ${firstSlider.image.desktop['detail-x2']} 2x`}
              />
            )
          }
        </>
      )}
      {/* <img src={firstSlider.image.preview} style={{ width: '100%' }} onLoad={setLoaded}  /> */}
      {/* { */}
      {/*  loaded && !isMobile && ( */}
      {/*    <div className={s.textBlockWrapper}> */}
      {/*      <Box mb='lg' className={s.textBlock}> */}
      {/*        <Box mb='sm' className={s.rectangle}> */}
      {/*          <Text>{firstSlider.subtitle}</Text> */}
      {/*        </Box> */}
      {/*        <Text className={s.boldText}> */}
      {/*          {firstSlider.title} */}
      {/*        </Text> */}
      {/*        <Text className={s.bottomText}>{firstSlider.entry}</Text> */}
      {/*      </Box> */}
      {/*    </div> */}
      {/*  ) */}
      {/* } */}
    </Box>
  )
}

const MainBanner = compose(
  withViewport,
)(MainBannerComponent)

export default connect((state) => ({
  firstSlider: state.app.firstSlider,
}))(MainBanner)
