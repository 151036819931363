import React, { useRef } from 'react'
import { Carousel, Typography, Row, Col } from 'antd'
import cx from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { useHistory } from 'react-router-dom'
import Box from '../../components/ui/Box/Box'
import Button from '../../components/ui/Button/Button'
import ProductItem from './components/ProductItem/ProductItem'

import { isMobileLayout, isTabletLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'
import links from '../../helpers/links'

import s from './ProductsCarousel.module.less'
import { useEffect } from '../../hooks'
import setSeoTags from '../../helpers/setSeoTags'


const { Text } = Typography

const ProductsCarouselComponent = (props) => {

  const { id,
    data,
    setModalVisible,
    productModifications,
    selectedRestaurantGuid,
    stopList,
    section_alias,
    product_alias,
    comboProductCode,
    groupAlias,
    favorites,
    viewport,
    isComboMealProducts,
    setAddedMeal } = props

  const isMobile = isMobileLayout(viewport)
  const isTablet = isTabletLayout(viewport)

  const carouselRef = useRef()
  const history = useHistory()

  const stop = stopList.find((list) => list.restaurant_guid === selectedRestaurantGuid) || {
    products: [],
  }

  const initialSlide = isComboMealProducts ? data.findIndex((product) => product.code === comboProductCode) || 0
    : data.findIndex((product) => product.alias === product_alias) || 0

  const item = data && data.length > 0 ? data[initialSlide < 0 ? 0 : initialSlide] : null

  const modificationIndex = productModifications && productModifications.findIndex((i) => i.product === item.products_guid)
  const modifications = modificationIndex > -1 ? productModifications[modificationIndex].modifications || [] : []

  const getTotalProductPrice = () => {
    if (modifications && modifications.length > 0) {
      let pricesArr = []
      modifications.forEach((i) => {
        pricesArr.push(i.price * i.quant)
      })
      return pricesArr.reduce((a, b) => a + b)
    }
    return 0
  }

  setSeoTags({
    title: item.seo_title || item.title,
    seo_desc: item ? item.seo_desc : '',
    seo_keyw: item ? item.seo_keyw : '',
  }, {
    title: item.title,
    description: item.ingredients,
    image: item.images && item.images[0].mobile?.detail,
    price: `${(getTotalProductPrice() + item.price) || item.price}`,
  })


  useEffect(() => {
    carouselRef.current.goTo(initialSlide)
  }, [ carouselRef, product_alias ])

  return (
    <Box className={cx(s.container, isMobile && s.mobileWrapper)}>
      {
        !isMobile && (
          <Button
            className={s.prevBtn}
            type="icon"
            icon="left"
            circle
            handleClick={() => carouselRef.current.prev()}
          />
        )
      }
      <Carousel
        beforeChange={(prev, current) => {
          setSeoTags({
            title: data[current].seo_title,
            seo_desc: data[current].seo_desc,
            seo_keyw: data[current].seo_keyw,
          })


          if (isComboMealProducts) {
            history.push(`${links.catalog}/${section_alias}/${product_alias}/${groupAlias}/${data[current].code}`)
            // history.replaceState({}, document.title, )
          }
          else {
            history.push(`${links.catalog}/${section_alias}/${data[current].alias}`)
            // history.replaceState({}, document.title, `${links.catalog}/${section_alias}/${data[current].alias}`)
          }
        }}
        ref={carouselRef}
        slidesToShow={1}
        slidesToScroll={1}
        infinite
        adaptiveHeight
        dotPosition="top"
        initialSlide={initialSlide}
        // effect="fade"
      >
        {
          data.map((item, i) => {
            const isFavorite = !!favorites.find((el) => el.products_guid === item.products_guid)
            item.toppings_objects?.forEach((top) => {
              top.disabled = stop.products.includes(top.component_guid)
            })

            const inStop = stop.products.includes(item.products_guid)
                          || stop.products.includes(item.component_guid)

            return (
              <ProductItem
                in_stop={inStop}
                key={i}
                item={item}
                isFavorite={isFavorite}
                setModalVisible={setModalVisible}
                isComboMealProducts={isComboMealProducts}
                setAddedMeal={setAddedMeal} />
            )
          })
        }
      </Carousel>
      {
        !isMobile && (
          <Button
            className={s.nextBtn}
            type="icon"
            icon="right"
            circle
            handleClick={() => carouselRef.current.next()}
          />
        )
      }
    </Box>
  )
}

const ProductsCarousel = compose(
  withViewport,
)(ProductsCarouselComponent)

export default connect((state) => ({
  favorites: state.app.favorites,
  stopList: state.app.stopList,
  productModifications: state.app.productModifications,
  selectedRestaurantGuid: state.app.selectedRestaurantGuid,
}))(ProductsCarousel)

