exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2ZV82_oB7Wtc2kDArO1Xw7 {\n  height: 120px;\n  border-radius: 16px;\n  position: relative;\n  overflow: hidden;\n  cursor: pointer;\n}\n@media (min-width: 415px) {\n  ._2ZV82_oB7Wtc2kDArO1Xw7 {\n    height: 160px;\n  }\n}\n._1AfBuOrjSUN-PVHeHrZjA_ {\n  position: absolute;\n  object-fit: cover;\n  height: 100%;\n}\n._29GBnD7Tt1KoJxqkWfd9y_ {\n  position: absolute;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  padding: 20px;\n  justify-content: flex-end;\n}\n@media (min-width: 415px) {\n  ._29GBnD7Tt1KoJxqkWfd9y_ {\n    justify-content: flex-end;\n    padding: 24px 30px;\n  }\n}\n._3kVR-vJkUigeIPzwzA92UB {\n  font-family: 'Circe-Bold', serif;\n  font-size: 20px;\n  line-height: 20px;\n  text-align: left;\n  color: #fff;\n  margin-bottom: 5px !important;\n  font-style: normal;\n  font-weight: bold;\n}\n@media (min-width: 415px) {\n  ._3kVR-vJkUigeIPzwzA92UB {\n    font-size: 34px;\n    line-height: 36px;\n    max-width: 210px;\n  }\n}\n.mQLfSraG5ahm3HfQNKMgv {\n  font-family: 'Circe-Regular', serif;\n  font-size: 14px;\n  line-height: 16px;\n  text-align: left;\n  color: #fff;\n}\n@media (min-width: 415px) {\n  .mQLfSraG5ahm3HfQNKMgv {\n    font-size: 20px;\n  }\n}\n._1fvquCbaTvTDTxquyFhhh- {\n  color: #3C3C3C;\n}\n", ""]);

// Exports
exports.locals = {
	"offerContainer": "_2ZV82_oB7Wtc2kDArO1Xw7",
	"brgImage": "_1AfBuOrjSUN-PVHeHrZjA_",
	"textBlock": "_29GBnD7Tt1KoJxqkWfd9y_",
	"mainText": "_3kVR-vJkUigeIPzwzA92UB",
	"secondaryText": "mQLfSraG5ahm3HfQNKMgv",
	"darkText": "_1fvquCbaTvTDTxquyFhhh-"
};