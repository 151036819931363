import React from 'react'
import { Drawer } from 'antd'
import cx from 'classnames'
import { compose } from 'redux'

import WidthContainer from 'components/layout/WidthContainer/WidthContainer'
import PageHeader from 'compositions/PageHeader/PageHeader'
import MenuContent from './MenuContent/MenuContent'

import { isMobileLayout, isTabletLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import s from './MenuDrawer.module.less'


const MenuDrawer = (props) => {

  const { isMenuDrawerVisible, setMenuDrawerVisible, isAccountDrawerVisible, setAccountDrawerVisible, viewport } = props

  const isMobile = isMobileLayout(viewport)

  return (
    <Drawer
      placement={isMobile ? 'left' : 'top'}
      maskClosable
      onClose={() => setMenuDrawerVisible(false)}
      visible={isMenuDrawerVisible}
      className={s.drawer}
      height={isMobile ? '100vh' : 'auto'}
      width="100wv"
    >
      <WidthContainer>
        <PageHeader
          isMenuDrawerVisible={isMenuDrawerVisible}
          setMenuDrawerVisible={setMenuDrawerVisible}
          isAccountDrawerVisible={isAccountDrawerVisible}
          setAccountDrawerVisible={setAccountDrawerVisible}
          withoutLogo
        />
        <MenuContent setDrawerVisible={setMenuDrawerVisible} />
      </WidthContainer>
    </Drawer>
  )
}


export default compose(
  withViewport,
)(MenuDrawer)
