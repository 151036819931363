import React from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'
import s from './ProductList.module.less'
import Box from '../../../../components/ui/Box/Box'
import getDeliveryPointById from '../../../../helpers/getDeliveryPointById'
import getRestaurantsFromCities from '../../../../helpers/getRestaurantsFromCities'
import ProductCard from '../../../ProductCard/ProductCard'
import links from '../../../../helpers/links'


const ProductList = (props) => {
  const {
    mappedSections,
    dishes,
    isMobile,
    onDishClick,
    onLikeClick,
    onPriceClick,
    favorites,
    stopList,
    selectedRestaurantGuid,
    selectedDeliveryPoint,
    sections,
    className,
  } = props

  const history = useHistory()

  const stop = stopList.find((list) =>
    list.restaurant_guid === selectedRestaurantGuid
    || list.restaurant_guid === selectedDeliveryPoint?.restaurant_guid) || {
    products: [],
  }

  return (
    <Row className={cx(s.container, className)} gutter={30}>
      {dishes.map((dish) => {
        const isFavorite = !!favorites.find((item) => item.products_guid === dish.products_guid)
        return (
          <Col span={isMobile ? 24 : 6} key={dish.products_guid}>
            <Box mb="lg" className={cx(s.itemContainer, isMobile && s.itemContainerMobile)}>
              <ProductCard
                in_stop={stop.products.indexOf(dish.products_guid) > -1}
                products_guid={dish.products_guid}
                section_alias={mappedSections[dish.section_guid]}
                alias={dish.alias}
                image={dish.images && dish.images[0]}
                size={dish.size}
                measure_type={dish.measure_type}
                weight={dish.weight}
                bigPrice={dish.price}
                title={dish.title}
                badges={dish.badges}
                isFavorite={isFavorite}
                onLikeClicked={() => onLikeClick(dish)}
                onPriceClicked={() => onPriceClick(dish)}
                onCardClick={onDishClick}
                groups_objects={dish.groups_objects}
              />
            </Box>
          </Col>
        )
      })}
    </Row>
  )
}

export default connect((state) => ({

  favorites: state.app.favorites,
  stopList: state.app.stopList,
  selectedRestaurantGuid: state.app.selectedRestaurantGuid,
  selectedDeliveryPoint: getDeliveryPointById(state.app.selectedDeliveryPointId, state.app.user && state.app.user.addresses
    || [], getRestaurantsFromCities(state.app.cities)),
}))(ProductList)
