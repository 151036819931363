exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3y8dYYuoMY9Vae1I_udX-4 {\n  height: 34px;\n  width: 104px;\n  border-radius: 13px;\n  background: #F7F9F8;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 5px;\n}\n.PAqYAogTtN4iSQWscTjki {\n  background: #2B5E51;\n}\n._422RXCYv35yZZSJK6b0-E {\n  background: #F7F9F8;\n  border-radius: 10px;\n  height: 26px;\n  width: 29px;\n  text-align: center;\n  cursor: pointer;\n}\n._1x1Sz10HYuqqV5mJGsR76J {\n  font-family: 'Circe-Regular', serif;\n  font-size: 16px;\n  line-height: 110%;\n  text-align: center;\n  color: #303231;\n}\n._3J5Oxp9Huc1PDt6vefeiml {\n  color: #fff;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "_3y8dYYuoMY9Vae1I_udX-4",
	"activeContainer": "PAqYAogTtN4iSQWscTjki",
	"btn": "_422RXCYv35yZZSJK6b0-E",
	"count": "_1x1Sz10HYuqqV5mJGsR76J",
	"activeCount": "_3J5Oxp9Huc1PDt6vefeiml"
};