export default (data = {}, microdata) => {
  document.title = data.title || 'Eat Street - Доставка еды и напитков'
  document.querySelector('meta[name="description"]')
    .setAttribute('content', data.seo_desc)
  document.querySelector('meta[name="keywords"]')
    .setAttribute('content', data.seo_keyw)

  const script = document.querySelector('script[type="application/ld+json"]')

  if (script) {
    let json = {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      'url': 'https://eatstreet.ru',
      'name': 'Eatstreet - доставка еды и напитков',
    }

    if (microdata) {
      json = {
        '@context': 'http://schema.org/',
        '@type': 'Product',
        'aggregateRating': {
          '@type': 'AggregateRating',
          'ratingValue': '5.0',
          'bestRating': '5.0',
          'reviewCount': '11',
        },
        'name': microdata.title,
        'description': microdata.description,
        'image': microdata.image || `http://${window.location.host}/NoImage.svg`,
        'url': `https://eatstreet.ru${window.location.pathname}`,
        'offers': {
          '@type': 'Offer',
          'availability': 'https://schema.org/InStock',
          'price': `${microdata.price}`,
          'priceCurrency': 'RUR',
        },
      }
    }

    script.innerText = JSON.stringify(json)
  }
}
