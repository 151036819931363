import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import s from './WidthContainer.module.less'


const WidthContainer = (props) => {
  const {
    children,
    isStretch,
    isNarrow,
    isMedium,
    className: _className,
    ...rest
  } = props

  const className = cx(_className, s.wrapper, {
    [s.isStretch]: isStretch,
    [s.isNarrow]: isNarrow,
    [s.isMedium]: isMedium,
  })

  return (
    <div className={className} {...rest}>
      {children}
    </div>
  )
}

WidthContainer.propTypes = {
  children: PropTypes.any,
  isStretch: PropTypes.bool,
}


export default WidthContainer
