exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2DUzw1zuh_YQeYB6w8s9ro {\n  display: flex;\n  justify-content: space-between;\n  height: 81px;\n  border-radius: 16px;\n  background: linear-gradient(0deg, #F7F9F8, #F7F9F8), #E8E8E8;\n  padding: 16px 21px;\n  align-items: center;\n}\n.i7QaidX2fNkVsO8TxO3fH {\n  background: linear-gradient(0deg, #FFEDE0, #FFEDE0), #E8E8E8;\n}\n._3c8fdpriP7k1orI8LySnC9 {\n  cursor: pointer;\n}\n._6xbvN222y6Q5197xp3D2L {\n  margin-right: 16px;\n}\n._3DaaalKMf8AWv17UOZc7JF {\n  flex-grow: 2;\n  text-align: left;\n}\n._2AtBvOmr7E1mc_F3P2m9Da {\n  display: block;\n  font-family: 'Circe-Regular', serif;\n  font-size: 16px;\n  line-height: 18px;\n  color: #303231;\n}\n._21XLtzADz22tKWLWAxPi2r {\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  max-width: 165px;\n}\n._32LZgQVmR_0Oja121bZOvP {\n  font-family: 'Circe-Light', serif;\n  font-size: 13px;\n  line-height: 16px;\n  color: #9CA092;\n}\n._17Ztcblw0A6cLPsBMo5bt7 {\n  font-family: 'Circe-Regular', sans-serif;\n  font-size: 17px;\n  line-height: 20px;\n  color: #E03F3F;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "_2DUzw1zuh_YQeYB6w8s9ro",
	"isRed": "i7QaidX2fNkVsO8TxO3fH",
	"clickable": "_3c8fdpriP7k1orI8LySnC9",
	"foodIcon": "_6xbvN222y6Q5197xp3D2L",
	"textBlock": "_3DaaalKMf8AWv17UOZc7JF",
	"mainText": "_2AtBvOmr7E1mc_F3P2m9Da",
	"truncatedText": "_21XLtzADz22tKWLWAxPi2r",
	"secondaryText": "_32LZgQVmR_0Oja121bZOvP",
	"warningText": "_17Ztcblw0A6cLPsBMo5bt7"
};