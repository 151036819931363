import React, { useState } from 'react'
import { Input, Layout, Typography } from 'antd'
import cx from 'classnames'
import { compose } from 'redux'

import { useEffect, useRef } from '../../../hooks'
import Box from '../../../components/ui/Box/Box'

import getRestaurantsFromCities from '../../../helpers/getRestaurantsFromCities'

import { isMobileLayout } from '../../../helpers/isMobileLayout'
import { withViewport } from '../../../helpers/withViewport'
import { addAddress, getGeoSuggestions } from '../../../redux/actions'

import s from '../Map.module.less'


const { Sider } = Layout
const { Text } = Typography

const MobileInput = ({ viewport, isInputFocused, setInputFocused, selectedAddress, setSelectedAddress, setInputValue }) => {

  const isMobile = isMobileLayout(viewport)


  const input = useRef(null)
  const [ inputValue, setLocalInputValue ] = useState()
  const [ geoSuggestions, setGeoSuggestions ] = useState([])
  // const [ selectedAddress, setSelectedAddress ] = useState(null)

  useEffect(() => {
    if (isInputFocused && isMobile) {
      window.scrollTo(0, 0)
      setTimeout(() => {
        input.current.focus()
      }, 1)
    }
  }, [ isInputFocused, input ])

  return (
    <Sider
      collapsible
      collapsed={!isInputFocused}
      className={cx(s.fullScreenInputContainer, !isMobile && s.hiddenOnMobile)}
      zeroWidthTriggerStyle
      trigger={null}
      collapsedWidth={0}
      width={isInputFocused && viewport.width}
    >
      <Box pt="sm" pl="xs" pr="xs" className={s.inputContainer}>
        <Box className={s.addressInputMobile}>
          <Input
            ref={input}
            placeholder="Введите адрес"
            value={inputValue}
            onChange={async (val) => {
              setLocalInputValue(val.target.value)
              if (val.target.value) {
                try {
                  const suggestions = await getGeoSuggestions(val.target.value) // debounce
                  if (suggestions) {
                    setGeoSuggestions(suggestions.splice(0, 5))
                  }
                  else {
                    setGeoSuggestions([])
                  }
                }
                catch (e) {
                  console.error(e)
                }
              }
              else {
                setGeoSuggestions([])
              }
            }}
            onPressEnter={() => {
              if (selectedAddress) {
                addAddress(selectedAddress)
                setInputFocused(false)
              }
              else {
                setInputFocused(false)
              }
            }}
          />
        </Box>
        <div className={s.divider} />
        <Box className={s.cancelContainer} onClick={() => setInputFocused(false)}>
          <Text>Отменить</Text>
        </Box>
      </Box>
      {
        geoSuggestions.length > 0 && (
          <div className={s.addressDropdownMobile}>
            {geoSuggestions.map((suggestion, index) => (
              <div
                key={index}
                onClick={() => {
                  setInputValue(suggestion.address)
                  setSelectedAddress(suggestion)
                  setGeoSuggestions([])
                  setInputFocused(false)
                }}>{suggestion.address}
              </div>
            ))}
          </div>
        )
      }
    </Sider>
  )
}

export default compose(
  withViewport,
)(MobileInput)

