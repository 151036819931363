import React from 'react'
import { Typography } from 'antd'

import s from './StatusTag.module.less'
import { statuses } from '../../../helpers/statuses'


const { Text } = Typography


const StatusTag = (props) => {

  const { status } = props

  const statusData = statuses.filter((el) => el.status === status)[0]

  return status ? (
    <div
      className={s.container}
      style={{ background: statusData.color }}
    >
      <Text className={s.text}>{statusData.text}</Text>
    </div>
  ) : null
}

export default StatusTag

