import philadelphia from '../../../components/ui/Image/images/Philadelphia.svg'
import giftPizza from '../../../components/ui/Image/images/GiftPizza.svg'
import discount from '../../../components/ui/Image/images/Discount.svg'


const sales = [
  {
    image: philadelphia,
    title: '2 по цене 1',
    descriptionShort: 'Филадельфия 490 руб. 20-30 ноября',
    description: 'С 20 по 30 ноября купите 2 сет филадельфия по цене одного за 490.',
    conditions: [
      'оформить заказ на сайте eatstreet.ru, в оффлайн корнере по адресу ул. Планетная, 45 или по тел.: +7 (495) 960-12-42',
      'cумма заказа с учетом применения скидок/ промокодов должна быть не менее или равна 1100 ₽',
    ],
    activeTill: '25.12.2020',
    promocode: 'COFFEE-968841',
    isActive: true,
    isDarkText: true,
  },
  {
    image: giftPizza,
    title: 'Пицца в подарок',
    descriptionShort: 'При заказе от 1100 руб.',
    description: 'При заказе от 1100 ₽, в подарок доставим пиццу (на выбор) Маргарита или Пепперони. ',
    conditions: [
      'оформить заказ на сайте eatstreet.ru, в оффлайн корнере по адресу ул. Планетная, 45 или по тел.: +7 (495) 960-12-42',
      'cумма заказа с учетом применения скидок/ промокодов должна быть не менее или равна 1100 ₽',
    ],
    activeTill: '25.12.2020',
    promocode: 'COFFEE-968841',
    isActive: true,
    isDarkText: true,
  },
  {
    image: discount,
    title: 'Скидка 20%',
    descriptionShort: 'По промокоду ES-OPEN',
    description: 'В честь открытия дарим скидку 20% на всё меню!',
    conditions: [
      'оформить заказ на сайте eatstreet.ru, в оффлайн корнере по адресу ул. Планетная, 45 или по тел.: +7 (495) 960-12-42',
      'cумма заказа с учетом применения скидок/ промокодов должна быть не менее или равна 1100 ₽',
    ],
    activeTill: '25.12.2020',
    promocode: 'COFFEE-968841',
    isActive: true,
  },
  {
    image: philadelphia,
    title: '2 по цене 1',
    descriptionShort: 'Филадельфия 490 руб. 20-30 ноября',
    description: 'С 20 по 30 ноября купите 2 сет филадельфия по цене одного за 490.',
    conditions: [
      'оформить заказ на сайте eatstreet.ru, в оффлайн корнере по адресу ул. Планетная, 45 или по тел.: +7 (495) 960-12-42',
      'cумма заказа с учетом применения скидок/ промокодов должна быть не менее или равна 1100 ₽',
    ],
    activeTill: '25.12.2020',
    promocode: 'COFFEE-968841',
    isActive: false,
    isDarkText: true,
  },
  {
    image: giftPizza,
    title: 'Пицца в подарок',
    descriptionShort: 'При заказе от 1100 руб.',
    description: 'При заказе от 1100 ₽, в подарок доставим пиццу (на выбор) Маргарита или Пепперони. ',
    conditions: [
      'оформить заказ на сайте eatstreet.ru, в оффлайн корнере по адресу ул. Планетная, 45 или по тел.: +7 (495) 960-12-42',
      'cумма заказа с учетом применения скидок/ промокодов должна быть не менее или равна 1100 ₽',
    ],
    activeTill: '25.12.2020',
    promocode: 'COFFEE-968841',
    isActive: false,
    isDarkText: true,
  },
  {
    image: discount,
    title: 'Скидка 20%',
    descriptionShort: 'По промокоду ES-OPEN',
    description: 'В честь открытия дарим скидку 20% на всё меню!',
    conditions: [
      'оформить заказ на сайте eatstreet.ru, в оффлайн корнере по адресу ул. Планетная, 45 или по тел.: +7 (495) 960-12-42',
      'cумма заказа с учетом применения скидок/ промокодов должна быть не менее или равна 1100 ₽',
    ],
    activeTill: '25.12.2020',
    promocode: 'COFFEE-968841',
    isActive: false,
  },
]

export default sales
