import React, { useEffect, useRef, useState, useMemo } from 'react'
import { Typography, Input, Row, Spin, Modal } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'
import Button from '../../components/ui/Button/Button'
import s from './SearchModal.module.less'
import WidthContainer from '../../components/layout/WidthContainer/WidthContainer'
import Box from '../../components/ui/Box/Box'
import { useDebouncedCallback } from '../../hooks'
import api from '../../helpers/api'
import request from '../../helpers/request'
import AddressModal from '../AddressModal/AddressModal'
import ProductList from './components/ProductList/ProductList'
import { setBasketItem, setFavorites } from '../../redux/actions'
import links from '../../helpers/links'
import getDeliveryPointById from '../../helpers/getDeliveryPointById'
import getRestaurantsFromCities from '../../helpers/getRestaurantsFromCities'
import NotFoundBlock from '../../components/ui/NotFoundBlock/NotFoundBlock'


const SearchModal = (props) => {

  const {
    sections,
    isMobile,
    isLoggedIn,
    selectedDeliveryPoint,
  } = props

  const history = useHistory()

  const { Text } = Typography
  const [ isAdressModalVisible, setAdressModalVisible ] = useState(false)
  const [ isSearchModalVisible, setIsSearchModalVisible ] = useState(false)
  const [ fetching, setFetching ] = useState(false)
  const [ dishes, setDishes] = useState([])

  const mappedSections = useMemo(() => sections.reduce((accum, section) => {
    accum[section.section_guid] = section.alias
    return accum
  }, {}), [sections])

  const onChangeHandler = useDebouncedCallback(async (e) => {
    if (!e.target.value) {
      setDishes([])
      return
    }

    setFetching(true)
    const response = await request.get(api.search(e.target.value)).catch((e) => {
      console.error(e)
    })
    if (response?.data) {
      const modifiedData = response.data.map((dish) => {
        if (dish.weight === 1) dish.groups_objects = true
        return dish
      })
      setDishes(modifiedData)
    }
    else {
      setDishes(null)
    }
    setFetching(false)

  }, 500, [ setFetching, setDishes ])

  let inputRef = useRef(null)

  const onClose = () => {
    setIsSearchModalVisible(false)
  }

  useEffect(() => {
    if (!isSearchModalVisible) {
      document.body.style = 'overflow: auto; width: 100%'
    }
    else {
      document.body.style = `width: calc(100% - 0px); overflow: hidden;`
    }

  }, [isSearchModalVisible])

  return (
    <>
      <Button
        type="icon"
        icon="searchBig"
        isSmall={isMobile}
        handleClick={() => {
          if (inputRef.current) {
            inputRef.current.state.value = ''
          }
          setDishes([])
          setIsSearchModalVisible(true)
        }}
      />
      <Modal
        visible={isSearchModalVisible}
        width="100%"
        zIndex={isSearchModalVisible ? 10000 : -1}
        className={isSearchModalVisible ? s.modalOpen : cx(s.modalOpen, s.modalClosed)}
        transitionName="none"
        maskTransitionName="none"
        style={{ display: 'block' }}
        onCancel={() => {
          setIsSearchModalVisible(false)
        }}
        maskStyle={{ backgroundColor: 'transparent' }}
        destroyOnClose
        modalRender={() => (
          <div className={s.searchModal}>
            <WidthContainer>
              <Box className={s.searchHeader} mb={isMobile ? 'xsm' : 'xlg'}>
                <Text className={s.searchText}>Поиск</Text>
                <Button
                  type="icon"
                  icon="close"
                  isSmall={isMobile}
                  handleClick={onClose}
                  className={s.closeButton}
                />
              </Box>
              <Box className={s.inputContainer}>
                <Input
                  type="text"
                  ref={(ref) => {
                    if (ref) {
                      ref.focus()
                      inputRef.current = ref
                    }
                  }}
                  placeholder="Найти блюдо"
                  maxLength={255}
                  className={s.input}
                  onChange={onChangeHandler}
                  allowClear
                />
              </Box>
              {fetching && (
                <div className={s.loader}>
                  <Spin spinning />
                </div>
              )}
              {!fetching && dishes && dishes.length > 0 && (
                <ProductList
                  dishes={dishes}
                  isLoading={fetching}
                  isMobile={isMobile}
                  mappedSections={mappedSections}
                  setModalVisible={setAdressModalVisible}
                  className={s.productList}
                  onDishClick={onClose}
                  onLikeClick={(dish) => {
                    if (isLoggedIn) {
                      setFavorites(dish)
                    }
                    else {
                      onClose()
                      history.push(links.login)
                    }
                  }}
                  onPriceClick={(dish) => {
                    if (dish.groups_objects) {
                      onClose()
                      history.push(`${links.catalog}/${mappedSections[dish.section_guid]}/${dish.alias}`)
                    }
                    else if (!selectedDeliveryPoint) {
                      setAdressModalVisible(true)
                    }
                    else {
                      setBasketItem(dish)
                      if (process.env.REACT_APP_API_ENV === 'prod') { ym(95942691,'reachGoal','add-basket')}
                    }
                  }}
                />
              )}
              {!fetching && dishes === null && (
                <Row justify="center">
                  <NotFoundBlock
                    className={s.notFoundBlock}
                    title="Ничего не найдено"
                    subtitle="Попробуйте составить другой запрос"
                  />
                </Row>
              )}
              <AddressModal isModalVisible={isAdressModalVisible} setModalVisible={setAdressModalVisible} />
            </WidthContainer>
          </div>
        )} />
    </>
  )
}

export default connect((state) => ({
  sections: state.app.sections,
  isLoggedIn: state.app.user && state.app.user.type !== 'guest',
  selectedDeliveryPoint: getDeliveryPointById(state.app.selectedDeliveryPointId, state.app.user && state.app.user.addresses
    || [], getRestaurantsFromCities(state.app.cities)),
}))(SearchModal)
