import { createStore, combineReducers } from 'redaction'
import reducers from './_reducers'


const initialState = {}

const store = createStore({
  reducers: {
    ...combineReducers(reducers),
  },
  initialState,
})


export default store
