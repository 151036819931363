import React from 'react'
import cx from 'classnames'
import { Typography } from 'antd'

import Icon from '../Icon'

import s from './CategoryTag.module.less'


const { Text } = Typography

const CategoryTag = (props) => {

  const { isMedium, isSmall, icon, text, onClick, height, className } = props

  const wrapperClassNames = cx(s.container, {
    [s.smallContainer]: isSmall,
    [s.mediumContainer]: isMedium,
    [s.bigContainer]: !isSmall && !isMedium,
  })

  const textClassNames = cx(s.text, {
    [s.textSmall]: isSmall,
    [s.textMedium]: isMedium,
    [s.textBig]: !isSmall && !isMedium,
  })

  return (
    <div className={cx(className, wrapperClassNames)} onClick={onClick}>
      <img src={icon} style={{ height: `${height}px` }} />
      <Text className={textClassNames}>{text}</Text>
    </div>
  )
}

export default CategoryTag

