import React, { Fragment } from 'react'
import { Typography, Row, Col } from 'antd'
import cx from 'classnames'

import Box from '../../../../components/ui/Box/Box'
import Image from '../../../../components/ui/Image'
import Icon from '../../../../components/ui/Icon'
import Button from '../../../../components/ui/Button/Button'
import ProductInfo from '../../../../components/ui/ProductInfo/ProductInfo'
import Increment from '../../../../components/ui/Increment/Increment'

import s from './BasketItemInfo.module.less'


const { Text } = Typography

const BasketItemInfo = (props) => {

  const { item, cutleryItem, isMobile, isCombo, totalWeight, comboItemsAmount, comboComponents, switcher } = props

  const { title, weight, size, modifications } = item || {}

  return (
    <>
      {
        !cutleryItem ? (
          <Box className={s.itemInfo} ml="xsm">
            <Text className={s.title}>{title}</Text>
            {
              !item.products ? (
                size && weight ? (
                  <ProductInfo weight={weight} size={size} />
                ) : (
                  <Text className={s.subText}>{parseInt(weight)} {item.measure_type}</Text>
                )
              ) : ''
            }
            {!isMobile && switcher && Object.keys(switcher).map((key, i) => (
              <Row key={i}>
                {
                  switcher[key].title && (
                    <Col span={16}>
                      <Text className={s.subTextDark}>{switcher[key].title}</Text>
                    </Col>
                  )
                }
              </Row>
            ))}
            {!isMobile && item.products && item.products.map((product, i) => (
              <Row key={i}>
                <Col span={16}>
                  <Text className={s.subTextDark}>{product.title}</Text>
                </Col>
                <Col span={8}>
                  <Text className={s.subText}>{parseInt(product.weight)} {product.measure_type}</Text>
                </Col>
              </Row>
            ))}
            {!isMobile && modifications && modifications.map((modification, i) => (
              <Row key={i}>
                {
                  modification.title && (
                    <Col span={16}>
                      <Text className={s.subTextDark}>{modification.quant} x {modification.title}</Text>
                    </Col>
                  )
                }
                {
                  (modification.quant && modification.price) ? (
                    <Col span={8}>
                      <Text className={s.subText}>{parseInt(modification.quant * modification.price)} ₽</Text>
                    </Col>
                  ) : null
                }
              </Row>
            ))}
          </Box>
        )
          // : isCombo ? (
          // <Box ml={isMobile ? 'sm' : 'md'}>
          //   <Text className={s.title}>{title}</Text>
          //   <ProductInfo totalWeight={totalWeight} comboItemsAmount={comboItemsAmount} />
          //   <Text className={cx(s.subText, s.subTextSmall)}>{(isCombo === 'breakfast') && 'До 12:00 каждый день'}</Text>
          //   {
          //     !isMobile && comboComponents.map((el, i) => (
          //       <Row key={i}>
          //         <Col span={16}>
          //           <Text className={s.subTextDark}>{el.productComponent.title}</Text>
          //         </Col>
          //         <Col span={8}>
          //           <Text className={s.subText}>{parseInt(el.productComponent.weight)} {el.measure_type}</Text>
          //         </Col>
          //       </Row>
          //     ))
          //   }
          // </Box>
        // )
          : (
            <Box className={s.itemInfo} ml="xsm">
              <Text className={s.title}>Количество персон</Text>
              <Text className={cx(s.subText, s.withWidth)}>Одноразовые приборы и салфетки</Text>
            </Box>
          )
      }

    </>
  )
}

export default BasketItemInfo

