exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3jYJNk545rU5BIZkOZJJJC {\n  width: 100%;\n  max-width: 1280px;\n  margin: 0 auto;\n  padding: 0 20px;\n  position: relative;\n}\n@media (max-width: 375px) {\n  ._3jYJNk545rU5BIZkOZJJJC {\n    padding: 0 16px;\n  }\n}\n._1f9um_u1WSLKDOGmAdUfdD {\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n}\n._3lxt0CHz4R2Vxm6I7b5iGR {\n  max-width: 844px;\n}\n._18ovztJ571vGRqCV_mG14s {\n  max-width: 1064px;\n}\n", ""]);

// Exports
exports.locals = {
	"wrapper": "_3jYJNk545rU5BIZkOZJJJC",
	"isStretch": "_1f9um_u1WSLKDOGmAdUfdD",
	"isNarrow": "_3lxt0CHz4R2Vxm6I7b5iGR",
	"isMedium": "_18ovztJ571vGRqCV_mG14s"
};