exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Ie9nX0EcvsrYxjQgwyc81 {\n  background: #F7F9F8;\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n.y4xxwCMlAcXnwYRl51hRj {\n  height: 43px;\n  width: fit-content;\n  flex-direction: row;\n  padding: 11px 12px 11px 12px;\n}\n._35zipQTPOPrCIWk9Y1mYEo {\n  height: 80px;\n}\n._3tB8omHRPZj6OnFmfL0jdo {\n  height: 120px;\n}\n._3m-8CLw8V67hAWWwp08k-L {\n  font-family: 'Circe-Regular', serif;\n}\n._1OsUlcZOEBbsx2-5if-TWF {\n  margin-left: 10px;\n  font-size: 17px;\n}\n.Uyhm7TxOB7BmWyezfBAQG {\n  margin-top: 0;\n}\n._2CbPleB3_KMbsYs-4g34Fx {\n  margin-top: 10px;\n  font-size: 17px;\n  line-height: 17px;\n  text-align: center;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "Ie9nX0EcvsrYxjQgwyc81",
	"smallContainer": "y4xxwCMlAcXnwYRl51hRj",
	"mediumContainer": "_35zipQTPOPrCIWk9Y1mYEo",
	"bigContainer": "_3tB8omHRPZj6OnFmfL0jdo",
	"text": "_3m-8CLw8V67hAWWwp08k-L",
	"textSmall": "_1OsUlcZOEBbsx2-5if-TWF",
	"textMedium": "Uyhm7TxOB7BmWyezfBAQG",
	"textBig": "_2CbPleB3_KMbsYs-4g34Fx"
};