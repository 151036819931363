import React, { useEffect, useState } from 'react'
import { Typography, Row, Col, Checkbox } from 'antd'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'
import { compose } from 'redux'

import Box from '../../components/ui/Box/Box'

import { isMobileLayout, isTabletLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import s from './ContractOffer.module.less'
import setSeoTags from '../../helpers/setSeoTags'


const { Text, Paragraph } = Typography

const ContractOffer = (props) => {

  const { viewport } = props

  useEffect(() => {

    setSeoTags({
      title: `Eat Street - Договор оферты`,
      seo_desc: '',
      seo_keyw: '',
    })
  }, [])

  const isMobile = isMobileLayout(viewport)

  return (
    <Box className={s.container}>
      <Box mt="xlg" alignCenter>
        <Text className={s.title}>Договор оферты</Text>
      </Box>
      <Box mt="md" alignCenter>
        <Text className={s.text}>
          Предложение о заключении сделки, в котором изложены существенные условия договора, адресованное определённому лицу, ограниченному или неограниченному кругу.
        </Text>
      </Box>
      <Box mt={isMobile ? 'md' : 'lg'} alignCenter>
        <Paragraph className={s.paragraph}>Законодательное определение</Paragraph>
        <Text className={s.text}>Согласно пункту 1 статьи 435 Гражданского кодекса РФ офертой признаётся адресованное одному или нескольким конкретным лицам предложение, которое достаточно определённо и выражает намерение лица, сделавшего предложение, считать себя заключившим договор с адресатом, которым будет принято предложение. Оферта должна содержать все существенные условия договора, а также, желательно, иные необходимые для наиболее полного информирования контрагента условия договора.</Text>
      </Box>
      <Box mt={isMobile ? 'md' : 'lg'} alignCenter>
        <Paragraph className={s.paragraph}>Особенности вступления договора в силу</Paragraph>
        <Text className={s.text}>Согласно статьям 440, 441 Гражданского кодекса РФ договор считается заключённым после того, как лицо, направившее оферту, получает согласие (акцепт). Статья 158 Гражданского кодекса Российской Федерации допускает, что согласие на сделку может следовать из поступков стороны, направленных на реализацию сделки (конклюдентные действия), даже если не было явных заявлений о воле принять условия оферты. Например, факт посадки в общественный транспорт свидетельствует о желании ехать и приемлемости описанных условий оказания транспортных услуг (маршрут, цена), что автоматически обязывает пассажира оплатить проезд.</Text>
      </Box>
    </Box>
  )
}

export default compose(
  withViewport,
)(ContractOffer)

