import React, { Fragment } from 'react'
import { Typography, Row, Col, Tooltip } from 'antd'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Box from '../../components/ui/Box/Box'
import Image from '../../components/ui/Image'

import links from '../../helpers/links'
import { isMobileLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import s from './CategoriesGrid.module.less'
import externalLinks from '../../helpers/externalLinks'
import BannerWithButton from '../BannerWithButton/BannerWithButton'


const { Text } = Typography

const CategoriesGridComponent = ({ sections, viewport }) => {

  const isMobile = isMobileLayout(viewport)

  return (
    <Box mt="lg" id="eatmenu">
      <Row className={s.container}>
        {
          sections && sections.filter((el) => el.is_visible && !el.parent_guid).map((item, i) => (
            <Fragment key={i}>
              <Col span={isMobile ? 12 : 6} className={cx(s.wrapper, isMobile && s.mobileWrapper)} key={i}>
                <Box className={s.corners}>
                  <NavLink to={`${links.catalog}/${item.alias}`} style={{ zIndex: 1, textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                    {
                      (isMobile && item.images && item.images[0]) ? (
                        <img
                          src={item.images[0].mobile.preview}
                          className={s.image}
                          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                          srcSet={`${item.images[0].mobile.preview} 1x, ${item.images[0].mobile['preview-x2']} 2x`}
                        />
                      ) : (!isMobile && item.images && item.images[0]) ? (
                        <img
                          src={item.images[0].desktop.preview}
                          className={s.image}
                          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                          srcSet={`${item.images[0].desktop.preview} 1x, ${item.images[0].desktop['preview-x2']} 2x`}
                        />
                      ) : (
                        <Image name="noImage" className={s.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                      )
                    }
                    {
                      !isMobile ? (
                        <Tooltip title={item.title} color="#2B5E51">
                          <Text className={s.text} ellipsis>{item.title}</Text>
                        </Tooltip>
                      ) : (
                        <Text className={s.text} ellipsis>{item.title}</Text>
                      )
                    }
                  </NavLink>
                </Box>
                {/* { */}
                {/*  item.image !== 'drinks' && !isMobile && ( */}
                {/*    <div className={s.shadow} /> */}
                {/*  ) */}
                {/* } */}
              </Col>
            </Fragment>
          ))
        }
        <Col span={isMobile ? 24 : 12} className={s.bannerWrapper}>
          <div className={s.banner}>
            <BannerWithButton
              isMobile={isMobile}
              title="Корпоративное питание"
              subtitle="Планируете мероприятие или хотите накормить всю команду?"
              buttonText="Оставить заявку"
              backgroundImageMobile="businessEatMobile"
              backgroundImageDesktop="businessEatDesktop"
              onClick={() => {
                window.open(externalLinks.businessEat)
              }}
            />
          </div>
        </Col>
      </Row>
    </Box>
  )
}

const CategoriesGrid = compose(
  withViewport,
)(CategoriesGridComponent)

export default connect((state) => ({
  sections: state.app.sections,
}))(CategoriesGrid)

