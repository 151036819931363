import React, { useEffect } from 'react'
import { Carousel, Typography, Menu } from 'antd'
import { NavLink, useParams } from 'react-router-dom'
import cx from 'classnames'

import { compose } from 'redux'
import { connect } from 'react-redux'
import Box from '../../components/ui/Box/Box'
import Button from '../../components/ui/Button/Button'

import links from '../../helpers/links'

import s from './AddressesRow.module.less'
import { setSelectedDeliveryPoint } from '../../redux/actions'
import { isMobileLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'
import getRestaurantsFromCities from '../../helpers/getRestaurantsFromCities'
import getDeliveryPointById from '../../helpers/getDeliveryPointById'
import formatAddress from '../../helpers/formatAddress'



const AddressesRowComponent = ({ user, selectedDeliveryPoint, viewport, restaurants, isLoggedIn, pt, pb }) => {

  const isMobile = isMobileLayout(viewport)

  return (
    <Box center pt={pt || (isMobile ? 'md' : 'lg')} pb={pb || isMobile ? 'md' : ''} className={cx(s.buttonsWrapper, isMobile && s.mobileButtonsWrapper)}>
      {
        (selectedDeliveryPoint)
          ? (
            <>
              <div className={s.addressesContainer}>
                {/* <NavLink to={{ */}
                {/*  pathname: links.map, */}
                {/*  state: { pickup: true }, */}
                {/* }}> */}
                {/*  <Button */}
                {/*    text="Выбрать адрес" */}
                {/*    secondary */}
                {/*    withBoldText */}
                {/*    isSmall={isMobile} */}
                {/*    style={{ marginRight: 20 }} */}
                {/*  /> */}
                {/* </NavLink> */}
                {
                  user.addresses && user.addresses.map((address) => {
                    const selected = address.address_id == (selectedDeliveryPoint && selectedDeliveryPoint.address_id)
                    return (
                      <Box
                        pr={isMobile ? 'xxs' : 'sm'}
                        mb={isMobile ? 'sm' : 'xxs'}
                        key={address.address_id}
                        onClick={() => {
                          setSelectedDeliveryPoint(address)
                        }}>
                        <Button
                          text={formatAddress(address)}
                          withBoldText
                          withEstimatedTime={address.time_delivery && `${address.time_delivery} мин`}
                          estimatedTimeGreen={!selected}
                          icon={selected ? 'mapPinWhite' : null}
                          secondary={!selected}
                          primary={selected}
                          isSmall={isMobile}
                        />
                      </Box>
                    )
                  })
                }
                {
                  restaurants.map((restaurant) => {
                    const selected = restaurant.areas == (selectedDeliveryPoint && selectedDeliveryPoint.areas)
                    if (!selected) {
                      return null
                    }
                    return (
                      <Box
                        pr={isMobile ? 'xxs' : 'sm'}
                        mb={isMobile ? 'sm' : 'xxs'}
                        key={restaurant.restaurant_guid}
                        onClick={() => {
                          setSelectedDeliveryPoint(restaurant)
                        }}>
                        <Button
                          text={`${restaurant.address}`}
                          withBoldText
                          icon={selected ? 'mapPinWhite' : null}
                          secondary={!selected}
                          primary={selected}
                          isSmall={isMobile}
                        />
                      </Box>
                    )
                  })
                }
              </div>
              <div className={s.addButton}>
                <NavLink to={{
                  pathname: links.map,
                }}>
                  <Button
                    type="icon"
                    icon="addAddress"
                    secondary
                    withBoldText
                    isSmall={isMobile}
                  />
                </NavLink>
              </div>
            </>
          ) : (
            <div className={s.buttonsWrapperCentered}>
              <Box pr={isMobile ? 'xxs' : 'sm'} mb={isMobile ? 'sm' : 'xxs'}>
                <NavLink to={links.map}>
                  <Button
                    text="Укажите адрес доставки"
                    icon="mapPinWhite"
                    withBoldText
                    primary
                    isSmall={isMobile}
                  />
                </NavLink>
              </Box>
              <NavLink to={{
                pathname: links.map,
                state: { pickup: true },
              }}>
                <Button
                  text="Или пункт самовывоза"
                  secondary
                  withBoldText
                  isSmall={isMobile}
                />
              </NavLink>
            </div>
          )
      }
    </Box>
  )
}

const AddressesRow = compose(
  withViewport,
)(AddressesRowComponent)

export default connect((state) => ({
  user: state.app.user,
  isLoggedIn: state.app.user && state.app.user.type !== 'guest',
  restaurants: getRestaurantsFromCities(state.app.cities),
  selectedDeliveryPoint: getDeliveryPointById(state.app.selectedDeliveryPointId, state.app.user && state.app.user.addresses || [], getRestaurantsFromCities(state.app.cities)),
}))(AddressesRow)

