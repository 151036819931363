import createLinks from './createLinks'


const links = {
  home: '',
  login: 'login',
  catalog: 'catalog',

  pizza: 'catalog/pizza',

  breakfast: 'breakfast',
  lunch: 'lunch',
  dinner: 'catalog/dinner',
  selectMeal: 'catalog/select-meal',

  basket: 'basket',
  orderForm: 'order-form',

  order: 'order',

  account: 'account',

  favorites: 'account/favorites',
  myData: 'account/my-data',
  myOrders: 'account/my-orders',
  myAddresses: 'account/my-addresses',
  myPromocodes: 'account/my-promocodes',
  bonuses: 'account/bonuses',
  support: 'account/support',
  offer: 'account/offer',

  map: 'map',

  about: 'about',
  delivery: 'delivery',
  sales: 'sales',
  news: 'news',
  blog: 'blog',
  contacts: 'contacts',
  notFound: 'notfound',
}


export default createLinks(links)
