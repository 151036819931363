import italy from './icons/Italy.svg'
import italySmall from './icons/ItalySmall.svg'
import america from './icons/America.svg'
import americaSmall from './icons/AmericaSmall.svg'
import russia from './icons/Russia.svg'
import russiaSmall from './icons/RussiaSmall.svg'
import hawaii from './icons/Hawaii.svg'
import hawaiiSmall from './icons/HawaiiSmall.svg'
import serbia from './icons/Serbia.svg'
import serbiaSmall from './icons/SerbiaSmall.svg'
import belgium from './icons/Belgium.svg'
import belgiumSmall from './icons/BelgiumSmall.svg'
import france from './icons/France.svg'
import franceSmall from './icons/FranceSmall.svg'
import authentic from './icons/Authentic.svg'
import authenticSmall from './icons/AuthenticSmall.svg'
import healthy from './icons/Healthy.svg'
import healthySmall from './icons/HealthySmall.svg'
import vegetarian from './icons/Vegetarian.svg'
import vegetarianSmall from './icons/VegetarianSmall.svg'
import spicy from './icons/Spicy.svg'
import inStop from './icons/InStop.svg'
import plusWhite from './icons/PlusWhite.svg'
import spicySmall from './icons/SpicySmall.svg'
import likeInActiveWhite from './icons/LikeInActiveWhite.svg'
import likeInActive from './icons/LikeInActive.svg'
import likeActive from './icons/LikeActive.svg'
import smallPoint from './icons/SmallPoint.svg'
import deliveryIcon from './icons/DeliveryIcon.svg'
import smallPoint2 from './icons/SmallPoint2.svg'
import left from './icons/Left.svg'
import right from './icons/Right.svg'
import down from './icons/Down.svg'
import close from './icons/Close.svg'
import checkmark from './icons/Checkmark.svg'
import clock from './icons/Clock.svg'
import clockWhite from './icons/ClockWhite.svg'
import burgerMenuIcon from './icons/BurgerMenuIcon.svg'
import account from './icons/Account.svg'
import trash from './icons/Trash.svg'
import mapPin from './icons/MapPin.svg'
import mapPinWhite from './icons/MapPinWhite.svg'
import edit from './icons/Edit.svg'
import logoWithText from './icons/LogoWithText.svg'
import logo from './icons/Logo.svg'
import logoNewYear from './icons/LogoNewYear.svg'
import arrowRightLong from './icons/ArrowRightLong.svg'
import plus from './icons/Plus.svg'
import shoppingTote from './icons/ShoppingTote.svg'
import shoppingToteWhite from './icons/ShoppingToteWhite.svg'
import directions from './icons/Directions.svg'
import deliveryMan from './icons/DeliveryMan.svg'
import appStore from './icons/AppStore.svg'
import googlePlay from './icons/GooglePlay.svg'
import yandexEats from './icons/YandexEats.svg'
import deliveryClub from './icons/DeliveryClub.svg'
import telegram from './icons/Telegram.svg'
import instagram from './icons/Instagram.svg'
import facebook from './icons/Facebook.svg'
import vkontakte from './icons/Vkontakte.svg'
import cutlery from './icons/Cutlery.svg'
import copy from './icons/Copy.svg'
import order from './icons/Order.svg'
import facebookGreen from './icons/FacebookGreen.svg'
import instagramGreen from './icons/InstagramGreen.svg'
import telegramGreen from './icons/TelegramGreen.svg'
import vkontakteGreen from './icons/vkontakteGreen.svg'
import cash from './icons/Cash.svg'
import cardOffline from './icons/CardOffline.svg'
import cardOnline from './icons/CardOnline.svg'
import applePay from './icons/ApplePay.svg'
import fryingPan from './icons/FryingPan.svg'
import shoppingBag from './icons/ShoppingBag.svg'
import asOnThePicture from './icons/asOnThePicture.svg'
import bestQuality from './icons/bestQuality.svg'
import ecology from './icons/ecology.svg'
import fresh from './icons/fresh.svg'
import quality from './icons/quality.svg'
import recepies from './icons/recepies.svg'
import support from './icons/support.svg'
import search from './icons/search.svg'
import searchBig from './icons/SearchBig.svg'
import topRightCorner from './icons/topRightCorner.svg'
import cookHatInCircle from './icons/CookHatInCircle.svg'
import bikeInCircle from './icons/BikeInCircle.svg'
import shrimpInCircle from './icons/ShrimpInCircle.svg'
import checkInCircle from './icons/CheckInCircle.svg'
import pictureInCircle from './icons/PictureInCircle.svg'
import threeDots from './icons/ThreeDots.svg'
import play from './icons/Play.svg'
import currencyInCircle from './icons/CurrencyInCircle.svg'
import onePlusOneInCircle from './icons/OnePlusOneInCircle.svg'
import starInCircle from './icons/StarInCircle.svg'
import arrowUp from './icons/ArrowUp.svg'
import cancel from './icons/Cancel.svg'
import addAddress from './icons/AddAddress.svg'
import youtube from './icons/Youtube.svg'
import tiktok from './icons/Tiktok.svg'
import orangeBasket from './icons/Basket.svg'
import borderConer from './icons/BorderConer.svg'


export default {
  orangeBasket,
  tiktok,
  addAddress,
  italy,
  italySmall,
  youtube,
  america,
  americaSmall,
  russia,
  russiaSmall,
  hawaii,
  hawaiiSmall,
  serbia,
  serbiaSmall,
  belgium,
  belgiumSmall,
  france,
  franceSmall,
  authentic,
  authenticSmall,
  healthy,
  healthySmall,
  vegetarian,
  vegetarianSmall,
  spicy,
  spicySmall,
  likeInActive,
  likeInActiveWhite,
  likeActive,
  smallPoint,
  smallPoint2,
  left,
  right,
  down,
  close,
  checkmark,
  clock,
  clockWhite,
  burgerMenuIcon,
  account,
  trash,
  mapPin,
  mapPinWhite,
  edit,
  logoWithText,
  logo,
  logoNewYear,
  arrowRightLong,
  plus,
  shoppingTote,
  shoppingToteWhite,
  directions,
  deliveryMan,
  appStore,
  googlePlay,
  yandexEats,
  deliveryClub,
  telegram,
  instagram,
  facebook,
  vkontakte,
  cutlery,
  copy,
  order,
  facebookGreen,
  instagramGreen,
  telegramGreen,
  vkontakteGreen,
  cash,
  cardOffline,
  cardOnline,
  applePay,
  fryingPan,
  shoppingBag,
  asOnThePicture,
  bestQuality,
  ecology,
  fresh,
  quality,
  recepies,
  support,
  search,
  searchBig,
  topRightCorner,
  deliveryIcon,
  cookHatInCircle,
  bikeInCircle,
  plusWhite,
  shrimpInCircle,
  checkInCircle,
  inStop,
  pictureInCircle,
  threeDots,
  play,
  currencyInCircle,
  onePlusOneInCircle,
  starInCircle,
  arrowUp,
  cancel,
  borderConer,
}
