import React, { useState } from 'react'
import { Input, Layout, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import { YMaps, Map, Clusterer, Placemark, Polygon, GeolocationControl, SearchControl } from 'react-yandex-maps'
import { connect } from 'react-redux'
import cx from 'classnames'
import { compose } from 'redux'
import { isSafari } from 'react-device-detect'

import { useEffect, useRef } from '../../hooks'
import Button from '../../components/ui/Button/Button'
import Box from '../../components/ui/Box/Box'
import MobileInput from './components/MobileInput'
import AddressSelection from '../../compositions/AddressSelection/AddressSelection'

import pickupPoints from '../../compositions/AddressSelection/util/pickupPoints'

import useLocalStorage from '../../hooks/useLocalStorage'
import getRestaurantsFromCities from '../../helpers/getRestaurantsFromCities'

import s from './Map.module.less'

import placemark from './images/placemark.svg'
import placemarkInactive from './images/placemark_inactive.svg'
import { isMobileLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'
import {
  addAddress,
  getGeoSuggestions,
  getAddressByCoords,
  setSelectedDeliveryPoint,
} from '../../redux/actions'
import setSeoTags from '../../helpers/setSeoTags'


const { Content, Sider } = Layout

const MapComponent = ({ restaurants, user, selectedDeliveryPointId, cachedAddresses, viewport }) => {

  const history = useHistory()
  const isMobile = isMobileLayout(viewport)

  const map = useRef(null)
  const [ isSiderCollapsed, setSiderCollapsed ] = useState(false)

  const [ selectedTab, setSelectedTab ] = useState(history.location.state && history.location.state.pickup ? 1 : 0)
  const [ isInputFocused, setInputFocused ] = useState(false)

  const [ selectedAddress, setSelectedAddress ] = useState(null)
  const [ inputValue, setInputValue ] = useState('')

  const [ coords, setCoords ] = useState([])


  const childRef = useRef(null)

  const clickOnMap = (event) => {
      getAddressByCoords(event.get('coords')).then((r) => {
        childRef && childRef.current && childRef.current.setInputAddress(r[0])
      }).catch((e) => {})
  }


  useEffect(() => {
    setSeoTags({
      title: `Eat Street - Карта`,
      seo_desc: '',
      seo_keyw: '',
    })

    navigator.geolocation.getCurrentPosition((r) => {
      const { latitude, longitude } = r.coords

      // fetch(`http://api-eatstreet.yami.su/api/geo/coords?lat=${latitude.toFixed(6)}&lng=${longitude.toFixed(6)}`, {
      //   method: 'GET',
      //   headers: {
      //     'api-agent': 'eatstreet',
      //     'Conception-Guid': 'fe470000-906b-0025-d2a7-08d89d1803ef',
      //     'content-type': 'application/json',
      //   },
      // }).then((r) => r.json()).then((r) => {
      //   console.log(r)
      // })
    })
  }, [])

  let polygons = []
  let disabled_polygons = []

  restaurants.forEach((restaurant) => {
    restaurant.areas.forEach((area) => {
      area.map_area.features.forEach((feature) => {
        if (restaurant.status) {
          polygons.push(feature.geometry.coordinates)
        }
        else {
          disabled_polygons.push(feature.geometry.coordinates)
        }
      })
    })
  })


  return (
    <Layout className={s.layout}>
      <Sider
        collapsible
        collapsed={isSiderCollapsed}
        trigger={null}
        width={375}
        className={cx(s.leftSider, isSafari && s.isSafariLeftSider)}
      >
        <AddressSelection
          handleSelect={setSelectedTab}
          ref={childRef}
          map={map}
          setInputFocused={setInputFocused}
          selectedAddress={selectedAddress || {
            address_object: {},
          }}
          setSelectedAddress={setSelectedAddress}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      </Sider>
      <Content>
        <YMaps>
          <div style={{ position: 'relative' }}>
            <Map
              instanceRef={(inst) => {
                if (inst) {
                  map.current = inst
                }
              }}
              onClick={clickOnMap}
              defaultState={{ center: [55.75, 37.57], zoom: 12 }}
              modules={['layout.ImageWithContent', 'geoObject.addon.balloon', 'geoObject.addon.hint']}
              style={{ position: 'absolute', width: '100vw', height: '100vh', zIndex: 1 }}>

              {selectedTab === 1 && restaurants && restaurants.map((point, index) => (
                <Placemark
                  key={index}
                  onClick={() => {
                    setSelectedDeliveryPoint(point)
                  }}
                  options={{
                    iconLayout: 'default#image',
                    iconImageHref: selectedDeliveryPointId == point.restaurant_guid ? placemark : placemarkInactive,
                  }}
                  geometry={point.coords}
                />
              ))}

              {selectedTab === 0 && user && user.addresses && user.addresses.map((address, index) => (
                <Placemark
                  key={index}
                  onClick={() => {
                    setSelectedDeliveryPoint(address)
                  }}
                  options={{
                    iconLayout: 'default#image',
                    iconImageHref: selectedDeliveryPointId == address.address_id ? placemark : placemarkInactive,
                  }}
                  geometry={address.coords}
                />
              ))}

              {selectedTab === 0 && !user && cachedAddresses && cachedAddresses.map((address, index) => (
                <Placemark
                  key={index}
                  options={{
                    iconLayout: 'default#image',
                    iconImageHref: selectedDeliveryPointId == address.address_id ? placemark : placemarkInactive,
                    hintContent: 'Это хинт',
                    balloonContent: 'Это балун',
                  }}
                  geometry={address.coords}
                />
              ))}

              {selectedTab === 0 && polygons.map((polygon, index) => (
                <Polygon
                  key={index}
                  geometry={[ polygon ]}
                  options={{ fillColor: 'rgba(249, 196, 94, 0.3)', strokeColor: '#F9C45E', strokeWidth: 3, interactivityModel: 'default#transparent' }}
                />
              ))}

              {selectedTab === 0 && disabled_polygons.map((polygon, index) => (
                <Polygon
                  key={index}
                  geometry={[ polygon ]}
                  options={{ fillColor: 'rgba(158, 158, 158, 0.3)', strokeColor: '#979797', strokeWidth: 3, interactivityModel: 'default#transparent' }}
                />
              ))}

              {/* <GeolocationControl options={{ float: 'left' }} /> */}
              {/* <SearchControl options={{ float: 'right' }} /> */}
            </Map>
          </div>
        </YMaps>
      </Content>
      <Sider width={48} className={s.rightSider}>
        <Button type="icon" circle icon="close" handleClick={() => history.goBack()} />
      </Sider>
      <MobileInput
        isInputFocused={isInputFocused}
        setInputFocused={setInputFocused}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        inputValue={inputValue}
        setInputValue={setInputValue}
      />
    </Layout>
  )
}

const MapComponentComposed = compose(
  withViewport,
)(MapComponent)

export default connect((state) => ({
  restaurants: getRestaurantsFromCities(state.app.cities),
  user: state.app.user,
  cachedAddresses: state.app.cachedAddresses,
  selectedDeliveryPointId: state.app.selectedDeliveryPointId,
}))(MapComponentComposed)
