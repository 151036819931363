import React, { Fragment, useState } from 'react'
import { Field } from 'react-final-form'
import { Col, Typography, Modal } from 'antd'
import cx from 'classnames'
import { compose } from 'redux'
import moment from 'moment'
import 'moment/locale/ru'

import { isMobileLayout, isTabletLayout } from '../../../../helpers/isMobileLayout'
import { withViewport } from '../../../../helpers/withViewport'

import s from './CustomSelect.module.less'
import { useEffect, useRef } from '../../../../hooks'


const { Text } = Typography


export default (props) => {


  const [ isDropdownVisible, setIsDropdownVisible ] = useState(false)
  const { label, value, values, setValue, customDropdown, customDropdownStyle } = props

  const wrapperRef = useRef(null)


  useEffect(() => {
    const handleClickOutside = (e) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsDropdownVisible(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => document.removeEventListener('click', handleClickOutside)
  }, [])

  return (
    <div
      className={cx(s.fieldContainer, s.greenContainer, s.dateInput)}
      onClick={() => {
        setIsDropdownVisible(!isDropdownVisible)
      }}
      ref={wrapperRef}
    >
      <Text className={s.smallText}>{label}</Text>
      {value}

      {isDropdownVisible && (
        <div
          className={s.dropdown}
          style={customDropdownStyle}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            if (e.target.className.indexOf('react-calendar__tile') > -1 || e.target.parentNode.className.indexOf('react-calendar__tile') > -1) {
              setTimeout(() => {
                setIsDropdownVisible(false)
              }, 10)
            }
          }}>
          {customDropdown}
          {!customDropdown && values.map((val, index) => (
            <div
              key={index}
              className={s.dropdownRow}
              onClick={() => {
                setValue(val)
                setIsDropdownVisible(false)
              }}>
              {val}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
