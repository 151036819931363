import React, { useState, useEffect } from 'react'
import { Col, Layout, Row, Typography } from 'antd'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'

import Box from 'components/ui/Box/Box'
import { connect } from 'react-redux'
import WidthContainer from '../../components/layout/WidthContainer/WidthContainer'
import ProductCard from '../ProductCard/ProductCard'

import useLocalStorage from '../../hooks/useLocalStorage'

import s from './Favorites.module.less'
import getRestaurantsFromCities from '../../helpers/getRestaurantsFromCities'
import { setBasketItem, setFavorites } from '../../redux/actions'
import BasketButton from '../../components/ui/BasketButton/BasketButton'
import links from '../../helpers/links'
import AddressModal from '../AddressModal/AddressModal'
import Button from '../../components/ui/Button/Button'
import getDeliveryPointById from '../../helpers/getDeliveryPointById'
import Image from '../../components/ui/Image'
import setSeoTags from '../../helpers/setSeoTags'


const { Content } = Layout
const { Text } = Typography

const Favorites = ({ favorites, sections, isLoggedIn, user, selectedDeliveryPoint }) => {

  const isMobile = window.matchMedia('(max-width: 768px)').matches

  const [ isModalVisible, setModalVisible ] = useState(false)

  useEffect(() => {
    setSeoTags({
      title: `Eat Street - Избранное`,
      seo_desc: '',
      seo_keyw: '',
    })
  }, [])

  const history = useHistory()

  return (
    <WidthContainer>
      <Layout>
        <BasketButton />
        <AddressModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} />
        <Content>
          <Box mt="xlg" alignCenter>
            <Text className={s.title}>Избранное</Text>
          </Box>
          {favorites.length === 0 && (
            <>
              <Box mt="xlg" alignCenter>
                <Image name="hurt" />
              </Box>
              <Box mt="md" alignCenter>
                <Text className={s.subText}>Добавляйте любимые блюда в избранное<br />
                  для быстрого доступа к ним!
                </Text>
              </Box>

              <Box mt="md" alignCenter>
                <Button
                  text="В меню"
                  primary
                  className={s.button}
                  handleClick={() => {
                    history.push('/')
                  }} />
              </Box>
            </>
          )}
          {
            sections.map((section) => {
              const itemsForSection = favorites.filter((item) => item.section_guid === section.section_guid)

              if (itemsForSection.length > 0) {
                return (
                  <div style={{ marginBottom: 40 }}>
                    <Text style={{ fontSize: 26, fontWeight: 'bold' }}>{section.title}</Text>
                    <Row>
                      {itemsForSection.map((el) => (
                        <Col span={isMobile ? 24 : 6} key={el.products_guid}>
                          <Box
                            mb="lg"
                            className={cx(s.itemContainer,
                              isMobile && s.itemContainerMobile)}>
                            {/* { */}
                            {/*  !isMobile && ( */}
                            {/*    <div className={s.shadow} /> */}
                            {/*  ) */}
                            {/* } */}
                            <ProductCard
                              id={el.products_guid}
                              products_guid={el.products_guid}
                              alias={el.alias}
                              section_alias={section ? section.alias : ''}
                              image={el.images && el.images[0]}
                              size={el.size}
                              weight={el.weight}
                              bigPrice={el.price}
                              measure_type={el.measure_type}
                              title={el.title}
                              badges={el.badges}
                              isFavorite
                              onPriceClicked={() => {
                                if (!selectedDeliveryPoint) {
                                  setModalVisible(true)
                                }
                                else {
                                  setBasketItem(el)
                                  if (process.env.REACT_APP_API_ENV === 'prod') { ym(95942691,'reachGoal','add-basket')}
                                }
                              }}
                              onLikeClicked={() => setFavorites(el)}
                            />
                          </Box>
                        </Col>
                      ))}
                    </Row>
                  </div>
                )
              }
              return null

            })
          }
        </Content>
      </Layout>
    </WidthContainer>
  )
}


export default connect((state) => ({
  favorites: state.app.favorites,
  sections: state.app.sections,
  user: state.app.user,
  isLoggedIn: state.app.user && state.app.user.type !== 'guest',
  selectedDeliveryPoint: getDeliveryPointById(state.app.selectedDeliveryPointId, state.app.user && state.app.user.addresses || [], getRestaurantsFromCities(state.app.cities)),
}))(Favorites)
