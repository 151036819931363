const _createLinks = (links, rootName) => {
  const linkStart = rootName ? `${rootName}/` : '/'

  let _links = {}

  Object.keys(links).forEach((linkItemKey) => {
    const linkItem = links[linkItemKey]

    if (typeof linkItem === 'object') {
      const { root = linkItemKey, ...restLinks } = linkItem

      const _root = `${linkStart}${root}`

      _links = {
        ..._links,
        [linkItemKey]: {
          root: _root,
          ..._createLinks(restLinks, _root),
        },
      }
    }

    if (typeof linkItem === 'string') {
      const link = `${linkStart}${linkItem}`

      _links = {
        ..._links,
        [linkItemKey]: link,
      }
    }
  })

  return _links
}

const createLinks = (links) => _createLinks(links)


export default createLinks
