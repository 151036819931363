import React, { Component as ReactComponent } from 'react'
import throttle from 'lodash/throttle'

/**
 * A higher order component (HOC) that provides the current viewport
 * dimensions to the wrapped component as a `viewport` prop that has
 * the shape `{ width: 600, height: 400}`.
 */
export const withViewport = (Component) => {
  // The resize event is flooded when the browser is resized. We'll
  // use a small timeout to throttle changing the viewport since it
  // will trigger rerendering.
  const WAIT_MS = 100

  class WithViewportComponent extends ReactComponent {
    constructor(props) {
      super(props)
      this.state = { width: 0, height: 0 }
      this.handleWindowResize = this.handleWindowResize.bind(this)
      this.setViewport = throttle(this.setViewport.bind(this), WAIT_MS)
    }
    componentDidMount() {
      this.setViewport()
      window.addEventListener('resize', this.handleWindowResize)
      window.addEventListener('orientationchange', this.handleWindowResize)
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowResize)
      window.removeEventListener('orientationchange', this.handleWindowResize)
    }
    handleWindowResize() {
      this.setViewport()
    }
    setViewport() {
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    render() {
      const viewport = this.state
      const props = { ...this.props, viewport }
      return <Component {...props} />
    }
  }

  WithViewportComponent.displayName = `withViewport(${Component.displayName || Component.name})`

  return WithViewportComponent
}
