import React from 'react'
import { Typography, Col } from 'antd'
import cx from 'classnames'

import Image from '../Image'

import s from './OfferCard.module.less'


const { Paragraph, Text } = Typography

const OfferCard = (props) => {

  const { image, mainText, secondaryText, isDarkText, handleClick, alias, isMobile } = props

  if (!image) {
    return null
  }

  return (
    <Col
      span={23}
      className={s.offerContainer}
      onClick={() => {
        handleClick(alias)
      }}>
      {
        isMobile ? (
          <img
            src={image.mobile.preview}
            className={s.brgImage}
            srcSet={`${image.mobile.preview} 1x, ${image.mobile['preview-x2']} 2x`}
          />
        ) : (
          <img
            src={image.desktop.preview}
            className={s.brgImage}
            srcSet={`${image.desktop.preview} 1x, ${image.desktop['preview-x2']} 2x`}
          />
        )
      }
      {/* <div className={s.textBlock}> */}
      {/*  <Paragraph className={cx(s.mainText, isDarkText && s.darkText)}>{mainText}</Paragraph> */}
      {/*  <Text className={cx(s.secondaryText, isDarkText && s.darkText)}>{secondaryText}</Text> */}
      {/* </div> */}
    </Col>
  )
}

export default OfferCard

