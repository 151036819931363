const dev = {
  // api: `http://test.eatstreet.ru:8000`,
  api: `${process.env.REACT_APP_API_URL}/`,
}

const prod = {
  api: `https://eatstreet.ru:8000/`,
}

const configs = { prod, dev }

const config = configs[process.env.REACT_APP_API_ENV || 'dev']


// export default config

export default {
  api: `${process.env.REACT_APP_API_URL}/`,
}
