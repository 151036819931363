import React, { Fragment, useState } from 'react'
import { Typography, Input, Checkbox, Slider, Row, Col } from 'antd'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Box from '../../components/ui/Box/Box'
import Button from '../../components/ui/Button/Button'
import links from '../../helpers/links'
import { isMobileLayout, isTabletMiddleLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'
import s from './MakeOrderCard.module.less'
import getTotalOrderPrice from '../../helpers/getTotalOrderPrice'
import getDishDeclension from '../../helpers/getDishDeclension'
import getDeliveryPointById from '../../helpers/getDeliveryPointById'
import getRestaurantsFromCities from '../../helpers/getRestaurantsFromCities'


const { Text } = Typography

const MakeOrderCardComponent = ({ viewport, totalOrderPrice, basketItems, selectedDeliveryPoint }) => {
  const history = useHistory()
  const isMobile = isMobileLayout(viewport)
  const isTabletMiddle = isTabletMiddleLayout(viewport)

  // const points = 100
  // const [ value, setValue ] = useState(0)
  // const [ amountMin, setAmountMin ] = useState(0)

  const totalItems = basketItems.reduce((r, a) => r + a.count, 0)
  const freeDeliveryAmount = selectedDeliveryPoint.order_sum_for_free_delivery - totalOrderPrice

  return (
    <div className={s.container}>
      <Box mt={isMobile ? 'xxl' : ''} p={isTabletMiddle ? 'md' : 'lg'} className={s.orderContainer}>
        <Box className={s.textContainer}>
          <Text className={s.darkText}>Заказ</Text>
          <Text className={s.largeText}>{totalOrderPrice} ₽</Text>
        </Box>
        {/* <Box mt='sm' className={s.inputContainer}> */}
        {/*  <Input placeholder='Промокод' bordered={false} /> */}
        {/*  <Button text='Применить' secondary isSmall className={s.applyBtn} /> */}
        {/* </Box> */}
        <Box mt="md" mb={isMobile ? 'xlg' : 'lg'} className={s.rows}>
          <Row className={isMobile ? s.orderRowMobile : s.totalPriseBox}>
            <Col span={18}>
              <Text className={s.thinText}>{getDishDeclension(totalItems)}</Text>
            </Col>
            <Col span={6}>
              <Text
                className={s.darkTextSmall}>{totalOrderPrice} ₽
              </Text>
            </Col>
          </Row>
          <Row className={isMobile && s.orderRowMobile}>
            <Col span={18}>
              <Text className={s.thinText}>Минимальная сумма заказа</Text>
            </Col>
            <Col span={6}>
              <Text className={s.darkTextSmall}>{selectedDeliveryPoint.amount_min || 0} ₽</Text>
            </Col>
          </Row>
          {/* Отключено в рамках задачи EatStreet-293 */}
          {/* <Row className={isMobile && s.orderRowMobile}>
            <Col span={18}>
              <Text className={s.thinText}>{freeDeliveryAmount > 0 ? 'До бесплатной доставки' : 'Доставка'} </Text>
            </Col>
            <Col span={6}>
              <Text className={s.darkTextSmall}>{freeDeliveryAmount > 0 ? Math.ceil(freeDeliveryAmount) : 0} ₽</Text>
            </Col>
          </Row> */}
        </Box>
        <Box mt="sm" className={s.btnContainer}>
          <Button
            primary
            text="К оформлению"
            isSmall
            disabled={basketItems.length === 0}
            handleClick={() => {
              if (window.gtag) {
                window.gtag('event', '_clickkoformleniu_')
              }
              if (selectedDeliveryPoint) {
                history.push(links.orderForm)
              }
              else {
                history.push(links.map)
              }
            }} />
        </Box>
        <Box mt="sm">
          <span
            className={s.smallText}>Итоговая сумма с учетом скидок, баллов
            и доставки рассчитывается далее
          </span>
        </Box>
      </Box>
    </div>
  )
}

const MakeOrderCard = compose(
  withViewport,
)(MakeOrderCardComponent)

export default connect((state) => ({
  basketItems: state.app.basketItems,
  totalOrderPrice: getTotalOrderPrice(state.app.basketItems),
  selectedDeliveryPoint: getDeliveryPointById(state.app.selectedDeliveryPointId, state.app.user && state.app.user.addresses || [], getRestaurantsFromCities(state.app.cities)),
}))(MakeOrderCard)
