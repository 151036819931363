import React, { useEffect, useMemo } from 'react'
import { Layout, Row, Col, Typography, Input } from 'antd'
import cx from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Box from 'components/ui/Box/Box'
import Button from 'components/ui/Button/Button'
import { Redirect, useHistory } from 'react-router-dom'
import WidthContainer from '../../components/layout/WidthContainer/WidthContainer'
import BasketList from '../../compositions/BasketList/BasketList'
import Recommendations from '../../compositions/Recommendations/Recommendations'
import MakeOrderCard from '../../compositions/MakeOrderCard/MakeOrderCard'

import { withViewport } from '../../helpers/withViewport'
import { isMobileLayout, isTabletMiddleLayout } from '../../helpers/isMobileLayout'

import s from './Basket.module.less'
import { clearBasket } from '../../redux/actions'
import setSeoTags from '../../helpers/setSeoTags'


const { Text } = Typography
const { Content } = Layout

const BasketComponent = ({ basketItems, viewport, sections, stopList, selectedRestaurantGuid, isLoggedIn, recommendations }) => {

  const isMobile = isMobileLayout(viewport)
  const isTabletMiddle = isTabletMiddleLayout(viewport)
  const history = useHistory()

  const mappedSections = useMemo(() => sections.reduce((accum, section) => {
    accum[section.section_guid] = section.alias
    return accum
  }, {}), [sections])

  const filtredRecommendations = useMemo(() => {
    const basketItemsSet = basketItems.reduce((accum, item) => {
      accum.add(item.products_guid)
      return accum
    }, new Set())

    const stopListInCurrentRestaurant = stopList.find((list) => list.restaurant_guid === selectedRestaurantGuid)

    const stopProductsSet = stopListInCurrentRestaurant.products.reduce((accum, item) => {
      accum.add(item)
      return accum
    }, new Set())

    return recommendations.filter((dish) => !basketItemsSet.has(dish.products_guid) && !stopProductsSet.has(dish.products_guid))
  }, [basketItems, stopList, selectedRestaurantGuid, recommendations])

  useEffect(() => {
    setSeoTags({
      title: `Eat Street - Корзина`,
      seo_desc: '',
      seo_keyw: '',
    })
  }, [])

  // if (!isLoggedIn) {
  //   return <Redirect to={links.login} />
  // }

  return (
    <WidthContainer>
      <Layout>
        <Content>
          <Box pt={isMobile ? 'sm' : ''} pb={isMobile ? 'xxxl' : ''} mb={isMobile ? 'xlg' : ''}>
            {
              isMobile && (
                <Box mb="md" className={s.topMobileRow}>
                  <Button
                    type="icon"
                    icon="down"
                    isSmall
                    onClick={() => {
                      history.push('/')
                    }} />
                  <Text onClick={clearBasket}>Очистить</Text>
                </Box>
              )
            }
            <Row>
              <Col span={isTabletMiddle ? 24 : 17}>
                <BasketList />
                {filtredRecommendations && filtredRecommendations.length > 0
                 && <Recommendations mappedSections={mappedSections} recommendations={isMobile ? filtredRecommendations.slice(0, 19) : filtredRecommendations.slice(0, 50)} />}
              </Col>
              <Col span={isTabletMiddle ? 24 : 7}>
                {
                  !isTabletMiddle && (
                    <Box mt="md" mb="md" alignRight>
                      <Text className={s.emptyBasket} onClick={clearBasket}>Очистить корзину</Text>
                    </Box>
                  )
                }
                <MakeOrderCard />
              </Col>
            </Row>
          </Box>
        </Content>
      </Layout>
    </WidthContainer>
  )
}

const Basket = compose(
  withViewport,
)(BasketComponent)

export default connect((state) => ({
  basketItems: state.app.basketItems,
  stopList: state.app.stopList,
  selectedRestaurantGuid: state.app.selectedRestaurantGuid,
  sections: state.app.sections,
  isLoggedIn: state.app.user && state.app.user.type !== 'guest',
  recommendations: state.app.recommendations,
}))(Basket)
