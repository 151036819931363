import React, { useEffect, useState, Fragment } from 'react'
import { Layout, Spin } from 'antd'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import WidthContainer from '../../components/layout/WidthContainer/WidthContainer'
import CatalogGrid from '../../compositions/CatalogGrid/CatalogGrid'
import AddressModal from '../../compositions/AddressModal/AddressModal'
import getTotalOrderPrice from '../../helpers/getTotalOrderPrice'
import { isMobileLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'
import useLocalStorage from '../../hooks/useLocalStorage'
import { setCatalogProducts, setCatalogProductsByLabel } from '../../redux/actions'
import s from './Catalog.module.less'
import BasketButton from '../../components/ui/BasketButton/BasketButton'
import AddressesRow from '../../compositions/AddressesRow/AddressesRow'
import setSeoTags from '../../helpers/setSeoTags'
import CategoriesCarouselMini from '../../compositions/CategoriesCarouselMini/CategoriesCarouselMini'


const { Content } = Layout

const CatalogComponent = ({ sections, labels, viewport, basketItems, totalOrderPrice, user, catalogProducts }) => {

  const [ isModalVisible, setModalVisible ] = useState(false)
  const [ data, setData ] = useState([])
  const [ section, setSection ] = useState(null)
  const [ subCategories, setSubCategories ] = useState([])

  const [ isLoading, setIsLoading ] = useState(null)

  const isMobile = isMobileLayout(viewport)

  const [ storedAddress, setAddress ] = useLocalStorage('address', {}, true)

  const params = useParams()

  useEffect(() => {
    setIsLoading(true)
    let section = sections.find((section) => section.alias === params.alias)

    if (section) {
      setSubCategories(sections.filter((el) => el.parent_guid === section.section_guid))
    }
    else {
      section = labels.find((label) => label.alias === params.alias)
      setSubCategories([])
    }

    if (section) {
      setSection(section)
      setSeoTags({
        title: section.seo_title,
        seo_desc: section.seo_desc,
        seo_keyw: section.seo_keyw,
      })

      if (section.section_guid) {
        setCatalogProducts(section.section_guid)
          .then((data) => setData(data))
          .finally(() => setIsLoading(false))
      }
      else {
        setCatalogProductsByLabel(section.label_guid)
          .then((data) => setData(data))
          .finally(() => setIsLoading(false))
      }
    }
  }, [ params, setData, sections, setSection ])


  return (
    <WidthContainer>
      <Layout style={{ overflow: 'hidden' }}>
        <Content>
          {
            isLoading ? (
              <div className={s.loader}>
                <Spin spinning={isLoading} />
              </div>
            ) : (
              <>
                <CategoriesCarouselMini />
                <AddressesRow />
                <CatalogGrid setModalVisible={setModalVisible} data={data} section={section} subCategories={subCategories} />
                <AddressModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} />
                <BasketButton />
              </>
            )
          }
        </Content>
      </Layout>
    </WidthContainer>
  )
}

const Catalog = compose(
  withViewport,
)(CatalogComponent)

export default connect((state) => ({
  sections: state.app.sections,
  labels: state.app.labels,
  basketItems: state.app.basketItems,
  user: state.app.user,
  totalOrderPrice: getTotalOrderPrice(state.app.basketItems),
  catalogProducts: state.app.catalogProducts,
}))(Catalog)

