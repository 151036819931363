exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".SzR5Hm9fMxsVEUhJKHMks {\n  max-width: 800px;\n  margin: 0 auto 36px auto;\n}\n._2-kqzgT0YaCxMNPcvAYZx7 {\n  font-family: 'Circe-Bold', sans-serif;\n  font-size: 32px;\n  line-height: 38px;\n  text-align: center;\n  color: #3C3C3C;\n}\n@media (min-width: 769px) {\n  ._2-kqzgT0YaCxMNPcvAYZx7 {\n    font-size: 40px;\n    line-height: 59px;\n  }\n}\n._1PR2LJtMfy2MiBU2MS3ZyK {\n  font-family: 'Circe-Bold', sans-serif;\n  font-size: 16px;\n  line-height: 18px;\n  text-align: center;\n  color: #303231;\n  width: 300px;\n}\n@media (min-width: 769px) {\n  ._1PR2LJtMfy2MiBU2MS3ZyK {\n    font-size: 24px;\n    line-height: 35px;\n  }\n}\n._1ietD8G2QPg5NojdpWe3it {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n}\n@media (min-width: 769px) {\n  ._1ietD8G2QPg5NojdpWe3it {\n    flex-direction: row;\n    align-items: flex-start;\n    justify-content: space-between;\n  }\n}\n._3zawArW7y39AZV5x6b4oCO {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 200px;\n  margin-bottom: 60px;\n}\n@media (min-width: 769px) {\n  ._3zawArW7y39AZV5x6b4oCO {\n    margin-bottom: 0;\n  }\n}\n._3zawArW7y39AZV5x6b4oCO span {\n  font-family: 'Circe-Regular', sans-serif;\n  font-size: 22px;\n  line-height: 32px;\n  text-align: center;\n  color: #3C3C3C;\n}\n._3zawArW7y39AZV5x6b4oCO div {\n  display: flex;\n}\n._2FXj1jx23HMSmyEpynH1V9 {\n  width: 100%;\n  max-width: 630px;\n  background: #F7F9F8;\n  border-radius: 12px;\n  margin-bottom: 20px;\n}\n._3kHytP7ntghpXiG-Ec-pJ_ {\n  font-family: 'Circe-Bold', sans-serif;\n  font-size: 22px;\n  line-height: 32px;\n  color: #3C3C3C;\n}\n._3uZnbjng6NB_zPhkMeUDOA {\n  font-family: 'SF Pro Display', sans-serif;\n  font-size: 14px;\n  line-height: 17px;\n  color: #303231;\n  margin-bottom: 10px;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "SzR5Hm9fMxsVEUhJKHMks",
	"title": "_2-kqzgT0YaCxMNPcvAYZx7",
	"paragraph": "_1PR2LJtMfy2MiBU2MS3ZyK",
	"imagesContainer": "_1ietD8G2QPg5NojdpWe3it",
	"imageItemContainer": "_3zawArW7y39AZV5x6b4oCO",
	"itemContainer": "_2FXj1jx23HMSmyEpynH1V9",
	"text": "_3kHytP7ntghpXiG-Ec-pJ_",
	"subText": "_3uZnbjng6NB_zPhkMeUDOA"
};