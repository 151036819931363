import React, { useState, Fragment } from 'react'
import { Typography, Row, Col } from 'antd'
import cx from 'classnames'

import { connect } from 'react-redux'
import Box from '../../../components/ui/Box/Box'
import ExtraFoodImage from '../../../components/ui/ExtraFoodImage/ExtraFoodImage'
import ProductInfo from '../../../components/ui/ProductInfo/ProductInfo'
import Increment from '../../../components/ui/Increment/Increment'

import s from './ToppingCard.module.less'
import { setBasketItem, setModifications, setProductModifications } from '../../../redux/actions'


const { Text } = Typography

const ToppingCard = ({ basketItems, item, product, basketModification, modifications, isMobile, maxTotal, total }) => {

  const { component_guid, title, weight, price, images, measure_type, disabled } = item

  const modification = modifications.find((m) => m.component_guid === component_guid)


  return item ? (
    <Box mt="xsm" mb="xsm">
      <Row className={disabled ? s.disabled : s.container}>
        {images && images[0] && (
          <Col span={4}>
            <img src={images[0].desktop['preview-x2']} style={{ width: '100%' }} />
          </Col>
        )}
        <Col span={12}>
          <Box ml={isMobile ? '' : 'sm'} className={s.toppingInfo}>
            <Text>{title}</Text>
            <ProductInfo weight={weight} price={price} measure_type={measure_type} />
          </Box>
        </Col>
        <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Increment
            total={total}
            maxTotal={maxTotal}
            max={item.amount_max}
            min={item.amount_min}
            defaultCount={modification ? modification.quant : item.amount_default}
            handleChange={(dec) => {
              setProductModifications(product, item, dec)
            }} />
        </Col>
      </Row>
    </Box>
  ) : null
}

export default connect((state) => ({
  basketItems: state.app.basketItems,
}))(ToppingCard)

