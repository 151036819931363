import React from 'react'
import { Col, Row, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import { compose } from 'redux'
import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux'
import classnames from 'classnames'

import WidthContainer from '../../components/layout/WidthContainer/WidthContainer'
import Box from '../../components/ui/Box/Box'
import Button from '../../components/ui/Button/Button'

import { isMobileLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import s from './News.module.less'
import { useEffect } from '../../hooks'


const { Text, Paragraph } = Typography

const NewsComponent = ({ viewport, news }) => {

  const isMobile = isMobileLayout(viewport)

  const history = useHistory()

  useEffect(() => {
    if (history.location.hash) {
      const node = findDOMNode(document.getElementById(history.location.hash.replace('#', '')))
      if (node) {
        window.scrollTo(0, node.offsetTop)
      }
    }
  }, [])

  return (
    <Box pt={isMobile ? 'sm' : ''} pb={isMobile ? 'xlg' : ''}>
      {
        isMobile && (
          <WidthContainer>
            <Row style={{ width: '100%', justifyContent: 'space-between' }}>
              <Col>
                <Button type="icon" icon="left" isSmall handleClick={() => history.goBack()} />
              </Col>
            </Row>
          </WidthContainer>
        )
      }

      <WidthContainer>
        {
          !isMobile && (
            <Box className={s.buttonBack}>
              <Button type="icon" icon="left" handleClick={() => history.goBack()} />
            </Box>
          )
        }

        <Box className={s.container}>
          {/* <Box mt={isMobile ? 'lg' : 'xlg'} mb={isMobile ? 'lg' : 'xlg'}> */}
          {/*  <Row className={s.title}> */}
          {/*    <Text>Новости</Text> */}
          {/*  </Row> */}
          {/* </Box> */}
          {
            news.map((item, i) => (
              <Row style={{ justifyContent: 'space-between', marginBottom: 53 }} id={item.alias}>
                <Col span={isMobile ? 24 : 8}>
                  <div className={s.offerCard}>
                    {
                      isMobile && item.image ? (
                        item.image.mobile && (
                          <img
                            src={item.image.mobile.detail}
                            style={{ borderRadius: 16 }}
                            srcSet={`${item.image.mobile.detail} 1x, ${item.image.mobile['detail-x2']} 2x`}
                          />
                        )
                      ) : !isMobile && item.image ? (
                        item.image.desktop && (
                          <img
                            src={item.image.desktop.detail}
                            style={{ borderRadius: 16, backgroundColor: 'black' }}
                            srcSet={`${item.image.desktop.detail} 1x, ${item.image.desktop['detail-x2']} 2x`}
                          />
                        )
                      ) : null
                    }
                  </div>
                </Col>
                <Col span={isMobile ? 24 : 15} className={s.textContainer}>
                  <Box mb="md">
                    <Text className={classnames(s.mainText, s.darkText)}>{item.title}</Text>
                  </Box>
                  <Box mb="md">
                    <div
                      className={s.description}
                      dangerouslySetInnerHTML={{ __html: item.content }} />
                  </Box>
                </Col>
              </Row>
            ))
          }
        </Box>
      </WidthContainer>
    </Box>
  )
}

const News = compose(
  withViewport,
)(NewsComponent)

export default connect((state) => ({
  user: state.app.user,
  news: state.app.news,
}))(News)
