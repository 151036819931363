import React from 'react'
import { Layout, Divider } from 'antd'
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom'
import links from 'helpers/links'
import { compose } from 'redux'

import cx from 'classnames'

import WidthContainer from 'components/layout/WidthContainer/WidthContainer'
import { connect } from 'react-redux'
import AccountMenu from '../../compositions/AccountMenu/AccountMenu'

import MyData from '../../compositions/MyData/MyData'
import MyOrders from '../../compositions/MyOrders/MyOrders'
import MyAddresses from '../../compositions/MyAddresses/MyAddresses'
import MyPromocodes from '../../compositions/MyPromocodes/MyPromocodes'
import Support from '../../compositions/Support/Support'
import ContractOffer from '../../compositions/ContractOffer/ContractOffer'
// import Order from '../../compositions/Order/Order'
// import PointsInfo from '../../compositions/PointsInfo/PointsInfo'
import Bonuses from '../../compositions/Bonuses/Bonuses'
import { isMobileLayout, isTabletLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'

import s from './Account.module.less'
import BasicAppLayout from '../../layouts/BasicAppLayout/BasicAppLayout'
import Favorites from '../../compositions/Favorites/Favorites'


const { Content } = Layout

const Account = (props) => {

  const { viewport, isLoggedIn } = props

  const isMobile = isMobileLayout(viewport)

  if (!isLoggedIn) {
    return <Redirect to={links.login} />
  }

  return (
    <WidthContainer>
      <Layout>
        <Content>
          {
            !isMobile && <AccountMenu />
          }

          <Switch>
            <Route component={MyData} path={`${links.account}/my-data`} />
            <Route component={MyOrders} path={`${links.account}/my-orders`} />
            {/* <Route exact component={Order} path={`${links.account}/my-orders/:id`} /> */}
            <Route exact component={Bonuses} path={`${links.account}/bonuses`} />
            <Route exact component={Favorites} path={`${links.account}/favorites`} />
            <Route component={MyAddresses} path={`${links.account}/my-addresses`} />
            <Route component={MyPromocodes} path={`${links.account}/my-promocodes`} />
            <Route component={Support} path={`${links.account}/support`} />
            <Route component={ContractOffer} path={`${links.account}/offer`} />
            <Redirect to={`${links.account}/my-data`} />
          </Switch>

        </Content>
      </Layout>
    </WidthContainer>
  )
}


const AccountComponent = compose(
  withViewport,
)(Account)


export default connect((state) => ({
  user: state.app.user,
  isLoggedIn: state.app.user && state.app.user.type !== 'guest',
}))(AccountComponent)

