import React, { Fragment, useState } from 'react'
import { Col, Typography } from 'antd'
import { compose } from 'redux'
import moment from 'moment'
import 'moment/locale/ru'
import Calendar from 'react-calendar'
import { withViewport } from '../../../../helpers/withViewport'

import s from '../Form/Form.module.less'
import CustomSelect from '../CustomSelect/CustomSelect'
import reducers from '../../../../redux/reducers'


const data = [
  {
    text: 'Дата',
    fieldName: 'date',
    options: [],
  },
  {
    text: 'Время',
    fieldName: 'time',
    options: [],
  },
]

const now = new Date()

const getMonthString = (month) => {
  switch (month) {
    case 0:
      return 'января'
    case 1:
      return 'февраля'
    case 2:
      return 'марта'
    case 3:
      return 'апреля'
    case 4:
      return 'мая'
    case 5:
      return 'июня'
    case 6:
      return 'июля'
    case 7:
      return 'августа'
    case 8:
      return 'сентября'
    case 9:
      return 'октября'
    case 10:
      return 'ноября'
    case 11:
      return 'декабря'
  }
}

data[0].options.push({
  title: `Сегодня, ${now.getDate()} ${getMonthString(now.getMonth())}`,
  value: now.toString(),
})
now.setDate(now.getDate() + 1)
data[0].options.push({
  title: `Завтра, ${now.getDate()} ${getMonthString(now.getMonth())}`,
  value: now.toString(),
})
now.setDate(now.getDate() + 1)
data[0].options.push({
  title: `Послезавтра, ${now.getDate()} ${getMonthString(now.getMonth())}`,
  value: now.toString(),
})

for (let i = 108; i < 276; i++) {
  let hours = Math.floor(i / 12)
  let minutes = (i - (hours * 12)) * 5
  data[1].options.push(`${hours < 10 ? `0${hours}` : hours}:${minutes < 10
    ? `0${minutes}`
    : minutes}`)
}

let hours = []
let minutes = []

for (let i = 11; i <= 23; i++) {
  hours.push(i)
}

for (let i = 0; i < 12; i++) {
  minutes.push(i * 5)
}

const { Text } = Typography

const SelectComponent = (props) => {

  const { viewport, setValue, estimated = 0, minDateDelivery } = props

  let nowDate = minDateDelivery ? new Date(minDateDelivery) : new Date((new Date()).getTime() + estimated * 60000)

  const nowHours = nowDate.getHours()
  const nowMinutes = nowDate.getMinutes()

  if (nowHours >= 22) {
    nowDate = new Date((nowDate).getTime() + 24 * 60 * 60000)
  }

  const nowDay = nowDate.getDate()

  const [ dateValue, setDateValue ] = useState(nowDate)
  const [ hoursValue, setHoursValue ] = useState()
  const [ minutesValue, setMinutesValue ] = useState()

  let h = []; let
    m = []

  if (nowDay === dateValue.getDate()) {
    h = hours.filter((hour) => hour >= nowHours)

    if (hoursValue === 23) {
      m = [ 0, 5, 10, 15, 20, 25, 30 ]
    }
    else {
      if (hoursValue === nowHours) {
        m = minutes.filter((minute) => minute >= nowMinutes)
      }
      else {
        m = [ ...minutes ]
      }
    }
  }
  else {
    h = [...hours]
    if (hoursValue === 23) {
      m = [ 0, 5, 10, 15, 20, 25, 30 ]
    }
    else {
      m = [ ...minutes ]
    }
  }

  if (hoursValue === 10) {
    m = m.filter((min) => min >= 0)
  }

  moment.locale('ru')

  return (
    <>
      <Col key="date" style={{ width: 200, marginRight: 20 }} className={s.calendarCol}>
        <CustomSelect
          label="Дата"
          value={moment(dateValue).format('DD.MM.YYYY')}
          customDropdown={(
            <Calendar
              onChange={(value) => {
                setDateValue(value)
                setValue('date', value)

                setHoursValue(null)
                setValue('hours', null)

                setMinutesValue(null)
                setValue('minutes', null)
              }}
              value={dateValue}
              locale="ru"
              tileDisabled={({ activeStartDate, date, view }) => moment(date).isBefore(moment(nowDate).startOf('day'))}
            />
          )}
          customDropdownStyle={{
            width: 360,
            maxHeight: 500,
          }} />
      </Col>
      <Col key="hours" style={{ width: 100, marginRight: 20 }}>
        <CustomSelect
          label="Часы"
          value={hoursValue}
          values={h}
          setValue={(value) => {
            setHoursValue(value)
            setValue('hours', value)

            setMinutesValue(null)
            setValue('minutes', null)

            reducers.app.setDelayedDeliveryHour(value)
          }} />
      </Col>
      <Col key="minutes" style={{ width: 100, marginRight: 20 }}>
        <CustomSelect
          label="Минуты"
          value={minutesValue}
          values={m}
          setValue={(value) => {
            setMinutesValue(value)
            setValue('minutes', value)
          }} />
      </Col>
    </>
  )
}

export default compose(
  withViewport,
)(SelectComponent)
