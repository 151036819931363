import React from 'react'
import { Field } from 'react-final-form'
import { Col, Typography, Radio } from 'antd'
import cx from 'classnames'
import { compose } from 'redux'

import Box from '../../../../components/ui/Box/Box'
import Icon from '../../../../components/ui/Icon'
import { isMobileLayout } from '../../../../helpers/isMobileLayout'
import { withViewport } from '../../../../helpers/withViewport'

import s from './RadioComponent.module.less'


const { Text } = Typography

const RadioComponent = ({ items, viewport, setValue }) => {

  const isMobile = isMobileLayout(viewport)

  return (
    <>
      {
        items && items.length > 0 && items.map((item, idx) => (
          <Col span={isMobile ? 24 : 12} key={idx}>
            <Box
              mt="xs"
              pr="sm"
              pl="sm"
              className={cx(s.paymentContainer, s.greenContainer)}>
              <div>
                {!item.id
                && <Text className={s.paymentText}>{item.display_label}</Text>}
                {item.id
                    && (
                      <>
                        <img src={item.icon_url} className={s.paymentSystemIcon} />
                        <Text className={s.paymentText}>{item.payment_system} <span className={s.middot}>&bull;</span>&bull; {item.display_label}</Text>
                      </>
                    )}
              </div>
              <Field
                name="card"
                component={({ input, ...rest }) => (
                  <Radio.Button
                    {...rest}
                    name={input.name}
                    type="radio"
                    value={input.value}
                    onChange={(e) => {
                      setValue('card', e.target.value)
                    }}
                  >
                    {
                      input.checked
                          && <Icon className={s.iconChecked} name="checkmark" />
                    }
                  </Radio.Button>
                )}
                type="radio"
                value={item.id}
              />
            </Box>
          </Col>
        ))
      }
    </>
  )
}

export default compose(
  withViewport,
)(RadioComponent)
