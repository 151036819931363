import React, { useEffect, useState } from 'react'
import { Typography, Row, Col, Checkbox, Modal } from 'antd'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'
import { connect } from 'react-redux'

import Box from '../../components/ui/Box/Box'
import Button from '../../components/ui/Button/Button'
import Icon from '../../components/ui/Icon'

import links from '../../helpers/links'
import { deleteAddress } from '../../redux/actions'

import s from './MyAddresses.module.less'
import formatAddress from '../../helpers/formatAddress'
import setSeoTags from '../../helpers/setSeoTags'


const { Text } = Typography

const MyAddresses = ({ user }) => {

  const [ isModalVisible, setModalVisible ] = useState(false)
  const [ selectedAddress, setSelectedAddress ] = useState()
  const [ selectedAddressDisplayName, setSelectedAddressDisplayName ] = useState()

  useEffect(() => {
    setSeoTags({
      title: `Eat Street - Адреса`,
      seo_desc: '',
      seo_keyw: '',
    })
  }, [])

  return (
    <Box className={s.container}>
      <Box mt="xlg" mb="lg" alignCenter>
        <Text className={s.title}>Адреса</Text>
      </Box>
      {
        user && user.addresses && user.addresses.map((item, i) => (
          <Box key={i} pr="sm" pl="sm" mb="sm" className={s.addressContainer}>
            <Col>
              <Row>
                <Text className={s.mainText}>{formatAddress(item)}</Text>
              </Row>
              <Row className={s.secondaryText}>
                {item.apartment && (
                  <>
                    <Text>Кв {item.apartment}</Text>
                  </>
                )}
                {item.entrance && (
                  <>
                    <Icon className={s.pointIcon} name="smallPoint2" />
                    <Text>Подъезд {item.entrance}</Text>
                  </>
                )}
                {item.floor && (
                  <>
                    <Icon className={s.pointIcon} name="smallPoint2" />
                    <Text>Этаж {item.floor}</Text>
                  </>
                )}
              </Row>
            </Col>
            <Col style={{ cursor: 'pointer' }}>
              <Icon
                name="close"
                onClick={() => {
                  setSelectedAddress(item.address_id)
                  setSelectedAddressDisplayName(`${item.street}, ${item.house} ${item.building ? `${item.building}к` : ''}`)
                  setModalVisible(true)
                }} />
            </Col>
          </Box>
        ))
      }
      <Box mt="lg" mb="lg" className={s.btnContainer}>
        <NavLink to={links.map}>
          <Button primary isSmall text="Добавить новый адрес" />
        </NavLink>
      </Box>
      <Modal
        visible={isModalVisible}
        maskStyle={{ background: 'rgba(255, 255, 255, .75)' }}
        className={s.wrapClassName}
        footer={null}
        closable
        maskClosable
        centered
        closeIcon={<Icon name="close" onClick={() => setModalVisible(false)} />}
      >
        <Box className={s.modalContent}>
          <Box mb="sm" alignCenter>
            <Text className={s.mainModalText}>Удалить этот адрес?</Text>
          </Box>
          <Box mt="xs" mb="sm">
            <Text className={s.secondaryModalText}>{selectedAddressDisplayName}</Text>
          </Box>
          <Box mt="sm" className={s.buttonsWrapper}>
            <Button
              text="Да"
              primary
              isSmall
              handleClick={() => {
                deleteAddress(selectedAddress)
                setModalVisible(false)
              }}
            />
            <Button text="Нет" secondary isSmall handleClick={() => setModalVisible(false)} />
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default connect((state) => ({
  user: state.app.user,
}))(MyAddresses)
