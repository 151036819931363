import { Col, Select, Row, Typography } from 'antd'
import React, { useMemo } from 'react'
import cx from 'classnames'
import { Field, useField } from 'react-final-form'
import Box from '../../../../components/ui/Box/Box'
import s from './PaymentType.module.less'
import Icon from '../../../../components/ui/Icon'
import RadioComponent from '../RadioComponent/RadioComponent'


const PaymentType = ({ payments, setValue, isMobile, cards }) => {
  const { Option } = Select
  const { Paragraph, Text } = Typography

  return (
    <>
      <Paragraph className={s.sectionTitle}>Оплата</Paragraph>
      <Row gutter={isMobile ? [20, 10] : [20, 14]}>
        <Col span={isMobile ? 24 : 12}>
          <Field
            name="payment"
            component={({ input, ...rest }) => (
              <Select
                {...rest}
                className={s.cardSelect}
                size="large"
                defaultValue={input.value}
                onChange={(value) => setValue('payment', value)}
                suffixIcon={<Icon className={s.selectIcon} name="arrowUp" />}
              >
                {payments && payments.map((el) => (
                  <Option key={el.payment_type_guid} value={el.payment_type_guid} label={el.title} className={s.option}>
                    <Box
                      className={cx(s.paymentContainer, s.greenContainer)}>
                      {
                        el.image && el.image.active && el.image.active.resize && <img src={el.image.active.resize['preview-x2']} className={s.optionIcon} />
                      }
                      <Text className={s.paymentText}>{el.title}</Text>
                    </Box>
                  </Option>
                ))}
              </Select>
            )}
          />
        </Col>
      </Row>
      {useField('payment').input.value === payments.find((payment) => payment.is_online_pay).payment_type_guid
        && (
          <Row gutter={isMobile ? [20, 10] : [20, 14]} className={s.radioComponentRow}>
            <RadioComponent items={cards} setValue={setValue}  />
          </Row>
        )}

    </>
  )
}

export default PaymentType

