import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import Button from '../../../../components/ui/Button/Button'
import Box from '../../../../components/ui/Box/Box'

import useLocalStorage from '../../../../hooks/useLocalStorage'


const ProductCardFooter = (props) => {

  const { price, onLikeClicked, onPriceClicked, isFavorite, products_guid, basketItems, groups_objects } = props

  const productsInBasket = basketItems ? basketItems.filter((i) => i.products_guid === products_guid) : []
  const productsInBasketCount = productsInBasket.reduce((acc, { count }) => acc + count, 0)

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Box mr="sm">
        <Button
          bright
          isSmall
          text={`${productsInBasketCount > 0 ? `${productsInBasketCount} x ` : ''}${price}` + ' ₽'}
          handleClick={onPriceClicked}
        />
      </Box>
      {!groups_objects && (
        <Button
          type="icon"
          icon={isFavorite ? 'likeActive' : 'likeInActive'}
          handleClick={onLikeClicked}
          isMedium
        />
      )}
    </div>
  )
}

export default connect((state) => ({
  basketItems: state.app.basketItems,
}))(ProductCardFooter)

