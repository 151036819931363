import React, { useEffect } from 'react'
import { Carousel, Typography, Menu } from 'antd'
import { NavLink, useParams } from 'react-router-dom'
import cx from 'classnames'

import Box from '../../components/ui/Box/Box'
import Button from '../../components/ui/Button/Button'

import links from '../../helpers/links'

import s from './AccountMenu.module.less'
import Icon from '../../components/ui/Icon'



const { Text } = Typography

const AccountMenu = (props) => {


  const menuItems = [
    {
      name: 'Мои данные',
      link: links.myData,
    },
    {
      name: 'Заказы',
      link: links.myOrders,
    },
    {
      name: 'Адреса',
      link: links.myAddresses,
    },
    // {
    //   name: 'Персональные промокоды',
    //   link: links.myPromocodes,
    // },
    {
      name: 'Бонусы',
      link: links.bonuses,
    },
    {
      name: 'Связаться с нами',
      link: links.support,
    },
    {
      name: <span><Icon name="likeActive" style={{ marginRight: 6 }} /> Избранное</span>,
      link: links.favorites,
    },
  ]

  const selectedIndex = menuItems.find((item) => document.location.href.indexOf(item.link) > -1)?.link

  if (selectedIndex === links.favorites) {
    menuItems[5].name = <span><Icon name="likeInActiveWhite" style={{ marginRight: 6 }} /> Избранное</span>
  }

  const { section } = useParams()


  useEffect(() => {

  }, [section])

  const items = menuItems.map((item, i) => (
    <Menu.Item key={item.link}>
      <NavLink to={item.link}>
        <Box pr="xs" key={i} className={s.textWrapper}>
          <Text className={s.text}>{item.name}</Text>
        </Box>
      </NavLink>
    </Menu.Item>
  ))

  return (
    <Menu
      mode="inline"
      selectedKeys={[selectedIndex.toString()]}
      className={s.container}
    >
      {items}
    </Menu>
  )
}

export default AccountMenu

