import React, { Fragment } from 'react'
import { Typography } from 'antd'
import cx from 'classnames'

import Icon from '../Icon'

import s from './ProductInfo.module.less'


const { Text } = Typography

const ProductInfo = (props) => {

  const { size, weight, price, totalWeight, comboItemsAmount, measure_type, groups_objects } = props

  const correctWord = comboItemsAmount ? (comboItemsAmount === 1) ? 'блюдо' : (comboItemsAmount < 5) ? 'блюда' : 'блюд' : ''

  return (
    <div className={s.container}>
      {
        size ? (
          <>
            {/* <Text className={cx(s.text, s.greenText, s.atLeft)}>{size} см</Text> */}
            {/* <Icon name='smallPoint' /> */}
            <Text className={cx(s.text, s.lowContrast, s.atRight)}>{parseInt(weight)} {measure_type}</Text>
          </>
        ) : (
          <>
            {!groups_objects && (
              <Text className={cx(s.text, s.lowContrast, s.atLeft)}>
                {!comboItemsAmount && price ? <>{price} ₽</> : <>{totalWeight ? `${parseInt(totalWeight)}` : `${parseInt(weight)}`} {measure_type}</>}
              </Text>
            )}
            {
              comboItemsAmount && (
                <>
                  <Icon name="smallPoint2" />
                  <Text className={cx(s.text, s.greenText, s.atRight)}>
                    {price ? `${price} ₽` : `${comboItemsAmount} ${correctWord}`}
                  </Text>
                </>
              )
            }
          </>
        )
      }
    </div>
  )
}

export default ProductInfo

