exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._27zcADc9Ov8mLAO5csM7qy {\n  height: 100%;\n  width: 100%;\n  font-family: 'Circe-Regular', serif;\n}\n._34Hxg-e_mMTfB7GVsdd6g_ {\n  width: 100vw;\n  margin-left: calc(-50vw + 50%);\n  margin-right: calc(-50vw + 50%);\n}\n._34Hxg-e_mMTfB7GVsdd6g_ .storytellerContainer-29GKcz {\n  padding-left: 16px !important;\n  padding-right: 16px !important;\n}\n._1d5SI_ab2FQ6MVChnAVnb {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n}\n._3QO68BKptxD1h6-LPrv8Pw .roundCellContainer-1OR5l0 svg {\n  display: none !important;\n}\n._3QO68BKptxD1h6-LPrv8Pw .roundCellContainer-1OR5l0 .storyCell-qjxGc6 {\n  border-radius: 20px !important;\n  border: 3px solid white !important;\n  box-shadow: 0 0 0 3px #FF7800 !important;\n}\n._3QO68BKptxD1h6-LPrv8Pw .roundCellContainer-1OR5l0 .storyCell-qjxGc6 .roundBackground--B095B {\n  border-radius: 20px !important;\n  object-fit: cover !important;\n}\n._3QO68BKptxD1h6-LPrv8Pw .roundCellContainer-1OR5l0 .storyCell-qjxGc6.storyViewed-2KQIJT {\n  border-radius: 20px !important;\n  box-shadow: 0 0 0 3px #C5C5C5 !important;\n}\n._3QO68BKptxD1h6-LPrv8Pw .storyTitle-1O1XMh {\n  display: none !important;\n}\n._3QO68BKptxD1h6-LPrv8Pw .live-2YvNrJ {\n  border: none !important;\n  bottom: auto !important;\n  top: 12% !important;\n  right: 10% !important;\n}\n.storyTitle-1O1XMh {\n  visibility: hidden !important;\n}\n", ""]);

// Exports
exports.locals = {
	"storytellerList": "_27zcADc9Ov8mLAO5csM7qy",
	"storytellerListMobile": "_34Hxg-e_mMTfB7GVsdd6g_",
	"notFoundBlockWrapper": "_1d5SI_ab2FQ6MVChnAVnb",
	"storytellerListDesktop": "_3QO68BKptxD1h6-LPrv8Pw"
};