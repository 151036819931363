exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2cxEBfhlG7NuK0ppOwK0oW {\n  width: 100%;\n  height: 500px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.Jid4O2lVy0i2NfmP0ttGM {\n  position: absolute;\n  left: 40px;\n  top: 0;\n}\n._2ZWL2xJ_2V_ohWKjWiNj7f {\n  position: absolute;\n  right: 0;\n  top: 0;\n}\n._3l8wwmsDcq53wrCa7OkxBb {\n  right: -5px;\n  top: -8px;\n  height: 20px;\n  width: 20px;\n  position: absolute;\n  border-radius: 20px;\n  background-color: #E03F3F;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n._3l8wwmsDcq53wrCa7OkxBb ._3CcAKLvHdRhi_TG-FZGOnH {\n  font-family: 'Roboto', sans-serif;\n  font-weight: bold;\n  font-size: 10px;\n  line-height: 10px;\n  text-align: center;\n  text-transform: uppercase;\n  color: #FFFFFF;\n}\n.SEBupCyrk27XS4C4pFos1 {\n  left: 0;\n  top: -5px;\n}\n._3MHNDL4kIMDmRZVqlICP-Y {\n  right: 0;\n  top: -5px;\n}\n._3nq_NshTERujaEw0QBNWHp {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n._3nq_NshTERujaEw0QBNWHp span {\n  font-family: 'Circe-Bold', serif;\n  font-size: 40px;\n  line-height: 59px;\n  text-align: center;\n  color: #3C3C3C;\n}\n@media (max-width: 375px) {\n  ._3nq_NshTERujaEw0QBNWHp span {\n    font-family: 'Circe-Bold', serif;\n    font-size: 20px;\n    line-height: 32px;\n    letter-spacing: 0.5px;\n    font-feature-settings: 'pnum' on, 'lnum' on;\n    color: #232323;\n  }\n}\n._3nq_NshTERujaEw0QBNWHp button {\n  position: absolute;\n  right: 0;\n}\n._36AWRed_DJ9I9-6WCi1u4f {\n  display: block;\n  position: fixed;\n  bottom: 20px;\n  z-index: 10001;\n  right: 20px;\n}\n@media (min-width: 769px) {\n  ._36AWRed_DJ9I9-6WCi1u4f {\n    bottom: 100px;\n  }\n}\n@media (min-width: 1281px) {\n  ._36AWRed_DJ9I9-6WCi1u4f {\n    right: calc((100vw - 1280px + 20px)/2);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"loader": "_2cxEBfhlG7NuK0ppOwK0oW",
	"topMobileLeft": "Jid4O2lVy0i2NfmP0ttGM",
	"topMobileRight": "_2ZWL2xJ_2V_ohWKjWiNj7f",
	"basketItemsCount": "_3l8wwmsDcq53wrCa7OkxBb",
	"basketItemsCountText": "_3CcAKLvHdRhi_TG-FZGOnH",
	"arrowDown": "SEBupCyrk27XS4C4pFos1",
	"basket": "_3MHNDL4kIMDmRZVqlICP-Y",
	"productNameContainer": "_3nq_NshTERujaEw0QBNWHp",
	"basketButton": "_36AWRed_DJ9I9-6WCi1u4f"
};