import React, { useState, useMemo } from 'react'
import { Radio, Row, Typography, Spin } from 'antd'
import moment from 'moment'
import Box from '../../components/ui/Box/Box'
import Icon from '../../components/ui/Icon'
import s from './PickupPoint.module.less'
import formatAddress from '../../helpers/formatAddress'
import {
  getAddressByCoords,
} from '../../redux/actions'


const { Text } = Typography

const PickupPoint = (props) => {
  const { data, selectedPickupPoint, setSelectedPickupPoint, map } = props

  const [ addresses, setAddresses ] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onChange = (e) => {
    const find = data.find((item) => (item.address_id == e.target.value) || (item.restaurant_guid == e.target.value))
    if (map && map.current) {
      map.current.setCenter(find.coords)
    }
    setSelectedPickupPoint(find)
  }

  // TODO: Delete after New Year
  const getCurrentNewYearTime = (guid) => {
    const nowDate = moment(new Date()).format('YYYY-MM-DD')

    if (guid === '8e40a13d-b0b5-4a15-8f1a-1bf7d13621a3') {

      if (moment(nowDate).isSame('2022-12-31')) {
        return 'с 10:00 до 20:00'
      }
      if (moment(nowDate).isSame('2023-01-01')) {
        return 'с 14:00 до 22:00'
      }
      if (moment(nowDate).isAfter('2023-01-01') && moment(nowDate).isBefore('2023-01-09')) {
        return 'c 10:00 до 22:00'
      }
      return ''
    }

    if (guid === '2cc74cef-abe0-42b9-910e-901719aa89b9') {
      if (moment(nowDate).isSame('2022-12-31')) {
        return 'с 10:00 до 20:00'
      }
      if (moment(nowDate).isSame('2023-01-01')) {
        return 'с 14:00 до 23:00'
      }
      if (moment(nowDate).isAfter('2023-01-01') && moment(nowDate).isBefore('2023-01-09')) {
        return 'c 10:00 до 23:00'
      }
      return ''
    }
  }

  const getRestrauntGuid = (id) => {
    const currentAddr = addresses.find((addr) => addr?.id === id)
    if (currentAddr) {
      return currentAddr.restaurant_guid
    }
    return ''
  }

  const getAdresses = async () => {
    const promisses = []
    data.map((addr) => {
      if (!addr?.restaurant_guid) {
        promisses.push(getAddressByCoords(addr.coords))
      }
    })

    if (promisses.length) {
      const res = await Promise.all(promisses).then((res) => res.map((r) => r[0])).catch((e) => console.error(e))

      if (res.length) {
        const newData = data.map((d, i) => ({ id: d.address_id, restaurant_guid: res[i].area.restaurant_guid }))
        setAddresses(newData)
      }
    }

    setIsLoading(false)
  }

  useMemo(() => {
    const nowDate = moment(new Date()).format('YYYY-MM-DD')
    if (moment(nowDate).isBefore('2022-12-31') || moment(nowDate).isAfter('2023-01-08')) return
    setIsLoading(true)
    getAdresses()
  }, [data])

  return (
    <Box>
      {isLoading ? <Spin />
        : (
          <Radio.Group onChange={onChange} value={selectedPickupPoint} style={{ width: '100%' }}>
            {
              data.sort((a, b) => b.address_id - a.address_id).map((item) => {

                const { address_id, restaurant_guid, images, title, working_time, street, house } = item || {}

                let fromHours
                let fromMinutes
                let toHours
                let toMinutes

                if (working_time && working_time.from && working_time.to) {
                  const from = new Date(working_time.from)
                  fromHours = from.getHours()
                  fromMinutes = from.getMinutes()
                  const to = new Date(working_time.to)
                  toHours = to.getHours()
                  toMinutes = to.getMinutes()
                }

                return (
                  <Box className={s.wrapper} key={address_id || restaurant_guid}>
                    <Radio.Button value={address_id || restaurant_guid} className={s.container}>
                      <Row wrap={false}>
                        {images && images[0] && <img src={images[0].desktop['preview-x2']} width={50} />}
                        <Box ml="sm" className={s.textContainer}>
                          {title ? <Text>{title}</Text> : <Text>{formatAddress(item)}</Text>}

                          {/* Delete after New Year */}
                          {getCurrentNewYearTime(restaurant_guid || getRestrauntGuid(address_id))
                            ? <Text>{getCurrentNewYearTime(restaurant_guid || getRestrauntGuid(address_id))}</Text>
                            : (working_time && working_time.from && working_time.to)
                              ? <Text>c {`${fromHours < 10 ? `0${fromHours}` : fromHours}:${fromMinutes < 10 ? `0${fromMinutes}` : fromMinutes}`} до {`${toHours < 10 ? `0${toHours}` : toHours}:${toMinutes < 10 ? `0${toMinutes}` : toMinutes}`}</Text>
                              : <Text>с 10:00 до 23:00</Text>}

                          {/* TODO: Uncomment after New Year */}
                          {/* {(working_time && working_time.from && working_time.to)
                          ? <Text>c {`${fromHours < 10 ? `0${fromHours}` : fromHours}:${fromMinutes < 10 ? `0${fromMinutes}` : fromMinutes}`} до {`${toHours < 10 ? `0${toHours}` : toHours}:${toMinutes < 10 ? `0${toMinutes}` : toMinutes}`}</Text>
                          : <Text>с 10:00 до 23:00</Text>} */}
                        </Box>
                        <Box className={s.iconContainer}>
                          {
                            selectedPickupPoint == (address_id || restaurant_guid) ? (
                              <Icon name="checkmark" />
                            ) : (
                              <div className={s.notChecked} />
                            )
                          }
                        </Box>
                      </Row>
                    </Radio.Button>
                  </Box>
                )
              })
            }
          </Radio.Group>
        )}
    </Box>
  )
}

export default PickupPoint
