import React from 'react'
import cx from 'classnames'
import { Typography } from 'antd'
import s from './NotFoundBlock.module.less'
import Icon from '../Icon'
import Box from '../Box/Box'


const NotFoundBlock = ({ className, title, subtitle }) => {
  const { Text } = Typography

  return (
    <Box className={cx(s.notFoundBlock, className)} alignCenter>
      <Text className={s.title}>{title}</Text>
      {subtitle && <Text className={s.subtitle}>{subtitle}</Text>}
      <Icon name="borderConer" className={cx(s.border, s.topLeft)} />
      <Icon name="borderConer" className={cx(s.border, s.topRight)} />
      <Icon name="borderConer" className={cx(s.border, s.bottomLeft)} />
      <Icon name="borderConer" className={cx(s.border, s.bottomRight)} />
    </Box>
  )

}

export default NotFoundBlock
