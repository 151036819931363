import { useRef } from 'react'


const getUniqueId = ((id) => () => String(++id))(1)

const useUniqueId = (prefix = '') => {
  const id = useRef(`${prefix}${getUniqueId()}`)

  return id.current
}


export default useUniqueId
