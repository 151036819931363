exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".e93qnVo8U9XdPQy2D53Gx {\n  box-sizing: border-box;\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  position: relative;\n  cursor: pointer;\n}\n.e93qnVo8U9XdPQy2D53Gx input,\n.e93qnVo8U9XdPQy2D53Gx textarea,\n.e93qnVo8U9XdPQy2D53Gx select {\n  font-family: 'Circe-Regular', serif;\n  font-size: 16px;\n  line-height: 19px;\n  color: #3C3C3C;\n  border: none;\n  outline: none;\n}\n.e93qnVo8U9XdPQy2D53Gx input::placeholder,\n.e93qnVo8U9XdPQy2D53Gx textarea::placeholder,\n.e93qnVo8U9XdPQy2D53Gx select::placeholder {\n  font-size: 16px;\n}\n.a7SKr6q6o87srR4eSdSj_ {\n  width: 100%;\n  height: 56px;\n  border: 1px solid #F0F0F0;\n}\n._3eaMAn4Y0kmElOXW5xcqZ {\n  height: 56px;\n  background: #F7F9F8;\n  border-radius: 10px;\n}\n._3eaMAn4Y0kmElOXW5xcqZ input,\n._3eaMAn4Y0kmElOXW5xcqZ textarea,\n._3eaMAn4Y0kmElOXW5xcqZ select {\n  background: #F7F9F8;\n}\n._2hDeZSYscDHqXrupszz64c {\n  margin-top: 8px;\n  padding: 7px 16px;\n  justify-content: flex-start;\n}\n.MLkQWFBBZ2pxuSVo1WxNA {\n  height: fit-content;\n  width: 100%;\n}\n.MLkQWFBBZ2pxuSVo1WxNA textarea {\n  height: fit-content;\n}\n._1wYWWq3Lb6rMBxCLg1DFmg {\n  font-family: 'Circe-Regular', serif;\n  font-size: 13px;\n  line-height: 19px;\n  color: #969696;\n}\n._2v_hooBHDphvEc7nUTAMos {\n  position: absolute;\n  top: 100%;\n  margin-top: 5px;\n  left: 0;\n  padding: 10px;\n  z-index: 9999;\n  background: #F7F9F8;\n  border-radius: 10px;\n  min-width: 100%;\n  max-height: 150px;\n  overflow: auto;\n}\n._2yWHAO1yHc1E4e8AzeOxqo:not(:last-child) {\n  margin-bottom: 5px;\n}\n", ""]);

// Exports
exports.locals = {
	"fieldContainer": "e93qnVo8U9XdPQy2D53Gx",
	"whiteContainer": "a7SKr6q6o87srR4eSdSj_",
	"greenContainer": "_3eaMAn4Y0kmElOXW5xcqZ",
	"dateInput": "_2hDeZSYscDHqXrupszz64c",
	"commentContainer": "MLkQWFBBZ2pxuSVo1WxNA",
	"smallText": "_1wYWWq3Lb6rMBxCLg1DFmg",
	"dropdown": "_2v_hooBHDphvEc7nUTAMos",
	"dropdownRow": "_2yWHAO1yHc1E4e8AzeOxqo"
};