import React from 'react'


const PointIcons = ({ color }) => (
  <div style={{ width: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <svg width="3" height="3" viewBox="0 0 3 3" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="1.5" cy="1.5" r="1.5" fill={color} />
    </svg>
    <svg width="3" height="3" viewBox="0 0 3 3" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="1.5" cy="1.5" r="1.5" fill={color} />
    </svg>
    <svg width="3" height="3" viewBox="0 0 3 3" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="1.5" cy="1.5" r="1.5" fill={color} />
    </svg>
  </div>
)

export default PointIcons
