import React, { useEffect, useState } from 'react'
import { Typography, Col } from 'antd'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'

import { connect } from 'react-redux'
import { compose } from 'redux'
import Image from '../Image'

import s from './ComboCard.module.less'
import links from '../../../helpers/links'
import { isMobileLayout } from '../../../helpers/isMobileLayout'
import { withViewport } from '../../../helpers/withViewport'


const { Paragraph, Text } = Typography

const ComboCardComponent = (props) => {

  const { image, secondaryText, isCarousel, section_guid, sections, viewport, time } = props

  const section = sections.find((section) => section_guid === section.section_guid)
  const timeTo = new Date(section?.expiration.to).getHours()
  const timeFrom = new Date(section?.expiration.from).getHours()

  const [className, setclassName] = useState('')

  useEffect(() => {
    if (time >= timeFrom && time < timeTo) setclassName(`${s.active}`)
  }, [time])

  const isMobile = isMobileLayout(viewport)

  return (
    <div className={cx(s.comboContainer, isCarousel && s.isCarousel, className)}>
      <NavLink to={`${links.catalog}/${section && section.alias || ''}`}>
        {/* <Image name={image} className={s.brgImage} /> */}
        {
          isMobile ? (
            <img
              src={image.mobile.preview}
              className={s.brgImageMobile}
              srcSet={`${image.mobile.preview} 1x, ${image.mobile['preview-x2']} 2x`}
            />
          ) : (
            <img
              src={image.desktop.preview}
              className={s.brgImage}
              srcSet={`${image.desktop.preview} 1x, ${image.desktop['preview-x2']} 2x`}
            />
          )
        }
        {/* <img src={image.preview} className={s.brgImage} /> */}
        {/* <div className={s.textBlock}> */}
        {/*  <div> */}
        {/*    <Paragraph className={s.mainText}>{mainText}</Paragraph> */}
        {/*    <Text className={s.secondaryText}>{secondaryText}</Text> */}
        {/*  </div> */}
        {/* </div> */}
      </NavLink>
    </div>
  )
}

const ComboCard = compose(
  withViewport,
)(ComboCardComponent)

export default connect((state) => ({
  sections: state.app.sections,
}))(ComboCard)
