exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".U8HrEmxD8pyr1qDLd1GEl {\n  display: flex;\n  width: 100%;\n  max-width: 1240px;\n  justify-content: space-between;\n  position: relative;\n  min-height: 122px;\n}\n@media (min-width: 769px) {\n}\n._32LRI7Nt4H97PJVFL-D9gm {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n}\n@media (min-width: 769px) {\n  ._32LRI7Nt4H97PJVFL-D9gm {\n    flex-direction: row;\n  }\n}\n@media (max-width: 770px) {\n  ._32LRI7Nt4H97PJVFL-D9gm {\n    margin: 0px 0px 30px 0px;\n  }\n  ._32LRI7Nt4H97PJVFL-D9gm > div:first-child {\n    margin: 0px 0px 20px 0px;\n  }\n}\n@media (max-width: 760px) {\n  ._32LRI7Nt4H97PJVFL-D9gm {\n    margin: 0;\n  }\n}\n._31xtoJl-CWxAo3zzjRq2iT {\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  overflow: auto;\n  margin-right: 5px;\n  scrollbar-width: thin;\n}\n._31xtoJl-CWxAo3zzjRq2iT > div:last-child {\n  padding-right: 100px;\n}\n@media (min-width: 769px) {\n  ._31xtoJl-CWxAo3zzjRq2iT > div:last-child {\n    padding-right: 155px;\n  }\n}\n@media (min-width: 1001px) {\n  ._31xtoJl-CWxAo3zzjRq2iT > div:last-child {\n    padding-right: 200px;\n  }\n}\n._2MDITeCu39T4ZgeqU1065- {\n  display: flex;\n  justify-content: flex-end;\n  width: 20%;\n  flex-direction: row;\n  position: absolute;\n  right: 0;\n  height: 100px;\n  background: linear-gradient(-90deg, #FFFFFF 35%, rgba(255, 255, 255, 0) 100%);\n  margin-top: -2px;\n  padding-top: 2px;\n}\n._2MDITeCu39T4ZgeqU1065- button {\n  width: 52px;\n  height: 52px;\n}\n@media (min-width: 769px) {\n  ._2MDITeCu39T4ZgeqU1065- button {\n    width: 64px;\n    height: 64px;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"buttonsWrapper": "U8HrEmxD8pyr1qDLd1GEl",
	"buttonsWrapperCentered": "_32LRI7Nt4H97PJVFL-D9gm",
	"addressesContainer": "_31xtoJl-CWxAo3zzjRq2iT",
	"addButton": "_2MDITeCu39T4ZgeqU1065-"
};