exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1LjMvnojfpP9w14j3Krx1V {\n  display: flex;\n  flex-direction: row;\n}\n._11MR61jsi71lIWznO4Wz5I {\n  font-family: 'Circe-Regular', serif;\n  font-size: 15px;\n  line-height: 19px;\n  text-align: left;\n}\n@media (max-width: 375px) {\n  ._11MR61jsi71lIWznO4Wz5I {\n    font-size: 13px;\n  }\n}\n._1t3KZryWrH4f-Uh6J8zSo9 {\n  color: #2B5E51;\n}\n._3kuv9cikzxxUtfMXCdICJi {\n  margin-right: 10px;\n}\n._2dDlZIS6umhuqRHpU1tH9D {\n  margin-left: 10px;\n}\n.oA-m6yq8B-UTfTxqht816 {\n  color: #9CA092;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "_1LjMvnojfpP9w14j3Krx1V",
	"text": "_11MR61jsi71lIWznO4Wz5I",
	"greenText": "_1t3KZryWrH4f-Uh6J8zSo9",
	"atLeft": "_3kuv9cikzxxUtfMXCdICJi",
	"atRight": "_2dDlZIS6umhuqRHpU1tH9D",
	"lowContrast": "oA-m6yq8B-UTfTxqht816"
};