import React from 'react'
import { Typography } from 'antd'
import cx from 'classnames'

import s from './Switcher.module.less'


const { Text } = Typography

const Switcher = (props) => {

  const { items, isSmall, onTabSelect, selectedTab } = props

  return items.length === 2 ? (
    <div className={cx(s.roundedTabsContainer, isSmall && s.smallContainer)}>
      <div className={cx(s.roundedTab, !selectedTab ? s.roundedTab1Active : s.roundedTabLastActive, isSmall && s.smallTab)} />
      <div
        className={cx(s.tab, !selectedTab ? s.tabActive : s.tabInactive)}
        onClick={() => {
          onTabSelect(0)
        }}
      >
        <Text>{items[0]}</Text>
      </div>
      <div
        className={cx(s.tab, selectedTab ? s.tabActive : s.tabInactive)}
        onClick={() => {
          onTabSelect(1)
        }}
      >
        <Text>{items[1]}</Text>
      </div>
    </div>
  ) : (
    <div className={s.roundedTabsContainer}>
      <div
        className={cx(
          s.roundedTabSmall,
          !selectedTab ? s.roundedTab1Active
            : selectedTab === 1 ? s.roundedTab2Active
              : s.roundedTabLastActive,
        )}
      />
      <div
        className={cx(s.tab, !selectedTab ? s.tabActive : s.tabInactive)}
        onClick={() => {
          onTabSelect(0)
        }}
      >
        <Text>{items[0]} {!selectedTab && 'см'}</Text>
      </div>
      <div
        className={cx(s.tab, selectedTab === 1 ? s.tabActive : s.tabInactive)}
        onClick={() => {
          onTabSelect(1)
        }}
      >
        <Text>{items[1]} {selectedTab === 1 && 'см'}</Text>
      </div>
      <div
        className={cx(s.tab, selectedTab === 2 ? s.tabActive : s.tabInactive)}
        onClick={() => {
          onTabSelect(2)
        }}
      >
        <Text>{items[2]} {selectedTab === 2 && 'см'}</Text>
      </div>
    </div>
  )
}

export default Switcher

