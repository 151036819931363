import React, { useEffect, useRef, useState } from 'react'
import { Typography, Row, Col, Dropdown, Menu, Divider, Spin, Modal } from 'antd'
import { NavLink, useHistory } from 'react-router-dom'
import links from 'helpers/links'
import cx from 'classnames'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Box from '../../components/ui/Box/Box'
import Icon from '../../components/ui/Icon'
import Button from '../../components/ui/Button/Button'
import { isMobileLayout, isTabletLayout, isTabletMiddleLayout } from '../../helpers/isMobileLayout'
import { withViewport } from '../../helpers/withViewport'
import s from './PageHeader.module.less'
import getDeliveryPointById from '../../helpers/getDeliveryPointById'
import getRestaurantsFromCities from '../../helpers/getRestaurantsFromCities'
import formatAddress from '../../helpers/formatAddress'
import SearchModal from '../SearchModal/SearchModal'



const { Text } = Typography

const menu = (
  <Menu>
    <Menu.Item>
      <NavLink to={links.myData}>
        Мои данные
      </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to={links.myOrders}>
        Заказы
      </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to={links.myAddresses}>
        Адреса
      </NavLink>
    </Menu.Item>
    {/* <Menu.Item> */}
    {/*  <NavLink to={links.myPromocodes}> */}
    {/*    Персональные промокоды */}
    {/*  </NavLink> */}
    {/* </Menu.Item> */}
    <Menu.Item>
      <NavLink to={links.support}>
        Связаться с нами
      </NavLink>
    </Menu.Item>
    <Divider />
    <Menu.Item>
      <NavLink to={links.favorites}>
        <Icon className={s.likeIcon} name="likeInActive" />
        Избранное
      </NavLink>
    </Menu.Item>
    <Divider />
    <Menu.Item>
      <Text
        className={s.phoneNumber}
        onClick={() => {
          document.location.href = 'tel:+74959601242'
        }}>
        +7 (495) 960 12 42
      </Text>
    </Menu.Item>
    <Menu.Item>
      <Text>
        Быстрая доставка
      </Text>
    </Menu.Item>
    <Menu.Item>
      <Text>
        Ежедневно с 10:00 до 23:00
      </Text>
    </Menu.Item>
  </Menu>
)

const loginLink = (
  <Menu>
    <Menu.Item>
      <NavLink to={links.login}>
        Войти
      </NavLink>
    </Menu.Item>
    <Divider />
    <Menu.Item>
      <Text
        className={s.phoneNumber}
        onClick={() => {
          document.location.href = 'tel:+74959601242'
        }}>
        +7 (495) 960 12 42
      </Text>
    </Menu.Item>
    <Menu.Item className={s.itemText}>
      <Text>
        Быстрая доставка
      </Text>
    </Menu.Item>
    <Menu.Item className={s.itemText}>
      <Text>
        Ежедневно с 10:00 до 23:00
      </Text>
    </Menu.Item>
  </Menu>
)


const PageHeaderComponent = (props) => {

  const {
    viewport,
    user,
    isLoggedIn,
    isMenuDrawerVisible,
    setMenuDrawerVisible,
    isAccountDrawerVisible,
    setAccountDrawerVisible,
    sections,
    withoutLogo,
    selectedDeliveryPoint,
    restaurants,
  } = props

  const isMobile = isMobileLayout(viewport)
  const isTabletMiddle = isTabletMiddleLayout(viewport)
  const [horizontal, setHorizontal] = useState('')

  const history = useHistory()

  const isAddressVisibleLayout = selectedDeliveryPoint && viewport.width > 1150

  const checkOrientation = () => {
    if (window.orientation === 90) {
      setHorizontal(false)
    }
    else {
      setHorizontal(true)
    }
  }

  const setPositionHeader = () => {
    window.addEventListener('orientationchange', checkOrientation)
    const bar = document.querySelector('.cf-bar')?.offsetHeight
    const css = `${bar}px`
    const tagColactions = document.getElementsByTagName('html')
    tagColactions[0].style.paddingTop = css
  }

  useEffect(() => {
    setPositionHeader()
    return () => window.removeEventListener('orientationchange', checkOrientation)
  })

  return (
    <Box
      pt={isMobile ? 'sm' : 'md'}
      pb={isMobile && (isMenuDrawerVisible || isAccountDrawerVisible) ? '' : (isMobile && !isMenuDrawerVisible && !isAccountDrawerVisible) ? 'sm' : 'md'}>
      <Row justify="space-between" className={s.container} style={isMobile && { marginTop: 22, background: '#fff', zIndex: 2000 }}>
        {
          !withoutLogo && (
            <>
              <Col className={s.menuWrapper} style={{ zIndex: 9999 }}>
                <Box mr="sm">
                  <Button
                    type="icon"
                    icon={isMenuDrawerVisible ? 'close' : 'burgerMenuIcon'}
                    isSmall={isMobile}
                    handleClick={() => {
                      if (isAccountDrawerVisible) {
                        setAccountDrawerVisible(false)
                      }
                      setMenuDrawerVisible(!isMenuDrawerVisible)
                    }}
                  />
                </Box>
                {
                  !isMobile && (
                    <Text onClick={() => {
                      if (isAccountDrawerVisible) {
                        setAccountDrawerVisible(false)
                      }
                      setMenuDrawerVisible(!isMenuDrawerVisible)
                    }}>Меню
                    </Text>
                  )
                }
                {
                  user && isAddressVisibleLayout && selectedDeliveryPoint && (
                    <Col style={{ marginLeft: 30 }}>
                      <NavLink to={links.map}>
                        <Button
                          // text={
                          //   `${user.addresses.find((address) => address.address_id && address.address_id == (selectedDeliveryPoint && selectedDeliveryPoint.address_id)
                          //     || address.restaurant_guid && address.restaurant_guid == (selectedDeliveryPoint && selectedDeliveryPoint.restaurant_guid)
                          //   ).street}, ${user.addresses.find((address) => {
                          //     if (address.address_id) {
                          //       return address.address_id == (selectedDeliveryPoint && selectedDeliveryPoint.address_id)
                          //     }
                          //     if (address.restaurant_guid) {
                          //       return address.restaurant_guid == (selectedDeliveryPoint && selectedDeliveryPoint.restaurant_guid)
                          //     }
                          //   }).house}`
                          // }
                          text={
                            `${
                              selectedDeliveryPoint.street
                                ? formatAddress(selectedDeliveryPoint)
                                : (selectedDeliveryPoint.address && selectedDeliveryPoint.address.length > 25)
                                  ? `${selectedDeliveryPoint.address.slice(0, 25)}...`
                                  : selectedDeliveryPoint.address
                            }`
                          }
                          icon="mapPin"
                          secondary
                          isSmall
                          flex
                        />
                      </NavLink>
                    </Col>
                  )
                }
              </Col>
              <Col className={cx(s.logoContainer, user && user.addresses && user.addresses.length > 0 && isAddressVisibleLayout && s.logoContainerWithoutPadding)}>
                <NavLink to={links.home}>
                  <Box>
                    <Icon name="logoWithText" style={{ width: isMobile ? 134 : 193 }} />
                    {/* <Icon name="logoNewYear" style={{ width: isMobile ? 150 : 215 }} /> */}
                  </Box>
                </NavLink>
              </Col>
              <Col className={s.selectWrapper}>
                <Box className={s.searchContainer}>
                  <SearchModal isMobile={isMobile} />
                </Box>
                <Box ml={isMobile ? 'xs' : 'xsm'}>
                  {
                    isMobile ? (
                      <Button
                        type="icon"
                        icon={isAccountDrawerVisible ? 'close' : 'account'}
                        isSmall
                        handleClick={() => {
                          if (isMenuDrawerVisible) {
                            setMenuDrawerVisible(false)
                          }
                          setAccountDrawerVisible(!isAccountDrawerVisible)
                        }}
                      />
                    ) : (
                      <Dropdown
                        trigger={['hover']}
                        overlay={isLoggedIn ? menu : loginLink}
                        overlayClassName={isLoggedIn ? s.dropdownContainer : s.smallContainer}
                        placement="bottomRight"
                        onClick={() => {
                          history.push(isLoggedIn ? links.myData : links.login)
                        }}
                      >
                        <Button
                          type="icon"
                          icon="account"
                          isSmall={isMobile}
                        />
                      </Dropdown>
                    )
                  }
                </Box>
              </Col>
            </>
          )
        }
      </Row>
    </Box>
  )
}

const PageHeader = compose(
  withViewport,
)(PageHeaderComponent)

export default connect((state) => ({
  user: state.app.user,
  sections: state.app.sections,
  isLoggedIn: state.app.user && state.app.user.type !== 'guest',
  restaurants: getRestaurantsFromCities(state.app.cities),
  selectedDeliveryPoint: getDeliveryPointById(state.app.selectedDeliveryPointId, state.app.user && state.app.user.addresses || [], getRestaurantsFromCities(state.app.cities)),
}))(PageHeader)

