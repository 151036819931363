exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1NZw4jjVqMR-PXjMiJxF-0 {\n  font-family: 'Circe-Bold', serif;\n  font-size: 32px;\n  line-height: 24px;\n  margin-bottom: 16px;\n  color: #3C3C3C;\n}\n@media (min-width: 414px) {\n  ._1NZw4jjVqMR-PXjMiJxF-0 {\n    font-size: 40px;\n    line-height: 59px;\n  }\n}\n._3q7tbddFV7EGKw0WH-lFhx {\n  font-family: 'Circe-Regular', serif;\n  font-size: 14px;\n  line-height: 29px;\n  color: #9CA092;\n}\n._2Ydr08zscDhant3NzP1LcJ {\n  font-family: 'Circe-Regular', serif;\n  font-size: 14px;\n  line-height: 29px;\n  color: #303231;\n}\n.Agu9Hhg44kRHcbzOzokhL {\n  width: 100%;\n  margin-bottom: 30px;\n}\n._1P3h_pUdtVSk_4b5LaaVnF {\n  width: 100%;\n  height: 52px;\n  background: #F7F9F8;\n  border-radius: 20px;\n  display: flex;\n  align-items: center;\n  padding: 0 20px;\n  justify-content: space-between;\n}\n@media (max-width: 375px) {\n  ._1P3h_pUdtVSk_4b5LaaVnF {\n    padding: 0 16px;\n  }\n}\n@media (min-width: 1025px) {\n  ._1P3h_pUdtVSk_4b5LaaVnF {\n    max-width: 705px;\n  }\n}\n._1P3h_pUdtVSk_4b5LaaVnF .ant-switch {\n  min-width: 39px;\n  background-color: #DBE3DF;\n}\n._1P3h_pUdtVSk_4b5LaaVnF .ant-switch:focus {\n  box-shadow: none;\n}\n._1P3h_pUdtVSk_4b5LaaVnF .ant-switch-handle {\n  height: 19px;\n}\n._1P3h_pUdtVSk_4b5LaaVnF .ant-switch-checked .ant-switch-handle {\n  left: calc(100% - 17px - 2px);\n}\n._1P3h_pUdtVSk_4b5LaaVnF .ant-switch-checked {\n  background-color: #2B5E51;\n}\n._9s-f82fbkUmUOrL2RnvLB {\n  font-family: 'Circe-Regular', serif;\n  font-size: 16px;\n  line-height: 19px;\n  color: #303231;\n}\n", ""]);

// Exports
exports.locals = {
	"sectionTitle": "_1NZw4jjVqMR-PXjMiJxF-0",
	"subText": "_3q7tbddFV7EGKw0WH-lFhx",
	"subTextDark": "_2Ydr08zscDhant3NzP1LcJ",
	"modificationsList": "Agu9Hhg44kRHcbzOzokhL",
	"switchContainer": "_1P3h_pUdtVSk_4b5LaaVnF",
	"text": "_9s-f82fbkUmUOrL2RnvLB"
};