exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3ktzVza4n6fYndd719MOSb {\n  height: 56px;\n  background: #F7F9F8;\n  border-radius: 10px;\n}\n._3ktzVza4n6fYndd719MOSb input,\n._3ktzVza4n6fYndd719MOSb textarea,\n._3ktzVza4n6fYndd719MOSb select {\n  background: #F7F9F8;\n}\n._1ZELSl8GNntMdXIFrlln4 {\n  box-sizing: border-box;\n  border-radius: 6px;\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n._1ZELSl8GNntMdXIFrlln4 > div {\n  display: flex;\n  align-items: center;\n}\n._1ZELSl8GNntMdXIFrlln4 img {\n  margin-right: 20px;\n}\n._1ZELSl8GNntMdXIFrlln4 input {\n  width: 20px;\n  height: 20px;\n}\n._1ZELSl8GNntMdXIFrlln4 .ant-radio-button-wrapper {\n  padding: 0;\n  background: transparent;\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 56px;\n  right: 0;\n  bottom: 0;\n  z-index: 100;\n  border: none;\n  pointer-events: all;\n}\n._1ZELSl8GNntMdXIFrlln4 .ant-radio-button-wrapper:after {\n  content: \"\";\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n  background: #E1EDE7;\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  right: 22px;\n}\n._1ZELSl8GNntMdXIFrlln4 .ant-radio-button-wrapper:not(:first-child)::before {\n  display: none;\n}\n._2gZaSN6QsJs63lG0LmY3TO {\n  font-family: 'Circe-Regular', serif;\n  font-size: 17px;\n  line-height: 19px;\n  color: #303231;\n}\n._2w4w8IS58QE__a7PPmgkLo {\n  position: absolute;\n  right: 0px;\n  top: 50%;\n  z-index: 999;\n  transform: translateY(-50%);\n}\n._2OeCciMyUp-EIms3Xlw2qX {\n  margin-right: 1px;\n}\n.pTXsMLhV5eWUsctnFXh-E {\n  width: 40px;\n  object-fit: contain;\n}\n", ""]);

// Exports
exports.locals = {
	"greenContainer": "_3ktzVza4n6fYndd719MOSb",
	"paymentContainer": "_1ZELSl8GNntMdXIFrlln4",
	"paymentText": "_2gZaSN6QsJs63lG0LmY3TO",
	"iconChecked": "_2w4w8IS58QE__a7PPmgkLo",
	"middot": "_2OeCciMyUp-EIms3Xlw2qX",
	"paymentSystemIcon": "pTXsMLhV5eWUsctnFXh-E"
};