exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1FVDaA3eTgL0hB-343bCyo {\n  color: #2B5E51;\n}\n.ant-layout-header {\n  padding: 0;\n}\n._1RICSjR0Yz1aJO3p1lTG-T {\n  display: flex;\n  width: fit-content;\n  justify-content: space-between;\n}\n._36B_02StHo95jIW825PbVQ {\n  flex-wrap: wrap;\n  width: 100%;\n}\n._36B_02StHo95jIW825PbVQ div,\n._36B_02StHo95jIW825PbVQ button,\n._36B_02StHo95jIW825PbVQ a {\n  width: 100%;\n}\n._35XWwhsrlGwOYARxyyTcyA .ant-select-arrow {\n  top: 23px !important;\n  right: 15px !important;\n}\n.HBFwoPRq2jrF_hEb9SLzz {\n  height: 240px;\n  margin-bottom: 0;\n}\n._2Jbge5ZvMQD44HCQeanXRH {\n  height: 150px;\n  margin-bottom: 0;\n}\n", ""]);

// Exports
exports.locals = {
	"text": "_1FVDaA3eTgL0hB-343bCyo",
	"buttonsWrapper": "_1RICSjR0Yz1aJO3p1lTG-T",
	"mobileButtonsWrapper": "_36B_02StHo95jIW825PbVQ",
	"searchContainer": "_35XWwhsrlGwOYARxyyTcyA",
	"storyteller": "HBFwoPRq2jrF_hEb9SLzz",
	"storytellerMobile": "_2Jbge5ZvMQD44HCQeanXRH"
};